import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { CalendarIcon, ArrowDownIcon, IconCross } from "assets/icons.js";
import styled from "styled-components";
import moment from "moment";

const StyledDateRangePickerUI = styled.div`
  font-family: Montserrat Alternates-semiBold;
  font-size: 15px;
  line-height: 13px;
  width: 100%;
  svg {
    cursor: pointer;
    font-size: 16px;
    vertical-align: top;
  }
  input {
    font-family: Montserrat Alternates-semiBold;
    font-size: 11px;
    line-height: 13px;
    color: #777980;
    vertical-align: top;
    padding: 5px;
    padding-top: 8px;
    padding-left: 10px;
    background-color: #232631;
  }
  .DateRangePickerInput {
    background-color: #232631;
    display: inline-block;
    border: 1px solid #707070;
    height: 30px;
  }
  .DateRangePickerInput__showClearDates {
    padding-right: 0px;
  }
  .DateInput {
    width: 128px;
    vertical-align: top;
  }
  .DateInput_input__focused {
    border-bottom: 2px solid #e5eced;
  }
  .DateRangePickerInput_arrow_svg {
    vertical-align: top;
    fill: #8e8686;
    height: 24px;
    width: 24px;
  }
  .DateRangePicker_picker__directionLeft {
    left: 50px;
  }
  .CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 32px;
    padding-bottom: 37px;
    caption-side: initial;
  }

  .DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: top;
    color: #a19191;
  }
  .DateRangePickerInput__showClearDates {
    padding-right: 30px;
  }
  .DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    top: 50%;
    right: 6%;
    transform: translateY(-50%);
  }
  .DateRangePickerInput_clearDates {
    right: 0%;
    padding: 3px;
  }
  .CalendarDay__selected_span {
    background: #232631; //background
    color: white; //text
    border: 1px solid $light-red; //default styles include a border
  }
`;
export default function DateRangePickerUI({
  handleDate,
  childFunc,
  startDate,
  endDate,
  focusedInput,
  setFocusedInput,
  setStartDate,
  setEndDate,
}) {
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [focusedInput, setFocusedInput] = useState(null);

  // useEffect(() => {
  //   if (childFunc) {
  //     childFunc.current = handleClear;
  //   }
  // }, []);
  // const handleClear = () => {
  //   setStartDate(null);
  //   setEndDate(null);
  // };
  return (
    <>
      <StyledDateRangePickerUI>
        <DateRangePicker
          startDate={startDate}
          startDateId="s_id"
          endDate={endDate}
          endDateId="e_id"
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
            handleDate(moment(startDate).toDate(), moment(endDate).toDate());
          }}
          isOutsideRange={() => false}
          // initialVisibleMonth={() => moment().subtract(2, "M")}
          // isOutsideRange ={ day => day.isAfter(moment()) || day.isBefore(moment().subtract(2, "Y"))}
          focusedInput={focusedInput}
          startDatePlaceholderText="MM/DD/YYYY"
          endDatePlaceholderText="MM/DD/YYYY"
          onFocusChange={(e) => setFocusedInput(e)}
          displayFormat="MM/DD/YYYY"
          showClearDates={true}
          customCloseIcon={<IconCross />}
          customInputIcon={<CalendarIcon />}
          inputIconPosition="after"
          noBorder
        />
      </StyledDateRangePickerUI>
    </>
  );
}
