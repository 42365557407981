import Card from "ui/Card";
import styled from "styled-components";
import { InsightsIcon } from "assets/icons.js";
import Loader from "ui/Loader";
import { useInsights } from "queries/stats";
import moment from "moment";
import ReactTooltip from "react-tooltip";

const StyledInsightIcon = styled.div`
  margin-right: 10px;
  svg {
    font-size: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-right: 5px;
  }
`;
const StyledList = styled.ul`
  li {
    word-break: break-word;
  }
`;
function PerformanceInsightsCard(props) {
  const data = props?.data;

  return (
    <Card>
      <Card.Header>{props.title}</Card.Header>
      <Card.Body>
        {props?.isLoading ? (
          <Loader />
        ) : (
          <>
            <StyledList>
              <li data-tip data-for="trainerDetails">
                <StyledInsightIcon>
                  <InsightsIcon />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Last tainer :{" "}
                {moment(data?.trainerLastRun?.updatedAt).format("YYYY-MM-DD")}
                <ReactTooltip
                  id="trainerDetails"
                  place={"bottom"}
                  className="tooltip"
                  html={false}
                >
                  {Object.keys(
                    data?.trainerLastRun ? data?.trainerLastRun : {}
                  ).map((key) => (
                    <div>
                      {key} : {data?.trainerLastRun[key]}
                    </div>
                  ))}
                </ReactTooltip>
              </li>
              <li data-tip data-for="forecastDetails">
                <StyledInsightIcon>
                  <InsightsIcon />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Last forecast :{" "}
                {moment(data?.predictorLastRun?.updatedAt).format("YYYY-MM-DD")}
                <ReactTooltip
                  id="forecastDetails"
                  place={"bottom"}
                  className="tooltip"
                  html={false}
                >
                  {Object.keys(
                    data?.predictorLastRun ? data?.predictorLastRun : {}
                  ).map((key) => (
                    <div>
                      {key} : {data?.predictorLastRun[key]}
                    </div>
                  ))}
                </ReactTooltip>
              </li>
              <li>
                <StyledInsightIcon>
                  <InsightsIcon />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Payments optimized : {data?.optimisedPayments}
              </li>
              <li>
                <StyledInsightIcon>
                  <InsightsIcon />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Forcasted Invoices : {data?.forcastedInvoices}
              </li>
            </StyledList>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default PerformanceInsightsCard;
