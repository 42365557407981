import React, { useEffect, useState } from "react";
import { StatusBar } from ".";

export default function Danger({message}) {

  return (
      <>
        <StatusBar style={{background:"#ff4444"}}>
        <span>{message}</span>
        <strong className="close">X</strong>
        </StatusBar>
      </>
  );
}