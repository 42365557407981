import React, { useState, useContext, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Card from "ui/Card";
import { BasicButton } from "ui/Buttons";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";
import { useHistory } from "react-router-dom";
import StepperComponent from "../Stepper";
//import StatusBarComponent from "ui/StatusBar";
import { UIContext } from "context/UIContext";
import SupplierListing from "./SupplierListing";
import { Route_URLs } from "routesURLs";
import { useProfile } from "queries/profile";

const StyledLabel = styled.span`
  font-family: Montserrat Alternates;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #909090;
  opacity: 1;
  margin-bottom: 8px;
  display: inline-block;
`;
export const StyledInputWithLabel = styled.div`
  .error-message {
    display: block !important;
  }
`;

export const StyledModal = styled.div`
  .modal-header {
    padding: 1rem 1rem;
    border-bottom: 1px solid #707070;
    margin: 0 10px;
    h1 {
      font-family: Montserrat Alternates-Bold;
      text-align: left;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }
    .account-verification {
      font-family: Montserrat Alternates;
      font-size: 11px;
      line-height: 20px;
      color: #909090;
      margin-right: 30px;
      margin-left: 5px;
    }
    svg {
      path {
        fill: #33c2df;
      }
    }
  }
`;
export const ModalSection = styled.div`
  border-bottom: 1px solid #707070;
  padding: 10px;
  .range {
    font-family: Montserrat Alternates;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }
  .labels {
    font-family: Montserrat Alternates;
    font-size: 10px;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.55);
  }
  .inner-div {
    display: flex;
    align-items: baseline;
    .form-field {
      margin-bottom: 0px;
    }
  }

  .modal-input {
    height: 25px !important;
    border-radius: 0px !important;
    margin: 0px 5px !important;
    font-size: 9px !important;
  }
  .align-right {
    text-align: right;
  }
`;

export const ApprovalReminders = styled.div`
  background-color: rgba(255, 255, 255, 0.18);
  padding: 5px;
`;
export const ApprovalRemindersDiv = styled.div`
  background-color: rgba(255, 255, 255, 0.08);
  padding: 5px;
  margin: 5px 0px;
`;

const StyledCard = styled.div``;
const OuterDiv = styled.div`
  padding: 0px 20px;
  .HeadingText {
    letter-spacing: 0px;
    color: #909090;
    font-family: Montserrat Alternates-Bold;
    font-size: 14px;
    line-height: 18px;
  }
`;

const NavigationButtons = styled.div`
  .back-button {
    float: left;
    background: none;
    border: 2px solid #909090;
  }
  .next-button {
    float: right;
  }
  .skip-button {
    float: right;
    background: none;
    border: 2px solid #909090;
  }
  padding: 20px;
`;

function SupplierPayment(props) {
  let history = useHistory();
  const { onBoarding, setOnBoarding } = useContext(UIContext);
  const { data: userData, isLoading } = useProfile();
  const userRole = userData?.role;
  return (
    <>
      <PrivateLayout>
        <Page>
          <Page.Header>
            <Row className="m-0">
              <Col className="p-0">
                {/* <StatusBarComponent /> */}
                <h1 className="page-title">Supplier Payment Approvals</h1>
                <StepperComponent currentPage={onBoarding ? 6 : 8} />
              </Col>
            </Row>
          </Page.Header>
          <Page.Body>
            <StyledCard>
              <Card>
                <Card.Body>
                  <OuterDiv>
                    <SupplierListing />
                  </OuterDiv>
                </Card.Body>
              </Card>
            </StyledCard>

            <NavigationButtons>
              {userRole != 'simplipay' && <BasicButton
                className="back-button"
                type="button"
                width="110px"
                onClick={() =>
                  history.push(Route_URLs.setup_dispute_management)
                }
              >
                Back
              </BasicButton>}

              <BasicButton
                className="next-button"
                type="button"
                width="180px"
                onClick={(e) => {
                  onBoarding
                    ? history.push(Route_URLs.profile)
                    : history.push(Route_URLs.dashboard);
                }}
              >
                {onBoarding ? "Go to Profile" : "Go to Dashboard"}
              </BasicButton>
            </NavigationButtons>
          </Page.Body>
        </Page>
      </PrivateLayout>
    </>
  );
}
export default SupplierPayment;
