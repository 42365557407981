import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useExpanded,
  useRowSelect,
} from "react-table";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SortIcon } from "assets/icons.js";
import { Table as BSTable } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Loader from "./Loader";
import Tooltip from "ui/Tooltip";
import { InfoIcon } from "assets/icons";

export const StyledSubRows = styled.span`
  content: "${(props) => props.depth}";
  padding-left: ${(props) =>
    (props.depth == 0 && "13px") ||
    (props.depth == 1 && "23px") ||
    `${props.depth * 11}px`};
`;

export const TableWrapper = styled.div`
  position: relative;
  background: #3a3b48 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  padding: 15px 25px;
  border-radius: 5px;
  min-height: 470px;
  .spinner-border.text-secondary {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .styledNoRecord {
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: inline-table;
    top: 151px;
  }
  input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 2px solid #707070;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    margin-top: 3px;
    background-repeat: no-repeat;
    &:after {
      content: "✔";
      color: transparent;
      position: absolute;
      top: -35%;
      right: 0%;
      font-size: 11px;
      padding: 2px;
    }
    &:checked {
      &:after {
        content: "✔";
        color: #33c2df;
        position: absolute;
        top: -35%;
        right: 0%;
        font-size: 11px;
        padding: 2px;
      }
    }
  }
`;
const StyledCheckboxHeader = styled.div`
  margin-top: 3px;
  input {
    background-color: transparent !important;
    border: 2px solid #ffffff !important;
  }
`;

const StyledLabel = styled.span`
  font-family: Montserrat Alternates;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #909090;
  opacity: 1;
  display: inline-block;
`;

export const StyledTable = styled(BSTable)`
  td {
    /* width: 72px; */
    height: 42px;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 25px;
    border-bottom: 1px solid gray;
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 9px;
    }
  }
  th {
    /* width: 72px; */
    height: 42px;
    text-align: center;
    font-family: Montserrat Alternates-semiBold;
    font-size: 11px;
    line-height: 25px;
    color: #ffffff;
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 9px;
    }
  }
  td:first-child {
    text-align: left;
    /* position: sticky;
    left: 0; */
  }
  th:first-child {
    text-align: left;
    /* position: sticky;
    left: 0; */
  }
  /* td:last-child {
    text-align: right;
  }
  th:last-child {
    text-align: right;
  } */
`;
export const TableHead = styled.thead`
  tr {
    background: transparent linear-gradient(90deg, #0081c5 0%, #31c0de 100%) 0%
      0%;
    border-bottom: underline;
    @media (min-width: 768px) and (max-width: 1024px) {
      th {
        background: #0081c5;
      }
    }
    &:hover {
      background: transparent linear-gradient(90deg, #0081c5 0%, #31c0de 100%)
        0% 0% no-repeat padding-box;
      color: #ffffff;
    }
  }
  svg {
    font-size: 8px;
    margin-left: 2px;
  }

  /* th:first-child {
    position: sticky;
    left: 0;
  } */
`;
export const StyledRow = styled.tr`
  color: #b5b5b5;
  cursor: pointer;
  &:hover {
    background-color: #636471;
    color: #ffffff;
  }
`;

export const PaginationWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Pagination = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  ul {
    margin: 10px;
  }

  & li {
    padding: 0.2rem;
    border-radius: 7px;
    padding-bottom: 0px !important;

    a {
      padding: 0.5rem;
    }
  }

  & .active {
    background-color: #33c2df;
  }
  .previous,
  .next {
    background-color: #373743;
  }
`;
const StyledNoRecord = styled.span`
  color: white;
`;
const StyledLoader = styled.div`
  /* font-size:1em; */
`;
const ScrollWindow = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background: #3a3b48;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }
`;
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);
function CashClassificationAccountsTable({
  isloading,
  editId,
  columns,
  data,
  onHeaderClick,
  totalCount,
  handlePageChange,
  initialPage,
  isEdit,
  updateData,
  checkbox,
  updateAccountData
}) {

  function updateAccount(e, Id) {
    let { name, value } = e.target;
    let accs = data;
    accs.forEach((elem) => {
      if (elem.Id === Id) {
        elem[name] = name === 'days' || name === 'amount' ? +value : value;
      }
    });
    updateAccountData(accs);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    gotoPage,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      manualPagination: true,
      initialState: { pageIndex: 0, pageSize: 10 },
      columns,
      data,
      manualSortBy: true,
      editId,
      isEdit,
      updateData,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      checkbox &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            width: 3,
            disableSortBy: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <StyledCheckboxHeader>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </StyledCheckboxHeader>
            ),
            Cell: ({ row }) => (
              <div onClick={(e) => e.stopPropagation()}>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  function getDaysOptions(frequency) {
    if (frequency === 'monthly') {
      // Generate options for month days
      const options = [];
      for (let i = 1; i <= 29; i++) {
        options.push(<option key={i} value={i}>{i}</option>);
      }
      const currentDate = new Date();
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
      options.push(<option value={lastDayOfMonth}>Last day</option>)
      return options;
    } else if (frequency === 'weekly') {
      // Generate options for weekdays as numeric starting from 0
      const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return weekdays.map((day, index) => (
        <option key={index} value={index}>{day}</option>
      ));
    } else if (frequency === 'bi-weekly') {
      // Generate options for 14 days
      const options = [];
      for (let i = 1; i <= 14; i++) {
        options.push(<option key={i} value={i}>{i}</option>);
      }
      return options;
    } else {
      return null;
    }
  }


  const [Pagecount, setPagecount] = useState();
  useEffect(() => {
    let temp = Math.ceil(Math.abs(totalCount / 10));
    setPagecount(temp > 1 ? temp : 1);
    if (totalCount % 10 === 0) {
      const lastPageIndex = temp === 0 ? 0 : temp - 1;
      handlePageChange(lastPageIndex);
    }
  }, [totalCount]);

  return (
    <>
      <TableWrapper className="table-wrapper">
        {isloading ? (
          <Loader />
        ) : (
          <>
            {/* <CustomScrollbars style={{ width: "100%", height: "100%" }}> */}
            <StyledTable borderless {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <StyledRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, pos) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                          {
                            style: {
                              width: column.width,
                              minWidth: pos === 0 ? '24rem' : '6rem',
                              maxWidth: pos === 0 ? '24rem' : '6rem',
                            },
                          }
                        )}
                        onClick={() =>
                          !column.disableSortBy && onHeaderClick(column)
                        }
                      >
                        {column.render("Header")}
                        {column?.Tooltip && (
                          <StyledLabel>
                            <InfoIcon
                              data-tip={column?.Tooltip}
                              data-for="info"
                              style={{ marginLeft: "5px" }}
                            />
                            <Tooltip place="right" id="info" />
                          </StyledLabel>
                        )}
                        {/* <span>{column.isSorted?(column.isSortedDesc ?<SortIcon/>:<SortIcon/>):" "}</span>  */}
                        {!column.disableSortBy && <span>{<SortIcon />}</span>}
                        {/* {<span>{<SortIcon />}</span>} */}
                      </th>
                    ))}
                  </StyledRow>
                ))}
              </TableHead>

              <tbody {...getTableBodyProps()}>
                {!isloading && data.length > 0 ? (
                  <>
                    {page.slice(initialPage * 10, (initialPage + 1) * 10).map((row, index) => {
                      prepareRow(row);
                      let rowVals = row.cells;
                      return (
                        <StyledRow
                          {...row.getRowProps()}
                          onClick={(e) => { e.preventDefault() }
                          }
                        >
                          {
                            rowVals.map((cell, pos) => {
                              return (
                                <td
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.width,
                                      minWidth: pos === 0 ? '24rem' : '6rem',
                                      maxWidth: pos === 0 ? '24rem' : '6rem',
                                    }
                                  })}
                                >
                                  <div style={{
                                    display: 'flex',
                                    justifyContent: pos === 0 ? 'flex-start' : 'center',
                                    alignItems: 'center',
                                    width: '100%'
                                  }}>
                                    {
                                      (cell.column.id === 'frequency' && cell.row.original.recurring) ?
                                        <select
                                          onChange={(e) => updateAccount(e, cell.row.original.Id)}
                                          value={cell.row.original.frequency}
                                          name="frequency"
                                          className="frequency-selector"
                                          style={{ cursor: 'pointer', textAlign: "center", height: "30px" }}
                                        >
                                          <option value="daily">Daily</option>
                                          <option value="weekly">Weekly</option>
                                          <option value="bi-weekly">Bi-Weekly</option>
                                          <option value="monthly">Monthly</option>
                                        </select>
                                        :
                                        (cell.column.id === 'amount' && cell.row.original.recurring) ?
                                          <input
                                            value={cell.row.original.amount}
                                            onChange={(e) => {
                                              const inputValue = e.target.value;
                                              if (/^\d*\.?\d*$/.test(inputValue)) {
                                                updateAccount(e, cell.row.original.Id)
                                              }
                                            }}
                                            id="accounts-incomes-expense-inputs"
                                            type="text"
                                            placeholder="Amount"
                                            name="amount"
                                          />
                                          :
                                          (cell.column.id === 'day' && cell.row.original.recurring) ?
                                            <select
                                              value={cell.row.original.days}
                                              onChange={(e) => updateAccount(e, cell.row.original.Id)}
                                              name="days"
                                              className="frequency-selector"
                                              style={{ textAlign: "center", height: "30px", cursor: cell.row.original.frequency === 'daily' ? 'not-allowed' : 'pointer' }}
                                              disabled={cell.row.original.frequency === 'daily' ? true : false}
                                            >
                                              {getDaysOptions(cell.row.original.frequency)}
                                            </select>
                                            :
                                            cell.render("Cell") === "" ? "-" : cell.render("Cell")
                                    }
                                  </div>
                                </td>
                              );
                            })
                          }
                        </StyledRow>
                      );
                    })}

                  </>
                ) : (
                  <tr className="styledNoRecord">No Records Found</tr>
                )}
              </tbody>
            </StyledTable>
            {/* </CustomScrollbars> */}
            {!isloading && data.length > 0 ? (
              <PaginationWrapper>
                <Pagination>
                  <ReactPaginate
                    // initialPage={initialPage}
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={Pagecount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={6}
                    // onPageChange={(_page) => gotoPage(_page.selected)}
                    onPageChange={(_page) => handlePageChange(_page.selected)}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    forcePage={initialPage}
                  />
                </Pagination>
              </PaginationWrapper>
            ) : (
              <></>
            )}
          </>
        )}
      </TableWrapper >
    </>
  );
}
export default CashClassificationAccountsTable;
