import { useQuery  } from "react-query";
import {
  getCash,
  getOrders,
  getPayables,
  getCashForcast,
  getCollectionEffectiveness,
  getCollections,
  getDaysPayablesOutstanding,
  getDaysSalesOutstanding,
  getInsights,
  getNetCashGraph,
  getOrderForcast,
  getPayableForcast,
  getPayablesAging,
  getPaymentProcessing,
  getPaymentTermsPayables,
  getPaymentTermsReceivables,
  getReceivableForcast,
  getReceivables,
  getReceivablesAging,
  getTopCustomersHighestOrder,
  getTopCustomersHighestOutstanding,
  getTopSuppliersHighestOrder,
  getTopSuppliersHighestOutstanding,
} from "services/stats.services";

export const useCash = () => {
  return useQuery("cash",() =>  getCash());
};


export const useOrders = () => {
  return useQuery("orders", () =>getOrders());
};

export const usePayables = () => {
  return useQuery("payables", () =>getPayables());
};

export const useReceivables = () => {
  return useQuery("receivables",() => getReceivables());
};

export const useCollections = () => {
  return useQuery("collections",() =>  getCollections());
};

export const useReceivableForcast = () => {
  return useQuery("receivableForcast", () => getReceivableForcast());
};

export const useOrderForcast = () => {
  return useQuery("orderForcast",() =>  getOrderForcast());
};

export const usePayableForcast = () => {
  return useQuery("payableForcast",() =>  getPayableForcast());
};

export const useCashForcast = () => {
  return useQuery("cashForcast", () => getCashForcast());
};

export const useDaysSalesOutstanding = () => {
  return useQuery("daysSalesOutstanding",() =>  getDaysSalesOutstanding());
};

export const useDaysPayablesOutstanding = () => {
  return useQuery("daysPayablesOutstanding",() =>  getDaysPayablesOutstanding());
};

export const useNetCashGraph = () => {
  return useQuery("netcash", getNetCashGraph);
};

export const usePaymentTermsPayables = () => {
  return useQuery("paymentTermsPayables", () => getPaymentTermsPayables());
};

export const usePaymentTermsReceivables = () => {
  return useQuery("paymentTermsReceivables",() => getPaymentTermsReceivables());
};

export const useTopCustomersHighestOutstanding = () => {
  return useQuery(
    "topCustomersHighestOutstanding",() => 
    getTopCustomersHighestOutstanding()
  );
};

export const useTopCustomersHighestOrder = () => {
  return useQuery("topCustomersHighestOrder",() =>  getTopCustomersHighestOrder());
};

export const useTopSuppliersHighestOutstanding = () => {
  return useQuery(
    "topSuppliersHighestOutstanding",() => 
    getTopSuppliersHighestOutstanding()
  );
};

export const useTopSuppliersHighestOrder = () => {
  return useQuery("topSuppliersHighestOrder",() =>  getTopSuppliersHighestOrder());
};

export const useReceivablesAging = () => {
  return useQuery("receivablesAging",() =>  getReceivablesAging());
};

export const usePayablesAging = () => {
  return useQuery("payablesAging", () => getPayablesAging());
};
export const useInsights = () => {
  return useQuery("insights", getInsights);
};

export const useCollectionEffectiveness = () => {
  return useQuery("collectionEffectiveness", () => getCollectionEffectiveness());
};

export const usePaymentProcessing = () => {
  return useQuery("paymentProcessing",() =>  getPaymentProcessing());
};
