import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { updateCustomers,updateCustomersBilling } from "services/customer.services";
import customersServices from "services/customers.services";

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ customerId, values }) => updateCustomers(customerId, values),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("customers");
      },
    }
  );
};
export const useUpdateCustomerBillingDetails = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (body) => updateCustomersBilling(body),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("customers");
      },
    }
  );
};

export const GenerateSessionToken = (data) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => customersServices.GenearteSessionTokenData(data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("sessiontoken");
      },
    }
  );
};

export const GenerateCheckSum = (data) => {

  const queryClient = useQueryClient();
  return useMutation(
    (data) => customersServices.GenearteCheckSumData(data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("checksum");
      },
    }
  );
};


export const SaveTransactionPayment = (data) => {

  const queryClient = useQueryClient();
  return useMutation(
    (data) => customersServices.saveTransactionPaymentPage(data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("saveTransactionPayment");
      },
    }
  );

};

export const GetCustomerInvoice = (data) => {

  const queryClient = useQueryClient();
  return useMutation(
    (data) => customersServices.getCustomerInvoiceData(data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("getCustomerInvoiceData");
      },
    }
  );

};

export const SaveTransactionPaymentcustomer = (data) => {

  const queryClient = useQueryClient();
  return useMutation(
    (data) => customersServices.transactionStatusData(data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("saveTransactionPayment");
      },
    }
  );

};

