import axios from "utils/APIv2";
export const getCustomers = async (page, filterData) => {
  const params = {
    page: page,
    limit: "10",
    customer: filterData.customerName,
    company: filterData.company,
    location: filterData.location,
    sortOrder: filterData.sortOrder,
    sortOn: filterData.sortOn,
    emailTemplateId: filterData.emailTemplateId,
    customerType: filterData.customerType,
    isOpenInvoiceCustomers: filterData.isOpenInvoiceCustomers,
  };
  const res = await axios.get(`/customers/manage-notifications`, {
    params: params,
  });
  return res.data;
};

export const getDisputCustomers = async (page, filterData) => {
  let sortOnValue = filterData.sortOn;
  if (filterData.sortOn == "AssigntoUsers") {
    sortOnValue = "dispute_approvers";
  }
  const params = {
    page: page,
    limit: "10",
    customer: filterData.customerName,
    company: filterData.company,
    location: filterData.location,
    sortOrder: filterData.sortOrder,
    sortOn: sortOnValue,
  };
  const res = await axios.get(`/customers/disput-customer`, {
    params: params,
  });
  return res.data;
};
export const updateCustomers = async (customerID, values) => {
  return await axios.put(`/customers/${customerID}`, values);
};
export const uploadInBucket = async (body) => {
  return await axios.post(`/disputefileupload`, body);
};
export const getAllCustomerWithSameTempalteIdBoolean = async (payload) => {
  return await axios.get(
    `/customers/getAllCustomersWithSameEmailTemplateIdBoolean`,
    { params: payload }
  );
};
export const deleteinbucket = async (body) => {
  return await axios.post(`/disputefiledelete`, body);
};
export const updateCustomersBilling = async (data) => {
  return await axios.post(`/customers/update-billing-info`, data);
};
export const getCustomerChildren = async (body) => {
  return await axios.post(`/customers/childrenData`, body);
};
