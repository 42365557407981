import React, { useEffect, useState, useContext, useRef } from "react";
import styled from "styled-components";
import { InfoIcon } from "assets/icons";
import {
  Form,
  InputwithLabel,
  Formik,
  SimpleSelect,
  DatePickerField,
  TimePickerField,
  Radio,
  RadioBlue,
  ErrorMessage,
  Checkbox,
  StyledTimePicker,
} from "ui/forms";
import * as Yup from "yup";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import JSONPretty from "react-json-pretty";
import moment from "moment";
import { UIContext } from "context/UIContext";
import { useUpdateSupplier } from "mutations/updateSupplier";
import { BasicButton } from "ui/Buttons";
import { FieldArray } from "formik";
const StyledLabel = styled.span`
  font-family: Montserrat Alternates;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #909090;
  opacity: 1;
  margin-bottom: 8px;
  display: inline-block;
`;
const ReminderSection = styled.div`
  /* input:not([type="checkbox"]) {
    height: 13px;
    width: 28px;
    margin: 0px 5px;
    background: #3a3b48;
    border: 1px solid #707070;
    font-family: Montserrat Alternates;
    color: #909090;
    &:focus {
      border: 1px solid #707070;
      outline: none;
      color: #3a3b48;
      background-color: #ffffff;
    }
  } */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:not([type="checkbox"]) {
    height: 13px !important;
    padding: 8px !important;
    border-radius: 0px !important;
    margin: 0px 5px !important;
    font-size: 9px !important;
    background: #3a3b48;
    border: 1px solid #707070;
    font-family: Montserrat Alternates;
    color: #909090;
    -moz-appearance: textfield;
    &:focus {
      border: 1px solid #707070;
      outline: none;
      color: #3a3b48;
      background-color: #ffffff;
    }
  }
`;
const Section = styled.div`
  border-bottom: 1px solid #707070;
  padding: 10px;
  .range {
    font-family: Montserrat Alternates;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }
  .labels {
    font-family: Montserrat Alternates;
    font-size: 10px;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.55);
  }
  .inner-div {
    display: flex;
    align-items: baseline;
    .form-field {
      margin-bottom: 0px;
    }
  }

  .modal-input {
    height: 25px !important;
    border-radius: 0px !important;
    margin: 0px 5px !important;
    font-size: 9px !important;
  }
  .align-right {
    text-align: right;
  }
`;
const StyleTimePicker = styled.div`
  .form-field {
    margin-bottom: 0px;
  }
  label {
    display: none;
  }
  input {
    width: 90px !important;
    background: #232631 !important ;
  }
`;

const HeadingText = styled.div`
  font-family: Montserrat Alternates-semiBold;
  font-size: 11px;
  line-height: 25px;
  color: #808186;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: baseline;
  input[type="checkbox"] {
    margin-bottom: -3px;
    margin-right: 5px;
  }
`;
export default function SupplierReminder({ nameFieldKey }) {
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <ErrorHandlingComponent errors={errorMessage} />
      <ReminderSection>
        <Section>
          <HeadingText>Automatic Approval Reminders</HeadingText>
          <CheckboxWrapper>
            <Checkbox
              label="Before the Due date."
              name={`${nameFieldKey}.beforeDueDate.isBeforeDueDate`}
            />
            <InputwithLabel
              style={{ width: "30px" }}
              name={`${nameFieldKey}.beforeDueDate.days`}
              type="number"
            />

            <HeadingText>Days</HeadingText>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Checkbox label="On Due date." name={`${nameFieldKey}.onDueDate`} />
          </CheckboxWrapper>

          <HeadingText>After Due Date</HeadingText>
          <div
            style={{
              margin: "0px 20px",
            }}
          >
            <CheckboxWrapper>
              <Checkbox
                label=""
                name={`${nameFieldKey}.afterDueDate.isAfterDueDate`}
              />
              <InputwithLabel
                style={{ width: "30px" }}
                name={`${nameFieldKey}.afterDueDate.days`}
                type="number"
              />
              <HeadingText>Days</HeadingText>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                label="Periodic Daily"
                name={`${nameFieldKey}.afterDueDate.perodicDaily.isPerodicDaily`}
              />
                     <InputwithLabel
                    style={{ width: "50px" }}
                    name={`${nameFieldKey}.afterDueDate.perodicDaily.hrs`}
                    type="number"
                    placeholder="Hrs"
                    />
                     <InputwithLabel
                    style={{ width: "50px" }}
                    name={`${nameFieldKey}.afterDueDate.perodicDaily.mins`}
                    type="number"
                    placeholder="Mins"
                  />

              <StyleTimePicker>
                <TimePickerField
                  name={`${nameFieldKey}.afterDueDate.selectFixedTime`}
                  // defaultValue={
                  //   values?.afterDueDate.selectFixedTime !== ""
                  //     ? moment(
                  //         values?.afterDueDate.selectFixedTime,
                  //         "HH:mm"
                  //       )
                  //     : ""
                  // }

                  placeholder="select fixed time"
                />
              </StyleTimePicker>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                label="Periodic Weekly"
                name={`${nameFieldKey}.afterDueDate.periodicWeekly.isPerodicWeekly`}
              />
                    <InputwithLabel
                      style={{ width: "32px" }}
                      name={`${nameFieldKey}.afterDueDate.periodicWeekly.days`}
                      type="number"
                    />
                    <HeadingText>Days</HeadingText>
                    <InputwithLabel
                    style={{ width: "36px" }}
                    name={`${nameFieldKey}.afterDueDate.periodicWeekly.hrs`}
                    type="number"
                    placeholder="Hrs"
                    /> 
                    <InputwithLabel
                    style={{ width: "40px" }}
                    name={`${nameFieldKey}.afterDueDate.periodicWeekly.mins`}
                    type="number"
                    placeholder="Mins"
                  />

              {/* // <StyleTimePicker>
              //   <TimePickerField
              //     name={`${nameFieldKey}.afterDueDate.selectFixedDayTime`}
              //     placeholder="select fixed Day-time"
              //     // defaultValue={
              //     //   values?.afterDueDate.selectFixedDayTime !== ""
              //     //     ? moment(
              //     //         values?.afterDueDate.selectFixedDayTime,
              //     //         "HH:mm"
              //     //       )
              //     //     : ""
              //     // }
              //   />
              // </StyleTimePicker> */}
            </CheckboxWrapper>
          </div>
        </Section>
        <Section>
          <HeadingText>
            <InfoIcon style={{ marginRight: "10px" }} />
            If None Checked No Reminder
          </HeadingText>
        </Section>
      </ReminderSection>
    </>
  );
}
