import styled from "styled-components";
import ReactTooltip from "react-tooltip";

const CustomTooltip = styled(ReactTooltip)`
    content: "${(props) => props.place}";
    max-width: 194px !important;
    color: #d2d2d4 !important;
    background: #232631 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 6px #0000000A !important;
    border: 1px solid #707070 !important;
    border-radius: 8px !important;
    font-size: 11px !important;
    line-height: 18px !important;
    font-family:  Montserrat Alternates-semiBold !important;
    letter-spacing: 0px !important;
    z-index: 100000 !important;
    padding:8px 11px !important;
    .multi-line {
       padding :0px !important;
       text-align: justify !important;
    }
     &::after { 
        left:${(props) => props.place === "bottom" && `${props.left}% !important`}
         } 
`;
const position=(pos)=>{
const newposition={top:pos.top, left:pos.left+60}
return newposition;
}
export default function Tooltip({place,id,left="15"}) {
    return (
        <CustomTooltip 
        id={id}
        place={place? place : "bottom"}
        left={left}
        multiline={true}
        effect="solid"
        overridePosition={place === "bottom" && position}
        // data-offset={{top: 10, left: -20}}
       />
    
    );
}