import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import Card from "ui/Card";
import StepperComponent from "../Stepper";
// import StatusBarComponent from "ui/StatusBar";
import { withRouter, useHistory } from "react-router-dom";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";
import { BasicButton } from "ui/Buttons";
import UsersListing from "screens/setup/user-management/UserListing";
import { UIContext } from "context/UIContext";
import { Route_URLs } from "routesURLs";

const OuterDiv = styled.div`
  padding: 0px 20px;
  .HeadingText {
    letter-spacing: 0px;
    color: #909090;
    font-family: Montserrat Alternates-Bold;
    font-size: 18px;
    line-height: 22px;
  }
`;

const StyledCard = styled.div`
  .modal-header {
    padding: 1rem 1rem;
    border-bottom: 1px solid #707070;
    h1 {
      font-family: Montserrat Alternates-Bold;
      text-align: left;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }
  }
`;

const NavigationButtons = styled.div`
  .back-button {
    float: left;
    background: none;
    border: 2px solid #909090;
  }
  .next-button {
    float: right;
  }
  .skip-button {
    float: right;
    background: none;
    border: 2px solid #909090;
  }
  padding: 20px;
`;

function UserManagement(props) {
  const { onBoarding } = useContext(UIContext);
  let history = useHistory();
  const [page, setPage] = useState(0);
  const [initialPage, setinitialPage] = useState(0);

  const handlePageChange = (page) => {
    setPage(page);
    setinitialPage(page);
  };

  return (
    <PrivateLayout>
      <Page>
        <Page.Header>
          <Row className="m-0">
            <Col className="p-0">
              {/* <StatusBarComponent /> */}
              <h1 className="page-title">User Management</h1>
              <StepperComponent currentPage={onBoarding ? 3 : 8} />
            </Col>
          </Row>
        </Page.Header>
        <Page.Body>
          <StyledCard>
            <Card>
              <Card.Body>
                <OuterDiv>
                  {/* User Listing */}
                  <UsersListing />
                </OuterDiv>
              </Card.Body>
            </Card>
          </StyledCard>

          {/* <!--- Modal ---> */}
          <NavigationButtons>
            <BasicButton
              className="back-button"
              type="button"
              width="110px"
              onClick={() => history.push(Route_URLs.setup_cash_classification)}
            >
              Back
            </BasicButton>

            <BasicButton
              className="next-button"
              type="button"
              width="110px"
              onClick={() =>
                history.push(Route_URLs.setup_customer_notification)
              }
            >
              Next
            </BasicButton>
           
          </NavigationButtons>
        </Page.Body>
      </Page>
    </PrivateLayout>
  );
}
export default UserManagement;
