import { createGlobalStyle } from "styled-components";

import Montserrat from "./MontserratAlternates-Regular.ttf";
import MontserratBold from "./MontserratAlternates-Bold.ttf";
import MontserratMedium from "./MontserratAlternates-Medium.ttf";
import MontserratSemiBold from "./MontserratAlternates-SemiBold.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: 'Montserrat Alternates';
        src: local('Montserrat Alternates'),
        url(${Montserrat}) format('truetype');
        
    }
    @font-face {
        font-family: 'Montserrat Alternates-Bold';
        src: local('Montserrat Alternates'),
        url(${MontserratBold}) format('truetype');
        
    }
    @font-face {
        font-family: 'Montserrat Alternates-semiBold';
        src: local('Montserrat Alternates'),
        url(${MontserratSemiBold}) format('truetype');
        
    }
    @font-face {
        font-family: 'Montserrat Alternates-Medium';
        src: local('Montserrat Alternates'),
        url(${MontserratMedium}) format('truetype');
        
    }
   
`;
