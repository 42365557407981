import styled  from "styled-components";
import { Popover as StyledPopover } from "ui/Popover";

export const Popover = styled(StyledPopover)`
  .popover-body {
    padding: 1rem 1rem;
  }
  .popover-arrow {
    &:before {
      left: 40px;
    }
    &:after {
      left: 40px;
    }
  }
`;
export const Label = styled.span`
  text-align: left;
  font-family: Montserrat Alternates-semiBold;
  font-size: 11px;
  line-height: 25px;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  color: #808186;
`;
export const ClearButton = styled.button`
  border: 1px solid #707070;
  background-color: #232631;
  height: 25px;
  color: #ffffff69;
  margin-bottom: 10px;
`;
export const StyledButton = styled.button`
  width: 142px;
  height: 40px;
  background: #3a3b48 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  text-align: left;
  font-family: Montserrat Alternates-semiBold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 12px;
  svg {
    margin-left: 55%;
  }
`;
export const Reset = styled.div`
  margin-left: 1px;
  background: #3a3b48 0% 0% no-repeat padding-box;
  color: #ffffff;
  width: 80px;
  height: 28px;
  padding: 6px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
export const Clear = styled.div`
  margin-left: 1px;
  background: #3a3b48 0% 0% no-repeat padding-box;
  color: #ffffff;
  width: 80px;
  height: 28px;
  padding: 6px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 14px;
`;
export const StyleFilter = styled.div``;
export const StyleBusinessFilter = styled.div`
  .react-select-container {
    width: 316px;
  }

  button {
    font-family: Montserrat Alternates;
    border: 1px solid #ffffff;
    background-color: #232631;
    border-radius: 0px;
    width: 100%;
    height: 25px;
    color: #ffffff;
    margin-bottom: 10px;
    &:focus {
      outline: none;
      border: 1px solid #ffffff;
      background-color: #232631;
    }
  }

  input[type="checkbox"] {
    margin-bottom: 3px;
    background-repeat: no-repeat;
    &:after {
      content: "\u2713";
      color: #232631;
      position: absolute;
      top: -20%;
      font-size: 11px;
      padding: 2px;
    }
    &:checked {
      &:after {
        content: "\u2713";
        color: #33c2df;
        position: absolute;
        top: -20%;
        font-size: 11px;
        padding: 2px;
      }
    }
  }
`;
export const StyleSelect = styled.div`
  .react-select-container .react-select__menu-list {
    max-height: 260px;
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #000;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .css-b62m3t-container .css-huhgh0-control {
    border: 1px solid #ffffff69;
  }
  .css-b62m3t-container .css-v0ntz1-placeholder {
    color: #ffffff69;
    font-family: Montserrat Alternates;
    font-size: 10px;
    line-height: 13px;
  }
  .css-b62m3t-container .css-pdsopu-indicatorContainer {
    svg {
      path {
        fill: #ffffff69;
      }
    }
  }
`;
export const StyledCheckbox = styled.div`
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 0px 4px;
    background-repeat: no-repeat;
    background-color: #232631;
    border-radius: 3px;

    &:checked {
      background-color: #232631;
      border-color: #909090;
      background-repeat: no-repeat;
    }
  }
  label {
    color: #ffffff69;
    font-family: Montserrat Alternates-semiBold;
    font-size: 10px;
    line-height: 13px;
    margin-left: 5px;
  }
`;
