import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { getQuickBooksDesktopSettings } from "services/quickbooks.services";

export const useQuickBooksDesktopSaveSettings = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => getQuickBooksDesktopSettings(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("quickBooksDesktopSettings");
    },
  });
};
