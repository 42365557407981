import React, { useEffect, useState } from "react";
import { StatusBar } from ".";
import { WhiteInfoIcon } from "assets/icons";

export default function Warning({ message }) {
  return message && message.trim() != "" ? (
    <>
      <StatusBar style={{ background: "#ff8101" }}>
        <WhiteInfoIcon
          style={{ marginRight: "10px" }}
          fontSize="17px"
          color={"#ffffff"}
        />
        <span>{message}</span>
      </StatusBar>
    </>
  ) : null;
}
