import { useQuery } from "react-query";
import operationsService from "services/operations.service";

export const useGetTenantsForLogin = (filterData) => {
  return useQuery(
    ["loginTenants"],
    () => operationsService.getTenantsForLogin(),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useEndCustomers = () => {
  return useQuery(
    ["EndCustomer"],
    () => operationsService.getCustomersForLogin(),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useGetTenantCustomers = (tenantName) => {
  return useQuery(
    ["tenantCustomer"],
    () => operationsService.getTenantUsers(tenantName),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useGetInsights = (tenantName) => {
  return useQuery(
    ["tenantInsights"],
    () => operationsService.getInsights(tenantName),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useGetDaily = (tenantName) => {
  return useQuery(
    ["tenantDaily"],
    () => operationsService.getDailys(tenantName),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useGetMetrics = (tenantName) => {
  return useQuery(
    ["tenantMetrics"],
    () => operationsService.getMetrics(tenantName),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useGetReportData = (tenantName) => {
  return useQuery(
    ["tenantReport"],
    () => operationsService.getReportData(tenantName),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useGetUsers = () => {
  return useQuery(["superUsers"], () => operationsService.getUsers(), {
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });
};
