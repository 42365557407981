import styled from "styled-components";
import { Modal } from "ui/Modal";
import UserForm from "./_UserForm";
import { useCreateUser } from "mutations/createUser";
import { useState } from "react";

const StyledModal = styled.div`
  .modal-header {
    padding: 1rem 1rem;
    border-bottom: 1px solid #707070;
    margin: 10px;
    h1 {
      font-family: Montserrat Alternates-Bold;
      text-align: left;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }
  }
`;

export default function AddUserModal({
  show,
  user,
  onHide,
  refetch,
  userRolesData,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const userAdd = useCreateUser();

  const onSuccess = (values) => {
    let serverUser = {
      givenName: values.firstName,
      familyName: values.lastName,
      phoneNumber: values.mobile,
      email: values.email,
      role: values.access,
      status: values.status,
      permissions: values.permissions,
      isMfaEnabled: false,
      // business: values.business?.map((department) =>{return(parseInt(department))}),
    };
    userAdd
      .mutateAsync(serverUser)
      .then(() => {
        refetch();
        onHide();
        setErrorMessage(null);
      })
      .catch(function (error) {
        if (error.response) {
          setErrorMessage(error.response.data.message);
        }
      });
  };

  return (
    <Modal show={show} size="lg" centered onHide={onHide}>
      <StyledModal>
        <Modal.Header closeButton>
          <h1>Add User</h1>
        </Modal.Header>
        <Modal.Body>
          <UserForm
            onSuccess={onSuccess}
            errorMessage={errorMessage}
            userRolesData={userRolesData}
          />
        </Modal.Body>
      </StyledModal>
    </Modal>
  );
}
