import axios from "utils/APIv2";
import { apiURL } from "utils/base-url";
export const getSuppliers = async (page, filterData) => {
  const params = {
    page: page,
    limit: "10",
    supplier: filterData.supplierName,
    company: filterData.company,
    sortOrder: filterData.sortOrder,
    sortOn: filterData.sortOn,
  };
  const res = await axios.get(`/suppliers`, {
    params: params,
  });
  return res.data;
};

export const updateSupplier = async (supplierID, values) => {
  return await axios.post(`/suppliers/stripe/${supplierID}`, values);
};


export const supplierapprovalsetting = async (values) => {
  return await axios.post(`/suppliers/approvalsettings`,values);
};


export const disputaddd = async (values) => {
  return await axios.post(`/suppliers/disputdataadd`,values);
};


export const remindermail = async (values) => {
  return await axios.post(`/emailAlert/adhocEmailAlert`,values);
};


export const disputadddFromAdmin = async (values) => {
  return await axios.post(`/suppliers/admin/disputdataadd`,values);
};




export const asigndUserAdd = async (values) => {
  return await axios.post(`/customers/add/approvers`,values);
};



export const asigndUserAddFordisputType = async (values) => {
  return await axios.post(`/customers/add/disput-approvers`,values);
};

export const adddiscount = async (values) => {
  return await axios.post(`/suppliers/add-updatediscount`, values);
};
export const getdiscount = async (values) => {
  return await axios.post(`/suppliers/supplierdiscountbyid?supplierid=${values}`);
};

export const getbase64 = async (invoiceNo,userId) => {
  return await axios.get(`/qbotest/invoicepdf?invoice=${invoiceNo}&userId=${userId}`,{});
};

export const getCustomersDisputData = async (invoiceNumber,userId) => {

  let body={
    invoiceNumber: invoiceNumber,
    userId: userId,
  }
  return await axios.post(`/suppliers/disputfetchdata`,body);
};


export const seennotification = async (body) => {

  return await axios.post(`/notification/seen`,body);
};

export const seennotificationadmin = async (body) => {

  return await axios.post(`/admin/notification/seen`,body);
};

export const getCustomersDisputDataFromAdmin = async (invoiceNumber) => {

  let body={
    invoiceNumber: invoiceNumber,
  }
  return await axios.post(`/suppliers/admin/disputfetchdata`,body);
};


export const usersearchdata = async (body) => {
  return await axios.post(`/customer/getdataformsearch`,body);
};

export const getmanagesettingdaata = async (body) => {
  return await axios.post(`/customers/supplierssetting`,body);
};

export const managesettingdataadd = async (body) => {
  return await axios.post(`/customers/add/supplierssetting`,body);
};


export const disputecatagory = async (body) => {
  return await axios.post(`/customers/fetch/disput-type-data`);
};

export const fetchapproveduser = async (body) => {
  return await axios.post(`/customers/get/approvers`);
};

export const manageedefaultassignedusers = async (body) => {
  return await axios.post(`/customers/finddefaultdata`);
};

export const manageedefaultusers = async (body) => {
  return await axios.post(`/customers/get/defaultadduser`);
};

export const manageedefaultusersadd = async (body) => {
  return await axios.post(`/customers/add/managedefaultuser`,body);
};

export const supplierStrategicAdd = async (body) => {
  return await axios.post(`/suppliers/update_strategic_status`,body);
};

export const cashflowforecastedit = async (body) => {
  return await axios.post(`/datewiseforcastupdate`,body);
};
export const updateBillPaymentDate = async (body) => {
  return await axios.post(`/update_payment_date`,body);
};
export const billDownload = async (body) => {
  return await axios.post(`/add_temp_optimize_payment_data`,body);
};

export const getSupplierDiscount = async (body) => {
  return await axios.get(`/suppliers/supplierdiscountbyid`, {params: body});
};















