import { useMutation, useQuery, useQueryClient  } from "react-query";
import businessServices from "services/business.services";
// import { addbusiness,getallbusiness } from "services/business.services";


export const Businessadd = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) =>
      businessServices.addbusiness(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("businessadd");
        },
      }
    );
  };


  export const useAllbusiness = (filterData, mo = false) => {
    return useQuery(
      ["business", { filterData }],
      () => businessServices.getallbusiness(filterData),
      {
        keepPreviousData: true,
        staleTime: 60 * 60 * 1000,
      }
    );
  };



  export const BusinessDisputTypeAsignUserAdd = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) =>
      businessServices.asigndUserAddFordisputType(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("assignduser-disputtype");
        },
      }
    );
  };