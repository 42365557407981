import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { InfoIcon } from "assets/icons";
import {
  Form,
  InputwithLabel,
  Formik,
  TimePickerField,
  Checkbox,
} from "ui/forms";
import moment from "moment";
import { BasicButton } from "ui/Buttons";
import { useState } from "react";
import axios from "utils/APIv2";
import {
  ManageSettingsData,
  ManageSettingsDataAdd,
} from "mutations/adddiscount";
import Tooltip from "ui/Tooltip";

const ReminderSection = styled.div`
  /* input:not([type="checkbox"]) {
    height: 13px;
    width: 28px;
    margin: 0px 5px;
    background: #3a3b48;
    border: 1px solid #707070;
    font-family: Montserrat Alternates;
    color: #909090;
    &:focus {
      border: 1px solid #707070;
      outline: none;
      color: #3a3b48;
      background-color: #ffffff;
    }
  } */

  input:not([type="checkbox"]) {
    height: 13px !important;
    padding: 8px !important;
    border-radius: 0px !important;
    margin: 0px 5px !important;
    font-size: 9px !important;
  }
`;
const StyleTimePicker = styled.div`
  .form-field {
    margin-bottom: 0px;
  }
  label {
    display: none;
  }
  input {
    width: 90px !important;
    background: #232631 !important ;
  }
`;

const HeadingText = styled.div`
  font-family: Montserrat Alternates-semiBold;
  font-size: 11px;
  line-height: 25px;
  color: #808186;
`;
const Section = styled.div`
  border-bottom: 1px solid #707070;
  padding: 10px;
  padding-top: 2px;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: baseline;
  input {
    background: transparent 0% 0% no-repeat padding-box !important;
    color: #808186 !important;
  }
  input[type="checkbox"] {
    margin-bottom: -3px;
    margin-right: 5px;
    background: transparent 0% 0% no-repeat padding-box;
    &:after {
      color: transparent;
    }
    &:checked {
      &:after {
        color: #33c2df;
      }
    }
  }
`;
export default function CustomerReminder({
  customer,
  childFunc,
  handleReminderSettings,
  handleReminderDefaults,
  errorMessage,
  initialValues,
  closeModal,
  childEmailCheck,
  parentRecord,
}) {
  const formRef = useRef();
  const isEmailTemplateSelected = customer?.preferences?.emailTemplateId;
  const fetchData = ManageSettingsData();
  const dataAdd = ManageSettingsDataAdd();
  const [beforeCheckvalue, setBeforeCheckvalue] = useState(false);
  const [beforeDueDatedays, setbeforeDueDatedays] = useState(null);

  const [onDueDateCheckvalue, setOnDueDateCheckvalue] = useState(false);
  const [isAfterDueDateCheckvalue, setIsAfterDueDateCheckvalue] =
    useState(false);
  const [isOnSelectedDateValue, setIsOnSelectedDateValue] = useState(false);
  const [isParentOnlyEnable, setIsParentOnlyEnable] = useState(false);
  const [isOnSelectedDayOfWeekValue, setIsOnSelectedDayOfWeekValue] =
    useState(false);

  // const [isPerodicDailyCheckvalue, setIsPerodicDailyCheckvalue] =
  //   useState(false);
  // const [isPerodicWeeklyCheckvalue, setisPerodicWeeklyCheckvalue] =
  //   useState(false);
  // const [periodicWeeklydays, setperiodicWeeklydays] = useState(null);
  // const [periodicWeeklyhrs, setperiodicWeeklyhrs] = useState(null);
  // const [periodicWeeklymins, setperiodicWeeklymins] = useState(null);
  // const [selectFixedTime, seselectFixedTime] = useState(null);
  // const [perodicDailymins, setperodicDailymins] = useState(null);
  // const [perodicDailyhrs, setperodicDailyhrs] = useState(null);

  const [afterDueDatedy, setafterDueDatedy] = useState(null);
  const [onSelectedDueDate, setOnSelectedDueDate] = useState(null);
  const [onSelectedDueDayOfWeek, setOnSelectedDueDayOfWeek] =
    useState("monday");

  const daysArray = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [reminderRowData, setreminderRowData] = useState(null);
  const [children, setChildren] = useState([]);

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = async () => {
    try {
      let body = {
        customer_id: customer?.Id,
      };

      let res = await fetchData.mutateAsync({ body });
      if (res && res?.data?.data) {
        setreminderRowData(res?.data?.data);
        setBeforeCheckvalue(
          res?.data?.data?.beforeDueDate?.isBeforeDueDate ? true : false
        );
        setbeforeDueDatedays(
          res?.data?.data?.beforeDueDate?.days
            ? res?.data?.data?.beforeDueDate?.days
            : 1
        );

        setOnDueDateCheckvalue(res?.data?.data?.onDueDate ? true : false);

        setIsAfterDueDateCheckvalue(
          res?.data?.data?.afterDueDate?.isAfterDueDate
        );
        setafterDueDatedy(
          res?.data?.data?.afterDueDate?.days
            ? res?.data?.data?.afterDueDate?.days
            : 1
        );

        setIsOnSelectedDateValue(
          res?.data?.data?.customerLevel?.isOnSelectedDate
        );
        setIsOnSelectedDayOfWeekValue(
          res?.data?.data?.customerLevel?.isOnSelectedDayOfWeek
        );
        setOnSelectedDueDate(
          res?.data?.data?.customerLevel?.monthDate
            ? res?.data?.data?.customerLevel?.monthDate
            : 1
        );
        setOnSelectedDueDayOfWeek(
          res?.data?.data?.customerLevel?.weekdays.toLocaleLowerCase()
            ? res?.data?.data?.customerLevel?.weekdays.toLocaleLowerCase()
            : "monday"
        );
        setIsParentOnlyEnable(res?.data?.data?.isParentOnlyEnable);
        // setIsPerodicDailyCheckvalue(res?.data?.data?.afterDueDate.perodicDaily);
        // setperodicDailyhrs(res?.data?.data?.afterDueDate.selectFixedDayTimeHour);
        // setperodicDailymins(res?.data?.data?.afterDueDate.selectFixedDayTimeMins);
        // setisPerodicWeeklyCheckvalue(res?.data?.data?.afterDueDate.periodicWeekly);
        // setperiodicWeeklydays(res?.data?.data?.afterDueDate.selectFixedTimeDay.toLocaleLowerCase());
        // setperiodicWeeklyhrs(res?.data?.data?.afterDueDate.selectFixedTimeHour);
        // setperiodicWeeklymins(res?.data?.data?.afterDueDate.selectFixedTimeMins);
      }
      if (res?.data?.childrens) {
        setChildren(res?.data?.childrens ? res?.data?.childrens : []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (childFunc) {
      childFunc.current = handleSubmit;
    }
  }, []);

  const handleSubmit = () => {};

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          {
            handleReminderDefaults && handleReminderDefaults(values);
          }

          const body = {
            _id: reminderRowData?._id ? reminderRowData._id : undefined,
            customer_id: customer?.Id,
            counterPartyId: customer?.Id,

            manageSettings: {
              onDueDate: onDueDateCheckvalue,
              beforeDueDate: {
                isBeforeDueDate: beforeCheckvalue,
                days: +beforeDueDatedays,
              },
              afterDueDate: {
                days: +afterDueDatedy,
                isAfterDueDate: isAfterDueDateCheckvalue,
                // perodicDaily: isPerodicDailyCheckvalue,
                // periodicWeekly: isPerodicWeeklyCheckvalue,
                // selectFixedTimeDay: periodicWeeklydays,
                // selectFixedTimeHour: +periodicWeeklyhrs,
                // selectFixedTimeMins: +periodicWeeklymins,
                // selectFixedDayTimeHour: +perodicDailyhrs,
                // selectFixedDayTimeMins: +perodicDailymins
              },
              customerLevel: {
                isOnSelectedDate: isOnSelectedDateValue,
                isOnSelectedDayOfWeek: isOnSelectedDayOfWeekValue,
                weekdays: onSelectedDueDayOfWeek,
                monthDate: +onSelectedDueDate,
              },
              isParentOnlyEnable: isParentOnlyEnable,
            },

            // isbefore_due_date: beforeCheckvalue,
            // before_due_date_days: beforeDueDatedays,

            // is_on_due_date: onDueDateCheckvalue,

            // isAfterDueDate: isAfterDueDateCheckvalue,
            // after_due_date_days: afterDueDatedy,

            // isPerodicDaily: isPerodicDailyCheckvalue,
            // perodicDaily_hrs: perodicDailyhrs,
            // perodicDaily_mins: perodicDailymins,

            // isPerodicWeekly: isPerodicWeeklyCheckvalue,
            // perodicWeekly_days: periodicWeeklydays,
            // perodicWeekly_hrs: periodicWeeklyhrs,
            // perodicWeekly_mins: periodicWeeklymins,
          };
          // const res = await axios.post(`/customers/add/supplierssetting`, body);

          let res = await dataAdd.mutateAsync({ body });
          closeModal();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          dirty,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            {(customer && customer?.email) || childEmailCheck ? (
              <ReminderSection>
                <>
                  <Section>
                    <HeadingText>Automatic Email Reminders</HeadingText>

                    {/*******************************Before the Due date*************************************** */}

                    <CheckboxWrapper>
                      <input
                        type="checkbox"
                        id="beforeduDate"
                        label="Before the Due date."
                        name="beforeduDate"
                        checked={beforeCheckvalue}
                        disabled={
                          isEmailTemplateSelected !== "" &&
                          isEmailTemplateSelected !== undefined &&
                          isEmailTemplateSelected !== null
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setBeforeCheckvalue(true);
                          } else {
                            setBeforeCheckvalue(false);
                          }
                        }}
                      />
                      {/* <label style={{ color: "#808186" }} for="beforeduDate">
                        {" "}
                        Before the Due date.
                      </label> */}

                      <input
                        type="number"
                        min={1}
                        placeholder="date"
                        style={{ width: "40px" }}
                        id="beforeDueDatedays"
                        name="beforeDueDatedays"
                        value={beforeDueDatedays}
                        disabled={
                          isEmailTemplateSelected !== "" &&
                          isEmailTemplateSelected !== undefined &&
                          isEmailTemplateSelected !== null
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          setbeforeDueDatedays(e.target.value);
                        }}
                      />

                      <label style={{ color: "#808186" }}>
                        days before the due date
                      </label>
                    </CheckboxWrapper>

                    {/*******************************On Due date section*************************************** */}

                    <CheckboxWrapper>
                      <input
                        type="checkbox"
                        id="onDueDate"
                        name="onDueDate"
                        checked={onDueDateCheckvalue}
                        disabled={
                          isEmailTemplateSelected !== "" &&
                          isEmailTemplateSelected !== undefined &&
                          isEmailTemplateSelected !== null
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setOnDueDateCheckvalue(true);
                          } else {
                            setOnDueDateCheckvalue(false);
                          }
                        }}
                      />
                      <label style={{ color: "#808186" }}>On Due date.</label>
                    </CheckboxWrapper>

                    {/*******************************After Due Date*************************************** */}

                    <HeadingText>After Due Date</HeadingText>
                    <div
                      style={{
                        marginLeft: "16px",
                      }}
                    >
                      <CheckboxWrapper>
                        <input
                          type="checkbox"
                          id="isAfterDueDate"
                          name="isAfterDueDate"
                          checked={isAfterDueDateCheckvalue}
                          disabled={
                            isEmailTemplateSelected !== "" &&
                            isEmailTemplateSelected !== undefined &&
                            isEmailTemplateSelected !== null
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setIsAfterDueDateCheckvalue(true);
                            } else {
                              setIsAfterDueDateCheckvalue(false);
                            }
                          }}
                        />
                        <label style={{ color: "#808186" }}>Every </label>

                        <input
                          type="number"
                          min={1}
                          placeholder="date"
                          style={{ width: "40px" }}
                          id="afterDueDatedy"
                          name="afterDueDatedy"
                          value={afterDueDatedy}
                          disabled={
                            isEmailTemplateSelected !== "" &&
                            isEmailTemplateSelected !== undefined &&
                            isEmailTemplateSelected !== null
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            setafterDueDatedy(e.target.value);
                          }}
                        />
                        <label style={{ color: "#808186" }}>
                          {" "}
                          days till invoice is paid.
                        </label>
                      </CheckboxWrapper>
                      {/* <CheckboxWrapper>
                        <input
                          type="checkbox"
                          id="isPerodicDaily"
                          name="isPerodicDaily"
                          checked={isPerodicDailyCheckvalue}
                          disabled={(isEmailTemplateSelected !== '' && isEmailTemplateSelected !== undefined) ? false : true}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setIsPerodicDailyCheckvalue(true);
                            } else {
                              setIsPerodicDailyCheckvalue(false);
                            }
                          }}
                        />
                        <label
                          style={{ color: "#808186" }}
                          for="isPerodicDaily"
                        >
                          {" "}
                          Periodic Daily.
                        </label>

                        <StyleTimePicker>
                          <input
                            type="number"
                            style={{ width: "35px" }}
                            id="perodicDailyhrs"
                            name="perodicDailyhrs"
                            value={perodicDailyhrs}
                            placeholder="Hrs"
                            disabled={(isEmailTemplateSelected !== '' && isEmailTemplateSelected !== undefined) ? false : true}
                            onChange={(e) => {
                              setperodicDailyhrs(e.target.value);
                            }}
                          />

                          <input
                            type="number"
                            style={{ width: "25px" }}
                            id="perodicDailymins"
                            name="perodicDailymins"
                            value={perodicDailymins}
                            placeholder="Mins"
                            disabled={(isEmailTemplateSelected !== '' && isEmailTemplateSelected !== undefined) ? false : true}
                            onChange={(e) => {
                              setperodicDailymins(e.target.value);
                            }}
                          />
                        </StyleTimePicker>
                      </CheckboxWrapper>

                      <CheckboxWrapper>
                        <input
                          type="checkbox"
                          id="isPerodicWeekly"
                          name="isPerodicWeekly"
                          checked={isPerodicWeeklyCheckvalue}
                          disabled={(isEmailTemplateSelected !== '' && isEmailTemplateSelected !== undefined) ? false : true}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setisPerodicWeeklyCheckvalue(true);
                            } else {
                              setisPerodicWeeklyCheckvalue(false);
                            }
                          }}
                        />
                        <label
                          style={{ color: "#808186" }}
                          for="isPerodicWeekly"
                        >
                          {" "}
                          Periodic Weekly.
                        </label>
                        <select
                          id="periodicWeeklydays"
                          name="periodicWeeklydays"
                          value={periodicWeeklydays}
                          disabled={(isEmailTemplateSelected !== '' && isEmailTemplateSelected !== undefined) ? false : true}
                          onChange={(e) => {
                            setperiodicWeeklydays(e.target.value);
                          }}
                        >
                          {daysArray.map((elem) => (
                            <option value={elem.toLocaleLowerCase()}>
                              {elem}
                            </option>
                          ))}
                        </select>
                        <HeadingText>Days</HeadingText>

                        <StyleTimePicker>
                          <input
                            type="number"
                            style={{ width: "35px" }}
                            id="periodicWeeklyhrs"
                            name="periodicWeeklyhrs"
                            value={periodicWeeklyhrs}
                            placeholder="Hrs"
                            disabled={(isEmailTemplateSelected !== '' && isEmailTemplateSelected !== undefined) ? false : true}
                            onChange={(e) => {
                              setperiodicWeeklyhrs(e.target.value);
                            }}
                          />

                          <input
                            type="number"
                            style={{ width: "35px" }}
                            id="periodicWeeklymins"
                            name="periodicWeeklymins"
                            value={periodicWeeklymins}
                            placeholder="Mins"
                            disabled={(isEmailTemplateSelected !== '' && isEmailTemplateSelected !== undefined) ? false : true}
                            onChange={(e) => {
                              setperiodicWeeklymins(e.target.value);
                            }}
                          />
                        </StyleTimePicker>
                      </CheckboxWrapper> */}
                    </div>
                    <HeadingText>Customer Level</HeadingText>
                    <CheckboxWrapper>
                      <input
                        type="checkbox"
                        id="isOnSelectedDate"
                        name="isOnSelectedDate"
                        checked={isOnSelectedDateValue}
                        disabled={
                          isEmailTemplateSelected !== "" &&
                          isEmailTemplateSelected !== undefined &&
                          isEmailTemplateSelected !== null
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setIsOnSelectedDateValue(true);
                          } else {
                            setIsOnSelectedDateValue(false);
                          }
                        }}
                      />

                      <label style={{ color: "#808186" }}>Day </label>
                      <input
                        type="number"
                        min={1}
                        style={{ width: "40px" }}
                        placeholder="date"
                        id="onSelectedDueDate"
                        name="onSelectedDueDate"
                        value={onSelectedDueDate}
                        disabled={
                          isEmailTemplateSelected !== "" &&
                          isEmailTemplateSelected !== undefined &&
                          isEmailTemplateSelected !== null
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          setOnSelectedDueDate(e.target.value);
                        }}
                      />
                      <label style={{ color: "#808186" }}> of each month</label>
                    </CheckboxWrapper>

                    <CheckboxWrapper>
                      <input
                        type="checkbox"
                        id="isOnSelectedDayOfWeek"
                        name="isOnSelectedDayOfWeek"
                        checked={isOnSelectedDayOfWeekValue}
                        disabled={
                          isEmailTemplateSelected !== "" &&
                          isEmailTemplateSelected !== undefined &&
                          isEmailTemplateSelected !== null
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setIsOnSelectedDayOfWeekValue(true);
                          } else {
                            setIsOnSelectedDayOfWeekValue(false);
                          }
                        }}
                      />

                      <select
                        id="onSelectedDueDayOfWeek"
                        name="onSelectedDueDayOfWeek"
                        value={onSelectedDueDayOfWeek}
                        disabled={
                          isEmailTemplateSelected !== "" &&
                          isEmailTemplateSelected !== undefined &&
                          isEmailTemplateSelected !== null
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          setOnSelectedDueDayOfWeek(e.target.value);
                        }}
                      >
                        {daysArray.map((elem) => (
                          <option value={elem.toLocaleLowerCase()}>
                            {elem}
                          </option>
                        ))}
                      </select>
                      <label style={{ color: "#808186" }}> of every week</label>
                    </CheckboxWrapper>
                    {parentRecord && (
                      <CheckboxWrapper>
                        <input
                          type="checkbox"
                          id="isParentOnlyEnable"
                          name="isParentOnlyEnable"
                          checked={isParentOnlyEnable}
                          disabled={
                            isEmailTemplateSelected !== "" &&
                            isEmailTemplateSelected !== undefined &&
                            isEmailTemplateSelected !== null
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setIsParentOnlyEnable(true);
                            } else {
                              setIsParentOnlyEnable(false);
                            }
                          }}
                        />
                        <HeadingText>Enable email for parent only</HeadingText>
                      </CheckboxWrapper>
                    )}
                  </Section>
                  <Section>
                    <HeadingText>
                      <InfoIcon style={{ marginRight: "10px" }} />
                      If None checked, No reminders are set
                    </HeadingText>
                    {(isEmailTemplateSelected === "" ||
                      isEmailTemplateSelected === undefined ||
                      isEmailTemplateSelected === null) && (
                      <HeadingText>
                        <InfoIcon style={{ marginRight: "10px" }} />
                        No Template is selected, select a template first
                      </HeadingText>
                    )}
                    {children.length > 1 ? (
                      <HeadingText>
                        <InfoIcon
                          style={{ marginRight: "10px" }}
                          data-tip={children.join(", ")}
                          data-for="info"
                        />
                        <Tooltip place="bottom" id="info" />
                        {customer.company} have {children.length} children
                      </HeadingText>
                    ) : null}
                  </Section>
                  <BasicButton
                    style={{ float: "right", margin: "20px" }}
                    type="submit"
                    width="110px"
                  >
                    Save
                  </BasicButton>
                </>
              </ReminderSection>
            ) : (
              <>
                <ReminderSection>
                  <div
                    style={{
                      color: "white",
                      textAlign: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Email id is missing.
                  </div>
                </ReminderSection>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
