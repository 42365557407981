import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { updateUser } from "services/user.service";

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(({ userId, values }) => updateUser(userId, values), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries("user");
    },
    // onError: (error) => {
    //   if (error.response && error.response.data.message) {
    //     alert(error.response.data.message);
    //   }
    // },
  });
};
