import BaseService from "./baseClass.services";
import axios from "utils/APIv2";
import { apiURL } from "utils/base-url";
class SuppliersService extends BaseService {
  getsuppliersListing = async (filterData) => {
    const params = {
      field: filterData.columnName,
      sort: filterData.order,
      pageNo: filterData.pageNumber,
      limit: 10,
      supplierName: filterData.searchString,
    };
    const res = await axios.get(`/suppliers/listing`, { params });

    return res.data;
  };
  getSuppliersListExport = (filterData) => {
    return `${apiURL}/suppliers/export?supplierName=${
      filterData.searchString
    }&key=${sessionStorage.getItem("userToken")}`;
  };
}
export default new SuppliersService();
