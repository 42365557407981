import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Multiselect from "multiselect-react-dropdown";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { values } from "lodash";
import { useDepartments } from "queries/departments";

const StyledSelect = styled.div`
  .react-select-container .react-select__menu-list {
    max-height: 150px;
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #000;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .react-select-container {
    position: absolute;
    background: #232631;
    border: 1px solid #707070;
    z-index: 99;
    color: #ffffff69;
  }
  .react-select-container .react-select__control {
    height: 25px;
    border: 1px solid #707070;
    background: #232631;
    color: #ffffff69;
    outline: none;
    box-shadow: none;
    font-size: 10px;
    line-height: 13px;
    &:hover {
      outline: none;
      box-shadow: none;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .css-1g6gooi {
    color: #ffffff69;
  }
  .react-select-container .react-select__menu {
    background: #232631;
  }
  .react-select-container .react-select__control--menu-is-open {
    background: #232631;
  }
  button {
    width: 240px;
    height: 40px;
    background: #3a3b48 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #707070;
    border-radius: 20px;
    text-align: left;
    font-family: Montserrat Alternates-semiBold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 12px;
  }
  .react-select-container .react-select__option {
    background: #232631;
    font-size: 10px;
    line-height: 13px;
    color: #ffffff69;
    display: flex;
    align-items: center;
    padding-top:2px;
  }
  input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #232631;
    border: 1px solid #707070;
    display: inline-block;
    width: 14px;
    height: 14px;

    background-repeat: no-repeat;
    &:after {
      content: "✔";
      color: #232631;
      position: absolute;
      top: -20%;
      font-size: 11px;
      padding: 2px;
    }
    &:checked {
      &:after {
        content: "✔";
        color: #33c2df;
        position: absolute;
        top: -20%;
        font-size: 11px;
        padding: 2px;
      }
    }
  }
`;
// const getDepartments = async () => {
//   const res = await axios.get(
//     `https://dev2-api.simplicapital.ai/api/departments`,
//     {
//       headers: {
//         organisation: "kilowott",
//       },
//     }
//   );
//   return res.data;
// };
function SelectWithCheckbox({ handleBusiness, childFunc,placeholder="Business (Search and select)" }) {
  const [value, setValue] = useState(null);
  const queryClient = useQueryClient();

  // const { data: departments, isLoading } = useQuery(
  //   "departments",
  //   getDepartments
  // );
  const { data: departments, isLoading } = useDepartments();
 
  useEffect(() => {
    if (childFunc) {
      childFunc.current = handleClear;
    }
  }, []);

  const setDepartmentData = (obj) => {
    let result = Object.keys(obj).map((key) => obj[key].value);
    handleBusiness(result);
    setValue(obj);
  };
  const handleClear = () => {
    setValue([]);
  };
  // const object = departments.map((department) => ({
  //   label: department.fullyQualifiedName,
  //   value: department.id,
  // }));

  const reformattedArray = departments?.map(({ fullyQualifiedName, id }) => ({
    label: fullyQualifiedName,
    value: id,
  }));

  const options = [
    { label: "Thing 1", value: 1 },
    { label: "Thing 2", value: 2 },
    { label: "Thing 3", value: 3 },
    { label: "Thing 4", value: 4 },
    { label: "Thing 5", value: 5 },
    { label: "Thing 6", value: 6 },
    { label: "Thing 7", value: 7 },
    { label: "Thing 8", value: 8 },
    { label: "Thing 9", value: 9 },
    { label: "Thing 10", value: 10 },
    { label: "Thing 11", value: 11 },
    { label: "Thing 12", value: 12 },
  ];

  return (
    <>
      <StyledSelect>
        <ReactMultiSelectCheckboxes
          value={value}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholderButtonLabel={placeholder}
          options={reformattedArray}
          onChange={(value) => setDepartmentData(value)}
          isClearable={true}
        />
      </StyledSelect>
    </>
  );
}
export default SelectWithCheckbox;
