import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { setAllGeneralSettings } from "services/generalSettings.services";

export const useAddGeneralSettings = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => setAllGeneralSettings(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("generalSettings");
    },
  });
};
