import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { deleteinbucket, uploadInBucket } from "services/customer.services";

import { adddiscount, asigndUserAdd, asigndUserAddFordisputType, billDownload, cashflowforecastedit, disputaddd, disputadddFromAdmin, disputecatagory, fetchapproveduser, getCustomersDisputData, 
  getCustomersDisputDataFromAdmin, getSupplierDiscount, getbase64, getdiscount, getmanagesettingdaata, manageedefaultassignedusers, manageedefaultusers, 
  manageedefaultusersadd, managesettingdataadd, remindermail, seennotification, seennotificationadmin, supplierStrategicAdd, supplierapprovalsetting, 
  updateBillPaymentDate, updateSupplier, usersearchdata } from "services/supplier.services";




export const useAdddiscounttosuppliers = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({values }) => adddiscount(values),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("suppliers");
      },
    }
  );
};
export const GetBasedata = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({invoiceNo,userId }) => getbase64(invoiceNo,userId),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("pdf");
      },
    }
  );
};


export const useGetdiscount = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({values }) => getdiscount(values),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("suppliers");
        },
      }
    );
  };


  export const Disputdataadd = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) =>
      disputaddd(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("discunadd");
        },
      }
    );
  };

  export const Uploader = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({formData }) =>
      uploadInBucket(formData),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("upload");
        },
      }
    );
  };


  export const Deletefroms3 = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({formData }) =>
      deleteinbucket(formData),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("delete");
        },
      }
    );
  };


  export const Remindersend = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) =>
      remindermail(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("remindersend");
        },
      }
    );
  };






  export const DisputdataaddFromAdmin = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) =>
      disputadddFromAdmin(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("discunadd");
        },
      }
    );
  };


  export const CustomerDisputAsignUserAdd = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) =>
      asigndUserAdd(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("assignduser");
        },
      }
    );
  };

  export const CustomerDisputTypeAsignUserAdd = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) =>
      asigndUserAddFordisputType(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("assignduser-disputtype");
        },
      }
    );
  };



  export const Supplierapprovalsettingaddupdate = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) =>
       supplierapprovalsetting(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("approvalsetting");
        },
      }
    );
  };





  export const CustomersDisputData = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({invoiceNumber,userId }) => getCustomersDisputData(invoiceNumber,userId),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("CustomersDisputData");
        },
      }
    );
  };


  export const Notificationseen = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => seennotification(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("CustomersNotificationseen");
        },
      }
    );
  };

  export const AdminNotificationseen = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => seennotificationadmin(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("AdminNotificationseen");
        },
      }
    );
  };

  export const CustomersDisputDataAdminside = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({invoiceNumber }) => getCustomersDisputDataFromAdmin(invoiceNumber),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("CustomersDisputDataAdminside");
        },
      }
    );
  };


  export const Getdataformsearch = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => usersearchdata(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("Getdataformsearch");
        },
      }
    );
  };


  export const ManageSettingsData = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => getmanagesettingdaata(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("ManageSettingsData");
        },
      }
    );
  };


  export const ManageSettingsDataAdd = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => managesettingdataadd(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("ManageSettingsDataAdd");
        },
      }
    );
  };


  export const FetchDisputeTypeData = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => disputecatagory(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("FetchDisputeTypeData");
        },
      }
    );
  };


  export const ApprovedUsers = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => fetchapproveduser(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("ApprovedUsers");
        },
      }
    );
  };


  export const ManagedefaultAssignedUsers = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => manageedefaultassignedusers(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("ApprovedUsers");
        },
      }
    );
  };


  export const ManagedefaultUsers = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => manageedefaultusers(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("ApprovedUsers");
        },
      }
    );
  };


  export const ManagedefaultUsersAdd = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => manageedefaultusersadd(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("ApprovedUsers");
        },
      }
    );
  };


  export const StrategicAdd = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => supplierStrategicAdd(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("StrategicAdd");
        },
      }
    );
  };





  export const CashFlowForecastEdit = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => cashflowforecastedit(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("CashFlowForecastEdit");
        },
      }
    );
  };

  export const UpdateBillPaymentDate = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => updateBillPaymentDate(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("UpdateBillPaymentDate");
        },
      }
    );
  };


  export const UsePaymentBillData = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => billDownload(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("billDownload");
        },
      }
    );
  };




  export const GetSupplierDiscount = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({body }) => getSupplierDiscount(body),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("getSupplierDiscount");
        },
      }
    );
  };

  








  
