import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import TimePicker from "react-time-picker";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import {
  Form as FForm,
  Formik,
  useField,
  Field,
  useFormikContext,
} from "formik";
import { CalendarIcon, MemoIosTime } from "assets/icons";

export const Form = styled(FForm)`
  width: 100%;
  text-align: left;
  padding: 0;
  margin: 0;

  label {
    font-family: Montserrat Alternates;
    text-align: left;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #909090;
    opacity: 1;
    margin-bottom: 8px;
    display: inline-block;
  }
  select {
    background: #3a3b48 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #707070;
    border-radius: 20px;
    color: #909091;
    height: 40px;
    width: 100%;
    padding: 5px;
    &:focus {
      outline: none;
    }
  }

  .form-field {
    margin-bottom: 15px;
  }

  input:not([type="checkbox"]):not([type="radio"]) {
    width: 100%;
    height: 40px;
    padding: 10px;
    background: #3a3b48 0% 0% no-repeat padding-box;
    /* font: normal normal normal 17px/20px Montserrat Alternates; */
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #707070;
    border-radius: 20px;
    font-size: 14px;
    line-height: 20px;
    font-family: Montserrat Alternates;
    letter-spacing: 0px;
    color: #909090;
    &:focus {
      border: 1px solid #707070;
      outline: none;
      color: #3a3b48;
      background-color: #ffffff;
    }
  }

  textarea {
    width: 100%;
    padding: 10px;
    background: #3a3b48 0% 0% no-repeat padding-box;
    /* font: normal normal normal 17px/20px Montserrat Alternates; */
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #707070;
    border-radius: 10px;
    font-size: 14px;
    line-height: 20px;
    font-family: Montserrat Alternates;
    letter-spacing: 0px;
    color: #909090;
    &:focus {
      border: 1px solid #707070;
      outline: none;
      color: #3a3b48;
      background-color: #ffffff;
    }
  }

  input:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  /* input[type="text"]:valid {
    background-color: #ffffff;
    color: #909090;
    &:focus{
    background-color:white; 
  }
  }
  input[type="text"]:invalid {
    background-color: #3a3b48;
    color: #909090;
  }
  input[type="password"]:valid {
    background-color: #ffffff;
    color: #909090;
  }
  input[type="password"]:invalid {
    background-color: #3a3b48;
    color: #909090;
  }
  input[type="email"]:valid {
    background-color: #ffffff;
    color: #909090;
  }
  input[type="email"]:invalid {
    background-color: #3a3b48;
    color: #909090;
  }
  input[type="number"]:valid {
    background-color: #ffffff;
    color: #909090;
  }
  input[type="number"]:invalid {
    background-color: #3a3b48;
    color: #909090;
  } */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .error {
    border: 2px solid #ff6565;
  }
`;

export const ErrorMessage = styled.span`
  color: #ff7171;
  font-size: 12px;
  margin-top: 10px;
  display: inline-block;
  font-family: Montserrat Alternates-semiBold;
`;
export const ErrorMessageHighThreshold = styled.span`
  color: #ff7171;
  font-size: 12px;
  margin-top: 10px;
  display: inline-block;
  font-family: Montserrat Alternates-semiBold;
  position: absolute;
  bottom: -80%;
  left: 51%;
  width: 46%;
  @media (min-width: 768px) and (max-width: 1024px) {
    bottom: -50%;
    left: 10%;
  }
`;
export const ErrorMessageLowThreshold = styled.span`
  color: #ff7171;
  font-size: 12px;
  margin-top: 10px;
  display: inline-block;
  font-family: Montserrat Alternates-semiBold;

  position: absolute;
  bottom: -30%;
  left: 50%;
  width: 46%;
  @media (min-width: 768px) and (max-width: 1024px) {
    bottom: -50%;
    left: 55%;
  }
`;
const InputWrapper = styled.div`
  .date-picker-icon {
    float: left;
    margin-left: 11px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
  }
`;

export const Input = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="form-field">
      <input
        {...field}
        {...props}
        className={props.className ? props.className : null}
      />
      {meta.error && meta.touched ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </div>
  );
};
export const InputwithLabel = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="form-field">
        <label htmlFor={props.id || props.name}>{label}</label>
        <span style={{ cursor: "pointer" }}>{props.infoIcon}</span>
        <input
          {...field}
          {...props}
          className={props.className ? props.className : null}
        />
        {meta.error && meta.touched ? (
          <ErrorMessage className="error-message">{meta.error}</ErrorMessage>
        ) : null}
      </div>
      <ReactTooltip />
    </>
  );
};
export const ThresholdInputHigh = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <input
        {...field}
        {...props}
        className={props.className ? props.className : null}
        placeholder={"Upper Limit"}
      />
      {meta.error && meta.touched ? (
        <ErrorMessageHighThreshold>{meta.error}</ErrorMessageHighThreshold>
      ) : null}
    </>
  );
};
export const ThresholdInputLow = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <input
        {...field}
        {...props}
        className={props.className ? props.className : null}
        placeholder={"Lower Limit"}
      />
      {meta.error && meta.touched ? (
        <ErrorMessageLowThreshold>{meta.error}</ErrorMessageLowThreshold>
      ) : null}
    </>
  );
};
export const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="form-field">
        <label htmlFor={props.id || props.name}>{label}</label>
        <span style={{ cursor: "pointer" }}>{props.infoIcon}</span>
        <textarea
          {...field}
          {...props}
          rows="4"
          cols="50"
          className={props.className}
          // className={meta.touched && meta.error ? "error" : null}
        />
        {meta.error ? (
          <ErrorMessage className="error-message">{meta.error}</ErrorMessage>
        ) : null}
      </div>
      <ReactTooltip />
    </>
  );
};
export const CustomInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <input
        {...field}
        {...props}
        className={props.className ? props.className : null}
      />
    </>
  );
};
export const SimpleSelect = ({ ...props }) => {
  const { label, name, options, value, placeholder } = props;
  return (
    <div className="form-field">
      <label htmlFor={props.id || props.name}>{props.label}</label>

      <Field value={value} name={name} component="select" {...props}>
        {/* {name === "sector" && (
          <option value="" label="Select sector">
            Select sector
          </option>
        )}
      */}
        <option value="" label={placeholder ? placeholder : "Select an option"}>
          {placeholder ? placeholder : "Select an option"}
        </option>

        {options.map((option) => {
          return (
            <>
              <option value={option.value} id={option.id} key={option.id}>
                {option.label}
              </option>
            </>
          );
        })}
      </Field>
    </div>
  );
};

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <InputWrapper className="form-group">
        <input
          value={props.value}
          className="form-control input"
          style={{ paddingLeft: "28px" }}
          onClick={props.onClick}
          ref={ref}
          // disabled
          // value={props.value}
          // className="form-control input"
          // style={{ paddingLeft: "28px" }}
          // onClick={props.onClick}
          // //onChange={props.onChange}
          // ref={ref}
          // //selected={props.selected}
          disabled={props.disabled}
          // dateFormat={props.dateFormat}
        />
        <CalendarIcon className="date-picker-icon" />
      </InputWrapper>
    );
  });
  return (
    <div className="form-field">
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <DatePicker
        {...field}
        {...props}
        customInput={<CustomInput />}
        placeholder={props.placeholder}
        selected={
          (field.value != null &&
            field.value != "" &&
            field.value.length != 0 &&
            new Date(field.value)) ||
          null
        }
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        autoComplete="off"
        wrapperClassName="styledDatePicker"
      />
    </div>
  );
};
export const StyledTimePicker = styled.div`
  span {
    display: inline;
  }
  input:not([type="checkbox"]):not([type="radio"]) {
    padding-left: 28px;
  }
  .date-picker-icon {
    float: left;
    margin-top: -25px;
    margin-left: 13px;
    position: relative;
  }
`;

export const TimePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <div className="form-field">
      <label htmlFor={props.id || props.name}>{props.label}</label>
      {/* <TimePicker
          {...field}
          {...props}
          // onChange={(val) => {
          //   setFieldValue(field.name, val);
          // }}
          // value={field.value}
        /> */}
      <StyledTimePicker>
        <TimePicker
          showSecond={false}
          className="xxx"
          format={"hh:mm a"}
          use12Hours
          clearIcon={<></>}
          inputIcon={
            props.icon ? <MemoIosTime className="date-picker-icon" /> : <></>
          }
          onChange={(val) => {
            setFieldValue(field.name, val.format("HH:mm"));
          }}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
        />
        {meta.error && meta.touched ? (
          <ErrorMessage className="error-message">{meta.error}</ErrorMessage>
        ) : null}
      </StyledTimePicker>
    </div>
  );
};
export const StyleRadio = styled.div`
  input,
  label {
    cursor: pointer;
  }

  fieldset {
    display: inline-flex;
    margin-right: 20px;
  }
  label {
    text-align: left;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #909090;
    margin-left: 8px;
  }
  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: transparent;
    background-clip: content-box;
    padding: 4px;
    border: 2px solid #ffffff;
    &:checked {
      background-color: white;
      &:after {
        background-color: white;
      }
    }
  }
`;
export const Radio = (props) => {
  const { label, name, options, ...rest } = props;
  const [field, meta] = useField(props);
  return (
    <div className="form-field">
      <StyleRadio>
        <Field name={name} {...rest}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <fieldset key={option.key}>
                  <input
                    type="radio"
                    {...field}
                    id={option.id}
                    value={option.value}
                    checked={field.value === option.value}
                    onChange={props.onChange ? props.onChange : field.onChange}
                    disabled={props.disabled ? props.disabled : false}
                    onClick={props.onClick ? props.onClick : field.onClick}
                  />
                  <label htmlFor={option.id}>{option.key}</label>
                </fieldset>
              );
            });
          }}
        </Field>
      </StyleRadio>
      {meta.error && meta.touched ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </div>
  );
};
export const StyleRadioBlue = styled.div`
  input,
  label {
    cursor: pointer;
  }

  fieldset {
    display: inline-flex;
    margin-right: 20px;
  }
  label {
    text-align: left;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #909090;
    margin-left: 8px;
  }
  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: transparent;
    background-clip: content-box;
    padding: 4px;
    border: 2px solid #ffffff;
    &:checked {
      border: 4px solid #4edaf7;
      background-color: white;
      padding: 0px;

      &:after {
        background-color: white;
      }
    }
  }
`;
export const RadioBlue = (props) => {
  const { label, name, options, ...rest } = props;
  const [field, meta] = useField(props);
  return (
    <div className="form-field">
      <StyleRadioBlue>
        <Field name={name} {...rest}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <fieldset key={option.key}>
                  <input
                    type="radio"
                    {...field}
                    id={option.id}
                    value={option.value}
                    checked={field.value === option.value}
                    onChange={props.onChange ? props.onChange : field.onChange}
                    disabled={props.disabled ? props.disabled : false}
                    onClick={props.onClick ? props.onClick : field.onClick}
                  />
                  <label htmlFor={option.id}>{option.key}</label>
                </fieldset>
              );
            });
          }}
        </Field>
      </StyleRadioBlue>
      {meta.error && meta.touched ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </div>
  );
};

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #3a3b48;
    border: 1px solid #707070;
    border-radius: 3px;
    display: inline-block;
    width: 14px;
    height: 14px;
    cursor: pointer;

    background-repeat: no-repeat;
    &:after {
      content: "✔";
      color: #3a3b48;
      position: absolute;
      top: -60%;
      font-size: 11px;
      padding: 2px;
      z-index: 4;
    }
    &:checked {
      &:after {
        content: "✔";
        color: #33c2df;
        position: absolute;
        top: -60%;
        font-size: 11px;
        padding: 2px;
        z-index: 4;
      }
    }
  }
  label {
    font-family: Montserrat Alternates-semiBold;
    font-size: 11px;
    line-height: 25px;
    color: #808186;
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const Checkbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <StyledCheckbox className={props.className}>
      <label className={props.required ? "required" : ""}>
        <Field type="checkbox" {...props} />
        {props.label} {children}
      </label>

      {/* {props.ticket ? (
        ""
      ) : meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null} */}
    </StyledCheckbox>
  );
};
const StyledPhoneInput = styled.div`
  .PhoneInput {
    border: 1px solid #707070;
    border-radius: 20px;
    margin-bottom: 15px;
    padding-left: 10px;
    background-color: #3a3b48;
  }
  .PhoneInputInput {
    border: none !important;
  }
  .PhoneInputCountrySelectArrow {
    /* color: white; */
    opacity: 1;
    font-size: 22px;
    margin-left: 14px;
    margin-bottom: 4px;
  }
  .PhoneInputCountry {
    border-right: 1px solid #707070;
    padding: 7px;
  }
`;
export const PhoneInputField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <div className="form-field">
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <StyledPhoneInput>
        <PhoneInput
          {...field}
          {...props}
          defaultCountry="US"
          international
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
        />
      </StyledPhoneInput>
      {meta.error && meta.touched ? (
        <ErrorMessage className="error-message">{meta.error}</ErrorMessage>
      ) : null}
    </div>
  );
};
export { Formik, useField, Field };
