import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import { ArrowDownWhiteIcon } from "assets/icons.js";
import Select, { components } from "react-select";

const IndicatorsContainer = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownWhiteIcon width="11px" height="5px" />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  control: (base) => ({
    ...base,
    background: "#232631 0% 0% no-repeat padding-box",
    borderRadius: 0,
    borderColor: "#FFFFFF",
    boxShadow: null,
    minHeight: "25px",
    height: "25px",
    "&:hover": {
      background: "#3a3b48 0% 0% no-repeat padding-box",
    },
    "&:focus": {
      background: "#3a3b48 0% 0% no-repeat padding-box",
    },
  }),
  input: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    color: " #FFFFFF",
    textAlign: "left",
    fontFamily: "Montserrat Alternates-semiBold",
    fontSize: "11px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: "8px",
    paddingLeft: 0,
  }),
  option: (base, { isFocused }) => ({
    ...base,
    color: "#ffffff69",
    boxShadow: "0px 3px 46px #00000029",
    border: "0px solid #FFFFFF",
    borderRadius: "0px",
    height: "25px",
    background: isFocused ? "#3a3b48" : "#232631 0% 0% no-repeat padding-box",
    display: "flex",
    alignItems: "center",
  }),
  valueContainer: (base) => ({
    ...base,
    color: "#FFFFFF",
    paddingTop: 0,
  }),
  placeholder: (base) => ({
    ...base,
    color: " #FFFFFF",
    textAlign: "left",
    fontFamily: "Montserrat Alternates-semiBold",
    fontSize: "11px",
  }),

  singleValue: (base) => ({
    ...base,
    color: " #FFFFFF",
    textAlign: "left",
    fontFamily: "Montserrat Alternates-semiBold",
    fontSize: "11px",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    margin: 0,
    padding: 0,
    background: "#232631 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 46px #00000029",
    border: "1px solid #FFFFFF",
    color: "#FFFFFF",
  }),
};
export default function FilterSelect({
  options,
  value,
  onChange,
  placeholder = "Search & Select",
  name = "business",
}) {
  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder={placeholder}
      defaultValue={" "}
      styles={customStyles}
      onChange={onChange}
      value={value}
      components={{
        IndicatorsContainer,
        IndicatorSeparator: () => null,
      }}
      options={options}
    />
  );
}
