import React from "react";
import styled, { css } from "styled-components";
import { OverlayTrigger, Button, Row, Col, Form } from "react-bootstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import { useState, useRef, useEffect, useCallback } from "react";
import { CalendarIcon, ArrowDownIcon, IconCross } from "assets/icons.js";
import SelectFilter from "ui/SelectFilter";
import {
  StyledCheckbox,
  StyledButton,
  ClearButton,
  StyleSelect,
  Popover,
} from "filters/Style";
import BSCheckbox from "ui/BSCheckbox";

const popoverPanel = (
  handleResetFilter,
  company,
  handleCompanyName,
  handleSupplierName,
  SupplierData,
  supplierName
) => (
  <Popover id="popover-basic">
    <Popover.Body>
      <span className="filter-heading">Supplier</span>
      <StyleSelect>
        <SelectFilter
          classNamePrefix="react-select"
          className="css-b62m3t-container"
          value={supplierName}
          options={SupplierData.map((supplier) => {
            return {
              label: supplier,
              value: supplier,
            };
          })}
          onChange={handleSupplierName}
        />
      </StyleSelect>

      <span className="filter-heading">Company Name</span>
      <input
        className="amount"
        onChange={handleCompanyName}
        value={company}
        placeholder="Search"
        type="text"
      ></input>
      <ClearButton onClick={handleResetFilter} style={{ marginTop: "10px" }}>
        Clear
      </ClearButton>
    </Popover.Body>
  </Popover>
);
const FilterSupplier = ({ handleFilterData, SupplierData }) => {
  const [supplierName, setSupplierName] = useState("");
  const [company, setCompany] = useState("");

  const handleSupplierName = (e) => {
    setSupplierName(e);
    const FilterData = { supplierName: e.value };
    handleFilterData(FilterData);
  };

  const handleCompanyName = (e) => {
    setCompany(e.target.value);
    const FilterData = { company: e.target.value };
    handleFilterData(FilterData);
  };

  const handleResetFilter = () => {
    setSupplierName("");
    setCompany("");
    const FilterData = {
      supplierName: "",
      company: "",
    };
    handleFilterData(FilterData);
  };

  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={popoverPanel(
          handleResetFilter,
          company,
          handleCompanyName,
          handleSupplierName,
          SupplierData,
          supplierName
        )}
      >
        <StyledButton>
          Filter
          <ArrowDownIcon />
        </StyledButton>
      </OverlayTrigger>
    </>
  );
};
export default FilterSupplier;
