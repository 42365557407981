import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Form, Formik, InputwithLabel } from "ui/forms";
import OracleLogo from "assets/images/Oracle_NetSuite_Logo.png";
import QBOLogo from "assets/images/Intuit_QuickBooks_logo.png";
import QBODesktopLogo from "assets/images/quickbook_destop.png";
import BCLogo from "assets/images/logo-business-central.png";
import { IconCheck } from "assets/icons";
import * as Yup from "yup";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";
import Card from "ui/Card";
import StepperComponent from "./Stepper";
import { BasicButton } from "ui/Buttons";
import { useHistory } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { InfoIcon } from "assets/icons.js";
import { Route_URLs } from "routesURLs";
import { Authorise } from "helpers/Authorization";
import { UIContext } from "context/UIContext";
import { useOrnetSaveSettings } from "../../mutations/useOrnetSaveSettings";
import { useQuickBooksSaveSettings } from "mutations/saveQuickBooksSettings";
import { useQuickBooksDesktopSaveSettings } from "mutations/saveQuickBooksDesktopSettings";
import { setOrnetCode } from "services/ornet.services";
import { getUserIntegrations } from "services/user.service";
import { setQboCode } from "services/quickbooks.services";

const StyledLabel = styled.span`
  font-family: Montserrat Alternates;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #909090;
  opacity: 1;
  margin-bottom: 8px;
  display: inline-block;
`;
const StyledCard = styled.div`
  .card {
    background: #444551;
  }
`;
const OuterDiv = styled.div`
  padding: 50px 100px 50px 100px;
  .HeadingText {
    letter-spacing: 0px;
    color: #909090;
    font-family: Montserrat Alternates-Bold;
    font-size: 20px;
    line-height: 24px;
  }
`;

const Innersection = styled.div`
  width: 60%;
`;

const TickIcon = styled.div`
  padding: 5px 6px;
  height: 28px;
  width: 28px;
  background-color: ${(props) => props.BackgroundColor};
  border-radius: 50%;
  margin-right: 10px;
`;
const LogoDiv = styled.div`
  width: 150px;
  height: 67px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 20px;
  padding: 20px 10px;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
`;

const NavigationButtons = styled.div`
  padding: 20px;
  .back-button {
    float: left;
    background: none;
    border: 2px solid #909090;
  }
  .next-button {
    float: right;
  }
`;

function Connection(props) {
  const { onBoarding } = useContext(UIContext);
  const [initialValues, setInitialValues] = useState({});
  const [selectedLogo, setSelectedLogo] = useState();
  const [connection, setConnection] = useState("");
  const [developerPortalLink, setDeveloperPortalLink] = useState("");
  const [connectionDetails, setConnectionDetails] = useState({});
  const [reConnect, setReConnect] = useState(true);
  const [qbdDownloadUrl, setQbdDownloadUrl] = useState("");

  const callBackUrl =
    process.env.REACT_APP_PUBLIC_URL +
    Route_URLs.setup_connect_integration +
    `/${connection}`;

  let history = useHistory();
  let query = useQuery();

  const code = query.get("code");
  const state = query.get("state");
  const realmId = query.get("realmId");
  const entity = query.get("entity");

  const ornetSaveSettings = useOrnetSaveSettings();
  const quickBooksSaveSettings = useQuickBooksSaveSettings();
  const quickBooksDesktopSaveSettings = useQuickBooksDesktopSaveSettings();

  const checkConnection = async () => {
    const connectionResponse = await getUserIntegrations();
    if (
      connectionResponse.data != null ||
      connectionResponse.data?.name != ""
    ) {
      setConnectionDetails(connectionResponse.data);
      if (
        connectionResponse.data.name != "qbd" &&
        connectionResponse.data?.details?.accessToken &&
        connectionResponse.data?.details?.refreshToken
      ) {
        setReConnect(false);
      } else if (connectionResponse.data?.name == "qbd") {
        setReConnect(false);
      } else {
      }
      if (connectionResponse.data?.name == "oracle") {
        setConnection("oracle");
        setSelectedLogo(OracleLogo);
      } else if (connectionResponse.data?.name == "qbo") {
        setConnection("qbo");
        setSelectedLogo(QBOLogo);
      } else if (connectionResponse.data?.name == "qbd") {
        setConnection("qbd");
        setSelectedLogo(QBODesktopLogo);
      }
    }
    setInitialValues(connectionResponse?.data?.details);
    setQbdDownloadUrl(connectionResponse?.data?.details?.downloadUrl);
  };

  const saveOrnetCode = async (code, state, realmId) => {
    let payload = {
      code: code,
      state: state,
      realmId: realmId,
    };
    try {
      await setOrnetCode(payload).then((res) => {
        checkConnection();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const saveQboCode = async (code, state, realmId) => {
    let payload = {
      code: code,
      state: state,
      realmId: realmId,
    };
    try {
      await setQboCode(payload).then((res) => {
        checkConnection();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const saveSettings = async (clientId, clientSecret, companyId) => {
    if (selectedLogo == OracleLogo) {
      let payload = {
        clientId: clientId,
        clientSecret: clientSecret,
        callbackURL: callBackUrl,
        baseURL: `https://${companyId}.suitetalk.api.netsuite.com/services/rest/auth/oauth2/v1/token`,
        refreshTokenURL: `https://${companyId}.suitetalk.api.netsuite.com/services/rest/auth/oauth2/v1/token`,
        companyId: companyId,
        state: "token_security",
        environment: "production",
      };
      ornetSaveSettings.mutateAsync(payload).then((res) => {
        window.location = res.data.uri;
      });
    } else if (selectedLogo == QBOLogo) {
      let payload = {
        clientId: clientId,
        clientSecret: clientSecret,
        callbackURL: callBackUrl,
        baseURL: "quickbooks.api.intuit.com",
        refreshTokenURL:
          "https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer",
        companyId: companyId,
        state: "token_security",
        environment: "production",
      };
      const response = await quickBooksSaveSettings.mutateAsync(payload);
      window.location.href = response.data.url;
    } else if (selectedLogo == BCLogo) {
    } else if (selectedLogo == QBODesktopLogo) {
      let payload = {
        environment: "production",
        appId: "345123",
        appURL: "https://sc-qbd-api.simplicapital.ai/wsdl",
        appSupportURL: "https://sc-qbd-api.simplicapital.ai/wsdl",
        scheduler: "86400",
        user: "sc-user",
      };
      const response = await quickBooksDesktopSaveSettings.mutateAsync(payload);
      //window.location.href = response.data.url;
      setQbdDownloadUrl(response?.downloadUrl);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedLogo(event.target.value);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(async () => {
    checkConnection();
  }, []);

  useEffect(() => {
    if (selectedLogo == OracleLogo) {
      setConnection("oracle");
      setDeveloperPortalLink(
        "https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_4393879073.html"
      );
    } else if (selectedLogo == QBOLogo) {
      setConnection("qbo");
      setDeveloperPortalLink(
        "https://developer.intuit.com/app/developer/qbo/docs/get-started/get-client-id-and-client-secret"
      );
    } else if (selectedLogo == BCLogo) {
      setConnection("bc");
      setDeveloperPortalLink("businesscentrallink");
    } else if (selectedLogo == QBODesktopLogo) {
      setConnection("qbd");
      setDeveloperPortalLink(
        "https://developer.intuit.com/app/developer/qbdesktop/docs/get-started"
      );
    }
  }, [selectedLogo]);

  useEffect(() => {
    if (code && code.length > 0) {
      if (window.location.href.indexOf("/oracle") > -1) {
        saveOrnetCode(code, state, entity);
      } else if (window.location.href.indexOf("/qbo") > -1) {
        saveQboCode(code, state, realmId);
      }
    }
  }, []);

  return (
    <>
      <PrivateLayout>
        <Page>
          <Page.Header>
            <Row className="m-0">
              <Col className="p-0">
                <h1 className="page-title">
                  Let’s configure SimpliCapital for you!
                </h1>
                <StepperComponent currentPage={onBoarding ? 0 : 8} />
              </Col>
            </Row>
          </Page.Header>
          <Page.Body>
            <StyledCard>
              <Card>
                <Card.Body>
                  <Authorise allowedRoles={["admin"]}>
                    <OuterDiv>
                      {connectionDetails && !reConnect ? (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <TickIcon BackgroundColor="#33c2df">
                            <IconCheck />
                          </TickIcon>
                          <div>
                            <p className="HeadingText">
                              Connection to{" "}
                              {capitalizeFirstLetter(connectionDetails.name)}{" "}
                              has been successfully established.
                            </p>
                            {connectionDetails &&
                            connectionDetails?.name == "qbd" ? (
                              <p>
                                Download your app connection file from here{" "}
                                <a
                                  target="_blank"
                                  href={qbdDownloadUrl}
                                  style={{ color: "#32c1de" }}
                                >
                                  {"sc-qbd.qwc"}
                                </a>{" "}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <>
                          <p className="HeadingText">Hi!</p>
                          <p className="HeadingText">Select your integration</p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              alignContent: "center",
                              flexWrap: "wrap",
                            }}
                            className="integrationdiv"
                          >
                            <div style={{ marginRight: "15px" }}>
                              <label className="logoLabel">
                                <input
                                  type="radio"
                                  value={OracleLogo}
                                  checked={selectedLogo === OracleLogo}
                                  onChange={handleRadioChange}
                                />
                                <LogoDiv>
                                  <img
                                    style={{ width: "110px" }}
                                    src={OracleLogo}
                                  ></img>
                                </LogoDiv>
                              </label>
                            </div>
                            <div style={{ marginRight: "15px" }}>
                              <label className="logoLabel">
                                <input
                                  type="radio"
                                  value={QBOLogo}
                                  checked={selectedLogo === QBOLogo}
                                  onChange={handleRadioChange}
                                />
                                <LogoDiv>
                                  <img
                                    style={{ width: "110px" }}
                                    src={QBOLogo}
                                  ></img>
                                </LogoDiv>
                              </label>
                            </div>
                            <div style={{ marginRight: "15px" }}>
                              <label className="logoLabel">
                                <input
                                  type="radio"
                                  value={QBODesktopLogo}
                                  checked={selectedLogo === QBODesktopLogo}
                                  onChange={handleRadioChange}
                                />
                                <LogoDiv>
                                  <img
                                    style={{ width: "110px" }}
                                    src={QBODesktopLogo}
                                  ></img>
                                </LogoDiv>
                              </label>
                            </div>
                            {/* <div style={{ marginRight: "15px" }}>
                              <label className="logoLabel">
                                <input
                                  type="radio"
                                  value={BCLogo}
                                  checked={selectedLogo === BCLogo}
                                  onChange={handleRadioChange}
                                />
                                <LogoDiv>
                                  <img
                                    style={{ width: "110px" }}
                                    src={BCLogo}
                                  ></img>
                                </LogoDiv>
                              </label>
                            </div> */}
                          </div>
                          {connection ? (
                            <>
                              <p>
                                You can get these details inside your{" "}
                                <a target="_blank" href={developerPortalLink}>
                                  {connection} Integration
                                </a>{" "}
                              </p>
                            </>
                          ) : null}
                          <Formik
                            initialValues={{
                              callbackUrl: initialValues?.callbackURL,
                              clientSecret: initialValues?.clientSecret,
                              clientId: initialValues?.clientId,
                              companyId: initialValues?.companyId,
                            }}
                            enableReinitialize={true}
                            validationSchema={Yup.object().shape({
                              clientSecret: Yup.string().nullable(),
                              clientId: Yup.string().nullable(),
                              companyId: Yup.string().nullable(),
                              appId: Yup.string().nullable(),
                              user: Yup.string().nullable(),
                              scheduler: Yup.string().nullable(),
                              appUrl: Yup.string().nullable(),
                              appSupportUrl: Yup.string().nullable(),
                            })}
                            onSubmit={(
                              values,
                              { setSubmitting, resetForm }
                            ) => {
                              saveSettings(
                                values.clientId,
                                values.clientSecret,
                                values.companyId
                              );
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleSubmit,
                              isSubmitting,
                            }) => (
                              <Form onSubmit={handleSubmit} className="mx-auto">
                                {selectedLogo == QBODesktopLogo ? (
                                  <>
                                    {" "}
                                    <Innersection>
                                      <Row>
                                        <Col>
                                          <InputwithLabel
                                            value="345123"
                                            name="appId"
                                            type="text"
                                            label="App ID"
                                            disabled
                                          />
                                          <InputwithLabel
                                            value="sc-user"
                                            name="user"
                                            type="text"
                                            label="User"
                                          />
                                        </Col>
                                        <Col>
                                          <InputwithLabel
                                            value="86400"
                                            name="scheduler"
                                            type="text"
                                            label="Scheduler"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <InputwithLabel
                                            name="appUrl"
                                            type="text"
                                            label="App URL"
                                            disabled
                                            value={
                                              "https://sc-qbd-api.simplicapital.ai/wsdl"
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <InputwithLabel
                                            name="appSupportUrl"
                                            type="text"
                                            label="Support URL"
                                            disabled
                                            value={
                                              "https://sc-qbd-api.simplicapital.ai/wsdl"
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Innersection>
                                    <BasicButton
                                      style={{ marginBottom: "30px" }}
                                      type="submit"
                                      disabled={false}
                                      width="130px"
                                    >
                                      Save
                                    </BasicButton>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <Innersection>
                                      <Row>
                                        <Col>
                                          <InputwithLabel
                                            placeholder="Client ID"
                                            name="clientId"
                                            type="text"
                                            label="Client ID"
                                          />
                                          <InputwithLabel
                                            placeholder="Company ID"
                                            name="companyId"
                                            type="text"
                                            label="Company ID"
                                          />
                                        </Col>
                                        <Col>
                                          <InputwithLabel
                                            placeholder="Client Secret"
                                            name="clientSecret"
                                            type="text"
                                            label="Client Secret"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <InputwithLabel
                                            name="callbackUrl"
                                            type="text"
                                            label="Callback URL"
                                            disabled
                                            value={callBackUrl}
                                            infoicon={
                                              <InfoIcon
                                                style={{ marginLeft: "3px" }}
                                                data-place="right"
                                                data-html={true}
                                                data-tip={`<p>
                                        You are required to copy this URL into your ${connection} integration portal redirect URIs list
                                      </p>`}
                                              />
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Innersection>
                                    <BasicButton
                                      style={{ marginBottom: "30px" }}
                                      type="submit"
                                      disabled={false}
                                      width="130px"
                                    >
                                      Save
                                    </BasicButton>
                                  </>
                                )}
                              </Form>
                            )}
                          </Formik>
                        </>
                      )}
                    </OuterDiv>
                  </Authorise>
                </Card.Body>
              </Card>
            </StyledCard>
            <NavigationButtons>
              <BasicButton
                onClick={() => history.push(Route_URLs.setup_general_settings)}
                className="next-button"
                type="button"
                width="110px"
              >
                Next
              </BasicButton>
            </NavigationButtons>
          </Page.Body>
        </Page>
      </PrivateLayout>
    </>
  );
}
export default Connection;
