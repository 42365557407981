import React, { useState, useRef} from "react";
import { Col, Row, OverlayTrigger } from "react-bootstrap";
import { Form, InputwithLabel, Formik, SimpleSelect, Checkbox } from "ui/forms";
import { PlusIcon, IconDelete, InfoIcon } from "assets/icons";
import Tooltip from "ui/Tooltip";
import * as Yup from "yup";
import { BasicButton } from "ui/Buttons";
import "react-datepicker/dist/react-datepicker.css";
import ToggleButtonComponent from "ui/ToggleButton";
import { FieldArray } from "formik";
import "react-json-pretty/themes/monikai.css";
import {
  ModalSection,
  StyledInputWithLabel,
  ApprovalReminders,
  ApprovalRemindersDiv,
} from ".";
import SupplierReminder from "./SupplierReminder";
import { Popover } from "ui/Popover";
import { forFutureRelease } from "utils/Configs";

const popover = (nameFieldKey) => (
  <Popover id="popover-basic">
    <Popover.Body>
      <SupplierReminder nameFieldKey={nameFieldKey} />
    </Popover.Body>
  </Popover>
);
export default function SupplierForm() {
  const [conditionValue, setConditionValue] = useState(null);
  const set = new Set();
  const scrollRefs = useRef();
  scrollRefs.current?.scrollIntoView({ behavior: "smooth" });
  const data = React.useMemo(
    () => [
      {
        id: "1",
        supplier: "Gitesh Shah 1",
        email: "Sandeep@gmail.com",
        city: "India",
        company: "XYZ Company",
      },
      {
        id: "2",
        supplier: "Gitesh Shah 2",
        email: "Sandeep@gmail.com",
        city: "India",
        company: "XYZ Company",
      },
      {
        id: "3",
        supplier: "Gitesh Shah 3",
        email: "Sandeep@gmail.com",
        city: "India",
        company: "XYZ Company",
      },
      {
        id: "4",
        supplier: "Gitesh Shah 4",
        email: "Sandeep@gmail.com",
        city: "India",
        company: "XYZ Company",
      },
      {
        id: "5",
        supplier: "Gitesh Shah 5",
        email: "Sandeep@gmail.com",
        city: "India",
        company: "XYZ Company",
      },
      {
        id: "6",
        supplier: "Gitesh Shah 6",
        email: "Sandeep@gmail.com",
        city: "India",
        company: "XYZ Company",
      },
    ],
    []
  );

  const supplierMap = new Map();

  data.forEach((supplier) => {
    supplierMap.set(supplier.id, supplier);
  });

  return (
    <Formik
      initialValues={{
        initialValues: {
          value: "",
          days: "",
          approvalRequired: false,
        },
        conditions: [
          {
            value: "",
            users: [],
            reminder: {
              beforeDueDate: {
                isBeforeDueDate: false,
                days: "",
              },
              onDueDate: false,
              afterDueDate: {
                isAfterDueDate: false,
                days: "",
                perodicDaily: {
                  isPerodicDaily: false,
                  // selectFixedTime: "",
                  hrs: "",
                  mins: "",
                },
                //selectFixedTime: "",
                periodicWeekly: {
                  isPerodicWeekly: false,
                  // selectFixedDayTime: ""
                  days: "",
                  hrs: "",
                  mins: "",
                },
              },
            },
            approvalRequired: false,
            approvalReminder: false,
          },
        ],
      }}
      validationSchema={Yup.object().shape({
        initialValues: Yup.object().shape({
          value: Yup.number().required("value is required"),
          //days: Yup.number().required(" Enter days"),
        }),
        conditions: Yup.array().of(
          Yup.object().shape({
            value: Yup.number()
              .min(
                conditionValue,
                "number can't be smaller than base " + conditionValue
              )
              .required("value is required")
              .nullable(true),
            approvalReminder: Yup.boolean(),
            reminder: Yup.object().when("approvalReminder", {
              is: true,
              then: Yup.object().shape({
                beforeDueDate: Yup.object().shape({
                  isBeforeDueDate: Yup.boolean(),
                  days: Yup.number().when("isBeforeDueDate", {
                    is: true,
                    then: Yup.number().required(" Enter days"),
                  }),
                }),
                afterDueDate: Yup.object().shape({
                  isAfterDueDate: Yup.boolean(),
                  days: Yup.number().when("isAfterDueDate", {
                    is: true,
                    then: Yup.number().required(" Enter days"),
                  }),
                  perodicDaily: Yup.boolean(),
                  selectFixedTime: Yup.string().when("perodicDaily", {
                    is: true,
                    then: Yup.string().required(" Enter time"),
                  }),
                  periodicWeekly: Yup.boolean(),
                  selectFixedDayTime: Yup.string().when("periodicWeekly", {
                    is: true,
                    then: Yup.string().required(" Enter Day Time"),
                  }),
                }),
              }),
            }),
          })
        ),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        isValid,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit} className="mx-auto">
          <ModalSection>
            {/* <JSONPretty data={values} /> */}
            <Row>
              <Col sm={9}>
                <div className="inner-div">
                  <span className="range">Below</span>
                  <StyledInputWithLabel>
                    <InputwithLabel
                      name="initialValues.value"
                      type="number"
                      style={{ width: "70px" }}
                      className="modal-input"
                    />
                  </StyledInputWithLabel>
                  <span
                    style={{ position: "absolute", left: "21%" }}
                    className="range"
                  >
                    USD
                  </span>
                </div>
              </Col>
              <Col sm={3}>
                <div className="inner-div">
                  {/* <InputwithLabel
                    placeholder="Enter days"
                    name="initialValues.days"
                    type="number"
                    style={{ width: "80px" }}
                    className="modal-input"
                  />
                  <span className="labels">Days from Due date</span> */}
                  <Checkbox
                    style={{
                      marginBottom: "-3px",
                      marginRight: "5px",
                    }}
                    label="Manual Approval"
                    name="initialValues.approvalRequired"
                  />

                  {forFutureRelease && <div style={{ marginLeft: "2px" }}>
                    <InfoIcon
                      font-size="10px"
                      data-tip="If no manual approval. Auto schedule for payment on Due date."
                      data-for="approval"
                    />
                  </div> }
                  <Tooltip place="right" id="approval" />
                </div>
              </Col>
            </Row>
          </ModalSection>

          <FieldArray
            name="conditions"
            render={(arrayHelpers) => (
              //let supplierSet = new Set()
              <div>
                {values.conditions &&
                  values.conditions.length > 0 &&
                  values.conditions?.map((condition, index) => {
                    let previousValue = null;
                    if (index == 0) {
                      previousValue = values.initialValues?.value;
                      setConditionValue(previousValue);
                    } else {
                      previousValue = values.conditions[index - 1].value;
                      setConditionValue(previousValue);
                    }

                    return (
                      <div
                        key={index}
                        className="selected-element"
                        ref={scrollRefs}
                      >
                        <ModalSection>
                          <Row>
                            <Col sm={9}>
                              <div className="inner-div">
                                <span className="range">
                                  From {previousValue} USD to
                                </span>
                                <StyledInputWithLabel>
                                  <InputwithLabel
                                    name={`conditions[${index}].value`}
                                    type="number"
                                    style={{ width: "70px" }}
                                    className="modal-input"
                                  />
                                </StyledInputWithLabel>
                                <span
                                  style={{
                                    position: "absolute",
                                    left: "32%",
                                  }}
                                  className="range"
                                >
                                  USD
                                </span>
                              </div>
                            </Col>
                            <Col sm={3}>
                              <div className="inner-div">
                                <Checkbox
                                  style={{
                                    marginBottom: "-3px",
                                    marginRight: "5px",
                                  }}
                                  label="Manual Approval"
                                  name={`conditions[${index}].approvalRequired`}
                                />
                                {forFutureRelease && <div style={{ marginLeft: "2px" }}>
                                  <InfoIcon
                                    font-size="10px"
                                    data-tip="If no manual approval. Auto schedule for payment on Due date."
                                    data-for="approval"
                                  />
                                </div> }
                                <Tooltip place="right" id="approval" />
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                  style={{
                                    background: "none",
                                    border: "none",
                                  }}
                                >
                                  <IconDelete font-size="12px" />
                                </button>
                              </div>
                            </Col>
                          </Row>

                          <ApprovalReminders>
                            <Row className="m-0">
                              <Col sm={9}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="labels">
                                    Approval Reminders
                                  </span>
                                  <div
                                    style={{
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <OverlayTrigger
                                      trigger="click"
                                      placement="bottom-start"
                                      overlay={popover(
                                        `conditions[${index}].reminder`
                                      )}
                                    >
                                      <div>
                                        <ToggleButtonComponent
                                          // name="status"
                                          name={`conditions[${index}].approvalReminder`}
                                          value={
                                            values.conditions[index]
                                              .approvalReminder
                                          }
                                          setToggle={(value) => {
                                            setFieldValue(
                                              `conditions[${index}].approvalReminder`,
                                              value
                                            );
                                            if (!value) {
                                              setFieldValue(
                                                `conditions[${index}].reminder`,
                                                {}
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </Col>

                              <Col className="align-right" sm={3}>
                                <span className="labels">Action</span>
                              </Col>
                            </Row>
                          </ApprovalReminders>
                          {values.conditions[index].users?.map((value) => {
                            return (
                              <>
                                <ApprovalRemindersDiv>
                                  <Row className="m-0">
                                    {" "}
                                    <Col sm={9}>
                                      <span className="labels">
                                        {" "}
                                        {value.supplier}
                                      </span>
                                    </Col>
                                    <Col className="align-right" sm={3}>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          set.delete(value);
                                          setFieldValue(
                                            `conditions[${index}].users`,
                                            Array.from(set)
                                          );
                                        }}
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                      >
                                        <IconDelete font-size="12px" />
                                      </button>
                                    </Col>
                                  </Row>
                                </ApprovalRemindersDiv>
                              </>
                            );
                          })}

                          <ApprovalRemindersDiv>
                            <Row>
                              <Col sm={6}>
                                <div className="inner-div">
                                  <SimpleSelect
                                    className="modal-input"
                                    placeholder="Search Users"
                                    name={`conditions[${index}].users`}
                                    options={data.map((option) => {
                                      return {
                                        value: option.id,
                                        label: option.supplier,
                                      };
                                    })}
                                    style={{ width: "150px" }}
                                    onChange={(event) => {
                                      set.add(
                                        supplierMap.get(event.target.value)
                                      );

                                      setFieldValue(
                                        `conditions[${index}].users`,
                                        Array.from(set)
                                      );
                                    }}
                                  />

                                  {/* <InputwithLabel
                                                  placeholder="Search Users"
                                                  name="search"
                                                  type="text"
                                                  style={{ width: "150px" }}
                                                /> */}

                                  <span
                                    className="labels"
                                    style={{ color: "#33C2DF" }}
                                  >
                                    <PlusIcon
                                      font-size="12px"
                                      style={{
                                        marginRight: "5px",
                                      }}
                                    />
                                    Add
                                  </span>
                                </div>
                              </Col>
                              <Col sm={6}></Col>
                            </Row>
                          </ApprovalRemindersDiv>
                        </ModalSection>
                      </div>
                    );
                  })}

                <Row>
                  <Col>
                    <div style={{ padding: "20px" }}>
                      <button
                        type="button"
                        // disabled={values.initialValues?.value == null}
                        disabled={!(isValid && dirty)}
                        onClick={() =>
                          arrayHelpers.push({
                            value: null,
                            users: [],
                            reminder: {},
                            approvalRequired: false,
                            approvalReminder: false,
                          })
                        }
                        style={{
                          background: "none",
                          border: "none",
                        }}
                      >
                        <span style={{ color: "#33C2DF" }} className="range">
                          <PlusIcon style={{ marginRight: "5px" }} />
                          Add Conditions
                        </span>
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          />
          <Row>
            <Col>
              <BasicButton
                style={{ margin: "10px", float: "right" }}
                type="submit"
                width="110px"
              >
                Save
              </BasicButton>
              <BasicButton
                style={{ margin: "10px", float: "right" }}
                type="submit"
                width="110px"
                background="#909090"
              >
                Cancel
              </BasicButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
