import axios from "utils/APIv2";
export const getDepartments = async (params) => {
  const res = await axios.get(`/departments`, { params });
  return res.data;
};

export const updateDepartments = async (payload) => {
  const res = await axios.post(`/departments`, payload);
  return res.data;
};
