import { useQuery } from "react-query";
import ActualService from "services/actual.services";
import { useMutation, useQueryClient } from "react-query";

export const useAllReceviables = (filterData, mo = false) => {
  return useQuery(
    ["receivables", { filterData }],
    () => ActualService.getallreceviables(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};
export const useAllCustomers = (filterData, mo = false) => {
  return useQuery(
    ["customers", { filterData }],
    () => ActualService.getallcustomers(filterData),
    {
      keepPreviousData: false,
      staleTime: 60 * 60 * 1000,
    }
  );
};
export const LoginCustomerData = (userId) => {
  return useQuery(
    ["log-customers", {userId }],
    () => ActualService.getLogCustomerData(userId),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const CustomerCardDataPayables = (decodedData) => {

  return useQuery(
    ["cardPayablescustomer", {decodedData}],
    () => ActualService.getcustomercarddatapayables(decodedData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};
export const useAllCollection = (filterData) => {
  return useQuery(
    ["collections", { filterData }],
    () => ActualService.getallcollections(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useAllPayables = (filterData, mo = false) => {
  return useQuery(
    ["payables", { filterData }],
    () => ActualService.getallpayables(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useNonControllablePayables = (filterData) => {
  return useQuery(
    ["nonControllablePayables", { filterData }],
    () => ActualService.getNonControllablePayableData(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useNonTradeRecievables = (filterData) => {
  return useQuery(
    ["nonTradeRecievables", { filterData }],
    () => ActualService.getNonTradeRecievavleData(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useGraphDataCollections = (cid, filterType) => {
  return useQuery(
    ["graphCollections", { cid, filterType }],
    () => ActualService.getgraphdatacollections(cid, filterType),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useGraphDataPayables = (sid, filterType) => {
  return useQuery(
    ["graphPayables", { sid, filterType }],
    () => ActualService.getgraphdatapayables(sid, filterType),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};
export const useGraphDataReceviables = (cid, filterType) => {
  return useQuery(
    ["graphreceviables", { cid, filterType }],
    () => ActualService.getgraphdatareceivables(cid, filterType),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};
export const useCardDataReceviables = (filterData) => {
  return useQuery(
    ["cardReceviables", {filterData}],
    () => ActualService.getcarddatareceivables(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useCardDataCollections = (filterData) => {
  return useQuery(
    ["cardCollections", {filterData}],
    () => ActualService.getcarddatacollections(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useCardDataPayables = (filterData) => {
  return useQuery(
    ["cardPayables", {filterData}],
    () => ActualService.getcarddatapayables(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useCardDataControllableNonControllable = () => {
  return useQuery(
    ["cardControllableNonControllable", {}],
    () => ActualService.getCardDataControllableNonControllable(),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};
export const Remindersdata = (filterData) => {
  return useQuery(
    ["remindersdata", {filterData}],
    () => ActualService.getreminders(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};
export const UseAllDisputeType = (userId) => {
  return useQuery(
    ["UseAllDisputeType", {userId}],
    () => ActualService.getAalldisputeType(userId),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};


export const UseAllPayments= (filterData) => {
  return useQuery(
    ["payments", { filterData }],
    () => ActualService.getallpayments(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};




export const UseCardDataPayments = (filterData) => {
  return useQuery(
    ["paymentscards", {filterData}],
    () => ActualService.getcarddatapayments(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};
export const UseCustomerDetails = (filterData) => {

  const queryClient = useQueryClient();
  return useMutation((data) => ActualService.getCustomerDetails(data), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("getCustomerDetails");
    },
  });
};

export const UseMerchantDetails = () => {
  return useQuery(["merchantDetails"], () =>
    ActualService.getMerchantDetails()
  );
};

export const UseSaveTransactionReceivable = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => ActualService.saveTransactionReceivable(data), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("saveReceivableTransactions");
    },
  });
};
export const UseTransactionList = (filterData) => {
  return useQuery(["receivableTransactionList", { filterData }], () =>
    ActualService.getCustomerTransaction(filterData)
  );
};

export const GenerateCheckSumReceivable = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => ActualService.GenerateCheckSumReceivableData(data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("checksum");
      },
    }
  );
};
export const GenerateSessionTokenReceivable = (data) => {
  const queryClient = useQueryClient();
  return useMutation((data) => ActualService.GenerateSessionTokenData(data), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("sessiontoken");
    },
  });
};

export const GetAllCustomerName = () => {
  return useQuery(["customerName"], () => ActualService.GetAllCustomer());
};
