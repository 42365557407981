import React, { useContext, useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import PrimaryPrivateNavbar from "./PrimaryPrivateNavbar";
import Sidebar from "../Sidebar";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { UIContext } from "context/UIContext";
import { UserContext } from "context/UserContext";
import Bus from "utils/Bus";
import Flash from "ui/flash-message";
import { QuickBookConnectionContext } from "context/QuickBookConnectionContext";

import { Authenticate } from "helpers/Authentication";

const PageStyles = styled.div``;

const PageContainer = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  .scroll-div {
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
    height: auto;
    background-color: #232631;
  }
`;

const ContentContainer = styled(Container)`
  /* overflow: auto; */
  max-width: 100%;
  flex: 1;
`;
const StickyDiv = styled.div`
  position: relative;
`;

const SidebarContainer = styled.div`
  position: relative;
  height: 100%;
  /* width: auto;
  overflow: auto; */
  display: block;
  /* border: 5px solid green; */
  z-index: 50000;
`;

const PrivateLayout = (props) => {
  const [scroll, setScroll] = useState(false);
  const location = useLocation();
  const page = location.pathname.split("/")[1];
  const { user } = useContext(UserContext);
  const QuickbookConnectionContext = useContext(QuickBookConnectionContext);

  window.flash = (
    operation = "close",
    type = "success",
    message,
    closeable,
    duration
  ) => Bus.emit("flash", { operation, type, message, closeable, duration });

  useEffect(() => {
    if (QuickbookConnectionContext.quickbookImportInProgress) {
      window.flash(
        "open",
        "progress",
        "SimpliCapital is retrieving your data",
        false
      );
    } else {
      window.flash("close", "progress");
    }

    if (
      QuickbookConnectionContext?.alerts &&
      QuickbookConnectionContext?.alerts != ""
    ) {
      window.flash(
        "open",
        "warning",
        `${QuickbookConnectionContext?.alerts}`,
        false
      );
    } else {
      window.flash("close", "warning");
    }
  }, [
    QuickbookConnectionContext.alerts,
    QuickbookConnectionContext.quickbookImportInProgress,
  ]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });
  }, []);

  // if (!user) {
  //   return <Redirect to="/" />;
  // }

  return (
    <Authenticate showUnAuthorisedComponenet={<Redirect to="/" />}>
      <PageContainer>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <ContentContainer>
          <StickyDiv
            className={
              scroll && page === "scenario" ? "scroll-div" : "no-scroll"
            }
          >
            <Row>
              <Col>
                <PrimaryPrivateNavbar
                  name={`${user?.firstName} ${user?.lastName}`}
                />
                <Flash />
              </Col>
            </Row>
          </StickyDiv>
          <Row>
            <Col>
              <>{props.children}</>
            </Col>
          </Row>
        </ContentContainer>
      </PageContainer>
    </Authenticate>
  );
};

export default PrivateLayout;
