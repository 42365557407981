import Bus from "utils/Bus";
import React, { useEffect, useState } from "react";
import ProgressBar from "./progress-bar";
import Primary from "./primary";
import Danger from "./danger";
import Warning from "./warning";
import styled from "styled-components";

export const StatusBar = styled.div`
  min-height: 40px;
  background: #33c2df;
  border-radius: 5px;
  padding: 8px;
  margin: 10px 0px;
  span {
    font-size: 12px;
    font-family: Montserrat Alternates-semiBold;
    line-height: 20px;
    color: #ffffff;
  }
  strong {
    color: #ffffff;
    cursor: pointer;
    margin-right: 10px;
    float: right;
  }
`;
export default function Flash() {
  let [visibility, setVisibility] = useState(false);
  let [message, setMessage] = useState("");
  let [type, setType] = useState("");
  let [closeable, setCloseable] = useState(false);
  useEffect(() => {
    Bus.addListener(
      "flash",
      ({ operation, type, message, closeable, duration }) => {
        if (operation === "open") {
          setVisibility(true);
          setMessage(message);
          setType(type);
          setCloseable(closeable);
        }

        if (operation === "close") {
          setVisibility(false);
        }

        if (duration) {
          setTimeout(() => {
            setVisibility(false);
          }, duration);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (document.querySelector(".close") !== null) {
      document
        .querySelector(".close")
        .addEventListener("click", () => setVisibility(false));
    }
  });
  const renderSwitch = (type, message, closeable) => {
    switch (type) {
      case "progress":
        return <ProgressBar message={message} closeable={closeable} />;
      case "danger":
        return <Danger message={message} closeable={closeable} />;
      case "warning":
        return <Warning message={message} closeable={closeable} />;
      case "primary":
        return <ProgressBar message={message} closeable={closeable} />;
      default:
        return null;
    }
  };
  return visibility && <>{renderSwitch(type, message, closeable)}</>;
}
