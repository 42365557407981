import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  RefreshIcon,
  ProfileIcon,
  BellIcon,
  LogoutIcon,
  IconGraph,
  IconFlag,
} from "assets/icons.js";
import SampleLogo from "assets/logo-placeholder.jpg";
import SampleImage from "assets/placeholder-image.png";
import { withRouter, Link } from "react-router-dom";
import { UserContext } from "context/UserContext";
import { applicationDate } from "utils/application-date";
import moment from "moment";
import { OverlayTrigger } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { useGeneralSettings } from "queries/generalSettings";
import { arForecastModelTrigger } from "services/app.services";
import { useUser } from "queries/user";
import { useProfile } from "queries/profile";
import { useForecastDate } from "queries/app";
import { UIContext } from "context/UIContext";
import { QuickBookConnectionContext } from "context/QuickBookConnectionContext";
import { Route_URLs } from "routesURLs";
import { Popover } from "ui/Popover";
import { forFutureRelease } from "utils/Configs";
import { Authorise } from "helpers/Authorization";
import Spinner from "react-bootstrap/Spinner";
import Loader from "ui/Loader";
import ReactTooltip from "react-tooltip";
import { Modal, StyledModal } from "ui/Modal";

const StyledNavBar = styled.nav`
  position: relative;
  width: 100%;
  background-color: #3a3b48;
  color: #ffffff;
  text-align: left;
  height: auto;
  overflow: auto;
  padding: 12px;
  margin: 15px 0;
  border-radius: 10px;

  li {
    text-decoration: none;
    list-style: none;
    float: right;
    font-size: 14px;
  }
  .refresh {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    font-family: Montserrat Alternates-Medium;
  }

  .profile-image {
    border-radius: 50%;
    height: 38px;
    width: 38px;
    margin-right: 15px;
  }

  .greetings {
    font-size: 18px;
    float: left;
    line-height: 22px;
    font-family: Montserrat Alternates-semiBold;
  }
  .logo-image {
    margin: 5px;
    height: 30px;
    width: 110px;
    border-radius: 5px;
    object-fit: contain;

    @media (min-width: 768px) and (max-width: 1024px) {
      width: 90px;
    }
  }
  .accounts-data {
    border-left: 1px solid #707070;
    padding: 5px 10px;
  }
`;
const Iconbackground = styled.div`
  height: 40px;
  width: 40px;
  background: #ffffff;
  box-shadow: 0px 3px 26px #00000017;
  border: 3px solid #5b5d6e;
  border-radius: 40px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  svg {
    font-size: 1.3em;
    margin-top: 8px;
  }
  .notification-icon {
    path {
      fill: #343540;
    }
  }
`;
const StyledRefreshIcon = styled.div`
  content: "${(props) => props.isloading}";
  display: inline-block;
  margin: 0px 10px;
  cursor: pointer;
  animation: ${(props) => props.isloading && "rotation 1s infinite linear"};
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
const StyledGarphIcon = styled.div`
  margin: 0px 10px;
`;

const DropdownContent = styled.div`
  display: block;
  color: white;
  text-align: left;
  background-color: #000000;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  right: 0;

  a {
    font-family: Montserrat Alternates-semiBold;
    font-weight: bold;
    font-size: 14px;
    line-height: 44px;
    font-style: normal;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: none;
    text-decoration: none;
    padding: 0 40px;
  }
`;

const NotificationDiv = styled.div`
  display: flex;
  border-bottom: 2px solid #707070;
  padding: 10px;
  .notifications-text {
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 20px;
    color: #909090;
    display: inline-block;
  }
  .flag {
    margin: 5px;
  }
`;
const ProfileDetails = styled.div`
  padding: 20px 15px;
  font-family: Montserrat Alternates-semiBold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: #909090;

  svg {
    margin-right: 10px;
    path {
      fill: white;
      stroke: white;
    }
  }
  a {
    text-decoration: none;
    color: #909090;
  }
`;

const LockOverlay = styled.div`
  top: 0;
  left: 0;
  background: transparent;
  cursor: not-allowed;
  width: 95%;
  display: block;
  position: absolute;
  height: 63px;
  z-index: 9999999;
`;

const BackStyledButton = styled.button`
  background: transparent linear-gradient(270deg, #33c2df 0%, #0081c5 100%);
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  text-align: left;
  font-family: Montserrat Alternates-semiBold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 12px;
`;

// const ProfileDropdown = () => {
//   let history = useHistory();

//   const onLogout = () => {
//     window.location.pathname = "/";
//   };

//   return (
//     <>
//       <Iconbackground>
//         <ProfileIcon />
//       </Iconbackground>

//       <Iconbackground>
//         <LogoutIcon onClick={onLogout} />
//       </Iconbackground>
//     </>
//   );
// };
const Notificationpopover = (
  <Popover id="popover-basic">
    <Popover.Body>
      {[...Array(3)].map((i) => (
        <NotificationDiv>
          <div>
            <IconFlag font-size="20px" className="flag" />
          </div>
          <div>
            <span className="notifications-text">
              Dispute 232323 raised by Ramesh with category Dispute Category on
              IN 2323232
            </span>
            <span className="notifications-text">20 min ago </span>
          </div>
        </NotificationDiv>
      ))}
      <Link to={Route_URLs.notifications} className="view-all">
        View All
      </Link>
    </Popover.Body>
  </Popover>
);
const ProfileDetailsPopover = (onLogout, onLogoutSuperUser) => (
  <Popover id="popover-basic">
    <Popover.Body>
      <ProfileDetails
        style={{
          borderBottom: "1px solid #909090",
        }}
      >
        <ProfileIcon font-size="20px" />

        <Link to={Route_URLs.profile} className="profile-details">
          <span style={{ marginLeft: "-5px" }}> Profile Settings</span>
        </Link>
      </ProfileDetails>
      {sessionStorage.getItem("isSuperUser") == "true" ? (
        <ProfileDetails onClick={onLogoutSuperUser}>
          <LogoutIcon font-size="20px" />
          <span className="profile-details">
            Logout as {sessionStorage.getItem("tenantName")}
          </span>
        </ProfileDetails>
      ) : (
        <ProfileDetails onClick={onLogout}>
          <LogoutIcon font-size="20px" />

          <span className="profile-details">Logout</span>
        </ProfileDetails>
      )}
    </Popover.Body>
  </Popover>
);
const NotificationDropdown = () => {
  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={Notificationpopover}
      >
        <Iconbackground>
          <BellIcon className="notification-icon" />
        </Iconbackground>
      </OverlayTrigger>
    </>
  );
};

const ProfileDetailsDropdown = ({ bucketUrl, isLoadingUserData }) => {
  const userContext = useContext(UserContext);

  const onLogout = async () => {
    try {
      await Auth.signOut();
      userContext.setUser(null);
      localStorage.clear();
      sessionStorage.clear();
      window.location.pathname = "/";
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  const onLogoutSuperUser = async () => {
    try {
      userContext.setUser(null);
      // localStorage.clear();
      // sessionStorage.clear();
      sessionStorage.removeItem("collapsed");
      sessionStorage.removeItem("tenantName");
      sessionStorage.removeItem("userToken");
      sessionStorage.removeItem("username");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("client_id");
      sessionStorage.removeItem("tokendata");
      window.location.pathname = "/admin/login";
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };
  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={ProfileDetailsPopover(onLogout, onLogoutSuperUser)}
      >
        <img
          alt="profile"
          className="profile-image"
          src={isLoadingUserData ? SampleImage : bucketUrl}
        />
      </OverlayTrigger>
    </>
  );
};

const PrimaryPrivateNavbar = ({ name }) => {
  const [isloading, setLoading] = useState(false);
  const { onBoarding } = useContext(UIContext);
  const { isQuickbookDataImported } = useContext(QuickBookConnectionContext);

  const { data: generalSettingsData } = useGeneralSettings();
  const { data: userData, isLoadingUserData } = useProfile();
  const [showPopup, setShowPopup] = useState(false);

  const {
    data: ForecastDate,
    isLoading: isLoadingDate,
    refetch,
  } = useForecastDate();

  const handleForecastModelTrigger = () => {
    setLoading(true);
    arForecastModelTrigger()
      .then(() => {
        setLoading(false);
        setShowPopup(true);
        refetch();
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data.message);
        }
      });
  };
  return (
    <StyledNavBar>
      {showPopup && (
        <Modal
          show={showPopup}
          onHide={() => setShowPopup(false)}
          centered
          style={{}}
        >
          <StyledModal>
            <div className="logout-modal">
              <Modal.Body style={{ padding: "15px" }}>
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ width: "100px", margin: "20px" }}
                    src="https://allassets.s3.amazonaws.com/predictive-chart.png"
                  />
                </div>
                <p style={{ color: "rgb(144, 144, 144)", textAlign: "center" }}>
                  Prediction In Progress
                  <br />
                  This might take upto 15 minutes. Results will be available on
                  actuals screen.
                </p>
                <div style={{ textAlign: "center", marginTop: "15px" }}>
                  <BackStyledButton
                    style={{
                      color: "#fff",
                      borderRadius: "6px",
                      padding: "9px 20px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowPopup(false)}
                  >
                    {" "}
                    Close
                  </BackStyledButton>
                </div>
              </Modal.Body>
            </div>
          </StyledModal>
        </Modal>
      )}
      {isQuickbookDataImported && <LockOverlay />}
      <li className="greetings">
        <img
          alt="logo"
          className="logo-image"
          src={
            generalSettingsData?.bucketUrl
              ? generalSettingsData.bucketUrl
              : SampleLogo
          }
        />
      </li>

      <li className="refresh">
        {!onBoarding && (
          <>
            <StyledGarphIcon>
              <IconGraph />
            </StyledGarphIcon>

            <span style={{ padding: "5px" }}>
              Forecast last run on {!isLoadingDate && ForecastDate.date}
            </span>
            <Authorise
              showUnAuthorisedComponenet={<></>}
              allowedRoles={["admin"]}
            >
              <StyledRefreshIcon
                isloading={isloading}
                onClick={handleForecastModelTrigger}
              >
                <RefreshIcon
                  data-tip={
                    isloading ? "Prediction in progress" : "Trigger prediction"
                  }
                />
              </StyledRefreshIcon>
            </Authorise>
            <span className="accounts-data">
              Accounts data last read on{" "}
              {moment(applicationDate).format("MM/DD/YYYY")}
            </span>

            <StyledRefreshIcon>
              {forFutureRelease && <RefreshIcon />}
            </StyledRefreshIcon>
          </>
        )}

        {forFutureRelease && <NotificationDropdown />}
        <ProfileDetailsDropdown
          bucketUrl={
            userData?.data?.bucketUrl ? userData.data.bucketUrl : SampleImage
          }
          isLoadingUserData={isLoadingUserData}
        />
      </li>
    </StyledNavBar>
  );
};
export default withRouter(PrimaryPrivateNavbar);
