import { getProfile, getRoles } from "services/userProfile.services";
import { useQuery } from "react-query";

export const useProfile = () => {
  return useQuery(["profile"], () => getProfile(), {
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });
};
export const useRoles = () => {
  return useQuery(["roles"], () => getRoles(), {
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });
};