import Card from "ui/Card";
import NumberFormat from "react-number-format";
import ChangeReverse from "ui/ChangeReverse";
import { LeftBlock, RightBlock } from "ui/general";
import SimpleLineGraph, { GraphCaption } from "graphs/SimpleLineGraph";
import { Link } from "react-router-dom";
import Loader from "ui/Loader";
import { useState } from "react";
import moment from "moment";

function PerformanceTimeCard(props) {
  // react query
  const data = props;
  return (
    <Link className="link-to-pages" to="#">
      <Card>
        <Card.Header>
          {props?.isLoading ? <></> : <>{data.title}</>}
        </Card.Header>
        <Card.Body>
          {props?.isLoading ? (
            <Loader />
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="metric">
                <div
                  style={{
                    "border-bottom": "none",
                    color: "#909090",
                    "font-size": "12px",
                    "line-height": "25px",
                    "font-family": "Montserrat Alternates-Bold",
                    "font-style": "normal",
                    background: "none",
                  }}
                >
                  {data?.date}
                </div>
                {data?.value?.toFixed(2)}
              </span>
              <span className="metric">
                <div
                  style={{
                    "border-bottom": "none",
                    color: "#909090",
                    "font-size": "12px",
                    "line-height": "25px",
                    "font-family": "Montserrat Alternates-Bold",
                    "font-style": "normal",
                    background: "none",
                  }}
                >
                  {data?.date2}
                </div>
                {data?.value2?.toFixed(2)}
              </span>
              <span className="metric">
                <div
                  style={{
                    "border-bottom": "none",
                    color: "#909090",
                    "font-size": "12px",
                    "line-height": "25px",
                    "font-family": "Montserrat Alternates-Bold",
                    "font-style": "normal",
                    background: "none",
                  }}
                >
                  {data?.date3}
                </div>
                {data?.value3?.toFixed(2)}
              </span>
            </div>
          )}
        </Card.Body>
        <Card.Footer>
          {props?.isLoading ? (
            <></>
          ) : (
            <>
              <LeftBlock></LeftBlock>
              <RightBlock style={{ display: "flex", "align-items": "center" }}>
                {/* <SimpleLineGraph
                  width={100}
                  height={80}
                  data={graphData.map(({ actual }) => actual)}
                  seriesData={graphData.map(({ date }) => date)}
                /> */}
                <div
                  className={
                    data?.value === data?.value2 &&
                    data?.value2 === data?.value3
                      ? "red-circle"
                      : "green-circle"
                  }
                ></div>
                <div>
                  {data?.value === data?.value2 && data?.value2 === data?.value3
                    ? "No change"
                    : "Change detected"}
                </div>
              </RightBlock>
            </>
          )}
        </Card.Footer>
      </Card>
    </Link>
  );
}
export default PerformanceTimeCard;
