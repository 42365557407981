export const dummyData = [
    {
        "_id": "62d68fb3d2401d208cc98d89",
        "address": "",
        "supplier": "Bob's Burger Joint",
        "company": "",
        "email": "",
        "supplierId": "56",
        "metaData": {
            "createdTime": "2021-11-06T14:28:52-07:00",
            "lastUpdatedTime": "2022-04-13T04:14:30-07:00"
        },
        "givenName": "",
        "familyName": "",
        "printOnCheckName": "Bob's Burger Joint",
        "balance": 190,
        "active": true,
        "accoutNumber": "12345678",
        "primaryPhone": "",
        "mobile": "",
        "stripeDetails": "",
        "subRows": [
            {
            "address": {
            "Id": "32",
            "Line1": "P.O. Box 5",
            "City": "Middlefield",
            "CountrySubDivisionCode": "CA",
            "PostalCode": "94482",
            "Lat": "43.8249453",
            "Long": "-79.2639122"
                      },
            "supplier": "Brosnahan Insurance Agency",
            "company": "Brosnahan Insurance Agency",
            "email": "", 
            "subRows": [
                {
                "address": {
                "Id": "32",
                "Line1": "P.O. Box 5",
                "City": "Middlefield",
                "CountrySubDivisionCode": "CA",
                "PostalCode": "94482",
                "Lat": "43.8249453",
                "Long": "-79.2639122"
                          },
                "supplier": "Brosnahan Insurance Agency",
                "company": "Brosnahan Insurance Agency",
                "email": "",
                "subRows":[]
                }
            ]
            }
        ]
    },
    {
        "_id": "62d68fb3d2401d208cc98d8a",
        "address": {
            "Id": "31",
            "Line1": "15 Main St.",
            "City": "Palo Alto",
            "CountrySubDivisionCode": "CA",
            "PostalCode": "94303",
            "Lat": "37.445013",
            "Long": "-122.1391443"
        },
        "supplier": "Books by Bessie",
        "company": "Books by Bessie",
        "email": "Books@Intuit.com",
        "supplierId": "30",
        "metaData": {
            "createdTime": "2021-10-16T10:07:56-07:00",
            "lastUpdatedTime": "2022-02-20T22:06:24-08:00"
        },
        "givenName": "Bessie",
        "familyName": "Williams",
        "printOnCheckName": "Books by Bessie",
        "balance": 500,
        "active": true,
        "accoutNumber": "1345",
        "primaryPhone": "(650) 555-7745",
        "mobile": "",
        "stripeDetails": "",
        "subRows": [
            {
            "address": {
            "Id": "32",
            "Line1": "P.O. Box 5",
            "City": "Middlefield",
            "CountrySubDivisionCode": "CA",
            "PostalCode": "94482",
            "Lat": "43.8249453",
            "Long": "-79.2639122"
                      },
            "supplier": "Brosnahan Insurance Agency",
            "company": "Brosnahan Insurance Agency",
            "email": "", 
            "subRows": [
                {
                "address": {
                "Id": "32",
                "Line1": "P.O. Box 5",
                "City": "Middlefield",
                "CountrySubDivisionCode": "CA",
                "PostalCode": "94482",
                "Lat": "43.8249453",
                "Long": "-79.2639122"
                          },
                "supplier": "Brosnahan Insurance Agency",
                "company": "Brosnahan Insurance Agency",
                "email": "",
                "subRows":[]
                }
            ]
            }
        ]
    },
    {
        "_id": "62d68fb3d2401d208cc98d8b",
        "address": {
            "Id": "32",
            "Line1": "P.O. Box 5",
            "City": "Middlefield",
            "CountrySubDivisionCode": "CA",
            "PostalCode": "94482",
            "Lat": "43.8249453",
            "Long": "-79.2639122"
        },
        "supplier": "Brosnahan Insurance Agency",
        "company": "Brosnahan Insurance Agency",
        "email": "",
        "supplierId": "31",
        "metaData": {
            "createdTime": "2021-10-16T10:12:02-07:00",
            "lastUpdatedTime": "2022-01-27T07:23:29-08:00"
        },
        "givenName": "Nick",
        "familyName": "Brosnahan",
        "printOnCheckName": "Brosnahan Insurance Agency",
        "balance": 6241.23,
        "active": true,
        "accoutNumber": "7653412",
        "primaryPhone": "(650) 555-9912",
        "mobile": "(650) 555-9874",
        "stripeDetails": "",
        "subRows": [
            {
            "address": {
            "Id": "32",
            "Line1": "P.O. Box 5",
            "City": "Middlefield",
            "CountrySubDivisionCode": "CA",
            "PostalCode": "94482",
            "Lat": "43.8249453",
            "Long": "-79.2639122"
                      },
            "supplier": "Brosnahan Insurance Agency",
            "company": "Brosnahan Insurance Agency",
            "email": "", 
            "subRows": [
                {
                "address": {
                "Id": "32",
                "Line1": "P.O. Box 5",
                "City": "Middlefield",
                "CountrySubDivisionCode": "CA",
                "PostalCode": "94482",
                "Lat": "43.8249453",
                "Long": "-79.2639122"
                          },
                "supplier": "Brosnahan Insurance Agency",
                "company": "Brosnahan Insurance Agency",
                "email": "",
                "subRows":[]
                }
            ]
            }
        ]
    },
    {
        "_id": "62d68fb3d2401d208cc98d8c",
        "address": {
            "Id": "33",
            "Line1": "10 Main St.",
            "City": "Palo Alto",
            "CountrySubDivisionCode": "CA",
            "PostalCode": "94303",
            "Lat": "37.445013",
            "Long": "-122.1391443"
        },
        "supplier": "Cal Telephone",
        "company": "Cal Telephone",
        "email": "",
        "supplierId": "32",
        "metaData": {
            "createdTime": "2021-10-16T10:13:24-07:00",
            "lastUpdatedTime": "2021-10-23T12:55:23-07:00"
        },
        "givenName": "",
        "familyName": "",
        "printOnCheckName": "Cal Telephone",
        "balance": 0,
        "active": true,
        "primaryPhone": "(650) 555-1616",
        "mobile": "",
        "stripeDetails": "",
        "subRows": [
            {
            "address": {
            "Id": "32",
            "Line1": "P.O. Box 5",
            "City": "Middlefield",
            "CountrySubDivisionCode": "CA",
            "PostalCode": "94482",
            "Lat": "43.8249453",
            "Long": "-79.2639122"
                      },
            "supplier": "Brosnahan Insurance Agency",
            "company": "Brosnahan Insurance Agency",
            "email": "", 
            "subRows": [
                {
                "address": {
                "Id": "32",
                "Line1": "P.O. Box 5",
                "City": "Middlefield",
                "CountrySubDivisionCode": "CA",
                "PostalCode": "94482",
                "Lat": "43.8249453",
                "Long": "-79.2639122"
                          },
                "supplier": "Brosnahan Insurance Agency",
                "company": "Brosnahan Insurance Agency",
                "email": "",
                "subRows":[]
                }
            ]
            }
        ]
    },
    {
        "_id": "62d68fb3d2401d208cc98d8d",
        "address": "",
        "supplier": "Chin's Gas and Oil",
        "company": "Chin's Gas and Oil",
        "email": "",
        "supplierId": "33",
        "metaData": {
            "createdTime": "2021-10-16T10:13:52-07:00",
            "lastUpdatedTime": "2021-10-16T10:13:52-07:00"
        },
        "givenName": "",
        "familyName": "",
        "printOnCheckName": "Chin's Gas and Oil",
        "balance": 0,
        "active": true,
        "primaryPhone": "",
        "mobile": "",
        "stripeDetails": "",
        "subRows": [
            {
            "address": {
            "Id": "32",
            "Line1": "P.O. Box 5",
            "City": "Middlefield",
            "CountrySubDivisionCode": "CA",
            "PostalCode": "94482",
            "Lat": "43.8249453",
            "Long": "-79.2639122"
                      },
            "supplier": "Brosnahan Insurance Agency",
            "company": "Brosnahan Insurance Agency",
            "email": "", 
            "subRows": [
                {
                "address": {
                "Id": "32",
                "Line1": "P.O. Box 5",
                "City": "Middlefield",
                "CountrySubDivisionCode": "CA",
                "PostalCode": "94482",
                "Lat": "43.8249453",
                "Long": "-79.2639122"
                          },
                "supplier": "Brosnahan Insurance Agency",
                "company": "Brosnahan Insurance Agency",
                "email": "",
                "subRows":[]
                }
            ]
            }
        ]
    },
    {
        "_id": "62d68fb3d2401d208cc98d8e",
        "address": "",
        "supplier": "Cigna Health Care",
        "company": "Cigna Health Care",
        "email": "",
        "supplierId": "34",
        "metaData": {
            "createdTime": "2021-10-16T10:14:48-07:00",
            "lastUpdatedTime": "2021-10-16T10:14:48-07:00"
        },
        "givenName": "",
        "familyName": "",
        "printOnCheckName": "Cigna Health Care",
        "balance": 0,
        "active": true,
        "accoutNumber": "123456789",
        "primaryPhone": "(520) 555-9874",
        "mobile": "",
        "stripeDetails": "",
        "subRows": [
            {
            "address": {
            "Id": "32",
            "Line1": "P.O. Box 5",
            "City": "Middlefield",
            "CountrySubDivisionCode": "CA",
            "PostalCode": "94482",
            "Lat": "43.8249453",
            "Long": "-79.2639122"
                      },
            "supplier": "Brosnahan Insurance Agency",
            "company": "Brosnahan Insurance Agency",
            "email": "", 
            "subRows": [
                {
                "address": {
                "Id": "32",
                "Line1": "P.O. Box 5",
                "City": "Middlefield",
                "CountrySubDivisionCode": "CA",
                "PostalCode": "94482",
                "Lat": "43.8249453",
                "Long": "-79.2639122"
                          },
                "supplier": "Brosnahan Insurance Agency",
                "company": "Brosnahan Insurance Agency",
                "email": "",
                "subRows":[]
                }
            ]
            }
        ]
    },
    {
        "_id": "62d68fb3d2401d208cc98d8f",
        "address": {
            "Id": "34",
            "Line1": "1515 Main St.",
            "City": "Middlefield",
            "CountrySubDivisionCode": "CA",
            "PostalCode": "94482",
            "Lat": "37.3787808",
            "Long": "-122.1158227"
        },
        "supplier": "Computers by Jenni",
        "company": "Computers by Jenni",
        "email": "Msfixit@Intuit.com",
        "supplierId": "35",
        "metaData": {
            "createdTime": "2021-10-16T10:16:29-07:00",
            "lastUpdatedTime": "2021-10-16T10:16:29-07:00"
        },
        "givenName": "Jenni",
        "familyName": "Winslow",
        "printOnCheckName": "Computers by Jenni",
        "balance": 0,
        "active": true,
        "primaryPhone": "(650) 555-8721",
        "mobile": "(650) 111-5648",
        "stripeDetails": ""
    },
    {
        "_id": "62d68fb3d2401d208cc98d90",
        "address": "",
        "supplier": "Diego's Road Warrior Bodyshop",
        "company": "Diego's Road Warrior Bodyshop",
        "email": "",
        "supplierId": "36",
        "metaData": {
            "createdTime": "2021-10-16T10:16:54-07:00",
            "lastUpdatedTime": "2021-10-21T11:31:42-07:00"
        },
        "givenName": "",
        "familyName": "",
        "printOnCheckName": "Diego's Road Warrior Bodyshop",
        "balance": 755,
        "active": true,
        "primaryPhone": "",
        "mobile": "",
        "stripeDetails": ""
    },
    {
        "_id": "62d68fb3d2401d208cc98d91",
        "address": "",
        "supplier": "EDD",
        "company": "EDD",
        "email": "",
        "supplierId": "37",
        "metaData": {
            "createdTime": "2021-10-16T10:17:10-07:00",
            "lastUpdatedTime": "2021-10-16T10:17:10-07:00"
        },
        "givenName": "",
        "familyName": "",
        "printOnCheckName": "EDD",
        "balance": 0,
        "active": true,
        "primaryPhone": "",
        "mobile": "",
        "stripeDetails": ""
    },
    {
        "_id": "62d68fb3d2401d208cc98d92",
        "address": {
            "Id": "35",
            "Line1": "45896 Main St.",
            "City": "Middlefield",
            "Country": "USA",
            "CountrySubDivisionCode": "CA",
            "PostalCode": "94303",
            "Lat": "37.445013",
            "Long": "-122.1391443"
        },
        "supplier": "Ellis Equipment Rental",
        "company": "Ellis Equipment Rental",
        "email": "Rental@intuit.com",
        "supplierId": "38",
        "metaData": {
            "createdTime": "2021-10-16T10:19:42-07:00",
            "lastUpdatedTime": "2021-10-16T10:19:42-07:00"
        },
        "givenName": "Julie",
        "familyName": "Ellis",
        "printOnCheckName": "Ellis Equipment Rental",
        "balance": 0,
        "active": true,
        "accoutNumber": "39765",
        "primaryPhone": "(650) 555-3333",
        "mobile": "(650) 445-3333",
        "stripeDetails": ""
    }
]