import { getCustomers, getDisputCustomers } from "services/customer.services";
import { useQuery } from "react-query";
import customersServices from "services/customers.services";

export const useCustomers = (page, filterData) => {
  return useQuery(
    ["customers", { page, filterData }],
    () => getCustomers(page, filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};
export const DisputCustomers = (page, filterData) => {
  return useQuery(
    ["customers", { page, filterData }],
    () => getDisputCustomers(page, filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useCustomersListing = (filterData) => {
  return useQuery(
    ["customerslisting", { filterData }],
    () => customersServices.getcustomerListing(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};
export const GetallCustomerName = (filterData) => {
  return useQuery(
    ["getallCustomerName", { filterData }],
    () => customersServices.getallCustomerName(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const GetAllTransaction = (filterData, mo = false) => {
  return useQuery(
    ["customers_transactions", { filterData }],
    () => customersServices.getcustomerTrans(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const GetCustomerData = (signUpData) => {
  return useQuery(
    ["signUpData", { signUpData }],
    () => customersServices.GetCustomerData(signUpData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const EnablePaymentLink = (EnablePaymentLinkData) => {
  return useQuery(
    ["enablePaymentLink", { EnablePaymentLinkData }],
    () => customersServices.EnablePaymentLink(EnablePaymentLinkData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const PaymentMethods = (data) => {
  return useQuery(
    ["paymentMethods", { data }],
    () => customersServices.paymentMethod(data),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const TransactionStatus = (data) => {
  return useQuery(
    ["transactionStatus", { data }],
    () => customersServices.transactionStatusData(data),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const CustomerChildren = (data) => {
  return useQuery(
    ["childrenData", { data }],
    () => customersServices.getCustomerChildren(data),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};
