import axios from "utils/APIv2";

export const updateUserProfile = async (values) => {
  const payloadTransformation = (object) => {
    Object.keys(object).forEach((mkey) => {
      Object.keys(object[mkey]).forEach((key) => {
        if (object[mkey][key] === "") {
          object[mkey][key] = null;
        }
      });
    });
    return object;
  };

  const FormattedUpdateFormData = (values) => {
    let formData = new FormData();
    let profileDetails = {
      givenName: values.firstName,
      familyName: values.lastName,
      phoneNumber: values.mobile,
      email: values.email,
      timeRangePreferences: values.timeRangePreferences,
      customRangeValue: values.customrange,
      cashThreshold: payloadTransformation(values.cashThreshold),
      ordersThreshold: payloadTransformation(values.ordersThreshold),
      receivablesThreshold: payloadTransformation(values.receivablesThreshold),
      payablesThreshold: payloadTransformation(values.payablesThreshold),
      collectionsThreshold: payloadTransformation(values.collectionsThreshold),
    };

    formData.append("data", JSON.stringify(profileDetails));
    formData.append("file", values.profileImage);

    return formData;
  };

  const newFormData = FormattedUpdateFormData(values);

  return await axios.patch(`/user-profile`, newFormData);
};

export const getProfile = async () => {
  const res = await axios.get(`/profile`);

  const responseTransformation = (object) => {
    Object.keys(object).forEach((mkey) => {
      Object.keys(object[mkey]).forEach((key) => {
        if (object[mkey][key] === null) {
          object[mkey][key] = "";
        }
      });
    });
    return object;
  };

  const transformedValues = {
    bucketUrl: res.data.bucketUrl,
    business: res.data.business,
    cashThreshold: responseTransformation(res.data.cashThreshold),
    collectionsThreshold: responseTransformation(res.data.collectionsThreshold),
    createdAt: res.data.createdAt,
    customRangeValue: res.data.customRangeValue,
    dashboardPreferences: res.data.dashboardPreferences,
    email: res.data.email,
    familyName: res.data.familyName,
    givenName: res.data.givenName,
    ordersThreshold: responseTransformation(res.data.ordersThreshold),
    payablesThreshold: responseTransformation(res.data.payablesThreshold),
    permissions: res.data.permissions,
    phoneNumber: res.data.phoneNumber,
    receivablesThreshold: responseTransformation(res.data.receivablesThreshold),
    role: res.data.role,
    status: res.data.status,
    timeRangePreferences: res.data.timeRangePreferences,
    updatedAt: res.data.updatedAt,
    billingAddress: res.data.billingAddress,
    __v: res.data.__v,
    _id: res.data._id,
  };
  return transformedValues;
};
export const getRoles = async () => {
  return await axios.get(`/roles`);
};