import BaseService from "./baseClass.services";
import moment from "moment";
import { apiURL } from "utils/base-url";
import axios from "utils/APIv2";


class BusinessService extends BaseService {
 getallbusiness = async (filterData, mo = false) => {
    const params = {
      field: filterData.columnName,
      sort: filterData.order,
      pageNo: filterData.pageNumber,
      limit: 10,
    //   name: filterData.name,
    //   description: filterData.description,
    //   parent_business: filterData.parent_business,
    };
    const res = await axios.get(`/business/list`, { params });
    return res.data;
  };


 addbusiness = async (values) => {
  return await axios.post(`/business/add`, values);
};



 asigndUserAddFordisputType = async (values) => {
    return await axios.post(`/add/approvers/business`,values);
  };






}
export default new BusinessService();


