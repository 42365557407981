import axios from "utils/APIv2";

export const getQuickBooksSettings = async () => {
  const res = await axios.get(`/qbo-settings`);
  return res.data;
};

export const getQuickBooksDesktopSettings = async (payload) => {
  const res = await axios.post(`/qbo-settings/desktop`, payload);
  return res.data;
};

export const setQuickBooksSettings = async (payload) => {
  return await axios.post(`/qbo-settings`, payload);
};

export const setQboCode = async (payload) => {
  return await axios.post(`/qbo-settings/generate-tokens`, payload);
};

export const getDataFetchStatus = async () => {
  return await axios.get(`/qbo-settings/cronStatus`);
};

export const getQBOUri = async () => {
  const res = await axios.get(`/qbo-settings/authUri`);
  return res.data;
};
