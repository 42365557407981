import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Row, Col } from "react-bootstrap";

import { Modal as BSModal } from "react-bootstrap";
import { ReceivablesIcon } from "assets/icons.js";
import CrossIcon from "assets/icon-cross.svg";

export const StyledModal = styled.div`
  .modal-text {
    text-align: left;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #909090;
    opacity: 1;
    margin-bottom: 0px;
  }
  .name {
    height: 29px;
    background-color: rgba(255, 255, 255, 0.18);
    border: 1px solid #707070;
  }
  .data {
    height: 29px;
    border-bottom: 1px solid #707070;
  }
  .modal-header {
    padding: 1rem 1rem;
    border-bottom: 1px solid #707070 !important;
    margin: 10px;
    .modal-body {
      padding: 0rem 1rem;
    }
    h1 {
      font-family: Montserrat Alternates-Bold;
      text-align: left;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }
  }
`;
export const Modal = styled(BSModal)`
  /* z-index: 77788; */

  .modal-content {
    background-color: #3a3b48;
    opacity: 1;
  }
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
    .btn-close {
      font-size: 20px;
      color: white !important;
      background-image: url(${CrossIcon});
    }
    h3 {
      font-family: Montserrat Alternates-Bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0px;
      color: #ffffff;
      margin-top: 5px;
    }
    .exportdiv {
      width: 57px;
      height: 40px;
      background: #3a3b48 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 26px #00000017;
      border: 1px solid #707070;
      border-radius: 20px;
      margin: 0px 15px;
      text-align: center;
      padding: 5px;
      cursor: pointer;
      svg {
        path {
          fill: #33c2df;
        }
      }
    }
  }
  .modal-body {
    .upper-section {
      border-top: 1px solid #d0d0d0;
      border-bottom: 1px solid #d0d0d0;
      padding: 17px;
      p {
        font-family: Montserrat Alternates-semiBold;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0px;
        color: #909090;
        margin-bottom: 5px;
      }
      .total-value {
        text-align: left;
        font-size: 22px;
        line-height: 27px;
        font-family: Montserrat Alternates-Bold;
        letter-spacing: 0px;
        color: #ffffff;
      }
      label {
        display: block;
        text-align: left;
        font-size: 13px;
        line-height: 14px;
        font-family: Montserrat Alternates-Medium;
        letter-spacing: 0px;
        color: #33c2df;
        opacity: 1;
      }
      span {
        left: 200px;
      }
    }
    .graph-section {
      .graph-filters {
        text-align: center;
        font-family: Montserrat Alternates-semiBold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0px;
        color: #909090;
        margin-top: 15px;
      }
    }
  }
`;

// function Modal() {
//   const [show, setShow] = useState(true);
//   return (
//     <>
//       <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
//         <Modal.Header closeButton>
//           <h3>SDF microchips</h3>
//           <div>
//             <ReceivablesIcon />
//           </div>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="upper-section">
//             <Row>
//               <Col>
//                 <p>Customer since</p>
//                 <span>1990</span>
//               </Col>
//               <Col>
//                 <p>Total Orders</p>
//                 <span>$30,890,000</span>
//                 <label>$02,890,000 (1Y)</label>
//               </Col>
//               <Col>
//                 <p>Paid on Time</p>
//                 <span>10%</span>
//                 <label>5% (1Y)</label>
//               </Col>
//             </Row>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// }
// export default Modal;
