import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Input = (props) => {
  return (
    <div >
      {props.options.length != props.selectProps.value?.length ?
      <span className="react-select-container"><components.Input {...props} />Enter user name</span>
      :""}
    </div>
  );
};
const styles = {
  placeholder(base, state) {
   return {
     ...base,
     display: state.isFocused && 'none',
   };
 }
}
const StyledSelect = styled.div`
  .react-select-container .react-select__control {
    background: #3a3b48 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #FFFFFF;
    border-radius: 0px;
    color: #909091;
    height: 29px;
    padding: 1px;
    width: 100%;
  }
  .react-select-container .react-select__menu-list {
    background: #3a3b48 0% 0% no-repeat padding-box;
  }
  .react-select-container .react-select__option {
    color: #b5b5b5;
    background: #3a3b48 0% 0% no-repeat padding-box;
    &:hover {
      background: #484A58 0% 0% no-repeat padding-box;
    }
  }
  .react-select-container .react-select__multi-value {
    background: #484A58 0% 0% no-repeat padding-box; 
    border-radius: 10px;
    width: 69px;
    height: 19px;
  }
  .react-select-container .react-select__multi-value__label {
    color: #B5B5B5;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 25px;
    align-items: center;
    border-radius: 8px;
    display: flex;
    padding-left: 8px;
    padding-right: 4px;
  }
  .react-select-container .react-select__input-container {
    color: #B5B5B5;
  }
  .react-select-container .react-select__multi-value__remove {
     background: #484A58 0% 0% no-repeat padding-box;
    &:hover {
    background: #484A58 0% 0% no-repeat padding-box;
    color: #B5B5B5;
    }
  }
  .react-select-container .react-select__indicators {
   display: none;
  }
  .react-select-container .react-select__placeholder {
   display: none;
  }
`;

const Options = [
  { value: "User1", label: "User1" },
  { value: "User2", label: "User2" },
  { value: "User3", label: "User3" },
  { value: "User4", label: "User4" },

];


export default function Tags({options,placeholder,onchange,value, defaultValue}) {
  return (
    <>
      <StyledSelect>
        <ReactSelect
          options={options? options: Options }
          isMulti
          defaultValue={defaultValue}
          placeholder={placeholder ? placeholder : "Enter user name"}
          components={{
            Input,
            IndicatorSeparator: () => null,
            DropdownIndicator:() => null,
          }}
          classNamePrefix="react-select"
          className="react-select-container"
          onChange={onchange}
          value={value}
        />
      </StyledSelect>
    </>
  );
}
