import React from "react";
import { useEffect } from "react";
import moment from "moment";

import { BasicButton } from "ui/Buttons";
import customersServices from "services/customers.services";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import { Route_URLs } from "routesURLs";
const SuccessPaymentPage = () => {
  const transactionId = sessionStorage.getItem("transactionId");
  const email = sessionStorage.getItem("email");
  const name = sessionStorage.getItem("name");
  const amount = sessionStorage.getItem("amount");
  let timestamp = sessionStorage.getItem("timestamp");
  const status = sessionStorage.getItem("status");
  const transactionType = sessionStorage.getItem("transactionType");
  const customerId = sessionStorage.getItem("customerId");
  const [loading, setLoading] = useState(true);
  let type;
  timestamp = parseInt(timestamp);
  const history = useHistory();

  if (transactionType == "ach") {
    type = "ACH";
  } else if (transactionType == "Credit") {
    type = "Credit Card";
  } else if (transactionType == "Debit") {
    type = "Debit Card";
  } else if (transactionType == "classic_ach") {
    type = "Classic ACH";
  }
  const handleDownload = async () => {
    const url = await customersServices.downloadPdf(transactionId, customerId);

    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; 

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace(Route_URLs.signin);
      }
    };
  }, [history]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {loading ? (
        <>
          <Spinner animation="border" style={{ color: "#fff" }}></Spinner>
        </>
      ) : (
        <div>
          <div
            style={{
              border: "1px solid grey",
              background: "rgb(58,59,73)",
              padding: "20px 40px",
              borderRadius: "8px",
            }}
          >
           <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {status === "APPROVED" ? (
                <h2
                  style={{
                    color: "#32A200",
                    fontWeight: "600",
                    fontSize: "34px",
                    padding: "0px 25px",
                  }}
                >
                  Success!
                </h2>
              ) : (

                <h2
                  style={{
                    color: "#d5d51e",
                    fontWeight: "600",
                    fontSize: "34px",
                    padding: "0px 25px",
                  }}
                >
                     Processing!

                </h2>


              )}
            </div>

            <div
              style={{
                color: "grey",
                borderTop: "1px solid lightgray",
                paddingTop: "10px",
                display: "grid"
              }}
            >
              <table>
                <tr>
                  <td style={{ fontWeight: "600", textAlign: "left" }}>
                    Payment Method
                  </td>
                  <td
                    style={{
                      textAlign: "end",
                      color: "#909090",
                      fontWeight: "600",
                    }}
                  >
                    {type}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "600", textAlign: "left" }}>
                    TransactionId
                  </td>
                  <td
                    style={{
                      textAlign: "end",
                      color: "#909090",
                      fontWeight: "600",
                    }}
                  >
                    {transactionId}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "600", textAlign: "left" }}>
                    Request Date
                  </td>
                  <td
                    style={{
                      textAlign: "end",
                      color: "#909090",
                      fontWeight: "600",
                    }}
                  >
                    {moment(timestamp).format("YYYY-MM-DD")}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "600", textAlign: "left" }}>
                    Total
                  </td>
                  <td
                    style={{
                      textAlign: "end",
                      color: "#909090",
                      fontWeight: "600",
                    }}
                  >
                    ${amount}
                  </td>
                </tr>
                <tr></tr>
           
              </table>
              <BasicButton
                onClick={handleDownload}
                style={{
                  background:
                    "linear-gradient(270deg, rgb(51, 194, 223) 0%, rgb(0, 129, 197) 100%) 0% 0% no-repeat;",
                  width: "98%",
                }}
              >
                Generate Receipt
              </BasicButton>
              {
                transactionType === "ach" ? (
                  <div
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "13px",
                      padding: "12px 35px 5px 35px",
                    }}
                  >
                    *Transactions done through Plaid will be processed within 2 business days.
                  </div>
                ) : transactionType === "classic_ach" ? (
                  <div
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "13px",
                      padding: "12px 35px 5px 35px",
                    }}
                  >

                    *Transactions will be processed within 7 business days.
                  </div>
                ) : ""
              }


              <p style={{ textAlign: "center", marginTop: "15px" }}>
                &copy;2024 SimpliCapital.All rights Reserved{" "}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuccessPaymentPage;
