import React from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { BasicButton } from "ui/Buttons";

const RedirectPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 1000);
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundColor: "#3a3b46",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",

        }}
      >
        <div style={{ fontWeight: "bold", color: "#fff" }}>
          <span
            style={{
              fontSize: "35px",
              fontFamily: "Montserrat Alternates-Bold",
            }}
          >
            Redirecting <Spinner animation="grow" size="sm" className="me-1" />
            <Spinner animation="grow" size="sm" className="me-1" />
            <Spinner animation="grow" size="sm" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default RedirectPage;
