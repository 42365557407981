import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { setQuickBooksSettings } from "services/quickbooks.services";

export const useQuickBooksSaveSettings = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => setQuickBooksSettings(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("quickBooksSettings");
    },
  });
};
