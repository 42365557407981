import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #3a3b48;
    border: 1px solid #707070;
    border-radius: 3px;
    display: inline-block;
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    /* &:after {
      content: "✔";
      color: #3a3b48;
      position: absolute;
      top: -60%;
      font-size: 11px;
      padding: 2px;
    } */
    &:checked {
      &:after {
        content: "✔";
        color: #33c2df;
        position: absolute;
        top: -60%;
        font-size: 11px;
        padding: 2px;
      }
    }
  }
  label {
    font-family: Montserrat Alternates-semiBold;
    font-size: 11px;
    line-height: 25px;
    color: #808186;
    margin-left: 10px;
  }
`;
export default function Checkbox({ label, value, name, onChange }) {
  return (
    <StyledCheckbox>
      <input type="checkbox" name={name} checked={value} onChange={onChange} />
      <label>{label}</label>
    </StyledCheckbox>
  );
}
