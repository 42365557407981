import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "App.css";
import { useEffect, useState } from "react";
import GlobalFonts from "assets/monserrat-fonts/fonts";
import { UserContext } from "context/UserContext";
import InsightsContext from "context/InsightsContext";
import { QuickBookConnectionContext } from "context/QuickBookConnectionContext";
import { Amplify, Auth } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import AppRoutes from "AppRoutes";
import jwt_decode from "jwt-decode";
import { UIContext } from "context/UIContext";
import { useQuery, QueryClientProvider, QueryClient } from "react-query";
import { getDataFetchStatus, getQBOUri } from "services/quickbooks.services";
import { getOracleDataFetchStatus } from "services/ornet.services";
import { ReactQueryDevtools } from "react-query/devtools";
import { getUserIntegrations } from "services/user.service";
import { Route_URLs } from "routesURLs";
import { Modal, StyledModal } from "ui/Modal";
import styled from "styled-components";
import moment from "moment";
const BackStyledButton = styled.button`
  background: transparent linear-gradient(270deg, #33c2df 0%, #0081c5 100%);
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  text-align: left;
  font-family: Montserrat Alternates-semiBold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 12px;
`;
function App() {
  const [user, setUserdata] = useState(null);
  const [userNotConfirmed, setUserNotConfirmed] = useState(null);
  const [HighlightInsightsBulbs, setHighlightInsightsBulbs] = useState();
  const [AccordionOpenState, setAccordionOpenState] = useState();
  const [onBoarding, setOnBoarding] = useState(false);
  const queryClient = new QueryClient();

  //hide status bar for demo
  const [quickbookImportInProgress, setQuickbookImportInProgress] =
    useState(false);

  const [alerts, setAlerts] = useState("");

  const SetBulbs = (value) => {
    setHighlightInsightsBulbs(value);
  };
  const SetAccordion = (value) => {
    setAccordionOpenState(value);
  };

  const setUser = (data) => {
    setUserdata(data);
  };
  const setUserUnconfirmed = (data) => {
    setUserNotConfirmed(data);
  };

  let isAuthenticated = false;
  let isAdminAuthenticated = false;

  const authToken = sessionStorage.getItem("userToken");
  const adminAuthToken = sessionStorage.getItem("adminToken");

  if (authToken && authToken !== "") {
    isAuthenticated = true;
  }

  if (user && typeof user != "undefined") {
    isAuthenticated = true;
  }

  /* for admin login */
  if (adminAuthToken && adminAuthToken !== "") {
    isAdminAuthenticated = true;
  }

  // if (authToken) {
  //   const decodedData = jwt_decode(authToken);
  //   console.log("decoded : ", JSON.stringify(decodedData));
  //   const url = new URL(decodedData.iss);
  //   const poolId = url.pathname.substring(1);

  //   Amplify.configure({
  //     aws_cognito_region: "us-east-1",
  //     aws_user_pools_id: poolId,
  //     aws_user_pools_web_client_id: decodedData?.client_id,
  //   });
  // }

  /* changed while netsuite release */
  useEffect(async () => {
    // call needs to be made only after signing in
    let windowArray = [
      "/",
      "/sign-in",
      "/sign-up/create-account",
      "/forgot-password",
      "/account/verify",
      "/account/password-reset",
    ];

    let adminWindowArray = ["/admin"];

    let startsWithAdmin = adminWindowArray.some((adminString) =>
      window.location.pathname.startsWith(adminString)
    );
    if (!windowArray.includes(window.location.pathname) && !startsWithAdmin) {
      const connectionResponse = await getUserIntegrations();
      if (
        connectionResponse?.data.name == "qbo" ||
        connectionResponse?.data.name == "qbd"
      ) {
        let response = await getDataFetchStatus();
        setAlerts(response?.data?.alert);
        if (response.data.status !== "Completed") {
          setQuickbookImportInProgress(true);
        } else {
          setQuickbookImportInProgress(false);
        }
      } else if (connectionResponse?.data.name == "oracle") {
        let response = await getOracleDataFetchStatus();
        setAlerts(response?.data?.alert);
        if (response.data.status) {
          setQuickbookImportInProgress(false);
        } else {
          setQuickbookImportInProgress(true);
        }
      }
    }
  }, []);

  // const { isSuccess, data } = useQuery("qb-status", getDataFetchStatus, {
  //   enabled: isAuthenticated,
  //   refetchInterval: (data) =>
  //     data && data.data.status === "Completed" ? false : 2000,
  // });

  // useEffect(() => {
  //   if (isSuccess && data.data.status !== "Completed") {
  //     setQuickbookImportInProgress(true);
  //   } else {
  //     setQuickbookImportInProgress(false);
  //   }
  // }, [data, isSuccess]);

  const [showPopup, setshowPopup] = useState(false);
  let popupTimeout = 15 * 60 * 1000; // 15 minutes
  let autoLogoutTimeout = 16 * 60 * 1000; //   16 minutes
  // This function will reset the timer whenever there's user activity
  const handleAutoLogout = async () => {
    await Auth.signOut();
    sessionStorage.clear();
    localStorage.clear();
    clearInterval(loggedinstateinterval);
    window.location.pathname = "/";
    localStorage.setItem("scLastActivityTime", 0);
  };

  let loggedinstateinterval = setInterval(() => {
    let lastActivityTime = localStorage.getItem("scLastActivityTime");
    let currentTime = new Date().getTime();
    let windowArray = [
      "/",
      Route_URLs.createaccount,
      Route_URLs.signin,
      Route_URLs.customer_signup,
      Route_URLs.forgotpassword,
      Route_URLs.accountverify,
      Route_URLs.passwordreset,
    ];

    let adminWindowArray = ["/admin"];

    let startsWithAdmin = adminWindowArray.some((adminString) =>
      window.location.pathname.startsWith(adminString)
    );

    if (
      !windowArray.includes(window.location.pathname) &&
      !startsWithAdmin &&
      lastActivityTime !== 0 &&
      lastActivityTime !== null &&
      lastActivityTime !== undefined
    ) {
      let idleTime = currentTime - lastActivityTime;
      if (idleTime >= autoLogoutTimeout) {
        handleAutoLogout();
      } else if (idleTime >= popupTimeout) {
        setshowPopup(true);
      } else {
      }
    }
  }, [30 * 1000]);

  const resetTimerOnEvents = () => {
    const events = ["mousemove", "mousedown", "keypress", "touchstart"];
    events.forEach((event) => {
      window.addEventListener(event, () => {
        if (
          localStorage.getItem("scLastActivityTime") !== 0 &&
          localStorage.getItem("scLastActivityTime") !== null &&
          localStorage.getItem("scLastActivityTime") !== undefined
        ) {
          let windowArray = [
            "/",
            Route_URLs.createaccount,
            Route_URLs.signin,
            Route_URLs.customer_signup,
            Route_URLs.forgotpassword,
            Route_URLs.accountverify,
            Route_URLs.passwordreset,
          ];

          let adminWindowArray = ["/admin"];

          let startsWithAdmin = adminWindowArray.some((adminString) =>
            window.location.pathname.startsWith(adminString)
          );

          if (
            !windowArray.includes(window.location.pathname) &&
            !startsWithAdmin
          ) {
            localStorage.setItem(
              "scLastActivityTime",
              new Date().getTime().toString()
            );
          }
        }
      });
    });
  };

  // This useEffect run and update any event wise last activity time in local storage
  // adding qbo accesstoken fix for automatic redirection

  async function isQboAuthNeeded() {
    if (adminAuthToken && adminAuthToken == "") {
      try {
        let isQboAuthNeeded = await getQBOUri();
        if (isQboAuthNeeded?.authUri != "") {
          window.location.href = isQboAuthNeeded?.authUri;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  useEffect(() => {
    resetTimerOnEvents();
    isQboAuthNeeded();
  }, []);

  return (
    <>
      {showPopup && (
        <Modal
          show={showPopup}
          onHide={() => setshowPopup(false)}
          centered
          style={{}}
        >
          <StyledModal>
            <div className="logout-modal">
              <Modal.Body style={{ padding: "15px" }}>
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ width: "150px" }}
                    src="https://allassets.s3.amazonaws.com/stillthere2-a34f5b3.svg"
                  />
                </div>
                <p style={{ color: "rgb(144, 144, 144)", textAlign: "center" }}>
                  Inactive Session Alert!
                  <br />
                  You have been inactive for 15 minutes. You will be
                  automatically logged out.
                </p>
                <div style={{ textAlign: "center", marginTop: "15px" }}>
                  <BackStyledButton
                    style={{
                      color: "#fff",
                      borderRadius: "6px",
                      padding: "9px 20px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setshowPopup(false)}
                  >
                    {" "}
                    I'm back
                  </BackStyledButton>
                </div>
              </Modal.Body>
            </div>
          </StyledModal>
        </Modal>
      )}
      <QueryClientProvider client={queryClient}>
        <UserContext.Provider
          value={{
            user,
            userNotConfirmed,
            setUserUnconfirmed,
            setUser,
          }}
        >
          <UIContext.Provider value={{ onBoarding, setOnBoarding }}>
            <QuickBookConnectionContext.Provider
              value={{
                quickbookImportInProgress,
                setQuickbookImportInProgress,
                alerts,
                setAlerts,
              }}
            >
              <InsightsContext.Provider
                value={{
                  HighlightInsightsBulbs,
                  AccordionOpenState,
                  SetBulbs,
                  SetAccordion,
                }}
              >
                <GlobalFonts />
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>SimpliCapital</title>
                </Helmet>
                <AppRoutes
                  isAuthenticated={isAuthenticated}
                  isAdminAuthenticated={isAdminAuthenticated}
                />
              </InsightsContext.Provider>
            </QuickBookConnectionContext.Provider>
          </UIContext.Provider>
        </UserContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}
export default App;
