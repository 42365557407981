import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Nav as BSNav } from "react-bootstrap";
import Logo from "assets/SimpliCapital-logo.png";
import CollapsedLogo from "assets/logo.png";
import {
  ScenarioIcon,
  ArrowExpandIcon,
  TopArrowSubmenuIcon,
  Tenants,
  User,
  Session,
  DataLoads,
  Analytics,
  Subscriptions,
  Switch,
  LoginIcon,
} from "assets/icons.js";
import { Link } from "react-router-dom";
import { Route_URLs } from "routesURLs";

const StyledSidebar = styled.div`
  background: #3a3b48;
  box-shadow: 0px 3px 16px #0000001d;
  position: fixed;
  display: block;
  padding: 0;
  z-index: 5;
  height: 100%;
  padding: 20px;
  /* overflow: auto; */
  width: ${(props) => (props.collapsed ? "85px" : "211px")};
  transition: all 0.2s ease-in;
  z-index: 55555;
  top: 0;

  ${({ disableTransitionEffects }) =>
    disableTransitionEffects &&
    `
   transition: none !important;
  `}

  .nav-link.active svg {
    fill: #33c2df;
  }

  .nav-text {
    opacity: ${(props) => (props.collapsed ? "0" : "1")};
    display: ${(props) => (props.collapsed ? "none" : "inline-block")};
    width: ${(props) => (props.collapsed ? "0" : "63%")};
    transition: opacity 0.7s ease-in;
  }

  .toggle-button {
    transform: ${(props) =>
      props.collapsed ? "rotate(0deg)" : "rotate(180deg)"};
    transition: transform 0s;

    svg {
      width: 41px;
      height: 41px;
    }
  }

  .indicator-icon,
  .submenu > div {
    display: ${(props) => (props.collapsed ? "none" : "block")};
  }

  .sub-menu {
    .nav-link {
      display: ${(props) => (props.collapsed ? "none" : "block")};
    }
  }
  .logo {
    display: ${(props) => (props.collapsed ? "none" : "block")};
  }
  .collapsed-logo {
    display: ${(props) => (props.collapsed ? "block" : "none")};
  }

  .indicator-icon {
    opacity: ${(props) => (props.collapsed ? "0" : "1")};
  }
`;

const SidebarShadow = styled(StyledSidebar)`
  position: relative;
  height: 1px;
  z-index: 555;
  top: 0;
  left: 0px;
  background: #3a3b48;
`;

const StyledLogo = styled.div`
  img {
    width: 100%;
  }
  margin-bottom: 36px;
`;
const StyledCollapsedLogo = styled.div`
  img {
    width: auto;
  }
  margin-bottom: 36px;
`;

const Nav = styled(BSNav)`
  flex-direction: column;

  svg {
    margin-right: 10px;
    font-size: 13px;
  }

  a.nav-link {
    text-align: left;
    font-family: Montserrat Alternates-semiBold;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 0.5rem;
    padding: 0.4rem 1rem;
    margin-left: 10x;
    z-index: 1;
    max-height: 42px;
  }
`;

const ExpandIcon = styled.div`
  position: absolute;
  //right: 0;
  //left: 0;
  bottom: 0;
  //text-align: center;
  width: 77%;
  padding: 0;
  margin: 0 auto;
  margin-bottom: 50px;
  z-index: 2;

  .icon {
    cursor: pointer;
    z-index: 2;
    position: absolute;
  }
`;

const SubmenuContent = styled.div`
  padding-left: 8px;
  overflow: hidden;
`;

const SubmenuCaption = styled.div`
  color: #fff;
  font-family: Montserrat Alternates-semiBold;
  font-size: 10px;
  line-height: 13px;
  cursor: pointer;
`;

const StyledSubmenu = styled.div`
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;

  .indicator-icon {
    float: right;
    font-size: 1em;
    margin-top: ${(props) => (props.activeLink ? "11px" : "2px")};
    transform: ${(props) => (props.open ? "rotateZ(0deg)" : "rotateZ(90deg)")};
    transition: all 0.2s ease-in-out;
  }

  a.nav-link {
    margin: 0;
    margin-bottom: 0.4rem;
  }

  ${SubmenuContent} {
    transition: all 0.5s ease-in-out;
    margin-top: ${(props) => (props.open ? "12px" : "0")};
    height: ${(props) => (props.open ? "auto" : "0")};
  }
`;

const LockOverlay = styled.div`
  background: transparent;
  cursor: not-allowed;
  width: 100%;
  display: block;
  position: absolute;
  height: 380px;
  z-index: 9999999;
`;
const ActiveLink = styled.span`
  width: 4px;
  height: 20px;
  display: inline-block;
  background-color: #33c2df;
  position: relative;
  left: ${(props) => (props.collapsed ? "65%" : "45%")};
  top: 7px;
`;
const StyledIcon = styled.div`
  display: inline-block;
  svg {
    path {
      fill: ${(props) => (props.active ? "#33C2DF" : "#A5A4BF")};
    }
  }
`;

const Submenu = (props) => {
  const sidebarCollapsed = props.sidebarCollapsed;
  const [open, setOpen] = useState(!sidebarCollapsed);

  const toggleMenuOpen = (event) => {
    setOpen(!open);
    event.preventDefault();
    event.stopPropagation();
  };

  const shouldBeOpen = () => {
    if (sidebarCollapsed) {
      return false;
    } else {
      return open;
    }
  };

  return (
    <StyledSubmenu
      activeLink={props.activeLink}
      open={shouldBeOpen()}
      style={{ maxHeight: "150px" }}
    >
      <SubmenuCaption>
        <TopArrowSubmenuIcon className="indicator-icon" />
        <Link to={props.to}>{props.icon}</Link>
        <span className="nav-text" onClick={toggleMenuOpen}>
          {props.title}
        </span>
        {props.activeLink}
      </SubmenuCaption>
      <SubmenuContent>{props.children}</SubmenuContent>
    </StyledSubmenu>
  );
};

function Sidebar(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [disableTransitionEffects, setDisableTransitionEffects] =
    useState(false);

  const toggleSidebar = () => {
    setDisableTransitionEffects(false);
    setCollapsed(!collapsed);
  };

  const activeRoute = (routeUrl) => {
    if (routeUrl === window.location.pathname) return true;
    return false;
  };

  return (
    <>
      <SidebarShadow
        {...props}
        collapsed={collapsed}
        disableTransitionEffects={disableTransitionEffects}
      />
      <StyledSidebar
        {...props}
        collapsed={collapsed}
        disableTransitionEffects={disableTransitionEffects}
      >
        <StyledLogo className="logo">
          <a href={Route_URLs.operationDashboard}>
            <img alt="SimpliCapital" src={Logo}></img>
          </a>
        </StyledLogo>
        <StyledCollapsedLogo className="collapsed-logo">
          <a href={Route_URLs.operationDashboard}>
            <img alt="SimpliCapital" src={CollapsedLogo}></img>
          </a>
        </StyledCollapsedLogo>
        <Nav {...props}>
          <Nav.Link as={Link} to={Route_URLs.operationDashboard}>
            <StyledIcon active={activeRoute(Route_URLs.operationDashboard)}>
              <ScenarioIcon />
            </StyledIcon>
            <span className="nav-text">Dashboard</span>
          </Nav.Link>
          <Submenu
            className="sub-menu"
            sidebarCollapsed={collapsed}
            icon={
              <StyledIcon active={activeRoute(Route_URLs.operationDashboard)}>
                <LoginIcon style={{ width: "20px", fill: "white" }} />
              </StyledIcon>
            }
            title="Login"
          >
            <Nav.Link as={Link} to={Route_URLs.tenantList}>
              <StyledIcon active={activeRoute(Route_URLs.tenantList)}>
                <Tenants style={{ width: "20px", fill: "white" }} />
              </StyledIcon>
              <span className="nav-text">Tenant</span>
            </Nav.Link>
            <Nav.Link as={Link} to={Route_URLs.customerList}>
              <StyledIcon active={activeRoute(Route_URLs.customerList)}>
                <User style={{ width: "20px", fill: "white" }} />
              </StyledIcon>
              <span className="nav-text">Customer</span>
            </Nav.Link>
            <Nav.Link as={Link} to={Route_URLs.sessionList}>
              <StyledIcon active={activeRoute(Route_URLs.sessionList)}>
                <Session style={{ width: "20px", fill: "white" }} />
              </StyledIcon>
              <span className="nav-text">Audit</span>
            </Nav.Link>
          </Submenu>
          <Nav.Link as={Link} to={Route_URLs.tenantList}>
            <StyledIcon active={activeRoute(Route_URLs.analytics)}>
              <Analytics style={{ width: "20px", fill: "white" }} />
            </StyledIcon>
            <span className="nav-text">Analytics</span>
          </Nav.Link>
          <Nav.Link as={Link} to={Route_URLs.adminUsers}>
            <StyledIcon active={activeRoute(Route_URLs.adminUsers)}>
              <User style={{ width: "20px", fill: "white" }} />
            </StyledIcon>
            <span className="nav-text">Users</span>
          </Nav.Link>
          <Nav.Link as={Link} to={Route_URLs.dataLoads}>
            <StyledIcon active={activeRoute(Route_URLs.dataLoads)}>
              <DataLoads style={{ width: "20px", fill: "white" }} />
            </StyledIcon>
            <span className="nav-text">Data Loads</span>
          </Nav.Link>
          <Nav.Link as={Link} to={Route_URLs.subscriptions}>
            <StyledIcon active={activeRoute(Route_URLs.subscriptions)}>
              <Subscriptions style={{ width: "20px" }} />
            </StyledIcon>
            <span className="nav-text">Subscriptions</span>
          </Nav.Link>
          <Nav.Link as={Link} to={Route_URLs.featureSwitches}>
            <StyledIcon active={activeRoute(Route_URLs.featureSwitches)}>
              <Switch style={{ width: "20px", fill: "white" }} />
            </StyledIcon>
            <span className="nav-text">Feature Switch</span>
          </Nav.Link>
        </Nav>
        <ExpandIcon className="toggle-button">
          <ArrowExpandIcon className="icon" onClick={toggleSidebar} />
        </ExpandIcon>
      </StyledSidebar>
    </>
  );
}
export default Sidebar;
