import Card from "ui/Card";
import { LeftBlock, RightBlock } from "ui/general";
import { Link } from "react-router-dom";
import Loader from "ui/Loader";
import { useState } from "react";
function PerformanceCard(props) {
  const data = props;
  return (
    <Link className="link-to-pages" to="#">
      <Card>
        <Card.Header>{data?.isLoading ? <></> : <>{data.title}</>}</Card.Header>
        <Card.Body>
          {data?.isLoading ? (
            <Loader />
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="metric">{data?.value ? data?.value : 0}</span>
            </div>
          )}
        </Card.Body>
      </Card>
    </Link>
  );
}
export default PerformanceCard;
