import axios from "utils/APIv2";

export const getLinkToken = async () => {
  return await axios.get(`/bank-settings/create_link_token`);
};

export const getAUserAllBankingDetails = async () => {
  const res = await axios.get(`/bank-settings`);
  return res.data;
};

export const getUserCashInBank = async () => {
  const res = await axios.get(`/bank-settings/get-cash-in-bank`);
  return res.data;
};

export const getUserCashInBankForForecast = async () => {
  const res = await axios.get(`/bank-settings/get-cash-in-bank-forecast`);
  return res.data;
};

export const exchangePublicTokenForAccessToken = async (publicToken) => {
  return await axios.post(`/bank-settings/set_access_token`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: publicToken,
    }
  );
};

export const setAllTokensForUserBankAccount = async (data) => {
  return await axios.post(`/bank-settings/save_bank_details`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: data,
    }
  );
};

export const removeABankAccount = async (data) => {
  return await axios.post(`/bank-settings/remove_bank_details`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: data,
    }
  );
};

export const updateBankForForecasting = async (data) => {
  return await axios.post(`/bank-settings/add-bank-to-forecast`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: data,
    }
  );
};

export const updateBankForReauthorisation = async (data) => {
  return await axios.post(`/bank-settings/reauthorise-bank`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: data,
    }
  );
};