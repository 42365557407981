import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { addUser, getCustomerDetailsforSingup } from "services/user.service";

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation((User) => addUser(User), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("users");
    },
    // onError: (error) => {
    //   if (error.response && error.response.data.message) {
    //     alert(error.response.data.message)
    //   }
    // },
  });
};

export const GetcustomerDetails = () => {
  const queryClient = useQueryClient();
  return useMutation(({ body }) => getCustomerDetailsforSingup(body), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("GetCustomerdataformsearch");
    },
  });
};
