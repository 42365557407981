import BaseService from "./baseClass.services";
import moment from "moment";
import { apiURL } from "utils/base-url";
class OrderService extends BaseService {
  getall = async (filterData) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";
    const params = {
      field: filterData.columnName,
      sort: filterData.order,
      pageNo: filterData.pageNumber,
      limit: 10,
      customerName: filterData.searchString,
      minAmount: filterData.minAmount,
      maxAmount: filterData.maxAmount,
      startOrderDate: formatedInvoiceStart,
      endOrderDate: formatedInvoiceEnd,
      startDueDeliveryDate: formatedDeliveryStart,
      endDueDeliveryDate: formatedDeliveryEnd,
    };
    const res = await this.axios.get(`/order/invoices`, { params });
    return res.data;
  };

  getgraphdata = async (cid, filterType) => {
    const params = {
      customerId: cid,
      filterType,
    };
    const res = await this.axios.get(`/order/invoices/graph`, { params });
    return res.data;
  };
  getInsightsdata = async (currentLevel) => {
    const params = {
      rangetype: currentLevel,
    };
    const res = await this.axios.get(`/order/insights`, { params });
    return res.data;
  };
  getTotal = async () => {
    const res = await this.axios.get(`/order/total`);
    return res.data;
  };

  getOrdersListingData = async (currentLevel, manualOveride = false) => {
    const params = {
      rangetype: currentLevel,
      mo: manualOveride,
    };
    const res = await this.axios.get(`/order/listing`, { params });
    return res.data;
  };

  saveOrdersData = (payload) => {
    return this.axios.post(`/order/manual_override`, payload);
  };

  //export urls
  exportUrlOrderOverview = (currentLevel) => {
    return `${apiURL}/order/export?rangetype=${currentLevel}&key=${sessionStorage.getItem(
      "userToken"
    )}`;
  };
  exportUrlOrderInvoice = (filterData) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";

    return `${apiURL}/order/invoices/export?field=${
      filterData.columnName
    }&sort=${filterData.order}&pageNo=${
      filterData.pageNumber
    }&limit=10&customerName=${filterData.searchString}&minAmount=${
      filterData.minAmount
    }&maxAmount=${
      filterData.maxAmount
    }&startOrderDate=${formatedInvoiceStart}&endOrderDate=${formatedInvoiceEnd}&startDueDeliveryDate=${formatedDeliveryStart}&endDueDeliveryDate=${formatedDeliveryEnd}&key=${sessionStorage.getItem(
      "userToken"
    )}`;
  };
  exportUrlBarGraphOrders = (customerid, timeFilter) => {
    return `${apiURL}/orders/graph/export?customerId=${customerid}&filterType=${timeFilter}&key=${sessionStorage.getItem(
      "userToken"
    )}`;
  };
}
export default new OrderService();
