import styled from "styled-components";
export const LeftBlock = styled.div`
  float: left;
  display: block;
  position: relative;
  width: auto;
  height: 100%;

  span.change-value {
    display: block;
    position: absolute;
    width: 100px;
    left: 0;
    bottom: 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    bottom: 58px;
  }
`;

export const RightBlock = styled.div`
  float: right;
  display: block;
  position: relative;
  width: auto;
  @media (min-width: 768px) and (max-width: 1024px) {
    float: left;
  }
`;
