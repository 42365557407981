import { getSuppliers } from "services/supplier.services";
import { useQuery  } from "react-query";
import suppliersService from "services/suppliers.service";

export const useSuppliers = (page, filterData) => {
  return useQuery(
    ["suppliers", { page, filterData }],
    () => getSuppliers(page, filterData),
    {
      keepPreviousData: true,
      staleTime: 0.5 * 60 * 1000,
    }
  );
};

export const useSuppliersListing = (filterData) => {
  return useQuery(["suppliersdata", { filterData }], () =>
    suppliersService.getsuppliersListing(filterData)
  );
};
