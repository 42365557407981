import axios from "axios";
import { Header } from "./auth-header";
import { history } from "react-router";

const getAccessToken = async () => {
  const token =
    sessionStorage.getItem("isSuperUser") &&
    sessionStorage.getItem("tenantName") == null
      ? sessionStorage.getItem("adminToken")
      : sessionStorage.getItem("userToken");

  return token;
};

const APIv2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL_v2
    ? process.env.REACT_APP_API_URL_v2
    : "https://api.simplicapital.ai/api/",
});

APIv2.interceptors.request.use(
  async (config) => {
    config.headers["Authorization"] = `${
      "Bearer" + " " + (await getAccessToken())
    }`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

APIv2.interceptors.response.use(
  (res) => {
    if (res.status === 500) {
      alert("something went wrong");
    }

    if (res.status === 401) {
      alert("something went wrong");
    }

    return Promise.resolve(res);
  },
  (e) => {
    // alert("something went wrong");
    return Promise.reject(e);
  }
);

//Log API calls
axios.interceptors.request.use((req) => {
  // you must return the request object after you are done
  return req;
});

axios.interceptors.response.use((res) => {
  // you must return the response object after you are done
  return res;
});

export default APIv2;
