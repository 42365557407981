import Card from "ui/Card";
import styled from "styled-components";
import { InsightsIcon, ReportIcon } from "assets/icons.js";
import Loader from "ui/Loader";
import { useInsights } from "queries/stats";

const StyledInsightIcon = styled.div`
  margin-right: 10px;
  svg {
    font-size: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-right: 5px;
  }
`;
const StyledList = styled.ul`
  li {
    word-break: break-word;
  }
`;
function ReportDownloadCard(props) {
  // react query
  //   const { data, isLoading } = useInsights();
  const data = props;

  console.log(data);

  return (
    <Card>
      <Card.Header>{data.title}</Card.Header>
      <Card.Body>
        {props?.isLoading ? (
          <Loader />
        ) : (
          <>
            <StyledList>
              <li className={"hover-cursor"}>
                <StyledInsightIcon>
                  <ReportIcon fill={"rgb(51, 194, 223)"} />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Customers ({data?.data?.customers})
              </li>
              <li className={"hover-cursor"}>
                <StyledInsightIcon>
                  <ReportIcon fill={"rgb(51, 194, 223)"} />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Vendors ({data?.data?.vendors})
              </li>
              <li className={"hover-cursor"}>
                <StyledInsightIcon>
                  <ReportIcon fill={"rgb(51, 194, 223)"} />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Customers using sc ({data?.data?.usersC})
              </li>
              <li className={"hover-cursor"}>
                <StyledInsightIcon>
                  <ReportIcon fill={"rgb(51, 194, 223)"} />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Receivable Predictions ({data?.data?.promise2pay})
              </li>
              <li className={"hover-cursor"}>
                <StyledInsightIcon>
                  <ReportIcon fill={"rgb(51, 194, 223)"} />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Invoice Forecast ({data?.data?.promise2pay})
              </li>
              <li className={"hover-cursor"}>
                <StyledInsightIcon>
                  <ReportIcon fill={"rgb(51, 194, 223)"} />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Comments ({data?.data?.comments})
              </li>
              <li className={"hover-cursor"}>
                <StyledInsightIcon>
                  <ReportIcon fill={"rgb(51, 194, 223)"} />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Users ({data?.data?.users})
              </li>
              <li className={"hover-cursor"}>
                <StyledInsightIcon>
                  <ReportIcon fill={"rgb(51, 194, 223)"} />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Disputes ({data?.data?.disputes})
              </li>
              <li className={"hover-cursor"}>
                <StyledInsightIcon>
                  <ReportIcon fill={"rgb(51, 194, 223)"} />
                </StyledInsightIcon>
                {/* {data.receivableForecast?.text} */}
                Dispute Types ({data?.data?.disputeTypes})
              </li>
            </StyledList>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default ReportDownloadCard;
