import React, { useContext, useState } from "react";
import styled from "styled-components";
import Page from "ui/Page";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Form, InputwithLabel, Formik, RadioBlue } from "ui/forms";
import { BasicButton } from "ui/Buttons";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import AccordionContext from "react-bootstrap/AccordionContext";
import {useAdddiscounttosuppliers} from "mutations/adddiscount";
import { useProfile } from "queries/profile";
import { useEffect } from "react";
import { useRef } from "react";
import Checkbox from "ui/Checkbox";
import * as Yup from "yup";
const Link = styled.div`
  cursor: pointer;
  margin-top: 30px;
  display: flex;
  .icon {
    path {
      fill: #909090;
    }
  }
`;

const Text = styled.div`
  text-align: left;
  font-family: Montserrat Alternates-bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-weight: bold;
  margin-top: 37px;
`;
const Title = styled.span`
  text-align: left;
  font-family: Montserrat Alternates-bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #909090;
  opacity: 1;
`;
const RadioButtonDiv = styled.div`
  .form-field {
    margin-top: 0px;
  }
  margin-top: 10px;
  margin-left: 20px;
  width: 70%;
`;
const StyledAccordion = styled(Accordion)`
  .card {
    background-color: #262734;
    margin-bottom: 8px;
  }
  .card-header {
      font-family: Montserrat Alternates-semiBold;
      font-size: 11px;
      line-height: 25px;
      letter-spacing: 0px;
      color: #ffffff;
      background: #262734;
      height: 45px;
      border-bottom:0px;

    input[type="checkbox"] {
      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: transparent;
      border: 1px solid #707070;
      display: inline-block;
      width: 14px;
      height: 14px;@
      border-radius: 5px;
      background-repeat: no-repeat;
      &:after {
        content: "✔";
        color: transparent;
        position: absolute;
        top: -65%;
        font-size: 11px;
        padding: 2px;
      }
      &:checked {
        &:after {
          content: "✔";
          color: #33c2df;
          position: absolute;
          top: -65%;
          font-size: 11px;
          padding: 2px;
        }
      }
    }
  }
  .card-body {
    background: #262734;
    label {
      font-family: Montserrat Alternates;
      text-align: left;
      font-size: 12px;
      line-height: 25px;
      color: #808186;
      opacity: 1;
      margin-bottom: 0px;
      font-weight: 600;
    }
  }
`;
const RadioDiv = styled.div`
  padding-top: 8px;
  display: flex;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: baseline;
  input {
    background: transparent 0% 0% no-repeat padding-box !important;
    color: #808186 !important;
  }
  input[type="checkbox"] {
    margin-bottom: -3px;
    margin-right: 5px;
    background: transparent 0% 0% no-repeat padding-box;
    &:after {
      color: transparent;
    }
    &:checked {
      &:after {
        color: #33c2df;
      }
    }
  }
`;

function CustomToggle({
  children,
  eventKey,
  callback,
  setFieldValue,
  addeddiscountData,
  defaultActiveKey,
}) {
  let { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback && callback(eventKey);
    if (eventKey === "0") {
      addeddiscountData.hvd = 1 - addeddiscountData.hvd;
      if (
        document.querySelectorAll(".accordion-collapse") !== null &&
        document.querySelector(".accordion-collapse") !== null
      )
        document.querySelector(".accordion-collapse").classList.remove("show");
    }
    if (eventKey === "1") {
      addeddiscountData.lpp = 1 - addeddiscountData.lpp;
      if (
        document.querySelectorAll(".accordion-collapse") !== null &&
        document.querySelectorAll(".accordion-collapse")[1] !== null
      )
        document
          .querySelectorAll(".accordion-collapse")[1]
          .classList.toggle("show");

      // if ()
    }
    if (eventKey === "2") {
      addeddiscountData.epd = 1 - addeddiscountData.epd;
    }

    if (addeddiscountData && addeddiscountData.hvd && eventKey == "0") {
      if (Array.isArray(activeEventKey)) {
        if (activeEventKey.includes(eventKey)) activeEventKey.push("0");
        else activeEventKey.splice(activeEventKey.indexOf(eventKey), 1);
      } else activeEventKey = 0;
    }
    if (addeddiscountData && addeddiscountData.lpp && eventKey == "1") {
      if (Array.isArray(activeEventKey)) {
        if (activeEventKey.includes(eventKey)) activeEventKey.push("1");
        else activeEventKey.splice(activeEventKey.indexOf(eventKey), 1);
      } else activeEventKey = 1;
    }
    if (addeddiscountData && addeddiscountData.epd && eventKey == "2") {
      // activeEventKey = '2'
      if (Array.isArray(activeEventKey)) {
        if (activeEventKey.includes(eventKey)) activeEventKey.push("2");
        else activeEventKey.splice(activeEventKey.indexOf(eventKey), 1);
      } else activeEventKey = 2;
    }
    if (Array.isArray(activeEventKey))
      activeEventKey = [...new Set(activeEventKey)];
  });
  useEffect(() => {}, [eventKey]);

  if (defaultActiveKey !== undefined) {
  }

  return (
    <>
      <input
        type="checkbox"
        onChange={decoratedOnClick}
        checked={
          (addeddiscountData?.hvd == 1 && eventKey == "0") ||
          (addeddiscountData?.lpp == 1 && eventKey == "1") ||
          (addeddiscountData?.epd == 1 && eventKey == "2")
            ? true
            : false
        }
      />
      {children}
    </>
  );
}

export default function SupplierDiscount({
  show,
  onHide,
  supplierId,
  userId,
  addeddiscountData,
}) {
  const adddiscounttosuppliers = useAdddiscounttosuppliers();
  let [defaultActiveKeyVal, setdefaultActiveKey] = useState([]);
  let [disable, setdisable] = useState(false);
  let selectedaccordion = useRef([]);

  useEffect(() => {
   
    if (addeddiscountData?.hvd === 1) {
      setdefaultActiveKey(defaultActiveKeyVal.push("0"));
      let tempval = selectedaccordion.current;
      if (tempval.length > 0) selectedaccordion.current = tempval.push("0");
      else selectedaccordion.current = ["0"];
     
    }

    if (addeddiscountData?.lpp === 1) {
      setdefaultActiveKey(defaultActiveKeyVal.push("1"));
      let tempval = selectedaccordion.current;
      tempval.push("1");
      selectedaccordion.current = tempval;

      
    }

    if (addeddiscountData?.epd === 1) {
      setdefaultActiveKey(defaultActiveKeyVal.push("2"));
      let tempval = selectedaccordion.current;
      tempval.push("2");
      selectedaccordion.current = tempval;

  
    }
    setdefaultActiveKey([...new Set(defaultActiveKeyVal)]);
 

    return () => {

    };
  }, []);



  return (
    <Page>
      <Page.Body>
        <Formik
          initialValues={{ ...addeddiscountData }}
          onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
            // return
            values = { ...values, supplier_id: supplierId, vendor_id: userId };
            if (values.hvd) {
              if (!values.order_type) {
                setErrors({ order_type: "Order Quantity is required" });
                // setSubmitting(false)
                return;
              }
              if (!values.order_value) {
                setErrors({ order_value: "Order Value is required" });
                return;
              }
              if (!values.discount_type) {
                setErrors({ discount_type: "Select Discount is required" });
                return;
              }
              if (!values.discount_value) {
                setErrors({ discount_value: "Discount Value is required" });
                return;
              }
              // if (!values.discount_type) {
              //   setErrors({ discount_type: "Select Discount is required" })
              // }
              // if (!values.discount_type) {
              //   setErrors({ discount_type: "Select Discount is required" })
              // }
              // if (!values.discount_type) {
              //   setErrors({ discount_type: "Select Discount is required" })
              // }
              // return
            }

            if (values.lpp) {
              if (!values.late_payment_type) {
                setErrors({
                  late_payment_type: "Late Payment Type days is required",
                });
                return;
              }
              if (!values.lp_penalty_days) {
                setErrors({ lp_penalty_days: "penalty days is required" });
                return;
              }
              if (!values.lp_penalty_amt) {
                setErrors({ lp_penalty_amt: "penalty amount is required" });
                return;
              }
            }

            if (values.epd) {
              if (!values.early_payment_discount_type) {
                setErrors({
                  early_payment_discount_type:
                    "Early payment type start is required",
                });
                return;
              }
              if (!values.early_payment_discount_type2) {
                setErrors({
                  early_payment_discount_type2:
                    "Early payment discount type is required",
                });
                return;
              }

              if (!values.early_payment_discount_val) {
                setErrors({
                  early_payment_discount_val:
                    "Early payment discount value is required",
                });
                return;
              }
            }

            // if (values.discount_type)
            //   values.discount_type = parseInt(values.discount_type);
            // if (values.order_type)
            //   values.order_type = parseInt(values.order_type);
            // if (values.late_payment_type)
            //   values.late_payment_type = parseInt(values.late_payment_type);
            // if (values.lp_discount_type)
            //   values.lp_discount_type = parseInt(values.lp_discount_type);
            // if (values.early_payment_discount_type)
            //   values.early_payment_discount_type = parseInt(
            //     values.early_payment_discount_type
            //   );
            // if (values.early_payment_discount_type2)
            //   values.early_payment_discount_type2 = parseInt(
            //     values.early_payment_discount_type2
            //   );

            if (addeddiscountData && addeddiscountData._id) {
              values = { ...values, _id: addeddiscountData._id };
            }

            if (values.hvd == 0) {
              delete values.order_value;
              delete values.order_type;
              delete values.discount_type;
              delete values.discount_value;
            }

            if (values.lpp == 0) {
              delete values.late_charge_increase_days;
              delete values.late_charge_increase_amt;
              delete values.late_payment_type;
              delete values.lp_penalty_type;
              delete values.lp_penalty_amt;
              delete values.lp_penalty_days;
            }

            if(values.late_payment_type == 1){
              delete values.late_charge_increase_amt;
              delete values.late_charge_increase_days
            }

            if (values.epd == 0) {
              delete values.early_payment_discount_deccrease_day;
              delete values.early_payment_discount_deccrease_percent;
              delete values.early_payment_discount_start;
              delete values.early_payment_discount_type;
              delete values.early_payment_discount_type2;
              delete values.early_payment_discount_val;
            }
            if(values.early_payment_discount_type == 1){
              delete values.early_payment_discount_deccrease_day;
              delete values.early_payment_discount_deccrease_percent
            }

            setSubmitting(true);
            adddiscounttosuppliers
              .mutateAsync({ values })
              .then((note) => {
                onHide();
              })
              .catch(function (error) {});
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto">
              <StyledAccordion defaultActiveKey={defaultActiveKeyVal}>
                <Card className="card">
                  <Card.Header className="card-header">
                    <div>
                      <input
                        checked={values.hvd === 1 ? true : false}
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked === true)
                            setFieldValue("hvd", 1);
                          else {
                            setFieldValue("hvd", 0);
                          }
                        }}
                      />
                      <Title>High Volume Discount</Title>{" "}
                    </div>
                  </Card.Header>

                  {values.hvd === 1 && (
                    <div>
                      <Card.Body className="card-body">
                        <Row>
                          <Col style={{ display: "flex" }}>
                            <RadioDiv>
                              <label style={{ paddingRight: "10px" }}>
                                Order Quantity:
                              </label>
                              <RadioBlue
                                name="order_type"
                                options={[
                                  {
                                    key: "Units",
                                    value: "1",
                                    id: "1",
                                  },
                                  {
                                    key: "Amount",
                                    value: "2",
                                    id: "2",
                                  },
                                ]}
                              ></RadioBlue>
                            </RadioDiv>

                            {values.order_type == 1 ? (
                              <InputwithLabel
                                name="order_value"
                                type="number"
                                placeholder="Enter Units"
                                style={{ width: "129px" }}
                              />
                            ) : (
                              <InputwithLabel
                                name="order_value"
                                type="number"
                                placeholder="Enter Amount"
                                style={{ width: "129px" }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="m-0" style={{ display: "flex" }}>
                            <RadioDiv>
                              <label style={{ paddingRight: "10px" }}>
                                Select Discount:
                              </label>
                              <RadioBlue
                                name="discount_type"
                                options={[
                                  {
                                    key: "Dollar",
                                    value: "1",
                                    id: "1",
                                  },
                                  {
                                    key: "Percent",
                                    value: "2",
                                    id: "2",
                                  },
                                ]}
                              ></RadioBlue>
                            </RadioDiv>
                            {values.discount_type == 1 ? (
                              <InputwithLabel
                                name="discount_value"
                                type="numbner"
                                placeholder="Enter Amount"
                                style={{ width: "129px" }}
                              />
                            ) : (
                              <InputwithLabel
                                name="discount_value"
                                type="number"
                                placeholder="Enter Percent"
                                style={{ width: "129px" }}
                              />
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </div>
                  )}
                </Card>

                <Card className="card">
                  <Card.Header className="card-header">
                    <div>
                      <input
                        checked={values.lpp === 1 ? true : false}
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked === true)
                            setFieldValue("lpp", 1);
                          else {
                            setFieldValue("lpp", 0);
                          }
                        }}
                      />
                      {"    "} <Title>Late Payment Penalties</Title>
                    </div>
                  </Card.Header>
                  {values.lpp === 1 && (
                    <div>
                      <Card.Body className="card-body">
                        <Row>
                          <Col>
                            <RadioBlue
                              name="late_payment_type"
                              options={[
                                {
                                  key: "Fixed",
                                  value: "1",
                                  id: "1",
                                },
                                {
                                  key: "Rolling",
                                  value: "2",
                                  id: "2",
                                },
                              ]}
                            ></RadioBlue>
                          </Col>
                        </Row>

                        {values.late_payment_type == 1 && (
                          <Row>
                            <Col style={{ display: "flex" }}>
                              <RadioDiv style={{ paddingTop: "26px" }}>
                                <label style={{ paddingRight: "8px" }}>
                                  Select Discount:
                                </label>
                                <RadioBlue
                                  name="lp_penalty_type"
                                  options={[
                                    {
                                      key: "Dollar",
                                      value: "1",
                                      id: "1",
                                    },
                                    {
                                      key: "Percent",
                                      value: "2",
                                      id: "2",
                                    },
                                  ]}
                                ></RadioBlue>
                              </RadioDiv>
                              <div
                                style={{
                                  paddingTop: "25px",
                                  paddingRight: "5px",
                                }}
                              >
                                {values.lp_penalty_type == 1 ? (
                                  <InputwithLabel
                                    name="lp_penalty_amt"
                                    type="number"
                                    placeholder="Enter Amount"
                                    style={{ width: "123px" }}
                                  />
                                ) : (
                                  <InputwithLabel
                                    name="lp_penalty_amt"
                                    type="number"
                                    placeholder="Enter Percent"
                                    style={{ width: "123px" }}
                                  />
                                )}
                              </div>

                              <div>
                                <label style={{ margin: 0 }}>Grace Days</label>
                                <InputwithLabel
                                  name="lp_penalty_days"
                                  type="number"
                                  placeholder="Enter Days"
                                  style={{ width: "100px" }}
                                />
                              </div>
                            </Col>
                            {errors.lp_penalty_amt ? (
                              <div>
                                <p className="errortext">
                                  {errors.lp_penalty_amt}
                                </p>
                              </div>
                            ) : null}
                            {errors.lp_penalty_days ? (
                              <div>
                                <p className="errortext">
                                  {errors.lp_penalty_days}
                                </p>
                              </div>
                            ) : null}
                          </Row>
                        )}

                        {values.late_payment_type == 2 && (
                          <>
                            <Row>
                              <Col style={{ display: "flex" }}>
                                <RadioDiv style={{ paddingTop: "26px" }}>
                                  <label style={{ paddingRight: "10px" }}>
                                    Select Penalty:
                                  </label>
                                  <RadioBlue
                                    name="lp_penalty_type"
                                    options={[
                                      {
                                        key: "Dollar",
                                        value: "1",
                                        id: "1",
                                      },
                                      {
                                        key: "Percent",
                                        value: "2",
                                        id: "2",
                                      },
                                    ]}
                                  ></RadioBlue>
                                </RadioDiv>
                                <div
                                  style={{
                                    paddingTop: "25px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {values.lp_penalty_type == 1 ? (
                                    <InputwithLabel
                                      name="lp_penalty_amt"
                                      type="number"
                                      placeholder="Enter Amount"
                                      style={{ width: "123px" }}
                                    />
                                  ) : (
                                    <InputwithLabel
                                      name="lp_penalty_amt"
                                      type="number"
                                      placeholder="Enter Percent"
                                      style={{ width: "123px" }}
                                    />
                                  )}
                                </div>

                                <div>
                                  <label style={{ margin: 0 }}>
                                    Grace Days
                                  </label>
                                  <InputwithLabel
                                    name="lp_penalty_days"
                                    type="number"
                                    placeholder="Enter Days"
                                    style={{ width: "100px" }}
                                  />
                                </div>
                              </Col>
                              {errors.lp_penalty_amt ? (
                                <div>
                                  <p className="errortext">
                                    {errors.lp_penalty_amt}
                                  </p>
                                </div>
                              ) : null}
                              {errors.lp_penalty_days ? (
                                <div>
                                  <p className="errortext">
                                    {errors.lp_penalty_days}
                                  </p>
                                </div>
                              ) : null}
                            </Row>
                            <Row>
                              <Col style={{ display: "flex" }}>
                                <label
                                  style={{
                                    paddingTop: "8px",
                                    paddingRight: "4px",
                                  }}
                                >
                                  Late charge increases every
                                </label>
                                <InputwithLabel
                                  name="late_charge_increase_days"
                                  type="number"
                                  //placeholder="Enter Amount"
                                  style={{ width: "70px" }}
                                />
                                <label
                                  style={{
                                    paddingTop: "8px",
                                    paddingRight: "3px",
                                    paddingLeft: "3px",
                                  }}
                                >
                                  days by {"  "}
                                </label>
                                <InputwithLabel
                                  name="late_charge_increase_amt"
                                  type="number"
                                  placeholder="Enter Amount"
                                  style={{ width: "123px" }}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </Card.Body>
                    </div>
                  )}
                </Card>

                <Card className="card">
                  <Card.Header className="card-header">
                    {/* <CustomToggle eventKey="2" addeddiscountData={addeddiscountData}> */}

                    <div>
                      {/* <Checkbox name="hvdppp" onChange={(e) => setFieldValue('hvdppp', e.target.value)} /> */}
                      <input
                        checked={values.epd === 1 ? true : false}
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked === true)
                            setFieldValue("epd", 1);
                          else {
                            setFieldValue("epd", 0);
                          }
                        }}
                      />
                      {"   "} <Title>Early Payment Discount</Title>
                    </div>
                    {/* </CustomToggle> */}
                  </Card.Header>
                  {/* <Accordion.Collapse eventKey="2"> */}

                  {values.epd === 1 && (
                    <div>
                      <Card.Body className="card-body">
                        <Row>
                          <Col>
                            <RadioBlue
                              name="early_payment_discount_type"
                              options={[
                                {
                                  key: "Fixed",
                                  value: "1",
                                  id: "1",
                                },
                                {
                                  key: "Rolling",
                                  value: "2",
                                  id: "2",
                                },
                              ]}
                            ></RadioBlue>
                          </Col>
                        </Row>
                        {values.early_payment_discount_type == 1 && (
                          <>
                            <Row>
                              <Col style={{ display: "flex" }}>
                                <RadioDiv>
                                  <label style={{ paddingRight: "10px" }}>
                                    Select Discount:
                                  </label>
                                  <RadioBlue
                                    name="early_payment_discount_type2"
                                    options={[
                                      {
                                        key: "Dollar",
                                        value: "1",
                                        id: "1",
                                      },
                                      {
                                        key: "Percent",
                                        value: "2",
                                        id: "2",
                                      },
                                    ]}
                                  ></RadioBlue>
                                </RadioDiv>
                                {values.early_payment_discount_type2 == 1 ? (
                                  <InputwithLabel
                                    name="early_payment_discount_val"
                                    type="number"
                                    placeholder="Enter Amount"
                                    style={{ width: "123px" }}
                                  />
                                ) : (
                                  <InputwithLabel
                                    name="early_payment_discount_val"
                                    type="number"
                                    placeholder="Enter Percent"
                                    style={{ width: "123px" }}
                                  />
                                )}
                              </Col>
                            </Row>

                            <Row>
                              <Col style={{ display: "flex" }}>
                                <label
                                  style={{
                                    paddingRight: "5px",
                                    paddingTop: "8px",
                                  }}
                                >
                                  Discount Starts
                                </label>
                                <InputwithLabel
                                  name="early_payment_discount_start"
                                  type="number"
                                  //placeholder="Enter Amount"
                                  style={{ width: "76px" }}
                                />
                                <label
                                  style={{
                                    paddingLeft: "8px",
                                    paddingTop: "8px",
                                  }}
                                >
                                  Days before due date
                                </label>
                              </Col>
                            </Row>
                          </>
                        )}
                        {values.early_payment_discount_type == 2 && (
                          <>
                            <>
                              <Row>
                                <Col style={{ display: "flex" }}>
                                  <RadioDiv>
                                    <label style={{ paddingRight: "10px" }}>
                                      Select Discount:
                                    </label>
                                    <RadioBlue
                                      name="early_payment_discount_type2"
                                      options={[
                                        {
                                          key: "Dollar",
                                          value: "1",
                                          id: "1",
                                        },
                                        {
                                          key: "Percent",
                                          value: "2",
                                          id: "2",
                                        },
                                      ]}
                                    ></RadioBlue>
                                  </RadioDiv>
                                  {values.early_payment_discount_type2 == 1 ? (
                                    <InputwithLabel
                                      name="early_payment_discount_val"
                                      type="number"
                                      placeholder="Enter Amount"
                                      style={{ width: "123px" }}
                                    />
                                  ) : (
                                    <InputwithLabel
                                      name="early_payment_discount_val"
                                      type="number"
                                      placeholder="Enter Percent"
                                      style={{ width: "123px" }}
                                    />
                                  )}
                                </Col>
                              </Row>
                              {errors.early_payment_discount_val ? (
                                <div>
                                  <p className="errortext">
                                    {errors.early_payment_discount_val}
                                  </p>
                                </div>
                              ) : null}
                              <Row>
                                <Col style={{ display: "flex" }}>
                                  <label
                                    style={{
                                      paddingRight: "5px",
                                      paddingTop: "8px",
                                    }}
                                  >
                                    Discount decrease every
                                  </label>
                                  <InputwithLabel
                                    name="early_payment_discount_deccrease_day"
                                    type="number"
                                    //placeholder="Enter Amount"
                                    style={{ width: "70px" }}
                                  />

                                  <label
                                    style={{
                                      paddingRight: "14px",
                                      paddingLeft: "8px",
                                      paddingTop: "8px",
                                    }}
                                  >
                                    days by
                                  </label>
                                  <InputwithLabel
                                    name="early_payment_discount_deccrease_percent"
                                    type="number"
                                    placeholder="Enter Amount"
                                    style={{ width: "123px" }}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col style={{ display: "flex" }}>
                                  <label
                                    style={{
                                      paddingRight: "5px",
                                      paddingTop: "8px",
                                    }}
                                  >
                                    Discount Starts
                                  </label>
                                  <InputwithLabel
                                    name="early_payment_discount_start"
                                    type="number"
                                    //placeholder="Enter Amount"
                                    style={{ width: "76px" }}
                                  />
                                  <label
                                    style={{
                                      paddingLeft: "8px",
                                      paddingTop: "8px",
                                    }}
                                  >
                                    Days before due date
                                  </label>
                                </Col>
                              </Row>
                            </>
                          </>
                        )}
                      </Card.Body>
                    </div>
                  )}
                </Card>
              </StyledAccordion>
              <Row>
                <Col>
                  <BasicButton
                    disabled={disable}
                    style={{ margin: "10px", float: "right" }}
                    type="submit"
                    width="110px"
                  >
                    Save
                  </BasicButton>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Page.Body>
    </Page>
  );
}
