import { useQueryClient, useMutation } from "react-query";
import { importCSV } from "services/user.service";

export const useImportUser = () => {
  const queryClient = useQueryClient();
  return useMutation((File) => importCSV(File), {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
    },
  });
};
