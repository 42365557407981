import React from "react";
import { SearchIcon } from "assets/icons.js";
import { StyledDiv, StyledInput } from "./Styles";

/* this is now general search */

export default function CustomerSearch({ handleFilterData }) {
  return (
    <>
      <StyledDiv>
        <SearchIcon />
        <StyledInput
          type="text"
          onChange={(e) => {
            handleFilterData({ searchString: e.target.value, pageNumber: 0 });
          }}
          placeholder="Search"
        />
      </StyledDiv>
    </>
  );
}
