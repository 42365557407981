import { height } from "dom-helpers";
import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

const GraphStyled = styled.div`
  height: 92px;

  .apexcharts-canvas {
    margin-top: -83px;

    svg {
      transform: scale(1.3);
      right: 0;
      position: relative;
      bottom: -26px;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .apexcharts-canvas {
      margin-top: -54px;
      svg {
        right: 10px;
      }
    }
  }
`;

function SimpleLineGraph({ data, xaxisData, height, width }) {
  return (
    <GraphStyled>
      <Chart
        type="area"
        width={width}
        height={height}
        // noData: {
        //   text: undefined,
        //   align: 'center',
        //   verticalAlign: 'middle',
        //   offsetX: 0,
        //   offsetY: 0,
        //   style: {
        //     color: undefined,
        //     fontSize: '14px',
        //     fontFamily: undefined
        //   }
        // },
        series={[{ data }]}
        options={{
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: ["#33C2DF"],
          stroke: {
            width: 1,
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },

          xaxis: {
            xaxisData,
            labels: { show: false },
            tooltip: { enabled: false },
            axisBorder: { show: false },
            axisTicks: { show: false },
          },

          yaxis: {
            labels: {
              show: false,
            },
          },
        }}
      ></Chart>
    </GraphStyled>
  );
}

export default SimpleLineGraph;

export const GraphCaption = styled.nav`
  line-height: 13px;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 6px;
`;
