import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { EditIcon } from "assets/icons";
import Filter from "ui/FilterUser";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import Table from "./table";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import useDebounce from "hooks/useDebounce";
import { Authorise } from "helpers/Authorization";
import { useRoles } from "queries/profile";

const TopSection = styled.div`
  .download {
    text-decoration: underline;
    font-family: Montserrat Alternates;
    font-size: 12px;
    line-height: 15px;
    color: #909090;
    cursor: pointer;
    margin: "10px";
    padding: "10px";
  }
`;

const StyledTable = styled.div`
  .table-wrapper {
    padding: 25px 0px;
  }
`;

const StyledFilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;
const StyledParagraph = styled.p`
  letter-spacing: 0px;
  color: rgb(144, 144, 144);
  font-family: "Montserrat Alternates-Bold";
  font-size: 24px;
  line-height: 22px;
`;

export default function EventTable() {
  const [page, setPage] = useState(0);
  const [initialPage, setinitialPage] = useState(0);
  const [filterData, setFilterData] = useState({
    username: "",
    statusArray: [],
    roleArray: [],
    businessArray: [],
    sortOn: "createdAt",
    sortOrder: "desc",
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        id: "id",
        maxWidth: 100,
      },
      {
        Header: "Name",
        accessor: "name",
        id: "name",
        maxWidth: 100,
      },
      {
        Header: "Source",
        accessor: "source",
        id: "source",
        maxWidth: 100,
      },
      {
        Header: "IP",
        accessor: "ip",
        id: "ip",
        maxWidth: 100,
      },
      {
        Header: "Browser",
        accessor: "browser",
        id: "browser",
        maxWidth: 100,
      },
      {
        Header: "Device",
        accessor: "device",
        id: "device",
        maxWidth: 100,
      },
    ],
    []
  );
  return (
    <>
      <ErrorHandlingComponent errors={""} />
      <TopSection>
        <Row>
          <Col>
            <StyledParagraph>Events</StyledParagraph>
          </Col>
          <Col>
            <StyledFilterSection>
              <Filter handleFilterData={() => {}} userRolesData={() => {}} />
            </StyledFilterSection>
          </Col>
        </Row>
      </TopSection>

      <Row>
        <Col>
          <StyledTable>
            <Table
              isloading={false}
              data={[]}
              columns={columns}
              totalCount={0}
              rowClick={() => {}}
              handlePageChange={() => {}}
              initialPage={0}
              onHeaderClick={() => {}}
            />
          </StyledTable>
        </Col>
      </Row>
    </>
  );
}
