import React from "react";
import { useEffect, useState } from "react";
import PrimaryPrivateNavbar from "./primaryPrivateNavbar";
import Sidebar from "./sidebar";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Flash from "ui/flash-message";
import Loader from "ui/Loader";

const PageContainer = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  .scroll-div {
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
    height: auto;
    background-color: #232631;
  }
`;

const ContentContainer = styled(Container)`
  /* overflow: auto; */
  max-width: 100%;
  flex: 1;
`;
const StickyDiv = styled.div`
  position: relative;
`;

const SidebarContainer = styled.div`
  position: relative;
  height: 100%;
  /* width: auto;
  overflow: auto; */
  display: block;
  /* border: 5px solid green; */
  z-index: 50000;
`;

const PrivateLayout = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    let isSuperUser = sessionStorage.getItem("isSuperUser");
    let adminToken = sessionStorage.getItem("adminToken");
    if (!isSuperUser || !adminToken) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/admin/login";
    } else {
      setIsLoading(false);
    }
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <PageContainer>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <ContentContainer>
        <StickyDiv>
          <Row>
            <Col>
              <PrimaryPrivateNavbar />
              <Flash />
            </Col>
          </Row>
        </StickyDiv>
        <Row>
          <Col>
            <>{props.children}</>
          </Col>
        </Row>
      </ContentContainer>
    </PageContainer>
  );
};

export default PrivateLayout;
