import React from "react";
import styled, { css } from "styled-components";
import { OverlayTrigger, Button, Row, Col, Form } from "react-bootstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import { useState, useRef, useEffect, useCallback } from "react";
import OrderService from "services/order.services";
import axios from "axios";
import { CalendarIcon, ArrowDownIcon, IconCross } from "assets/icons.js";
import DropDownSearchAndSelect from "ui/DropDownSearchAndSelect";
import { StyledCheckbox, StyledButton, ClearButton,Popover } from "filters/Style";
import BSCheckbox from "ui/BSCheckbox";
// import OrderService from "services/order.services";

const StyledSelect = styled.div`
  .react-select-container .react-select__menu-list {
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #000;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .react-select-container {
    position: absolute;
    background: #232631;
    border: 1px solid #707070;
    z-index: 99;
    color: #ffffff69;
    width: 300px;
  }
  .react-select-container .react-select__control {
    height: 25px;
    border: 1px solid #707070;
    background: #232631;
    color: #ffffff69;
    outline: none;
    box-shadow: none;
    font-size: 10px;
    line-height: 13px;
    &:hover {
      outline: none;
      box-shadow: none;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .react-select-container .react-select__menu {
    background: #232631;
  }
  .react-select-container .react-select__control--menu-is-open {
    background: #232631;
  }
  button {
    font-family: Montserrat Alternates;
    border: 1px solid #707070;
    background-color: #232631;
    border-radius: 0px;
    width: 100%;
    height: 25px;
    color: #ffffff69;
    margin-bottom: 10px;
    &:focus {
      outline: none;
      border: 1px solid #707070;
      background-color: #232631;
    }
  }
  .react-select-container .react-select__option {
    background: #232631;
    font-size: 10px;
    line-height: 13px;
    color: #ffffff69;
    display: flex;
    align-items: center;
  }
  input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #232631;
    border: 1px solid #707070;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-bottom: 3px;

    background-repeat: no-repeat;
    &:after {
      content: "\u2713";
      color: #232631;
      position: absolute;
      top: -20%;
      font-size: 11px;
      padding: 2px;
    }
    &:checked {
      &:after {
        content: "\u2713";
        color: #33c2df;
        position: absolute;
        top: -20%;
        font-size: 11px;
        padding: 2px;
      }
    }
  }
`;
const popoverPanel =(
  handleResetFilter,
  username,
  handleUserName,
  StatusChangedInactive,
  StatusChangedActive,
  RoleChangedReadOnly,
  RoleChangedReadWrite,
  RoleChangedAdmin,
  role,
  status
) => (
  <Popover id="popover-basic">
    <Popover.Body>
      <span className="filter-heading">Type Of Access</span>
      <StyledCheckbox>
        <Row className="m-0" style={{ paddingTop: "5px" }}>
          <Col className="p-0">
            <BSCheckbox
              inline
              name="access"
              label="Admin"
              type="checkbox"
              id={`inline-checkbox-1`}
              checked={role.admin}
              onChange={(e) => {
                RoleChangedAdmin("admin", e);
              }}
            />
          </Col>
          <Col className="p-0">
            <BSCheckbox
              inline
              name="access"
              label="Read-Write"
              type="checkbox"
              id={`inline-checkbox-2`}
              checked={role.readwrite}
              onChange={(e) => {
                RoleChangedReadWrite("readwrite", e);
              }}
            />
          </Col>
          <Col className="p-0">
            <BSCheckbox
              inline
              name="access"
              label="Read-Only"
              type="checkbox"
              id={`inline-checkbox-3`}
              checked={role.readonly}
              onChange={(e) => {
                RoleChangedReadOnly("readonly", e);
              }}
            />
          </Col>
        </Row>
      </StyledCheckbox>
      <span className="filter-heading">Status</span>
      <StyledCheckbox>
        <Row className="m-0" style={{ paddingTop: "5px" }}>
          <Col className="p-0" sm="3">
            <BSCheckbox
              inline
              name="status"
              label="Active"
              type="checkbox"
              id={`inline-checkbox-4`}
              checked={status.active}
              onChange={(e) => {
                StatusChangedActive("active", e);
              }}
            />
          </Col>
          <Col className="p-0">
            <BSCheckbox
              inline
              name="status"
              label="Inactive"
              type="checkbox"
              id={`inline-checkbox-5`}
              checked={status.inactive}
              onChange={(e) => {
                StatusChangedInactive("inactive", e);
              }}
            />
          </Col>
        </Row>
      </StyledCheckbox>
      <span className="filter-heading">Name</span>
      <input
        className="amount"
        onChange={handleUserName}
        value={username}
        placeholder="Search by name"
        type="text"
      ></input>
      {/* <span className="filter-heading">Business</span>

      <StyledSelect>
        <DropDownSearchAndSelect
          childFunc={childFunc}
          handleBusiness={handleBusiness}
        />
      </StyledSelect> */}
      <ClearButton onClick={handleResetFilter} style={{ marginTop: "10px" }}>Clear</ClearButton>
    </Popover.Body>
  </Popover>
);
const FilterUser = ({ handleFilterData }) => {
  const childFunc = useRef(null);
  const [username, setUserName] = useState("");
  const [businessArray, setBusinessArray] = useState([]);
  const [role, setRole] = useState({
    admin: false,
    readwrite: false,
    readonly: false,
  });
  const [roleArray, setRoleArray] = useState([]);
  const [status, setStatus] = useState({
    active: false,
    inactive: false,
  });
  const [statusArray, setStatusArray] = useState([]);

  const handleUserName = (e) => {
    setUserName(e.target.value);
  };
  const RoleChangedAdmin = (value, e) => {
    setRole((prev) => ({ ...prev, admin: !prev[value] }));
    if (e.target.checked) {
      setRoleArray([...roleArray, "admin"]);
    } else {
      setRoleArray(roleArray.filter((val) => val !== "admin"));
    }
  };
  const RoleChangedReadWrite = (value, e) => {
    setRole((prev) => ({ ...prev, readwrite: !prev[value] }));

    if (e.target.checked) {
      setRoleArray([...roleArray, "read-write"]);
    } else {
      setRoleArray(roleArray.filter((val) => val !== "read-write"));
    }
  };
  const RoleChangedReadOnly = (value, e) => {
    setRole((prev) => ({ ...prev, readonly: !prev[value] }));
    if (e.target.checked) {
      setRoleArray([...roleArray, "read-only"]);
    } else {
      setRoleArray(roleArray.filter((val) => val !== "read-only"));
    }
  };
  const StatusChangedActive = (value, e) => {
    setStatus((prev) => ({ ...prev, active: !prev[value] }));
    if (e.target.checked) {
      setStatusArray([...statusArray, "active"]);
    } else {
      setStatusArray(statusArray.filter((val) => val !== "active"));
    }
  };
  const StatusChangedInactive = (value, e) => {
    setStatus((prev) => ({ ...prev, inactive: !prev[value] }));
    if (e.target.checked) {
      setStatusArray([...statusArray, "inactive"]);
    } else {
      setStatusArray(statusArray.filter((val) => val !== "inactive"));
    }
  };
  // const handleBusiness = (data) => {
  //   setBusinessArray(data);
  // };
  const handleResetFilter = () => {
    setUserName("");
    //setBusinessArray([]);
    setRoleArray([]);
    setStatusArray([]);
    setRole((prev) => ({
      ...prev,
      admin: false,
      readwrite: false,
      readonly: false,
    }));
    setStatus((prev) => ({ ...prev, active: false, inactive: false }));
    //childFunc.current();
  };
  useEffect(() => {
    const FilterData = {
      username,
      roleArray,
      statusArray,
      //businessArray,
    };

    handleFilterData(FilterData);
  }, [username, roleArray, statusArray, businessArray]);

 
  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={popoverPanel(
          handleResetFilter,
          username,
          handleUserName,
          StatusChangedInactive,
          StatusChangedActive,
          RoleChangedReadOnly,
          RoleChangedReadWrite,
          RoleChangedAdmin,
          role,
          status
        )}
      >
        <StyledButton>
          Filter
          <ArrowDownIcon />
        </StyledButton>
      </OverlayTrigger>
    </>
  );
};
export default FilterUser;
