import React from "react";

export const Authenticate = ({
  // authorisedRoles = ["admin", "read-write", "read-only"],
  showUnAuthorisedComponenet = null,
  children,
}) => {
  //To DO: roles and permissions
  const token = sessionStorage.getItem("userToken");
  if (token && token !== "") return <>{children}</>;

  if (showUnAuthorisedComponenet) return <>{showUnAuthorisedComponenet}</>;

  return (
    <p style={{ color: "white", margin: "100px", textAlign: "center" }}>
      {" "}
      401- You are not Authorized !
    </p>
  );
};
