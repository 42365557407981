import { Row, Container } from "react-bootstrap";
import styled from "styled-components";
import DropdownButton from "react-bootstrap/DropdownButton";
import PercentageChange from "ui/cardsPercentage";
import Change from "ui/Change";
import { BasicButton } from "ui/Buttons";
import { Popover as StyledPopover } from "ui/Popover";
export const Counter = styled.div`
  background-color: #33c2df;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  display: inline-block;
  color: white;
  position: relative;
  top: 5px;
`;

export const Popover = styled(StyledPopover)`
  .popover-body {
    padding: 0rem 1rem;
  }
  .popover-arrow {
    &:before {
      left: 0px;
    }
    &:after {
      left: 0px;
    }
  }
`;

export const PageContainer = styled(Container)`
  height: auto;
  width: 100%;
  margin-top: 9px;
`;

export const StyledButton = styled(BasicButton)`
  float: right;
  margin: 0;
  margin-left: 20px;
`;

export const FilterSection = styled(Row)`
  .reminder-text {
    font-family: Montserrat Alternates-semiBold;
    font-size: 17px;
    line-height: 25px;
    color: #909090;
    display: inline-block;
    margin-top: 10px;
  }
`;

export const TableSection = styled(Row)`
  margin: 36px 0px;
`;

export const StyledChange = styled(PercentageChange)`
  float: right;
  position: relative;
  line-height: 25px;
  background: white;
  border-radius: 20px;
  padding: 0px 5px;
`;
export const StyledChangeTotal = styled(Change)`
  float: right;
  position: relative;
  line-height: 25px;
  background: white;
  border-radius: 20px;
  padding: 0px 5px;
`;
export const StyledEditIcon = styled.div`
  svg {
    font-size: 18px;
    cursor: pointer;
  }
`;

export const StyledModal = styled.div`
  .modal-text {
    text-align: left;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #909090;
    opacity: 1;
    margin-bottom: 0px;
  }
  .name {
    height: 29px;
    background-color: rgba(255, 255, 255, 0.18);
    border: 1px solid #707070;
  }
  .data {
    height: 29px;
    border-bottom: 1px solid #707070;
  }
  .modal-header {
    padding: 1rem 1rem;
    border-bottom: 1px solid #707070;
    margin: 10px;
    .modal-body {
      padding: 0rem 1rem;
    }
    h1 {
      font-family: Montserrat Alternates-Bold;
      text-align: left;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }
  }
`;

export const DownloadSection = styled.div`
  display: flex;
  justify-content: flex-end;

  .icon-div {
    width: 61px;
    height: 40px;
    background: #3a3b48 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #707070;
    border-radius: 20px;
    display: inline-block;
    margin-left: 10px;
    padding: 5px;
    cursor: pointer;
    svg {
      margin-left: 12px;
      font-size: 25px;
    }
  }
`;

export const ExportLogo = styled.img`
  margin: 30px 0px;
  height: 30px;
`;
export const StyledLoader = styled.div`
  .spinner-border {
    font-weight: 500;
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const StyledRadio = styled.div`
  float: right;
  input[type="radio"] {
    -webkit-appearance: none;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: transparent;
    border: 2px solid #ffffff;
    &:checked {
      background: white;
      &:after {
        content: "✓";
        color: black;
        font-size: 12px;
        position: absolute;
        top: -6px;
        right: 2px;
        font-weight: 800;
      }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      top: 4px;
    }
  }
`;
export const StyledDropDown = styled(DropdownButton)`
  .dropdown-toggle {
    padding-left: 0;
    text-align: left;
    /* width: 194px; */
    font-size: 24px;
    line-height: 29px;
    font-family: Montserrat Alternates-Bold;
    letter-spacing: 0px;
    color: #909090;
    background: none;
    outline: none;
    border: none;
    box-shadow: none;
    &:focus,
    &:hover,
    &:active,
    &:checked {
      font-size: 24px;
      line-height: 29px;
      font-family: Montserrat Alternates-Bold;
      letter-spacing: 0px;
      color: #909090;
      background: none;
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  /* .show {
    .btn-secondary.dropdown-toggle {
      color: #909090;
      background-color: transparent;
      border-color: none;

      &:focus {
        color: #909090;
        box-shadow: none;
        background: none;
        border: none;
        outline: none;
      }
    }
  } */
  .dropdown-menu {
    width: 164px;
    background-color: #232631;
    font-family: Montserrat Alternates-semiBold;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #ffffff;
    border: 1px solid #707070;
    /* &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: -19%;
      left: 83%;
      border-top: solid 10px gray;
      border-left: solid 10px gray;
      border-right: solid 10px gray;
      border-bottom: solid 10px #232631;
    } */
  }

  .dropdown-item {
    color: #ffffff;
    background-color: #232631;
    padding: 10px 26px;
    &:hover {
      background-color: #232631;
      color: #ffffff;
    }
  }
`;
export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  .filter-popup {
    width: 142px;
    height: 40px;
    background: #3a3b48 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #707070;
    border-radius: 20px;
    text-align: left;
    font-family: Montserrat Alternates-semiBold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 12px;
  }
  .icon-div {
    width: 51px;
    height: 40px;
    background: #3a3b48 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #707070;
    border-radius: 20px;
    display: inline-block;
    margin-left: 10px;
    padding: 5px;
    svg {
      margin-left: 14px;
      path {
        fill: #33c2df;
      }
    }
  }
`;

export const StyledLinks = styled.a`
  margin-left: 8px;
  margin-right: 8px;
  text-decoration: none;
  font-family: Montserrat Alternates-semiBold;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0px;
  color: #909090;
  color: ${(props) => (props.active ? "#33C2DF" : "#909090")};
  cursor: pointer;

  &:active {
    color: red;
  }

  &:hover {
    text-decoration: none;
    font-family: Montserrat Alternates-semiBold;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0px;
    color: #33c2df;
    margin-top: 15px;
    cursor: pointer;
  }
`;
export const SearchSection = styled.div`
  display: flex;
`;
