import { getLinkToken, getAUserAllBankingDetails, getUserCashInBank, getUserCashInBankForForecast } from "services/bankSettings.services";
import { useQuery } from "react-query";

export const useLinkToken = () => {
  return useQuery("createLinkToken", getLinkToken);
};

export const useGetAUserAllBankingDetails = () => {
  return useQuery("bankSettings", getAUserAllBankingDetails);
};

export const useGetUserCashInBankSystem = () => {
  return useQuery("cashInBankSystem", getUserCashInBank);
};

export const useGetUserCashInBankSysteForForecast = () => {
  return useQuery("cashInBankSystem-forecast", getUserCashInBankForForecast);
};