import React, { useState } from "react";
import { Modal } from "ui/Modal";
import { Row, Col } from "react-bootstrap";
import { Form, InputwithLabel, Formik } from "ui/forms";
import * as Yup from "yup";
import { BasicButton } from "ui/Buttons";
import { ModalSection, StyledModal } from ".";
import { EditIcon, IconCheckCircle } from "assets/icons";
import { useUpdateSupplier } from "mutations/updateSupplier";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";

export default function StripeModal({ show, onHide, currentSelectedSupplier }) {
  const supplierUpdate = useUpdateSupplier();
  const [errorMessage, setErrorMessage] = useState("");
  const supplierId = currentSelectedSupplier?.supplierId;
  const handleStripeDetails = (values) => {
    supplierUpdate
      .mutateAsync({ supplierId, values })
      .then(() => {
        setErrorMessage(null);
        onHide();
        // setShowStripe(false)
      })
      .catch(function (error) {
        if (error.response) {
          setErrorMessage(error.response.data.message);
        }
      });
  };
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <StyledModal>
        <Modal.Header closeButton>
          <h1>Setup a connection with stripe</h1>
          <div style={{ marginLeft: "30px" }}>
            <IconCheckCircle font-size="12px" />
            <span className="account-verification">Account Verified</span>
            <EditIcon font-size="12px" />
            <span className="account-verification">Edit Account</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={
              currentSelectedSupplier?.stripeDetails === ""
                ? {
                    bankAccountNumber: "",
                    routingNumber: "",
                    IBAN: "",
                  }
                : {
                    bankAccountNumber:
                      currentSelectedSupplier?.stripeDetails?.bankAccountNumber,
                    routingNumber:
                      currentSelectedSupplier?.stripeDetails?.routingNumber,
                    IBAN: currentSelectedSupplier?.stripeDetails?.IBAN,
                  }
            }
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              bankAccountNumber: Yup.string().required(
                "Please enter Bank Account Name"
              ),
              routingNumber: Yup.string().required(
                "Please enter Routing Number "
              ),
              IBAN: Yup.string().required("Please enter IBAN"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              handleStripeDetails(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} className="mx-auto">
                <ErrorHandlingComponent errors={errorMessage} />
                <ModalSection>
                  <Row>
                    <Col>
                      <InputwithLabel
                        placeholder="Bank account No"
                        name="bankAccountNumber"
                        type="text"
                        label="Bank account No"
                      />
                    </Col>
                    <Col>
                      <InputwithLabel
                        placeholder="Routing No"
                        name="routingNumber"
                        type="text"
                        label="Routing No"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputwithLabel
                        placeholder="IBAN"
                        name="IBAN"
                        type="text"
                        label="IBAN"
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col>
                      <BasicButton
                        style={{ marginBottom: "30px" }}
                        type="submit"
                        width="110px"
                      >
                        Verify
                      </BasicButton>
                    </Col>
                  </Row>
                </ModalSection>
                <Row>
                  <Col>
                    <BasicButton
                      style={{ margin: "10px", float: "right" }}
                      type="submit"
                      width="110px"
                    >
                      Save
                    </BasicButton>
                    <BasicButton
                      style={{ margin: "10px", float: "right" }}
                      type="button"
                      width="110px"
                      background="#909090"
                      onClick={() => {
                        onHide();
                        // setShowStripe(false)
                      }}
                    >
                      Cancel
                    </BasicButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </StyledModal>
    </Modal>
  );
}
