import styled from "styled-components";
import { UpIcon, DownIcon,InfoIcon } from "assets/icons";
import Tooltip from "./Tooltip";

const Up = styled.span`
  color: #07ba00;
  font-weight: bold;

  svg {
    font-size: 10px;
    margin-bottom: 2px;
    margin-left: 3px;
  }
`;

const Down = styled.span`
  color: #ff0505;
  font-weight: bold;
  svg {
    font-size: 10px;
    margin-bottom: 2px;
    margin-left: 3px;
  }
`;

function Change(props) {
  if (props.value > 0) {
    return (
      <Up {...props}>
        + {props.value} %<UpIcon />
        <InfoIcon
        data-tip="Change from last time period"/>
        <Tooltip place="bottom"/>
      </Up>
    );
  } else {
    return (
      <Down {...props}>
        {props.value} %
        <DownIcon />
        <InfoIcon
        data-tip="Change from last time period"/>
        <Tooltip place="bottom"/>
      </Down>
    );
  }
}
export default Change;
