import axios from "utils/APIv2";

export const getCash = async () => {
  const res = await axios.get(`/dashboard/cash`);
  return res.data;
};

export const getOrders = async () => {
  const res = await axios.get(`/dashboard/order`);
  return res.data;
};

export const getPayables = async () => {
  const res = await axios.get(`/dashboard/payable`);
  return res.data;
};
export const getReceivables = async () => {
  const res = await axios.get(`/dashboard/receivable`);
  return res.data;
};
export const getCollections = async () => {
  const res = await axios.get(`/dashboard/collection`);
  return res.data;
};

export const getReceivableForcast = async () => {
  const res = await axios.get(`/dashboard/receivable-forecast`);
  return res.data;
};
export const getOrderForcast = async () => {
  const res = await axios.get(`/dashboard/order-forecast`);
  return res.data;
};
export const getPayableForcast = async () => {
  const res = await axios.get(`/dashboard/payable-forecast`);
  return res.data;
};
export const getCashForcast = async () => {
  const res = await axios.get(`/dashboard/cash-forecast`);
  return res.data;
};

export const getDaysSalesOutstanding = async () => {
  const res = await axios.get(`/dashboard/days-sales-outstanding`);
  return res.data;
};
export const getDaysPayablesOutstanding = async () => {
  const res = await axios.get(`/dashboard/days-payables-outstanding`);
  return res.data;
};

export const getNetCashGraph = async () => {
  const res = await axios.get(`/dashboard/netcash/graph`);
  return res.data;
};

export const getPaymentTermsPayables = async () => {
  const res = await axios.get(`/dashboard/payment-term-payable`);
  return res.data;
};
export const getPaymentTermsReceivables = async () => {
  const res = await axios.get(`/dashboard/payment-term-receivable`);
  return res.data;
};

export const getTopCustomersHighestOutstanding = async () => {
  const res = await axios.get(`/dashboard/top-customer-highest-outstanding`);
  return res.data;
};
export const getTopCustomersHighestOrder = async () => {
  const res = await axios.get(`/dashboard/top-customer-highest-order`);
  return res.data;
};
export const getTopSuppliersHighestOutstanding = async () => {
  const res = await axios.get(`/dashboard/top-supplier-highest-outstanding`);
  return res.data;
};
export const getTopSuppliersHighestOrder = async () => {
  const res = await axios.get(`/dashboard/top-supplier-highest-order`);
  return res.data;
};
export const getReceivablesAging = async () => {
  const res = await axios.get(`/dashboard/receivable-aging`);
  return res.data;
};
export const getPayablesAging = async () => {
  const res = await axios.get(`/dashboard/payable-aging`);
  return res.data;
};

export const getInsights = async () => {
  const res = await axios.get(`/dashboard/insights`);
  return res.data.data;
};
export const getCollectionEffectiveness = async () => {
  const res = await axios.get(`/dashboard/collection-effectiveness`);
  return res.data;
};
export const getPaymentProcessing = async () => {
  const res = await axios.get(`/dashboard/payment-processing`);
  return res.data.data;
};
