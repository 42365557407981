import styled from "styled-components";

const StyledError = styled.p`
  color: #ff7171;
  font-size: 12px;
  font-family: Montserrat Alternates-semiBold;
`;

export default function ErrorHandlingComponent({ errors }) {
  if (Array.isArray(errors) && errors.length > 0) {
    return errors.map((error) => <StyledError>{error}</StyledError>);
  } else return <StyledError>{errors}</StyledError>;
}
