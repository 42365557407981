import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import {
  ImportIcon,
  EditIcon,
  IconExpandArrow,
  AddIcon,
  IconFileDownload,
} from "assets/icons";
import Filter from "ui/FilterUser";
import { BasicButton } from "ui/Buttons";

import FormCheck from "react-bootstrap/FormCheck";
import styled from "styled-components";
import {
  Form,
  InputwithLabel,
  Formik,
  SimpleSelect,
  DatePickerField,
  TimePickerField,
  Radio,
  RadioBlue,
  ErrorMessage,
} from "ui/forms";
import Checkbox from "ui/Checkbox";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import * as Yup from "yup";
import Card from "ui/Card";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";
import ReactTooltip from "react-tooltip";

import "react-datepicker/dist/react-datepicker.css";
import Table from "ui/table";

import ToggleButtonComponent from "ui/ToggleButton";
import { withRouter, useHistory } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import axios from "axios";
import Loader from "ui/Loader";
//import { resolve } from "core-js/fn/promise";
import { useDepartments } from "queries/departments";
import { useUsers } from "queries/users";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import { useCreateUser } from "mutations/createUser";
import { useUpdateUser } from "mutations/updateUser";
import { useImportUser } from "mutations/importUser";
import EditUserModal from "./EditUserModal";
import AddUserModal from "./AddUserModal";
import { importCSV, downloadSampleImportFile } from "services/user.service";
import Tooltip from "ui/Tooltip";
import useDebounce from "hooks/useDebounce";
import { duration } from "moment";
import { Authorise } from "helpers/Authorization";
import { forFutureRelease } from "utils/Configs";
import { useRoles } from "queries/profile";

const TopSection = styled.div`
  .download {
    text-decoration: underline;
    font-family: Montserrat Alternates;
    font-size: 12px;
    line-height: 15px;
    color: #909090;
    cursor: pointer;
  }
`;

const Counter = styled.div`
  background-color: #33c2df;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  display: inline-block;
  color: white;
  position: relative;
  top: 5px;
`;

const StyledTable = styled.div`
  .table-wrapper {
    padding: 25px 0px;
  }
`;

const StyledFilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Import = styled.div`
  width: 100px;
  height: 40px;
  background: #3a3b48 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  font-family: Montserrat Alternates-semiBold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 12px;
  /* margin: 0px 10px; */
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

export default function UserListing() {
  let history = useHistory();

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [currentSelectedUser, setCurrentSelectUser] = useState(null);

  const { data: roleData } = useRoles();
  const userRolesData = roleData?.data;

  const [page, setPage] = useState(0);
  const [initialPage, setinitialPage] = useState(0);
  const [filterData, setFilterData] = useState({
    username: "",
    statusArray: [],
    roleArray: [],
    businessArray: [],
    sortOn: "createdAt",
    sortOrder: "desc",
  });

  const debouncedfilterData = useDebounce(filterData, 500);

  const [errorMessage, setErrorMessage] = useState("");
  const userAdd = useCreateUser();
  const userUpdate = useUpdateUser();
  const queryClient = useQueryClient();
  const importUser = useImportUser();

  const {
    data: users,
    isLoading,
    refetch,
  } = useUsers(page, debouncedfilterData);
  const { data: departments } = useDepartments();

  const tableData = users ? users.rows : [];
  const TotalRowsCount = users ? users.count : "";

  const updateTableData = (updatedFilterData) => {
    setPage(0);
    setinitialPage(0);
    setFilterData({ ...filterData, ...updatedFilterData });
  };
  const handleImport = (event) => {
    let file = new FormData();
    file.append("file", event.currentTarget.files[0]);
    importUser
      .mutateAsync(file)
      .then(() => {
        window.flash(
          "open",
          "primary",
          "User imported successfully",
          true,
          3000
        );
        refetch();
        //setErrorMessage("User imported successfully");
      })
      .catch(function (error) {
        if (error.response) {
          window.flash(
            "open",
            "danger",
            `${error.response.data.message}`,
            true,
            3000
          );
          //setErrorMessage(error.response.data.message);
        }
      });
  };
  const onAddUserModalHide = () => {
    setShowAddUserModal(false);
  };

  const onEditUserModalHide = () => {
    setShowEditUserModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "givenName",
        id: "givenName",
        maxWidth: 100,
        Cell: (props) => {
          return (
            <div>
              <span style={{ marginBottom: "0px" }}>
                {props.row.original.givenName +
                  " " +
                  props.row.original.familyName}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Mobile",
        accessor: "phoneNumber",
        id: "phoneNumber",
      },
      {
        Header: "Email",
        accessor: "email",
        id: "email",
        maxWidth: 150,
      },
      {
        Header: "User Permission",
        accessor: "role",
        id: "role",
        Cell: (props) => (
          <>
            <Capitalize>
              {props.row.values.role == "simplicollect"
                ? "SimpliCollect"
                : props.row.values.role == "simplipay"
                ? "SimpliPay"
                : props.row.values.role == "SimpliCollect&Simplipay"
                ? "SimpliCollect and SimpliPay"
                : props.row.values.role}
            </Capitalize>
          </>
        ),
      },

      /* businesses are hidden for current development */
      {
        Header: "Businesses",
        accessor: "business",
        id: "business",
        show: forFutureRelease,
        // disableSortBy: true,
        Cell: (props) => (
          <>
            {props.row.values.business.map((department) => (
              <>
                <div>
                  <span style={{ marginBottom: "0px" }}>{department.name}</span>
                  <IconExpandArrow font-size="7px" />
                  <Counter data-tip={department.fullyQualifiedName}>
                    <span
                      style={{
                        position: "absolute",
                        left: "2px",
                        bottom: "-5px",
                      }}
                    >
                      {department.fullyQualifiedName.split(":").length - 1 > 0
                        ? `+${
                            department.fullyQualifiedName.split(":").length - 1
                          }`
                        : department.fullyQualifiedName.split(":").length}
                    </span>
                  </Counter>
                </div>
                <Tooltip place="bottom" />
              </>
            ))}
          </>
        ),
      },
      {
        Header: "MFA",
        accessor: "isMfaEnabled",
        id: "isMfaEnabled",
        maxWidth: 100,
        Cell: (props) => {
          return (
            <div>
              {props.row.original.isMfaEnabled === true ? (
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-for="mfa"
                  data-tip={`MFA is enabled, SMS will be sent out to ${props.row.original.phoneNumber}`}
                >
                  <path
                    d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                    stroke="#3dbee5"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-for="mfa"
                  data-tip={`MFA is disabled`}
                >
                  <path
                    d="M3 3L21 21M17 10V8C17 5.23858 14.7614 3 12 3C11.0283 3 10.1213 3.27719 9.35386 3.75681M7.08383 7.08338C7.02878 7.38053 7 7.6869 7 8V10.0288M19.5614 19.5618C19.273 20.0348 18.8583 20.4201 18.362 20.673C17.7202 21 16.8802 21 15.2 21H8.8C7.11984 21 6.27976 21 5.63803 20.673C5.07354 20.3854 4.6146 19.9265 4.32698 19.362C4 18.7202 4 17.8802 4 16.2V14.8C4 13.1198 4 12.2798 4.32698 11.638C4.6146 11.0735 5.07354 10.6146 5.63803 10.327C5.99429 10.1455 6.41168 10.0647 7 10.0288M19.9998 14.4023C19.9978 12.9831 19.9731 12.227 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C17.773 10.0269 17.0169 10.0022 15.5977 10.0002M10 10H8.8C8.05259 10 7.47142 10 7 10.0288"
                    stroke="#9a9999"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              <ReactTooltip id="mfa" place={"top"} className="tooltip" />
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        id: "status",
        Cell: (props) =>
          props.row.values.status === "active" ? (
            <Capitalize style={{ color: "#00CE7F" }}>
              {props.row.values.status}
            </Capitalize>
          ) : (
            <Capitalize>In Active</Capitalize>
          ),
      },
      {
        Header: "Action",
        id: "action",
        disableSortBy: true,
        Cell: (props) => (
          <>
            <Authorise
              showUnAuthorisedComponenet={<></>}
              allowedOperation="read-write"
              allowedPermission="SETTINGS_UM"
              allowedRoles={["admin", "read-write"]}
            >
              <EditIcon
                onClick={() => {
                  setCurrentSelectUser(props.row.original);
                  setShowEditUserModal(true);
                }}
                font-size="18px"
              />
            </Authorise>
          </>
        ),
      },
    ],
    []
  );

  const rowClick = (row) => {
    return null;
  };
  const onHeaderClick = (column) => {
    filterData.sortOn = column.id;
    filterData.sortOrder = filterData.sortOrder === "desc" ? "asc" : "desc";
    setFilterData({ ...filterData, ...filterData });
  };

  const handlePageChange = (page) => {
    setPage(page);
    setinitialPage(page);
  };

  return (
    <>
      <ErrorHandlingComponent errors={errorMessage} />
      <TopSection>
        <Row>
          <Col>
            {" "}
            <p className="HeadingText">Add Users and assign Roles to them</p>
          </Col>
          <Col>
            <StyledFilterSection>
              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedRoles={["admin"]}
              >
                <>
                  <IconFileDownload font-size="20px" />
                  <a
                    href={downloadSampleImportFile()}
                    style={{ marginLeft: "5px" }}
                  >
                    <span className="download">
                      Download sample <br /> Import File
                    </span>
                  </a>

                  <label
                    style={{ cursor: "pointer", margin: "0px 10px" }}
                    htmlFor="fileUpload"
                  >
                    <Import>
                      <ImportIcon font-size="14px" />
                      <span style={{ marginLeft: "5px" }}>Import</span>
                      <input
                        hidden
                        id="fileUpload"
                        type="file"
                        accept=".csv"
                        onChange={handleImport}
                      ></input>
                    </Import>
                  </label>
                </>
              </Authorise>
              <Filter
                handleFilterData={updateTableData}
                userRolesData={userRolesData}
              />
              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedOperation="read-write"
                allowedPermission="SETTINGS_UM"
                allowedRoles={["admin", "read-write"]}
              >
                <BasicButton
                  type="button"
                  width="110px"
                  onClick={() => {
                    setShowAddUserModal(true);
                  }}
                >
                  Add User
                </BasicButton>
              </Authorise>
            </StyledFilterSection>
          </Col>
        </Row>
      </TopSection>
      <Row>
        <Col>
          <StyledTable>
            <Table
              isloading={isLoading}
              data={tableData}
              columns={columns}
              totalCount={TotalRowsCount}
              rowClick={rowClick}
              handlePageChange={handlePageChange}
              initialPage={initialPage}
              onHeaderClick={onHeaderClick}
            />
          </StyledTable>
        </Col>
      </Row>
      <AddUserModal
        show={showAddUserModal}
        onHide={onAddUserModalHide}
        refetch={refetch}
        userRolesData={userRolesData}
      />
      {currentSelectedUser && (
        <EditUserModal
          show={showEditUserModal}
          user={currentSelectedUser}
          onHide={onEditUserModalHide}
          refetch={refetch}
          userRolesData={userRolesData}
        />
      )}
    </>
  );
}
