import styled from "styled-components";

export const StyledDiv = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: 14px;
    left: 20px;
  }
`;
export const StyledInput = styled.input`
  height: 40px;
  width: 217px;
  font-family: Montserrat Alternates-semiBold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  background: #3a3b48 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
  ::placeholder {
    color: white;
  }
  &:focus {
    outline: none;
    ::placeholder {
      color: transparent;
    }
  }
`;