import React from "react";
import styled, { css } from "styled-components";
import { OverlayTrigger, Button, Row, Col, Form } from "react-bootstrap";
import moment from "moment";
import { useState, useRef, useEffect, useCallback } from "react";
import { CalendarIcon, ArrowDownIcon, IconCross } from "assets/icons.js";
import SelectFilter from "ui/SelectFilter";
import {
  StyledCheckbox,
  StyledButton,
  ClearButton,
  StyleSelect,
  Popover,
} from "filters/Style";
import BSCheckbox from "ui/BSCheckbox";

const popoverPanel = (
  CustomerData,
  emailTemplateId,
  handleTemplateName,
  handleCustomerName,
  handleCompanyName,
  handleResetFilter,
  location,
  handleLocationName,
  company,
  customerName,
  emailTemplates,
  customersTypes,
  handleCustomerType,
  customerType,
  handleOpenInvoice,
  isOpenInvoiceCustomers
) => (
  <Popover id="popover-basic">
    <Popover.Body>
      {/* <span className="filter-heading">Customer</span>
      <StyleSelect>
        <SelectFilter
          classNamePrefix="react-select"
          className="css-b62m3t-container"
          value={customerName}
          options={CustomerData.map((customer) => {
            return {
              label: customer,
              value: customer,
            };
          })}
          onChange={handleCustomerName}
        />
      </StyleSelect> */}
      {/* <span className="filter-heading">Company Name</span>
      <input
        className="amount"
        onChange={handleCompanyName}
        value={company}
        placeholder="Search"
        type="text"
      ></input> */}
      <span className="filter-heading">Billing Location</span>
      <input
        className="amount"
        onChange={handleLocationName}
        value={location}
        placeholder="Search (City/State/Country)"
        type="text"
      ></input>
      <span className="filter-heading">Email Template</span>
      <select
        className="filterCustomerAndEmailSelect"
        value={emailTemplateId}
        onChange={(e) => {
          handleTemplateName(e);
        }}
      >
        <option value={""}>Select Email Template</option>
        {emailTemplates.map((elem) => (
          <option value={elem.id}>{elem.name}</option>
        ))}
      </select>
      <span className="filter-heading">Customer Type</span>
      <select
        className="filterCustomerAndEmailSelect"
        value={customerType}
        onChange={(e) => {
          handleCustomerType(e);
        }}
      >
        <option value={""}>Select customer type</option>
        {customersTypes?.map((elem) => (
          <option value={elem}>{elem}</option>
        ))}
      </select>
      <BSCheckbox
        type="checkbox"
        label="Customers with Open Invoices Only"
        name="invoiceCustomers"
        id={`invoiceCustomers`}
        value={isOpenInvoiceCustomers}
        checked={isOpenInvoiceCustomers}
        onChange={(e) => {
          handleOpenInvoice(e.target.checked);
        }}
      />
      <ClearButton onClick={handleResetFilter} style={{ marginTop: "10px" }}>
        Clear
      </ClearButton>
    </Popover.Body>
  </Popover>
);
const FilterCustomer = ({
  handleFilterData,
  CustomerData,
  emailTemplates,
  customersTypes,
}) => {
  const [customerName, setCustomerName] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [emailTemplateId, setEmailTemplateId] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [isOpenInvoiceCustomers, setIsOpenInvoiceCustomers] = useState(false);
  const handleCustomerName = (e) => {
    setCustomerName(e);
    const FilterData = { customerName: e.value };
    handleFilterData(FilterData);
  };

  const handleCompanyName = (e) => {
    setCompany(e.target.value);
    const FilterData = { company: e.target.value };
    handleFilterData(FilterData);
  };

  const handleLocationName = (e) => {
    setLocation(e.target.value);
    const FilterData = { location: e.target.value };
    handleFilterData(FilterData);
  };

  const handleTemplateName = (e) => {
    setEmailTemplateId(e.target.value);
    const FilterData = { emailTemplateId: e.target.value };
    handleFilterData(FilterData);
  };

  const handleCustomerType = (e) => {
    setCustomerType(e.target.value);
    const FilterData = { customerType: e.target.value };
    handleFilterData(FilterData);
  };
  const handleOpenInvoice = (value) => {
    setIsOpenInvoiceCustomers(value);
    const FilterData =
      value === true
        ? {
            isOpenInvoiceCustomers: value,
            sortOn: "outstanding",
            sortOrder: "desc",
          }
        : {
            isOpenInvoiceCustomers: value,
            sortOn: "customer",
            sortOrder: "asc",
          };

    handleFilterData(FilterData);
  };

  const handleResetFilter = () => {
    setCustomerName("");
    setCompany("");
    setLocation("");
    setEmailTemplateId("");
    setCustomerType("");
    setIsOpenInvoiceCustomers(false);
    const FilterData = {
      customerName: "",
      company: "",
      location: "",
      emailTemplateId: "",
      customerType: "",
      isOpenInvoiceCustomers: false,
    };
    handleFilterData(FilterData);
  };

  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={popoverPanel(
          CustomerData,
          emailTemplateId,
          handleTemplateName,
          handleCustomerName,
          handleCompanyName,
          handleResetFilter,
          location,
          handleLocationName,
          company,
          customerName,
          emailTemplates,
          customersTypes,
          handleCustomerType,
          customerType,
          handleOpenInvoice,
          isOpenInvoiceCustomers
        )}
      >
        <StyledButton>
          Filter
          <ArrowDownIcon />
        </StyledButton>
      </OverlayTrigger>
    </>
  );
};
export default FilterCustomer;
