import axios from "utils/APIv2";

export const getOrnetSettings = async () => {
  const res = await axios.get(`/ornet-settings`);
  return res.data;
};

export const setOrnetSettings = async (payload) => {
  return await axios.post(`/ornet-settings`, payload);
};

export const setOrnetCode = async (payload) => {
  return await axios.post(`/ornet-settings/generate-tokens`, payload);
};

export const getOracleDataFetchStatus = async () => {
  return await axios.get(`/ornet-settings/oracleCronStatus`);
};