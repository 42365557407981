import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { setAllTokensForUserBankAccount, removeABankAccount, updateBankForForecasting, updateBankForReauthorisation } from "services/bankSettings.services";

export const useAddBankSettings = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => setAllTokensForUserBankAccount(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("bankSettings");
    },
  });
};

export const useRemoveBankAccount = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => removeABankAccount(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("remove-bankSettings");
    },
  });
};

export const useBankForForecasting = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => updateBankForForecasting(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("add-bank-for-forecasting");
    },
  });
};

export const useBankForReauthorisation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => updateBankForReauthorisation(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("add-bank-for-reauthorisation");
    },
  });
};