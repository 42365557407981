import React from "react";
import { ArrowDownWhiteIcon } from "assets/icons.js";
import { components } from "react-select";
import Select from "react-select-virtualized";
import styled from "styled-components";

const StyleSelect = styled.div`
  .react-select-container .react-select__menu {
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #000;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .css-b62m3t-container .fast-option {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Montserrat Alternates;
  }
  .css-b62m3t-container .fast-option-focused {
    background-color: #222;
    font-family: Montserrat Alternates;
  }
`;

const IndicatorsContainer = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownWhiteIcon width="11px" height="5px" />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  control: (base) => ({
    ...base,
    background: "#232631 0% 0% no-repeat padding-box",
    borderRadius: 0,
    borderColor: "#ffffff69",
    boxShadow: null,
    minHeight: "25px",
    height: "25px",
    "&:hover": {
      background: "#3a3b48 0% 0% no-repeat padding-box",
    },
    "&:focus": {
      background: "#3a3b48 0% 0% no-repeat padding-box",
    },
    "::-webkit-scrollbar": {
      width: "3px",
    },
    //   ::-webkit-scrollbar-track {
    //     background: #000;
    //   }
    //   ::-webkit-scrollbar-thumb {
    //     background: #888;
    //   }
    //   ::-webkit-scrollbar-thumb:hover {
    //     background: #555;
    //   }
  }),

  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: "8px",
    paddingLeft: 0,
  }),
  option: (base, { isFocused }) => ({
    ...base,
    color: "#ffffff69",
    boxShadow: "0px 3px 46px #00000029",
    border: "0px solid #FFFFFF",
    borderRadius: "0px",
    //color:"#FFFFFF",
    height: "25px",
    background: isFocused ? "#3a3b48" : "#232631 0% 0% no-repeat padding-box",
    display: "flex",
    alignItems: "center",
  }),
  valueContainer: (base) => ({
    ...base,
    color: "#ffffff69",
    paddingTop: 0,
  }),
  placeholder: (base) => ({
    ...base,
    color: " #ffffff69",
    textAlign: "left",
    fontFamily: "Montserrat Alternates-semiBold",
    fontSize: "11px",
  }),
  input: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    color: " #ffffff69",
    textAlign: "left",
    fontFamily: "Montserrat Alternates-semiBold",
    fontSize: "11px",
  }),
  singleValue: (base) => ({
    ...base,
    color: " #ffffff69",
    textAlign: "left",
    fontFamily: "Montserrat Alternates-semiBold",
    fontSize: "11px",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    margin: 0,
    padding: 0,
    background: "#232631 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 46px #00000029",
    border: "1px solid #ffffff69",
    color: "#ffffff69",
  }),
};

export default function SelectWithVirtualized({
  options,
  value,
  onChange,
  multiple,
  clearable,
  disabled,
  placeholder = "Search & Select",
}) {
  console.log("options => ", options);
  return (
    <StyleSelect>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        placeholder={placeholder}
        styles={customStyles}
        onChange={onChange}
        value={value}
        multiple={multiple}
        isMulti={multiple}
        clearable={clearable}
        disabled={disabled}
        components={{
          IndicatorsContainer,
          IndicatorSeparator: () => null,
        }}
        options={options}
      />
    </StyleSelect>
  );
}
