import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";

const StyledCheckbox = styled.div`
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 0px 4px;
    background-repeat: no-repeat;
    background-color: #232631;
    border-radius: 3px;
    border: 1px solid #909090;
    opacity: 1;    
    &:checked {
      background-color: #232631;
      border-color: #909090;
      background-repeat: no-repeat;
    }
  }

  label {
    color: #ffffff69;
    font-family: Montserrat Alternates-semiBold;
    font-size: 10px;
    line-height: 13px;
    margin-left: 5px;
  }
`;
export default function BSCheckbox({ label, checked, name,id,type,onChange}) {

  return (
    <StyledCheckbox>
    <Form>
    <Form.Check
      inline
      name={name}
      label={label}
      type="checkbox"
      id={id}
      checked={checked}
      onChange={onChange}
    />
    </Form>
    </StyledCheckbox>
  );
}
