import React, { useState, useContext, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Form, Formik, Checkbox } from "ui/forms";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";
import Card from "ui/Card";
import { BasicButton } from "ui/Buttons";
import { TopArrowSubmenuIcon, SearchIcon } from "assets/icons.js";
import { useHistory } from "react-router-dom";
import StepperComponent from "./Stepper";
import { UIContext } from "context/UIContext";
import { Route_URLs } from "routesURLs";
import { Authorise } from "helpers/Authorization";
import Loader from "ui/Loader";
import useDebounce from "hooks/useDebounce";
import {
  getReceivablesAccounts,
  getPayablesAccounts,
  getCashClassificationBudgetingExpenseList,
  getCashClassificationBudgetingIncomeList,
  // getBanks,
} from "services/cashClassification.services";
import { Desktop, Tablet, Mobile } from "ui/mediaQueries";
import Accordion from "react-bootstrap/Accordion";
import {
  useCashClassificationAccounts,
  useCashClassificationIncomeAccounts,
  useCashClassificationExpenseAccounts,
} from "queries/cashClassificationAccounts";
import {
  useAddCashClassificationAccounts,
  useAddCashClassificationBudgeting,
  useDeleteCashClassificationBudgeting,
} from "mutations/addCashClassificationAccounts";
import { FieldArray } from "formik";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import Table, { StyledSubRows } from "ui/table";
import CashClassificationAccountsTable from "ui/CashClassificationAccountsTable";
import { ArrowDownIcon, UpIcon } from "assets/icons";
import { useQueryClient } from "react-query";
import Toggleicon from "assets/arrow-down-new.svg";
import InsightsContext from "context/InsightsContext";
import { useGeneralSettings } from "queries/generalSettings";

const StyledLabel = styled.span`
  font-family: Montserrat Alternates;
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #909090;
  opacity: 1;
  margin-bottom: 8px;
  display: inline-block;
`;

const StyledSelect = styled.div`
  .react-select-container .react-select__menu-list {
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #000;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .react-select-container {
    position: absolute;
    background: #3a3b48;
    border: 1px solid #707070;
    z-index: 99;
    color: #ffffff69;
  }
  .react-select-container .react-select__control {
    /* height: 25px; */
    border: 1px solid #707070;
    background: #3a3b48;
    color: #ffffff69;
    outline: none;
    box-shadow: none;
    font-size: 12px;
    line-height: 13px;
    min-width: 228px;

    &:hover {
      outline: none;
      box-shadow: none;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .react-select-container .react-select__menu {
    background: #3a3b48;
  }
  .react-select-container .react-select__control--menu-is-open {
    background: #3a3b48;
  }
  .css-11unzgr {
    max-height: 100px;
  }
  .css-1g6gooi {
    color: #ffffff69;
  }
  .css-151xaom-placeholder {
    top: 80%;
  }
  .react-select-container .react-select__value-container {
    height: 25px;
  }

  button {
    width: 240px;
    height: 40px;
    background: #3a3b48 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #707070;
    border-radius: 20px;
    text-align: left;
    font-family: Montserrat Alternates-semiBold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0px;
    color: #ffffff69;
    padding: 12px;
  }
  .react-select-container .react-select__option {
    background: #3a3b48;
    font-size: 10px;
    line-height: 13px;
    color: #ffffff69;
    display: flex;
    align-items: center;
  }
  input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #3a3b48;
    border: 1px solid #707070;
    display: inline-block;
    width: 14px;
    height: 14px;

    background-repeat: no-repeat;
    &:after {
      content: "✔";
      color: #3a3b48;
      position: absolute;
      top: -20%;
      font-size: 11px;
      padding: 2px;
    }
    &:checked {
      &:after {
        content: "✔";
        color: #33c2df;
        position: absolute;
        top: -20%;
        font-size: 11px;
        padding: 2px;
      }
    }
  }
`;

const StyledCard = styled.div``;
const OuterDiv = styled.div`
  padding: 30px;
  .HeadingText {
    letter-spacing: 0px;
    color: #909090;
    font-family: Montserrat Alternates-Bold;
    font-size: 18px;
    line-height: 22px;
  }
`;
const PartionDiv = styled.div`
  padding: 20px 10px;
  min-height: 250px;
`;

const AccountsDiv = styled.div`
  padding: 10px;
  p {
    display: inline-block;
    font-family: Montserrat Alternates;
    text-align: left;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #909090;
    opacity: 1;
    margin-bottom: 8px;
  }
`;

const NavigationButtons = styled.div`
  .back-button {
    float: left;
    background: none;
    border: 2px solid #909090;
  }
  .next-button {
    float: right;
  }
  padding: 20px;
`;
const MainContainer = styled.div`
  position: relative;
`;
const TopSection = styled.div`
  width: 240px;
  height: 40px;
  background: #3a3b48 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  text-align: left;
  font-family: Montserrat Alternates-semiBold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff69;
  padding: 12px;
  cursor: pointer;
  svg {
    margin-top: 5px;
    transform: rotateZ(180deg);
    transition: all 0.2s ease-in-out;
    float: right;
    path {
      fill: #ffffff69;
    }
  }
`;
const BottomSection = styled.div`
  /* width: 240px; */

  padding: 10px;
  margin-top: 10px;
  height: 200px;
  /* position: absolute; */
  background: #3a3b48;
  border: 1px solid #707070;
  z-index: 99;
  color: #ffffff69;
  .search-icon {
    position: absolute;
    top: 30%;
    right: 10%;
  }
`;
const SearchInput = styled.input`
  border: 1px solid #707070;
  background: #3a3b48;
  color: #ffffff69;
  font-size: 12px !important;
  /* min-width: 228px; */
  border-radius: 0px !important;
  &:focus {
    background-color: #3a3b48 !important;
    color: #ffffff69 !important;
  }
`;
const CheckboxSection = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #000;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  overflow-y: scroll;
  height: 135px;
  margin-top: 10px;
  input {
    margin-right: 5px;
  }
  label {
    display: flex;
    align-items: center;
  }
  li {
    padding-bottom: 8px;
  }
  input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #3a3b48;
    border: 1px solid #707070;
    display: inline-block;
    width: 14px;
    height: 14px;

    background-repeat: no-repeat;
    &:after {
      content: "✔";
      color: #3a3b48;
      position: absolute;
      top: -45%;
      font-size: 11px;
      padding: 2px;
    }
    &:checked {
      &:after {
        content: "✔";
        color: #33c2df;
        position: absolute;
        top: -45%;
        font-size: 11px;
        padding: 2px;
      }
    }
  }
`;

const StyledTable = styled.div`
  .table-wrapper {
    padding: 25px 0px;
  }
`;
const ExpandIcon = styled.div`
  display: flex;
  cursor: pointer;
  .icon {
    width: 8px;
    height: 6px;
    align-items: center;
    justify-content: center;
    path {
      fill: #33c2df;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  margin-top: 20px;
  .accordion-item:first-of-type {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .accordion-item:last-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .accordion-item {
    background-color: #3a3b48;
    margin-bottom: 12px;
    .accordion-button:not(.collapsed) {
      box-shadow: none;
    }
    p {
      text-align: left;
      font-family: Montserrat Alternates-Bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0px;
      color: #909090;
      opacity: 1;
      margin-top: 20px;
      margin-bottom: 24px;
    }
    img {
      width: 100%;
      /* height: 336px; */
    }
  }
  .accordion-header {
    button {
      text-align: left;
      font-family: Montserrat Alternates-Bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0px;
      color: #909090;
      background-color: #3a3b48;
    }
    .accordion-button::after {
      background-image: url(${Toggleicon});
    }
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: baseline;
  input {
    background: transparent 0% 0% no-repeat padding-box !important;
    color: #808186 !important;
  }
  input[type="checkbox"] {
    margin-bottom: -3px;
    margin-right: 5px;
    background: transparent 0% 0% no-repeat padding-box;
    &:after {
      color: transparent;
    }
    &:checked {
      &:after {
        color: #33c2df;
      }
    }
  }
`;

function CashClassification() {
  let history = useHistory();
  const { onBoarding } = useContext(UIContext);
  const [showARList, setShowARList] = useState(false);
  const [showAPList, setShowAPList] = useState(false);
  const [showIncomeAccountsList, setShowIncomeAccountsList] = useState(false);
  const [showExpenseAccountsList, setShowExpenseAccountsList] = useState(false);
  const [showBankList, setShowBankList] = useState(false);
  const [receivablesPage, setReceivablesPage] = useState(0);
  const [payablesPage, setPayablesPage] = useState(0);
  const [bankPage, setBankPage] = useState(0);
  const [receivablesData, setReceivablesData] = useState([]);
  const [payablesData, setPayablesData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [totalCountBanks, setTotalCountBanks] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCountReceivables, setTotalCountReceivables] = useState();
  const [totalCountPayables, setTotalCountPayables] = useState();
  const [RecordsLengthReceivables, setRecordsLengthReceivables] = useState();
  const [RecordsLengthPayables, setRecordsLengthPayables] = useState();
  const [receivablesSearch, setReceivablesSearch] = useState("");
  const [incomesSearch, setIncomesSearch] = useState("");
  const [expensesSearch, setExpensesSearch] = useState("");
  const [payablesSearch, setPayablesSearch] = useState("");
  const [bankSearch, setBankSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const debouncedSearchReceivables = useDebounce(receivablesSearch, 500);
  const debouncedSearchPayables = useDebounce(payablesSearch, 500);
  const debounceBankSearch = useDebounce(bankSearch, 500);

  const [incomeAccounts, setIncomeAccounts] = useState([]);
  const [expenseAccounts, setExpenseAccounts] = useState([]);
  const [tableRerender, setTableRerender] = useState(false);
  const [showIncomeInfoMessage, setShowIncomeInfoMessage] = useState(false);
  const [showExpenseInfoMessage, setShowExpenseInfoMessage] = useState(false);
  const [incomeInitialPage, setIncomeInitialPage] = useState(0);
  const [expenseInitialPage, setExpenseInitialPage] = useState(0);
  const queryClient = useQueryClient();
  const { AccordionOpenState } = useContext(InsightsContext);
  const bottomSectionRef = useRef(null);

  const [isSubmit, setIsSubmit] = useState(false);

  const { data: cashClassificationAccounts, isLoading } =
    useCashClassificationAccounts();

  const { data: cashClassificationIncomeAccounts, isLoadingIncomeAccounts } =
    useCashClassificationIncomeAccounts();

  const { data: cashClassificationExpenseAccounts, isLoadingExpenseAccounts } =
    useCashClassificationExpenseAccounts();
  const { data: generalSettingsData } = useGeneralSettings();

  const [mapCCIncomeAccounts, setMapCCIncomeAccounts] = useState([]);
  const [mapCCExpenseAccounts, setMapCCExpenseAccounts] = useState([]);

  async function getIncomeAccountsTableList() {
    try {
      let res = await getCashClassificationBudgetingIncomeList({
        pageNumber: incomeInitialPage,
        limit,
      });
      setIncomeAccounts(
        res.data.data.map((e) => {
          if (!e.recurring) {
            let amount = "-";
            let frequency = "-";
            let days = "-";
            return {
              ...e,
              amount,
              frequency,
              days,
            };
          } else {
            return e;
          }
        })
      );
      // setIncomeAccounts(res.data.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function getExpenseAccountsTableList() {
    try {
      let res = await getCashClassificationBudgetingExpenseList({
        pageNumber: expenseInitialPage,
        limit,
      });
      setExpenseAccounts(
        res.data.data.map((e) => {
          if (!e.recurring) {
            let amount = "-";
            let frequency = "-";
            let days = "-";
            return {
              ...e,
              amount,
              frequency,
              days,
            };
          } else {
            return e;
          }
        })
      );
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(async () => {
    await getIncomeAccountsTableList();
    await getExpenseAccountsTableList();
  }, []);

  const AddCashClassificationAccounts = useAddCashClassificationAccounts();
  const AddCashClassificationBudgeting = useAddCashClassificationBudgeting();
  const DeleteCashClassificationBudgeting =
    useDeleteCashClassificationBudgeting();

  const arAccoutsData = cashClassificationAccounts?.arAccounts;
  const apAccoutsData = cashClassificationAccounts?.apAccounts;
  const bankAccountData = cashClassificationAccounts?.bankAccounts;

  const [fillAllForIncomes, setFillAllForIncomes] = useState(false);
  const [fillAllForExpenses, setFillAllForExpenses] = useState(false);

  function updateIncomeAccount(accs) {
    setIncomeAccounts(accs);
    setTableRerender(!tableRerender);
  }
  function updateExpenseAccount(accs) {
    setExpenseAccounts(accs);
    setTableRerender(!tableRerender);
  }

  function deleteAccount(caller, arr) {
    try {
      let finalArray = arr.filter((e) => e.Id != caller.Id);
      return finalArray;
    } catch (e) {
      console.log(e);
    }
  }

  const clickRowToUpdate = (row, obj, caller) => {
    try {
      if (caller === "remove") {
        // Check if the clicked element is within the "remove" column
        if (
          row &&
          row.target &&
          row.target.classList.contains("remove-column")
        ) {
          // Perform your action here (e.g., delete the row)
          if (
            ["income", "othincome", "otherincome", "other income"].includes(
              obj["AccountType"].toLowerCase()
            )
          ) {
            setIncomeAccounts(deleteAccount(obj, incomeAccounts));
          } else {
            setExpenseAccounts(deleteAccount(obj, expenseAccounts));
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  async function handlePageChangeIncome(pageNumber) {
    setIncomeInitialPage(pageNumber);
  }
  async function handlePageChangeExpense(pageNumber) {
    setExpenseInitialPage(pageNumber);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Account Name",
        accessor: "FullyQualifiedName",
        id: "fullyQualifiedName",
        Cell: ({ row, cell }) =>
          row.canExpand ? (
            <div
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 10}px`,
                },
              })}
            >
              {row.isExpanded ? (
                <ExpandIcon>
                  <div>
                    <UpIcon className="icon" />
                  </div>
                  {"  "}
                  <span style={{ paddingLeft: "5px" }}>{cell.value}</span>
                </ExpandIcon>
              ) : (
                <ExpandIcon>
                  <div>
                    <ArrowDownIcon className="icon" />
                  </div>
                  {"  "}
                  <span style={{ paddingLeft: "5px" }}>{cell.value}</span>
                </ExpandIcon>
              )}
            </div>
          ) : (
            <StyledSubRows>{cell.value}</StyledSubRows>
          ),
      },
      {
        Header: "Recurring",
        accessor: "recurring",
        id: "accountType",
        Cell: ({ row, cell }) => {
          return (
            <CheckboxWrapper>
              <input
                type="checkbox"
                label="recurring?"
                checked={row.original.recurring}
                onChange={(e) => {
                  const newValue = e.target.checked; // Get the new value of the checkbox
                  // Update the state based on the checkbox value and unique identifier
                  if (
                    [
                      "income",
                      "othincome",
                      "otherincome",
                      "other income",
                    ].includes(row.original.AccountType.toLowerCase())
                  ) {
                    setIncomeAccounts((prevAccounts) =>
                      prevAccounts.map((elem) => {
                        if (elem.Id === row.original.Id) {
                          return {
                            AccountType: elem.AccountType,
                            frequency: newValue ? "daily" : "-",
                            days: newValue ? 1 : "-",
                            amount: newValue ? "" : "-",
                            FullyQualifiedName: elem.FullyQualifiedName,
                            recurring: newValue,
                            Id: elem.Id,
                          };
                        } else {
                          return elem;
                        }
                      })
                    );
                  } else {
                    setExpenseAccounts((prevAccounts) =>
                      prevAccounts.map((elem) => {
                        if (elem.Id === row.original.Id) {
                          return {
                            AccountType: elem.AccountType,
                            frequency: newValue ? "daily" : "-",
                            days: newValue ? 1 : "-",
                            amount: newValue ? "" : "-",
                            FullyQualifiedName: elem.FullyQualifiedName,
                            recurring: newValue,
                            Id: elem.Id,
                          };
                        } else {
                          return elem;
                        }
                      })
                    );
                  }
                }}
              />
            </CheckboxWrapper>
          );
        },
      },
      {
        Header: "Frequency",
        accessor: "frequency",
        id: "frequency",
      },
      {
        Header: "Day",
        accessor: "days",
        id: "day",
      },
      {
        Header: "Amount",
        accessor: "amount",
        id: "amount",
      },
      {
        Header: "Remove",
        accessor: "removeAccount",
        id: "removeAccount",
        width: 180,
        minwidth: 180,
        disableSortBy: true,
        Cell: ({ row, cell }) => (
          <>
            <span
              className="remove-column"
              style={{
                marginLeft: "10px",
                color: "#33C2DF",
                textDecoration: "underline",
              }}
              onClick={(e) => {
                e.stopPropagation();
                clickRowToUpdate(e, cell.row.original, "remove");
              }}
            >
              Remove
            </span>
          </>
        ),
      },
    ],
    [expenseAccounts, incomeAccounts, mapCCExpenseAccounts, mapCCIncomeAccounts]
  );

  useEffect(() => {}, [incomeAccounts, expenseAccounts]);
  const reformattedAR = arAccoutsData?.map(
    ({ AccountType, FullyQualifiedName, _id, Id }) => ({
      AccountType: AccountType,
      FullyQualifiedName: FullyQualifiedName,
      _id: _id,
      Id: Id,
    })
  );

  const reformattedAP = apAccoutsData?.map(
    ({ AccountType, FullyQualifiedName, _id, Id }) => ({
      AccountType: AccountType,
      FullyQualifiedName: FullyQualifiedName,
      _id: _id,
      Id: Id,
    })
  );

  const reformattedBank = bankAccountData?.map(
    ({ AccountType, FullyQualifiedName, _id, Id }) => ({
      AccountType: AccountType,
      FullyQualifiedName: FullyQualifiedName,
      _id: _id,
      Id: Id,
    })
  );

  useEffect(() => {
    getReceivables(receivablesPage, limit, debouncedSearchReceivables);
    getPayables(payablesPage, limit, debouncedSearchPayables);
    // getBanksData(bankPage, limit, debounceBankSearch);
  }, [receivablesPage, payablesPage, bankPage]);

  useEffect(() => {
    getReceivablesSearch(receivablesPage, limit, debouncedSearchReceivables);
    getPayablesSearch(payablesPage, limit, debouncedSearchPayables);
    // getBankSearch(bankPage, limit, debounceBankSearch);
  }, [debouncedSearchReceivables, debouncedSearchPayables, debounceBankSearch]);

  const getReceivables = (
    receivablesPage,
    limit,
    debouncedSearchReceivables
  ) => {
    getReceivablesAccounts(receivablesPage, limit, debouncedSearchReceivables)
      .then((res) => {
        let result = res.data;
        let records = [...receivablesData, ...result];
        setRecordsLengthReceivables(records.length);
        setReceivablesData(records);
        setTotalCountReceivables(res.totalCount);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getReceivablesSearch = (
    receivablesPage,
    limit,
    debouncedSearchReceivables
  ) => {
    getReceivablesAccounts(receivablesPage, limit, debouncedSearchReceivables)
      .then((res) => {
        let result = res.data;
        setReceivablesData(result);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getPayablesSearch = (payablesPage, limit, debouncedSearchPayables) => {
    getPayablesAccounts(payablesPage, limit, debouncedSearchPayables)
      .then((res) => {
        let result = res.data;
        setPayablesData(result);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getPayables = (payablesPage, limit, debouncedSearchPayables) => {
    getPayablesAccounts(payablesPage, limit, debouncedSearchPayables)
      .then((res) => {
        let result = res.data;
        let records = [...payablesData, ...result];
        setRecordsLengthPayables(records.length);
        setPayablesData(records);
        setTotalCountPayables(res.totalCount);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const getBankSearch = (banksPage, limit, debounceBankSearch) => {
  //   getBanks(banksPage, limit, debounceBankSearch)
  //     .then((res) => {
  //       let result = res.data;
  //       setBankData(result);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };
  // const getBanksData = (banksPage, limit, debounceBankSearch) => {
  //   getBanks(banksPage, limit, debounceBankSearch)
  //     .then((res) => {
  //       let result = res.data;
  //       let records = [...bankData, ...result];
  //       setTotalCountBanks(records.length);
  //       setBankData(records);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const handleSubmitAccounts = (values) => {
    let index = 0; // Assuming index is set somewhere in your code

    let iAcBool = false;
    let pageIncomeNumberElement = 0;
    for (let i = 0; i < incomeAccounts.length; i++) {
      let e = incomeAccounts[i];
      if (e.recurring && (e.frequency === "" || e.amount == 0)) {
        iAcBool = true;
        pageIncomeNumberElement = index + i + 1; // Calculate position using index + i + 1
        break;
      }
      if (!e.recurring && e.amount === 0) {
        iAcBool = true;
        pageIncomeNumberElement = index + i + 1; // Calculate position using index + i + 1
        break;
      }
    }

    let eAcBool = false;
    let pageExpenseNumberElement = 0;
    for (let i = 0; i < expenseAccounts.length; i++) {
      let e = expenseAccounts[i];
      if (e.recurring && (e.frequency === "" || e.amount == 0)) {
        eAcBool = true;
        pageExpenseNumberElement = index + i + 1; // Calculate position using index + i + 1
        break;
      }
      if (!e.recurring && e.amount === 0) {
        eAcBool = true;
        pageExpenseNumberElement = index + i + 1; // Calculate position using index + i + 1
        break;
      }
    }

    setIncomeInitialPage(Math.floor(pageIncomeNumberElement / 10));
    setExpenseInitialPage(Math.floor(pageExpenseNumberElement / 10));

    setFillAllForIncomes(iAcBool);
    setFillAllForExpenses(eAcBool);

    if (!eAcBool && !iAcBool) {
      setIsSubmit(true);

      const eAccs = expenseAccounts.map((e) => {
        if (!e.recurring) {
          return {
            FullyQualifiedName: e.FullyQualifiedName,
            recurring: e.recurring,
            Id: e.Id,
            AccountType: e.AccountType,
          };
        } else {
          return {
            AccountType: e.AccountType,
            FullyQualifiedName: e.FullyQualifiedName,
            recurring: e.recurring,
            Id: e.Id,
            amount: +e.amount,
            frequency: e.frequency,
            days: `${e.days}`,
          };
        }
      });
      const iAccs = incomeAccounts.map((e) => {
        if (!e.recurring) {
          return {
            FullyQualifiedName: e.FullyQualifiedName,
            recurring: e.recurring,
            Id: e.Id,
            AccountType: e.AccountType,
          };
        } else {
          return {
            AccountType: e.AccountType,
            FullyQualifiedName: e.FullyQualifiedName,
            recurring: e.recurring,
            Id: e.Id,
            amount: +e.amount,
            frequency: e.frequency,
            days: `${e.days}`,
          };
        }
      });
      let payload = {
        apAccounts: values.apAccouts?.map((obj) => obj._id),
        arAccounts: values.arAccouts?.map((obj) => obj._id),
        bankAccounts: values.bankAccounts?.map((obj) => obj._id),
        expenseAccounts: eAccs,
        incomeAccounts: iAccs,
      };
      AddCashClassificationAccounts.mutateAsync(payload)
        .then((res) => {
          setIsSubmit(false);
          history.push(Route_URLs.setup_user_management);
        })
        .catch(function (error) {
          if (error.response) {
          }
        });
    }
  };

  const updateIncomeExpenseArrayForSearchFilter = (caller, keyword) => {
    try {
      if (caller.toLowerCase() === "income") {
        let filteredAccounts = cashClassificationIncomeAccounts.data.filter(
          (option) =>
            keyword.trim() === "" ||
            option.FullyQualifiedName.toLowerCase().includes(
              keyword.toLowerCase()
            )
        );
        setMapCCIncomeAccounts(filteredAccounts);
      } else {
        let filteredAccounts = cashClassificationExpenseAccounts.data.filter(
          (option) =>
            keyword.trim() === "" ||
            option.FullyQualifiedName.toLowerCase().includes(
              keyword.toLowerCase()
            )
        );
        setMapCCExpenseAccounts(filteredAccounts);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateIncomeExpenseSelectAll = (caller) => {
    try {
      if (caller.toLowerCase() === "income") {
        // Create a Set to keep track of unique FullyQualifiedNames
        const uniqueNames = new Set(
          incomeAccounts.map((account) => account.FullyQualifiedName)
        );

        // Filter out items from mapCCIncomeAccounts that are not already in incomeAccounts
        const uniqueNewItems = mapCCIncomeAccounts.filter(
          (option) => !uniqueNames.has(option.FullyQualifiedName)
        );

        // Update the expenseAccounts array with the unique new items
        setIncomeAccounts((prevAccounts) => [
          ...prevAccounts,
          ...uniqueNewItems.map((option) => ({
            frequency: "",
            days: "",
            amount: "",
            Id: option.Id,
            AccountType: option.AccountType,
            FullyQualifiedName: option.FullyQualifiedName,
            recurring: false,
          })),
        ]);
      } else {
        // Create a Set to keep track of unique FullyQualifiedNames
        const uniqueNames = new Set(
          expenseAccounts.map((account) => account.FullyQualifiedName)
        );

        // Filter out items from mapCCExpenseAccounts that are not already in expenseAccounts
        const uniqueNewItems = mapCCExpenseAccounts.filter(
          (option) => !uniqueNames.has(option.FullyQualifiedName)
        );

        // Update the expenseAccounts array with the unique new items
        setExpenseAccounts((prevAccounts) => [
          ...prevAccounts,
          ...uniqueNewItems.map((option) => ({
            frequency: "",
            days: "",
            amount: "",
            Id: option.Id,
            AccountType: option.AccountType,
            FullyQualifiedName: option.FullyQualifiedName,
            recurring: false,
          })),
        ]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  function performOperationOnArray(operation, array, data) {
    if (operation === "add") {
      return [...array, data];
    } else {
      let updatedArray = array.filter((e) => {
        if (e.Id !== data.Id) return e;
      });
      return updatedArray;
    }
  }

  const updateIncomeExpenseAccountsArray = (caller, operation, data) => {
    if (caller.toLowerCase() === "income") {
      setIncomeAccounts([
        ...performOperationOnArray(operation, incomeAccounts, data),
      ]);
    } else {
      setExpenseAccounts([
        ...performOperationOnArray(operation, expenseAccounts, data),
      ]);
    }
  };

  useEffect(async () => {
    setMapCCIncomeAccounts(cashClassificationIncomeAccounts?.data);
    setMapCCExpenseAccounts(cashClassificationExpenseAccounts?.data);
  }, [incomeAccounts, expenseAccounts, tableRerender]);

  function handleTopSectionClick(event, caller) {
    // Check if the click target is within the TopSection or its children
    if (
      event.target.closest(".top-section") ||
      event.target.closest(".top-arrow-submenu-icon")
    ) {
      // Don't toggle the showAPList state
      return;
    }
    if (caller.toLowerCase() == "ap") {
      // Toggle the showAPList state
      setShowAPList(!showAPList);
    } else if (caller.toLowerCase() == "ar") {
      setShowARList(!showARList);
    } else if (caller.toLowerCase() == "income") {
      setShowIncomeAccountsList(!showIncomeAccountsList);
    } else if (caller.toLowerCase() == "expense") {
      setShowExpenseAccountsList(!showExpenseAccountsList);
    }
  }
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        bottomSectionRef.current &&
        !bottomSectionRef.current.contains(event.target)
      ) {
        setShowARList(false);
        setShowAPList(false);
        setShowIncomeAccountsList(false);
        setShowExpenseAccountsList(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [bottomSectionRef]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : !isSubmit ? (
        <PrivateLayout>
          <Page>
            <Page.Header>
              <Row className="m-0">
                <Col className="p-0">
                  <h1 className="page-title">Cash Classification</h1>
                  <StepperComponent currentPage={onBoarding ? 2 : 8} />
                </Col>
              </Row>
            </Page.Header>
            <Page.Body>
              <Formik
                initialValues={{
                  arAccouts: reformattedAR,
                  apAccouts: reformattedAP,
                  bankAccounts: reformattedBank,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  handleSubmitAccounts(values);
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit} className="mx-auto">
                    <ErrorHandlingComponent errors={errorMessage} />
                    {/* <JSONPretty
                    style={{ color: "white" }}
                    data={values}
                  ></JSONPretty> */}
                    <Authorise allowedRoles={["admin"]}>
                      <OuterDiv>
                        <p className="HeadingText">
                          Help us understand how your accounts are set up?
                        </p>
                        <StyledAccordion defaultActiveKey={AccordionOpenState}>
                          {/* <Accordion.Item eventKey="0">
                              <Accordion.Header>Accounts Receivables</Accordion.Header>
                              <Accordion.Body>
                                <Desktop>
                                  <PartionDiv
                                    style={{ borderBottom: "1px solid #909090" }}
                                  >
                                    <Row>
                                      <Col sm={3}>
                                        <StyledLabel>
                                          Specify All The Non Trade AR accounts
                                        </StyledLabel>
                                        <MainContainer>
                                          <TopSection onClick={(e) => handleTopSectionClick(e, 'ar')}>
                                            <span>
                                              {values.arAccouts?.length > 0
                                                ? `${values.arAccouts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {showARList && (
                                            <BottomSection ref={bottomSectionRef}>
                                              <SearchInput
                                                value={receivablesSearch}
                                                onChange={(e) =>
                                                  setReceivablesSearch(
                                                    e.target.value
                                                  )
                                                }
                                                placeholder="Search..."
                                              ></SearchInput>
                                              <SearchIcon className="search-icon" />
                                              <CheckboxSection>
                                                <FieldArray
                                                  name="arAccouts"
                                                  render={({ push, remove }) => {
                                                    return (
                                                      <ul style={{ listStyle: "none" }}>
                                                        {receivablesData.length >
                                                          2 ? (
                                                          <li key={"select-all-ar"}>
                                                            <label>
                                                              <input
                                                                name="arAccounts-select-all"
                                                                type="checkbox"
                                                                value={"Select All"}
                                                                checked={
                                                                  values.arAccouts
                                                                    .length ==
                                                                  receivablesData.length
                                                                }
                                                                onChange={(e) => {
                                                                  if (
                                                                    e.target.checked
                                                                  ) {
                                                                    const indexs =
                                                                      values.arAccouts.map(
                                                                        function (e) {
                                                                          return e._id;
                                                                        }
                                                                      );
                                                                    indexs.forEach(
                                                                      (i) => {
                                                                        remove(i);
                                                                      }
                                                                    );
                                                                    receivablesData.forEach(
                                                                      (option) => {
                                                                        push({
                                                                          _id: option._id,
                                                                          Id: option.Id,
                                                                          AccountType:
                                                                            option.AccountType,
                                                                          FullyQualifiedName:
                                                                            option.FullyQualifiedName,
                                                                        });
                                                                      }
                                                                    );
                                                                  } else {
                                                                    const indexs =
                                                                      values.arAccouts.map(
                                                                        function (e) {
                                                                          return e._id;
                                                                        }
                                                                      );
                                                                    indexs.forEach(
                                                                      (i) => {
                                                                        remove(i);
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              Select All
                                                            </label>
                                                          </li>
                                                        ) : null}
                                                        {receivablesData?.map(
                                                          (option) => (
                                                            <li key={option.id}>
                                                              <label>
                                                                <input
                                                                  name="arAccounts"
                                                                  type="checkbox"
                                                                  value={option}
                                                                  checked={values.arAccouts
                                                                    .map((e) => e._id)
                                                                    .includes(
                                                                      option._id
                                                                    )}
                                                                  onChange={(e) => {
                                                                    if (
                                                                      e.target.checked
                                                                    ) {
                                                                      push({
                                                                        _id: option._id,
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                      });
                                                                    } else {
                                                                      const index =
                                                                        values.arAccouts
                                                                          .map(
                                                                            function (
                                                                              e
                                                                            ) {
                                                                              return e._id;
                                                                            }
                                                                          )
                                                                          .indexOf(
                                                                            option._id
                                                                          );
                                                                      remove(index);
                                                                    }
                                                                  }}
                                                                />
                                                                <span />
                                                                {`${option.AccountType} | ${option.FullyQualifiedName}`}
                                                              </label>
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    )
                                                  }}
                                                />

                                                {totalCountReceivables >
                                                  RecordsLengthReceivables && (
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        padding: "5px",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setReceivablesPage(
                                                          (receivablesPage) =>
                                                            receivablesPage + 1
                                                        );
                                                      }}
                                                    >
                                                      load more...
                                                    </div>
                                                  )}
                                              </CheckboxSection>
                                            </BottomSection>
                                          )}
                                        </MainContainer>
                                      </Col>
                                      <Col sm={9}>
                                        <AccountsDiv>
                                          <Row>
                                            {values.arAccouts?.map((option) => (
                                              <Col sm={3}>
                                                <p className="ar-ap-list-accordion">{option.FullyQualifiedName}</p>
                                              </Col>
                                            ))}
                                          </Row>
                                        </AccountsDiv>
                                      </Col>
                                    </Row>
                                  </PartionDiv>
                                </Desktop>
                                <Tablet>
                                  <PartionDiv
                                    style={{ borderBottom: "1px solid #909090" }}
                                  >
                                    <Row>
                                      <Col sm={3}>
                                        <StyledLabel>
                                          Specify All The Non Trade AR accounts
                                        </StyledLabel>
                                        <MainContainer>
                                          <TopSection onClick={(e) => handleTopSectionClick(e, 'ar')}>
                                            <span>
                                              {values.arAccouts?.length > 0
                                                ? `${values.arAccouts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {showARList && (
                                            <BottomSection ref={bottomSectionRef}>
                                              <SearchInput
                                                value={receivablesSearch}
                                                onChange={(e) =>
                                                  setReceivablesSearch(
                                                    e.target.value
                                                  )
                                                }
                                                placeholder="Search..."
                                              ></SearchInput>
                                              <SearchIcon className="search-icon" />
                                              <CheckboxSection>
                                                <FieldArray
                                                  name="arAccouts"
                                                  render={({ push, remove }) => {
                                                    return (
                                                      <ul style={{ listStyle: "none" }}>
                                                        {receivablesData.length >
                                                          2 ? (
                                                          <li key={"select-all-ar"}>
                                                            <label>
                                                              <input
                                                                name="arAccounts-select-all"
                                                                type="checkbox"
                                                                value={"Select All"}
                                                                checked={
                                                                  values.arAccouts
                                                                    .length ==
                                                                  receivablesData.length
                                                                }
                                                                onChange={(e) => {
                                                                  if (
                                                                    e.target.checked
                                                                  ) {
                                                                    const indexs =
                                                                      values.arAccouts.map(
                                                                        function (e) {
                                                                          return e._id;
                                                                        }
                                                                      );
                                                                    indexs.forEach(
                                                                      (i) => {
                                                                        remove(i);
                                                                      }
                                                                    );
                                                                    receivablesData.forEach(
                                                                      (option) => {
                                                                        push({
                                                                          _id: option._id,
                                                                          Id: option.Id,
                                                                          AccountType:
                                                                            option.AccountType,
                                                                          FullyQualifiedName:
                                                                            option.FullyQualifiedName,
                                                                        });
                                                                      }
                                                                    );
                                                                  } else {
                                                                    const indexs =
                                                                      values.arAccouts.map(
                                                                        function (e) {
                                                                          return e._id;
                                                                        }
                                                                      );
                                                                    indexs.forEach(
                                                                      (i) => {
                                                                        remove(i);
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              Select All
                                                            </label>
                                                          </li>
                                                        ) : null}
                                                        {receivablesData?.map(
                                                          (option) => (
                                                            <li key={option.id}>
                                                              <label>
                                                                <input
                                                                  name="arAccounts"
                                                                  type="checkbox"
                                                                  value={option}
                                                                  checked={values.arAccouts
                                                                    .map((e) => e._id)
                                                                    .includes(
                                                                      option._id
                                                                    )}
                                                                  onChange={(e) => {
                                                                    if (
                                                                      e.target.checked
                                                                    ) {
                                                                      push({
                                                                        _id: option._id,
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                      });
                                                                    } else {
                                                                      const index =
                                                                        values.arAccouts
                                                                          .map(
                                                                            function (
                                                                              e
                                                                            ) {
                                                                              return e._id;
                                                                            }
                                                                          )
                                                                          .indexOf(
                                                                            option._id
                                                                          );
                                                                      remove(index);
                                                                    }
                                                                  }}
                                                                />
                                                                <span />
                                                                {`${option.AccountType} | ${option.FullyQualifiedName}`}
                                                              </label>
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    )
                                                  }}
                                                />

                                                {totalCountReceivables >
                                                  RecordsLengthReceivables && (
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        padding: "5px",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setReceivablesPage(
                                                          (receivablesPage) =>
                                                            receivablesPage + 1
                                                        );
                                                      }}
                                                    >
                                                      load more...
                                                    </div>
                                                  )}
                                              </CheckboxSection>
                                            </BottomSection>
                                          )}
                                        </MainContainer>
                                      </Col>
                                      <Col sm={9}>
                                        <AccountsDiv>
                                          <Row>
                                            {values.arAccouts?.map((option) => (
                                              <Col sm={3}>
                                                <p className="ar-ap-list-accordion">{option.FullyQualifiedName}</p>
                                              </Col>
                                            ))}
                                          </Row>
                                        </AccountsDiv>
                                      </Col>
                                    </Row>
                                  </PartionDiv>
                                </Tablet>
                                <Mobile>
                                  <PartionDiv
                                    style={{ borderBottom: "1px solid #909090" }}
                                  >
                                    <Row>
                                      <Col sm={3}>
                                        <StyledLabel>
                                          Specify All The Non Trade AR accounts
                                        </StyledLabel>
                                        <MainContainer>
                                          <TopSection onClick={(e) => handleTopSectionClick(e, 'ar')}>
                                            <span>
                                              {values.arAccouts?.length > 0
                                                ? `${values.arAccouts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {showARList && (
                                            <BottomSection ref={bottomSectionRef}>
                                              <SearchInput
                                                value={receivablesSearch}
                                                onChange={(e) =>
                                                  setReceivablesSearch(
                                                    e.target.value
                                                  )
                                                }
                                                placeholder="Search..."
                                              ></SearchInput>
                                              <SearchIcon className="search-icon" />
                                              <CheckboxSection>
                                                <FieldArray
                                                  name="arAccouts"
                                                  render={({ push, remove }) => {
                                                    return (
                                                      <ul style={{ listStyle: "none" }}>
                                                        {receivablesData.length >
                                                          2 ? (
                                                          <li key={"select-all-ar"}>
                                                            <label>
                                                              <input
                                                                name="arAccounts-select-all"
                                                                type="checkbox"
                                                                value={"Select All"}
                                                                checked={
                                                                  values.arAccouts
                                                                    .length ==
                                                                  receivablesData.length
                                                                }
                                                                onChange={(e) => {
                                                                  if (
                                                                    e.target.checked
                                                                  ) {
                                                                    const indexs =
                                                                      values.arAccouts.map(
                                                                        function (e) {
                                                                          return e._id;
                                                                        }
                                                                      );
                                                                    indexs.forEach(
                                                                      (i) => {
                                                                        remove(i);
                                                                      }
                                                                    );
                                                                    receivablesData.forEach(
                                                                      (option) => {
                                                                        push({
                                                                          _id: option._id,
                                                                          Id: option.Id,
                                                                          AccountType:
                                                                            option.AccountType,
                                                                          FullyQualifiedName:
                                                                            option.FullyQualifiedName,
                                                                        });
                                                                      }
                                                                    );
                                                                  } else {
                                                                    const indexs =
                                                                      values.arAccouts.map(
                                                                        function (e) {
                                                                          return e._id;
                                                                        }
                                                                      );
                                                                    indexs.forEach(
                                                                      (i) => {
                                                                        remove(i);
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              Select All
                                                            </label>
                                                          </li>
                                                        ) : null}
                                                        {receivablesData?.map(
                                                          (option) => (
                                                            <li key={option.id}>
                                                              <label>
                                                                <input
                                                                  name="arAccounts"
                                                                  type="checkbox"
                                                                  value={option}
                                                                  checked={values.arAccouts
                                                                    .map((e) => e._id)
                                                                    .includes(
                                                                      option._id
                                                                    )}
                                                                  onChange={(e) => {
                                                                    if (
                                                                      e.target.checked
                                                                    ) {
                                                                      push({
                                                                        _id: option._id,
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                      });
                                                                    } else {
                                                                      const index =
                                                                        values.arAccouts
                                                                          .map(
                                                                            function (
                                                                              e
                                                                            ) {
                                                                              return e._id;
                                                                            }
                                                                          )
                                                                          .indexOf(
                                                                            option._id
                                                                          );
                                                                      remove(index);
                                                                    }
                                                                  }}
                                                                />
                                                                <span />
                                                                {`${option.AccountType} | ${option.FullyQualifiedName}`}
                                                              </label>
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    )
                                                  }}
                                                />

                                                {totalCountReceivables >
                                                  RecordsLengthReceivables && (
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        padding: "5px",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setReceivablesPage(
                                                          (receivablesPage) =>
                                                            receivablesPage + 1
                                                        );
                                                      }}
                                                    >
                                                      load more...
                                                    </div>
                                                  )}
                                              </CheckboxSection>
                                            </BottomSection>
                                          )}
                                        </MainContainer>
                                      </Col>
                                      <Col sm={9}>
                                        <AccountsDiv>
                                          <Row>
                                            {values.arAccouts?.map((option) => (
                                              <Col sm={3}>
                                                <p className="ar-ap-list-accordion">{option.FullyQualifiedName}</p>
                                              </Col>
                                            ))}
                                          </Row>
                                        </AccountsDiv>
                                      </Col>
                                    </Row>
                                  </PartionDiv>
                                </Mobile>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>Accounts Payables</Accordion.Header>
                              <Accordion.Body>
                                <Desktop>
                                  <PartionDiv
                                    style={{ borderBottom: "1px solid #909090" }}
                                  >
                                    <Row>
                                      <Col sm={3}>
                                        <StyledLabel>
                                          Specify All the Non Controllable AP
                                          accounts
                                        </StyledLabel>
                                        <MainContainer>
                                          <TopSection onClick={(e) => handleTopSectionClick(e, 'ap')}>
                                            <span>
                                              {values.apAccouts?.length > 0
                                                ? `${values.apAccouts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {showAPList && (
                                            <BottomSection ref={bottomSectionRef}>
                                              <SearchInput
                                                value={payablesSearch}
                                                onChange={(e) =>
                                                  setPayablesSearch(e.target.value)
                                                }
                                                placeholder="Search..."
                                              ></SearchInput>
                                              <SearchIcon className="search-icon" />
                                              <CheckboxSection>
                                                <FieldArray
                                                  name="apAccouts"
                                                  render={({ push, remove }) => (
                                                    <ul style={{ listStyle: "none" }}>
                                                      {payablesData.length > 2 ? (
                                                        <li key={"select-all-ap"}>
                                                          <label>
                                                            <input
                                                              name="apAccounts-select-all"
                                                              type="checkbox"
                                                              value={"Select All"}
                                                              checked={
                                                                values.apAccouts
                                                                  .length ==
                                                                payablesData.length
                                                              }
                                                              onChange={(e) => {
                                                                if (
                                                                  e.target.checked
                                                                ) {
                                                                  const indexs =
                                                                    values.apAccouts.map(
                                                                      function (e) {
                                                                        return e._id;
                                                                      }
                                                                    );
                                                                  indexs.forEach(
                                                                    (i) => {
                                                                      remove(i);
                                                                    }
                                                                  );
                                                                  payablesData.forEach(
                                                                    (option) => {
                                                                      push({
                                                                        _id: option._id,
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                      });
                                                                    }
                                                                  );
                                                                } else {
                                                                  const indexs =
                                                                    values.apAccouts.map(
                                                                      function (e) {
                                                                        return e._id;
                                                                      }
                                                                    );
                                                                  indexs.forEach(
                                                                    (i) => {
                                                                      remove(i);
                                                                    }
                                                                  );
                                                                }
                                                              }}
                                                            />
                                                            <span />
                                                            Select All
                                                          </label>
                                                        </li>
                                                      ) : null}
                                                      {payablesData?.map(
                                                        (option) => (
                                                          <li key={option.id}>
                                                            <label>
                                                              <input
                                                                name="apAccounts"
                                                                type="checkbox"
                                                                value={option}
                                                                checked={values.apAccouts
                                                                  .map((e) => e._id)
                                                                  .includes(
                                                                    option._id
                                                                  )}
                                                                onChange={(e) => {
                                                                  if (
                                                                    e.target.checked
                                                                  ) {
                                                                    push({
                                                                      _id: option._id,
                                                                      Id: option.Id,
                                                                      FullyQualifiedName:
                                                                        option.FullyQualifiedName,
                                                                      AccountType:
                                                                        option.AccountType,
                                                                    });
                                                                  } else {
                                                                    const index =
                                                                      values.apAccouts
                                                                        .map(
                                                                          function (
                                                                            e
                                                                          ) {
                                                                            return e._id;
                                                                          }
                                                                        )
                                                                        .indexOf(
                                                                          option._id
                                                                        );
                                                                    remove(index);
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              {`${option.AccountType} | ${option.FullyQualifiedName}`}
                                                            </label>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                />

                                                {totalCountPayables >
                                                  RecordsLengthPayables && (
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        padding: "5px",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setPayablesPage(
                                                          (payablesPage) =>
                                                            payablesPage + 1
                                                        );
                                                      }}
                                                    >
                                                      load more...
                                                    </div>
                                                  )}
                                              </CheckboxSection>
                                            </BottomSection>
                                          )}
                                        </MainContainer>
                                      </Col>
                                      <Col sm={9}>
                                        <AccountsDiv>
                                          <Row>
                                            {values.apAccouts?.map((option) => (
                                              <Col sm={3}>
                                                <p className="ar-ap-list-accordion">{option.FullyQualifiedName}</p>
                                              </Col>
                                            ))}
                                          </Row>
                                        </AccountsDiv>
                                      </Col>
                                    </Row>
                                  </PartionDiv>
                                </Desktop>
                                <Tablet>
                                  <PartionDiv
                                    style={{ borderBottom: "1px solid #909090" }}
                                  >
                                    <Row>
                                      <Col sm={3}>
                                        <StyledLabel>
                                          Specify All the Non Controllable AP
                                          accounts
                                        </StyledLabel>
                                        <MainContainer>
                                          <TopSection onClick={(e) => handleTopSectionClick(e, 'ap')}>
                                            <span>
                                              {values.apAccouts?.length > 0
                                                ? `${values.apAccouts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {showAPList && (
                                            <BottomSection ref={bottomSectionRef}>
                                              <SearchInput
                                                value={payablesSearch}
                                                onChange={(e) =>
                                                  setPayablesSearch(e.target.value)
                                                }
                                                placeholder="Search..."
                                              ></SearchInput>
                                              <SearchIcon className="search-icon" />
                                              <CheckboxSection>
                                                <FieldArray
                                                  name="apAccouts"
                                                  render={({ push, remove }) => (
                                                    <ul style={{ listStyle: "none" }}>
                                                      {payablesData.length > 2 ? (
                                                        <li key={"select-all-ap"}>
                                                          <label>
                                                            <input
                                                              name="apAccounts-select-all"
                                                              type="checkbox"
                                                              value={"Select All"}
                                                              checked={
                                                                values.apAccouts
                                                                  .length ==
                                                                payablesData.length
                                                              }
                                                              onChange={(e) => {
                                                                if (
                                                                  e.target.checked
                                                                ) {
                                                                  const indexs =
                                                                    values.apAccouts.map(
                                                                      function (e) {
                                                                        return e._id;
                                                                      }
                                                                    );
                                                                  indexs.forEach(
                                                                    (i) => {
                                                                      remove(i);
                                                                    }
                                                                  );
                                                                  payablesData.forEach(
                                                                    (option) => {
                                                                      push({
                                                                        _id: option._id,
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                      });
                                                                    }
                                                                  );
                                                                } else {
                                                                  const indexs =
                                                                    values.apAccouts.map(
                                                                      function (e) {
                                                                        return e._id;
                                                                      }
                                                                    );
                                                                  indexs.forEach(
                                                                    (i) => {
                                                                      remove(i);
                                                                    }
                                                                  );
                                                                }
                                                              }}
                                                            />
                                                            <span />
                                                            Select All
                                                          </label>
                                                        </li>
                                                      ) : null}
                                                      {payablesData?.map(
                                                        (option) => (
                                                          <li key={option.id}>
                                                            <label>
                                                              <input
                                                                name="apAccounts"
                                                                type="checkbox"
                                                                value={option}
                                                                checked={values.apAccouts
                                                                  .map((e) => e._id)
                                                                  .includes(
                                                                    option._id
                                                                  )}
                                                                onChange={(e) => {
                                                                  if (
                                                                    e.target.checked
                                                                  ) {
                                                                    push({
                                                                      _id: option._id,
                                                                      Id: option.Id,
                                                                      FullyQualifiedName:
                                                                        option.FullyQualifiedName,
                                                                      AccountType:
                                                                        option.AccountType,
                                                                    });
                                                                  } else {
                                                                    const index =
                                                                      values.apAccouts
                                                                        .map(
                                                                          function (
                                                                            e
                                                                          ) {
                                                                            return e._id;
                                                                          }
                                                                        )
                                                                        .indexOf(
                                                                          option._id
                                                                        );
                                                                    remove(index);
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              {`${option.AccountType} | ${option.FullyQualifiedName}`}
                                                            </label>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                />

                                                {totalCountPayables >
                                                  RecordsLengthPayables && (
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        padding: "5px",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setPayablesPage(
                                                          (payablesPage) =>
                                                            payablesPage + 1
                                                        );
                                                      }}
                                                    >
                                                      load more...
                                                    </div>
                                                  )}
                                              </CheckboxSection>
                                            </BottomSection>
                                          )}
                                        </MainContainer>
                                      </Col>
                                      <Col sm={9}>
                                        <AccountsDiv>
                                          <Row>
                                            {values.apAccouts?.map((option) => (
                                              <Col sm={3}>
                                                <p className="ar-ap-list-accordion">{option.FullyQualifiedName}</p>
                                              </Col>
                                            ))}
                                          </Row>
                                        </AccountsDiv>
                                      </Col>
                                    </Row>
                                  </PartionDiv>
                                </Tablet>
                                <Mobile>
                                  <PartionDiv
                                    style={{ borderBottom: "1px solid #909090" }}
                                  >
                                    <Row>
                                      <Col sm={3}>
                                        <StyledLabel>
                                          Specify All the Non Controllable AP
                                          accounts
                                        </StyledLabel>
                                        <MainContainer>
                                          <TopSection onClick={(e) => handleTopSectionClick(e, 'ap')}>
                                            <span>
                                              {values.apAccouts?.length > 0
                                                ? `${values.apAccouts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {showAPList && (
                                            <BottomSection ref={bottomSectionRef}>
                                              <SearchInput
                                                value={payablesSearch}
                                                onChange={(e) =>
                                                  setPayablesSearch(e.target.value)
                                                }
                                                placeholder="Search..."
                                              ></SearchInput>
                                              <SearchIcon className="search-icon" />
                                              <CheckboxSection>
                                                <FieldArray
                                                  name="apAccouts"
                                                  render={({ push, remove }) => (
                                                    <ul style={{ listStyle: "none" }}>
                                                      {payablesData.length > 2 ? (
                                                        <li key={"select-all-ap"}>
                                                          <label>
                                                            <input
                                                              name="apAccounts-select-all"
                                                              type="checkbox"
                                                              value={"Select All"}
                                                              checked={
                                                                values.apAccouts
                                                                  .length ==
                                                                payablesData.length
                                                              }
                                                              onChange={(e) => {
                                                                if (
                                                                  e.target.checked
                                                                ) {
                                                                  const indexs =
                                                                    values.apAccouts.map(
                                                                      function (e) {
                                                                        return e._id;
                                                                      }
                                                                    );
                                                                  indexs.forEach(
                                                                    (i) => {
                                                                      remove(i);
                                                                    }
                                                                  );
                                                                  payablesData.forEach(
                                                                    (option) => {
                                                                      push({
                                                                        _id: option._id,
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                      });
                                                                    }
                                                                  );
                                                                } else {
                                                                  const indexs =
                                                                    values.apAccouts.map(
                                                                      function (e) {
                                                                        return e._id;
                                                                      }
                                                                    );
                                                                  indexs.forEach(
                                                                    (i) => {
                                                                      remove(i);
                                                                    }
                                                                  );
                                                                }
                                                              }}
                                                            />
                                                            <span />
                                                            Select All
                                                          </label>
                                                        </li>
                                                      ) : null}
                                                      {payablesData?.map(
                                                        (option) => (
                                                          <li key={option.id}>
                                                            <label>
                                                              <input
                                                                name="apAccounts"
                                                                type="checkbox"
                                                                value={option}
                                                                checked={values.apAccouts
                                                                  .map((e) => e._id)
                                                                  .includes(
                                                                    option._id
                                                                  )}
                                                                onChange={(e) => {
                                                                  if (
                                                                    e.target.checked
                                                                  ) {
                                                                    push({
                                                                      _id: option._id,
                                                                      Id: option.Id,
                                                                      FullyQualifiedName:
                                                                        option.FullyQualifiedName,
                                                                      AccountType:
                                                                        option.AccountType,
                                                                    });
                                                                  } else {
                                                                    const index =
                                                                      values.apAccouts
                                                                        .map(
                                                                          function (
                                                                            e
                                                                          ) {
                                                                            return e._id;
                                                                          }
                                                                        )
                                                                        .indexOf(
                                                                          option._id
                                                                        );
                                                                    remove(index);
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              {`${option.AccountType} | ${option.FullyQualifiedName}`}
                                                            </label>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                />

                                                {totalCountPayables >
                                                  RecordsLengthPayables && (
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        padding: "5px",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setPayablesPage(
                                                          (payablesPage) =>
                                                            payablesPage + 1
                                                        );
                                                      }}
                                                    >
                                                      load more...
                                                    </div>
                                                  )}
                                              </CheckboxSection>
                                            </BottomSection>
                                          )}
                                        </MainContainer>
                                      </Col>
                                      <Col sm={9}>
                                        <AccountsDiv>
                                          <Row>
                                            {values.apAccouts?.map((option) => (
                                              <Col sm={3}>
                                                <p className="ar-ap-list-accordion">{option.FullyQualifiedName}</p>
                                              </Col>
                                            ))}
                                          </Row>
                                        </AccountsDiv>
                                      </Col>
                                    </Row>
                                  </PartionDiv>
                                </Mobile>
                              </Accordion.Body>
                            </Accordion.Item> */}
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              Cash Inflow Accounts
                            </Accordion.Header>
                            <Accordion.Body>
                              <Desktop>
                                <PartionDiv
                                //style={{ borderBottom: "1px solid #909090" }}
                                >
                                  <Row>
                                    <Col sm={3}>
                                      <MainContainer>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            width: "36rem",
                                          }}
                                        >
                                          <TopSection
                                            onClick={(e) =>
                                              handleTopSectionClick(e, "income")
                                            }
                                          >
                                            <span>
                                              {incomeAccounts?.length > 0
                                                ? `${incomeAccounts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {fillAllForIncomes && (
                                            <span className="table-alert">
                                              Please fill all the required
                                              fields.
                                            </span>
                                          )}
                                        </div>
                                        {showIncomeAccountsList && (
                                          <BottomSection ref={bottomSectionRef}>
                                            <SearchInput
                                              value={incomesSearch}
                                              onChange={(e) => {
                                                setIncomesSearch(
                                                  e.target.value
                                                );
                                                updateIncomeExpenseArrayForSearchFilter(
                                                  "income",
                                                  e.target.value
                                                );
                                              }}
                                              placeholder="Search..."
                                            ></SearchInput>
                                            <SearchIcon className="search-icon" />
                                            <CheckboxSection>
                                              {mapCCIncomeAccounts?.length >
                                                0 && (
                                                <FieldArray
                                                  name="incomeAccounts"
                                                  render={({
                                                    push,
                                                    remove,
                                                  }) => (
                                                    <ul
                                                      style={{
                                                        listStyle: "none",
                                                      }}
                                                    >
                                                      {mapCCIncomeAccounts.length >
                                                      2 ? (
                                                        <li
                                                          key={
                                                            "select-all-expenses"
                                                          }
                                                        >
                                                          <label>
                                                            <input
                                                              name="incomeAccounts-select-all"
                                                              type="checkbox"
                                                              value={
                                                                "Select All"
                                                              }
                                                              checked={
                                                                incomeAccounts.length ==
                                                                mapCCIncomeAccounts.length
                                                              }
                                                              onChange={(e) => {
                                                                if (
                                                                  e.target
                                                                    .checked
                                                                ) {
                                                                  updateIncomeExpenseSelectAll(
                                                                    "income"
                                                                  );
                                                                } else {
                                                                  getIncomeAccountsTableList();
                                                                }
                                                              }}
                                                            />
                                                            <span />
                                                            Select All
                                                          </label>
                                                        </li>
                                                      ) : null}
                                                      {mapCCIncomeAccounts.map(
                                                        (option) => (
                                                          <li key={option.id}>
                                                            <label>
                                                              <input
                                                                name="incomeAccounts"
                                                                type="checkbox"
                                                                value={option}
                                                                checked={incomeAccounts
                                                                  .map(
                                                                    (e) => e.Id
                                                                  )
                                                                  .includes(
                                                                    option.Id
                                                                  )}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.target
                                                                      .checked
                                                                  ) {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "income",
                                                                      "add",
                                                                      {
                                                                        frequency:
                                                                          "",
                                                                        days: "",
                                                                        amount:
                                                                          "",
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                        recurring: false,
                                                                      }
                                                                    );
                                                                  } else {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "income",
                                                                      "remove",
                                                                      {
                                                                        Id: option.Id,
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              {
                                                                option.FullyQualifiedName
                                                              }
                                                            </label>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                />
                                              )}
                                            </CheckboxSection>
                                          </BottomSection>
                                        )}
                                      </MainContainer>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12}>
                                      <StyledTable>
                                        <CashClassificationAccountsTable
                                          isloading={isLoadingIncomeAccounts}
                                          data={incomeAccounts}
                                          columns={columns}
                                          totalCount={incomeAccounts.length}
                                          rowClick={clickRowToUpdate}
                                          handlePageChange={
                                            handlePageChangeIncome
                                          }
                                          // onHeaderClick={onHeaderClick}
                                          initialPage={incomeInitialPage}
                                          caller={"income"}
                                          updateAccountData={
                                            updateIncomeAccount
                                          }
                                        />
                                      </StyledTable>
                                    </Col>
                                  </Row>
                                </PartionDiv>
                              </Desktop>
                              <Tablet>
                                <PartionDiv
                                //style={{ borderBottom: "1px solid #909090" }}
                                >
                                  <Row>
                                    <Col sm={3}>
                                      <MainContainer>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            width: "36rem",
                                          }}
                                        >
                                          <TopSection
                                            onClick={(e) =>
                                              handleTopSectionClick(e, "income")
                                            }
                                          >
                                            <span>
                                              {incomeAccounts?.length > 0
                                                ? `${incomeAccounts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {fillAllForIncomes && (
                                            <span className="table-alert">
                                              Please fill all the required
                                              fields.
                                            </span>
                                          )}
                                        </div>
                                        {showIncomeAccountsList && (
                                          <BottomSection ref={bottomSectionRef}>
                                            <SearchInput
                                              value={incomesSearch}
                                              onChange={(e) => {
                                                setIncomesSearch(
                                                  e.target.value
                                                );
                                                updateIncomeExpenseArrayForSearchFilter(
                                                  "income",
                                                  e.target.value
                                                );
                                              }}
                                              placeholder="Search..."
                                            ></SearchInput>
                                            <SearchIcon className="search-icon" />
                                            <CheckboxSection>
                                              {mapCCIncomeAccounts?.length >
                                                0 && (
                                                <FieldArray
                                                  name="incomeAccounts"
                                                  render={({
                                                    push,
                                                    remove,
                                                  }) => (
                                                    <ul
                                                      style={{
                                                        listStyle: "none",
                                                      }}
                                                    >
                                                      {mapCCIncomeAccounts.length >
                                                      2 ? (
                                                        <li
                                                          key={
                                                            "select-all-expenses"
                                                          }
                                                        >
                                                          <label>
                                                            <input
                                                              name="incomeAccounts-select-all"
                                                              type="checkbox"
                                                              value={
                                                                "Select All"
                                                              }
                                                              checked={
                                                                incomeAccounts.length ==
                                                                mapCCIncomeAccounts.length
                                                              }
                                                              onChange={(e) => {
                                                                if (
                                                                  e.target
                                                                    .checked
                                                                ) {
                                                                  updateIncomeExpenseSelectAll(
                                                                    "income"
                                                                  );
                                                                } else {
                                                                  getIncomeAccountsTableList();
                                                                }
                                                              }}
                                                            />
                                                            <span />
                                                            Select All
                                                          </label>
                                                        </li>
                                                      ) : null}
                                                      {mapCCIncomeAccounts.map(
                                                        (option) => (
                                                          <li key={option.id}>
                                                            <label>
                                                              <input
                                                                name="incomeAccounts"
                                                                type="checkbox"
                                                                value={option}
                                                                checked={incomeAccounts
                                                                  .map(
                                                                    (e) => e.Id
                                                                  )
                                                                  .includes(
                                                                    option.Id
                                                                  )}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.target
                                                                      .checked
                                                                  ) {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "income",
                                                                      "add",
                                                                      {
                                                                        frequency:
                                                                          "",
                                                                        days: "",
                                                                        amount:
                                                                          "",
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                        recurring: false,
                                                                      }
                                                                    );
                                                                  } else {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "income",
                                                                      "remove",
                                                                      {
                                                                        Id: option.Id,
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              {
                                                                option.FullyQualifiedName
                                                              }
                                                            </label>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                />
                                              )}
                                            </CheckboxSection>
                                          </BottomSection>
                                        )}
                                      </MainContainer>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12}>
                                      <StyledTable>
                                        <CashClassificationAccountsTable
                                          isloading={isLoadingIncomeAccounts}
                                          data={incomeAccounts}
                                          columns={columns}
                                          totalCount={incomeAccounts.length}
                                          rowClick={clickRowToUpdate}
                                          handlePageChange={
                                            handlePageChangeIncome
                                          }
                                          // onHeaderClick={onHeaderClick}
                                          initialPage={incomeInitialPage}
                                          caller={"income"}
                                          updateAccountData={
                                            updateIncomeAccount
                                          }
                                        />
                                      </StyledTable>
                                    </Col>
                                  </Row>
                                </PartionDiv>
                              </Tablet>
                              <Mobile>
                                <PartionDiv
                                //style={{ borderBottom: "1px solid #909090" }}
                                >
                                  <Row>
                                    <Col sm={3}>
                                      <MainContainer>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            width: "36rem",
                                          }}
                                        >
                                          <TopSection
                                            onClick={(e) =>
                                              handleTopSectionClick(e, "income")
                                            }
                                          >
                                            <span>
                                              {incomeAccounts?.length > 0
                                                ? `${incomeAccounts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {fillAllForIncomes && (
                                            <span className="table-alert">
                                              Please fill all the required
                                              fields.
                                            </span>
                                          )}
                                        </div>
                                        {showIncomeAccountsList && (
                                          <BottomSection ref={bottomSectionRef}>
                                            <SearchInput
                                              value={incomesSearch}
                                              onChange={(e) => {
                                                setIncomesSearch(
                                                  e.target.value
                                                );
                                                updateIncomeExpenseArrayForSearchFilter(
                                                  "income",
                                                  e.target.value
                                                );
                                              }}
                                              placeholder="Search..."
                                            ></SearchInput>
                                            <SearchIcon className="search-icon" />
                                            <CheckboxSection>
                                              {mapCCIncomeAccounts?.length >
                                                0 && (
                                                <FieldArray
                                                  name="incomeAccounts"
                                                  render={({
                                                    push,
                                                    remove,
                                                  }) => (
                                                    <ul
                                                      style={{
                                                        listStyle: "none",
                                                      }}
                                                    >
                                                      {mapCCIncomeAccounts.length >
                                                      2 ? (
                                                        <li
                                                          key={
                                                            "select-all-expenses"
                                                          }
                                                        >
                                                          <label>
                                                            <input
                                                              name="incomeAccounts-select-all"
                                                              type="checkbox"
                                                              value={
                                                                "Select All"
                                                              }
                                                              checked={
                                                                incomeAccounts.length ==
                                                                mapCCIncomeAccounts.length
                                                              }
                                                              onChange={(e) => {
                                                                if (
                                                                  e.target
                                                                    .checked
                                                                ) {
                                                                  updateIncomeExpenseSelectAll(
                                                                    "income"
                                                                  );
                                                                } else {
                                                                  getIncomeAccountsTableList();
                                                                }
                                                              }}
                                                            />
                                                            <span />
                                                            Select All
                                                          </label>
                                                        </li>
                                                      ) : null}
                                                      {mapCCIncomeAccounts.map(
                                                        (option) => (
                                                          <li key={option.id}>
                                                            <label>
                                                              <input
                                                                name="incomeAccounts"
                                                                type="checkbox"
                                                                value={option}
                                                                checked={incomeAccounts
                                                                  .map(
                                                                    (e) => e.Id
                                                                  )
                                                                  .includes(
                                                                    option.Id
                                                                  )}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.target
                                                                      .checked
                                                                  ) {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "income",
                                                                      "add",
                                                                      {
                                                                        frequency:
                                                                          "",
                                                                        days: "",
                                                                        amount:
                                                                          "",
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                        recurring: false,
                                                                      }
                                                                    );
                                                                  } else {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "income",
                                                                      "remove",
                                                                      {
                                                                        Id: option.Id,
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              {
                                                                option.FullyQualifiedName
                                                              }
                                                            </label>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                />
                                              )}
                                            </CheckboxSection>
                                          </BottomSection>
                                        )}
                                      </MainContainer>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12}>
                                      <StyledTable>
                                        <CashClassificationAccountsTable
                                          isloading={isLoadingIncomeAccounts}
                                          data={incomeAccounts}
                                          columns={columns}
                                          totalCount={incomeAccounts.length}
                                          rowClick={clickRowToUpdate}
                                          handlePageChange={
                                            handlePageChangeIncome
                                          }
                                          // onHeaderClick={onHeaderClick}
                                          initialPage={incomeInitialPage}
                                          caller={"income"}
                                          updateAccountData={
                                            updateIncomeAccount
                                          }
                                        />
                                      </StyledTable>
                                    </Col>
                                  </Row>
                                </PartionDiv>
                              </Mobile>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              Cash Outflow Accounts
                            </Accordion.Header>
                            <Accordion.Body>
                              <Desktop>
                                <PartionDiv
                                //style={{ borderBottom: "1px solid #909090" }}
                                >
                                  <Row>
                                    <Col sm={3}>
                                      <MainContainer>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            width: "36rem",
                                          }}
                                        >
                                          <TopSection
                                            onClick={(e) =>
                                              handleTopSectionClick(
                                                e,
                                                "expense"
                                              )
                                            }
                                          >
                                            <span>
                                              {expenseAccounts?.length > 0
                                                ? `${expenseAccounts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {fillAllForExpenses && (
                                            <span className="table-alert">
                                              Please fill all the required
                                              fields.
                                            </span>
                                          )}
                                        </div>
                                        {showExpenseAccountsList && (
                                          <BottomSection ref={bottomSectionRef}>
                                            <SearchInput
                                              value={expensesSearch}
                                              onChange={(e) => {
                                                setExpensesSearch(
                                                  e.target.value
                                                );
                                                updateIncomeExpenseArrayForSearchFilter(
                                                  "expense",
                                                  e.target.value
                                                );
                                              }}
                                              placeholder="Search..."
                                            ></SearchInput>
                                            <SearchIcon className="search-icon" />
                                            <CheckboxSection>
                                              {mapCCExpenseAccounts?.length >
                                                0 && (
                                                <FieldArray
                                                  name="expenseAccounts"
                                                  render={({
                                                    push,
                                                    remove,
                                                  }) => (
                                                    <ul
                                                      style={{
                                                        listStyle: "none",
                                                      }}
                                                    >
                                                      {mapCCExpenseAccounts.length >
                                                      2 ? (
                                                        <li
                                                          key={
                                                            "select-all-expenses"
                                                          }
                                                        >
                                                          <label>
                                                            <input
                                                              name="expenseAccounts-select-all"
                                                              type="checkbox"
                                                              value={
                                                                "Select All"
                                                              }
                                                              checked={
                                                                expenseAccounts.length ==
                                                                mapCCExpenseAccounts.length
                                                              }
                                                              onChange={(e) => {
                                                                if (
                                                                  e.target
                                                                    .checked
                                                                ) {
                                                                  updateIncomeExpenseSelectAll(
                                                                    "expense"
                                                                  );
                                                                } else {
                                                                  getExpenseAccountsTableList();
                                                                }
                                                              }}
                                                            />
                                                            <span />
                                                            Select All
                                                          </label>
                                                        </li>
                                                      ) : null}
                                                      {mapCCExpenseAccounts.map(
                                                        (option) => (
                                                          <li key={option.id}>
                                                            <label>
                                                              <input
                                                                name="expenseAccounts"
                                                                type="checkbox"
                                                                value={option}
                                                                checked={expenseAccounts
                                                                  .map(
                                                                    (e) => e.Id
                                                                  )
                                                                  .includes(
                                                                    option.Id
                                                                  )}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.target
                                                                      .checked
                                                                  ) {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "expense",
                                                                      "add",
                                                                      {
                                                                        frequency:
                                                                          "",
                                                                        days: "",
                                                                        amount:
                                                                          "",
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                        recurring: false,
                                                                      }
                                                                    );
                                                                  } else {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "expense",
                                                                      "remove",
                                                                      {
                                                                        Id: option.Id,
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              {
                                                                option.FullyQualifiedName
                                                              }
                                                            </label>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                />
                                              )}

                                              {/* {totalCountReceivables >
                                    RecordsLengthReceivables && (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                          padding: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setReceivablesPage(
                                            (receivablesPage) =>
                                              receivablesPage + 1
                                          );
                                        }}
                                      >
                                        load more...
                                      </div>
                                    )} */}
                                            </CheckboxSection>
                                          </BottomSection>
                                        )}
                                      </MainContainer>
                                    </Col>
                                    <Row>
                                      <Col sm={12}>
                                        <StyledTable>
                                          <CashClassificationAccountsTable
                                            isloading={isLoadingExpenseAccounts}
                                            data={expenseAccounts}
                                            columns={columns}
                                            totalCount={expenseAccounts.length}
                                            rowClick={clickRowToUpdate}
                                            handlePageChange={
                                              handlePageChangeExpense
                                            }
                                            // onHeaderClick={onHeaderClick}
                                            initialPage={expenseInitialPage}
                                            caller={"expense"}
                                            updateAccountData={
                                              updateExpenseAccount
                                            }
                                          />
                                        </StyledTable>
                                      </Col>
                                    </Row>
                                  </Row>
                                </PartionDiv>
                              </Desktop>
                              <Tablet>
                                <PartionDiv
                                //style={{ borderBottom: "1px solid #909090" }}
                                >
                                  <Row>
                                    <Col sm={3}>
                                      <MainContainer>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            width: "36rem",
                                          }}
                                        >
                                          {" "}
                                          <TopSection
                                            onClick={(e) =>
                                              handleTopSectionClick(
                                                e,
                                                "expense"
                                              )
                                            }
                                          >
                                            <span>
                                              {expenseAccounts?.length > 0
                                                ? `${expenseAccounts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {fillAllForExpenses && (
                                            <span className="table-alert">
                                              Please fill all the required
                                              fields.
                                            </span>
                                          )}
                                        </div>
                                        {showExpenseAccountsList && (
                                          <BottomSection ref={bottomSectionRef}>
                                            <SearchInput
                                              value={expensesSearch}
                                              onChange={(e) => {
                                                setExpensesSearch(
                                                  e.target.value
                                                );
                                                updateIncomeExpenseArrayForSearchFilter(
                                                  "expense",
                                                  e.target.value
                                                );
                                              }}
                                              placeholder="Search..."
                                            ></SearchInput>
                                            <SearchIcon className="search-icon" />
                                            <CheckboxSection>
                                              {mapCCExpenseAccounts?.length >
                                                0 && (
                                                <FieldArray
                                                  name="expenseAccounts"
                                                  render={({
                                                    push,
                                                    remove,
                                                  }) => (
                                                    <ul
                                                      style={{
                                                        listStyle: "none",
                                                      }}
                                                    >
                                                      {mapCCExpenseAccounts.length >
                                                      2 ? (
                                                        <li
                                                          key={
                                                            "select-all-expenses"
                                                          }
                                                        >
                                                          <label>
                                                            <input
                                                              name="expenseAccounts-select-all"
                                                              type="checkbox"
                                                              value={
                                                                "Select All"
                                                              }
                                                              checked={
                                                                expenseAccounts.length ==
                                                                mapCCExpenseAccounts.length
                                                              }
                                                              onChange={(e) => {
                                                                if (
                                                                  e.target
                                                                    .checked
                                                                ) {
                                                                  updateIncomeExpenseSelectAll(
                                                                    "expense"
                                                                  );
                                                                } else {
                                                                  getExpenseAccountsTableList();
                                                                }
                                                              }}
                                                            />
                                                            <span />
                                                            Select All
                                                          </label>
                                                        </li>
                                                      ) : null}
                                                      {mapCCExpenseAccounts.map(
                                                        (option) => (
                                                          <li key={option.id}>
                                                            <label>
                                                              <input
                                                                name="expenseAccounts"
                                                                type="checkbox"
                                                                value={option}
                                                                checked={expenseAccounts
                                                                  .map(
                                                                    (e) => e.Id
                                                                  )
                                                                  .includes(
                                                                    option.Id
                                                                  )}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.target
                                                                      .checked
                                                                  ) {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "expense",
                                                                      "add",
                                                                      {
                                                                        frequency:
                                                                          "",
                                                                        days: "",
                                                                        amount:
                                                                          "",
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                        recurring: false,
                                                                      }
                                                                    );
                                                                  } else {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "expense",
                                                                      "remove",
                                                                      {
                                                                        Id: option.Id,
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              {
                                                                option.FullyQualifiedName
                                                              }
                                                            </label>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                />
                                              )}

                                              {/* {totalCountReceivables >
                                    RecordsLengthReceivables && (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                          padding: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setReceivablesPage(
                                            (receivablesPage) =>
                                              receivablesPage + 1
                                          );
                                        }}
                                      >
                                        load more...
                                      </div>
                                    )} */}
                                            </CheckboxSection>
                                          </BottomSection>
                                        )}
                                      </MainContainer>
                                    </Col>
                                    <Row>
                                      <Col sm={12}>
                                        <StyledTable>
                                          <CashClassificationAccountsTable
                                            isloading={isLoadingExpenseAccounts}
                                            data={expenseAccounts}
                                            columns={columns}
                                            totalCount={expenseAccounts.length}
                                            rowClick={clickRowToUpdate}
                                            handlePageChange={
                                              handlePageChangeExpense
                                            }
                                            // onHeaderClick={onHeaderClick}
                                            initialPage={expenseInitialPage}
                                            caller={"expense"}
                                            updateAccountData={
                                              updateExpenseAccount
                                            }
                                          />
                                        </StyledTable>
                                      </Col>
                                    </Row>
                                  </Row>
                                </PartionDiv>
                              </Tablet>
                              <Mobile>
                                <PartionDiv
                                //style={{ borderBottom: "1px solid #909090" }}
                                >
                                  <Row>
                                    <Col sm={3}>
                                      <MainContainer>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            width: "36rem",
                                          }}
                                        >
                                          <TopSection
                                            onClick={(e) =>
                                              handleTopSectionClick(
                                                e,
                                                "expense"
                                              )
                                            }
                                          >
                                            <span>
                                              {expenseAccounts?.length > 0
                                                ? `${expenseAccounts?.length} selected`
                                                : "Choose Account"}
                                              <TopArrowSubmenuIcon fontSize="9px" />
                                            </span>
                                          </TopSection>
                                          {fillAllForExpenses && (
                                            <span className="table-alert">
                                              Please fill all the required
                                              fields.
                                            </span>
                                          )}
                                        </div>
                                        {showExpenseAccountsList && (
                                          <BottomSection ref={bottomSectionRef}>
                                            <SearchInput
                                              value={expensesSearch}
                                              onChange={(e) => {
                                                setExpensesSearch(
                                                  e.target.value
                                                );
                                                updateIncomeExpenseArrayForSearchFilter(
                                                  "expense",
                                                  e.target.value
                                                );
                                              }}
                                              placeholder="Search..."
                                            ></SearchInput>
                                            <SearchIcon className="search-icon" />
                                            <CheckboxSection>
                                              {mapCCExpenseAccounts?.length >
                                                0 && (
                                                <FieldArray
                                                  name="expenseAccounts"
                                                  render={({
                                                    push,
                                                    remove,
                                                  }) => (
                                                    <ul
                                                      style={{
                                                        listStyle: "none",
                                                      }}
                                                    >
                                                      {mapCCExpenseAccounts.length >
                                                      2 ? (
                                                        <li
                                                          key={
                                                            "select-all-expenses"
                                                          }
                                                        >
                                                          <label>
                                                            <input
                                                              name="expenseAccounts-select-all"
                                                              type="checkbox"
                                                              value={
                                                                "Select All"
                                                              }
                                                              checked={
                                                                expenseAccounts.length ==
                                                                mapCCExpenseAccounts.length
                                                              }
                                                              onChange={(e) => {
                                                                if (
                                                                  e.target
                                                                    .checked
                                                                ) {
                                                                  updateIncomeExpenseSelectAll(
                                                                    "expense"
                                                                  );
                                                                } else {
                                                                  getExpenseAccountsTableList();
                                                                }
                                                              }}
                                                            />
                                                            <span />
                                                            Select All
                                                          </label>
                                                        </li>
                                                      ) : null}
                                                      {mapCCExpenseAccounts.map(
                                                        (option) => (
                                                          <li key={option.id}>
                                                            <label>
                                                              <input
                                                                name="expenseAccounts"
                                                                type="checkbox"
                                                                value={option}
                                                                checked={expenseAccounts
                                                                  .map(
                                                                    (e) => e.Id
                                                                  )
                                                                  .includes(
                                                                    option.Id
                                                                  )}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.target
                                                                      .checked
                                                                  ) {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "expense",
                                                                      "add",
                                                                      {
                                                                        frequency:
                                                                          "",
                                                                        days: "",
                                                                        amount:
                                                                          "",
                                                                        Id: option.Id,
                                                                        AccountType:
                                                                          option.AccountType,
                                                                        FullyQualifiedName:
                                                                          option.FullyQualifiedName,
                                                                        recurring: false,
                                                                      }
                                                                    );
                                                                  } else {
                                                                    updateIncomeExpenseAccountsArray(
                                                                      "expense",
                                                                      "remove",
                                                                      {
                                                                        Id: option.Id,
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                              <span />
                                                              {
                                                                option.FullyQualifiedName
                                                              }
                                                            </label>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                />
                                              )}

                                              {/* {totalCountReceivables >
                                    RecordsLengthReceivables && (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                          padding: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setReceivablesPage(
                                            (receivablesPage) =>
                                              receivablesPage + 1
                                          );
                                        }}
                                      >
                                        load more...
                                      </div>
                                    )} */}
                                            </CheckboxSection>
                                          </BottomSection>
                                        )}
                                      </MainContainer>
                                    </Col>
                                    <Row>
                                      <Col sm={12}>
                                        <StyledTable>
                                          <CashClassificationAccountsTable
                                            isloading={isLoadingExpenseAccounts}
                                            data={expenseAccounts}
                                            columns={columns}
                                            totalCount={expenseAccounts.length}
                                            rowClick={clickRowToUpdate}
                                            handlePageChange={
                                              handlePageChangeExpense
                                            }
                                            // onHeaderClick={onHeaderClick}
                                            initialPage={expenseInitialPage}
                                            caller={"expense"}
                                            updateAccountData={
                                              updateExpenseAccount
                                            }
                                          />
                                        </StyledTable>
                                      </Col>
                                    </Row>
                                  </Row>
                                </PartionDiv>
                              </Mobile>
                            </Accordion.Body>
                          </Accordion.Item>
                        </StyledAccordion>
                        {/* <PartionDiv
                                style={{ borderBottom: "1px solid #909090" }}
                              >
                                <Row>
                                  <StyledLabel>
                                    Expenses
                                  </StyledLabel>
                                </Row>
                                <Row>
                                  <Col sm={4}>
                                    <MainContainer>
                                      <TopSection
                                        onClick={() =>
                                          setShowAPList(!showAPList)
                                        }
                                      >
                                        <span>
                                          {values.apAccouts?.length > 0
                                            ? `${values.apAccouts?.length} selected`
                                            : "Select your Payroll Accounts"}
                                          <TopArrowSubmenuIcon fontSize="9px" />
                                        </span>
                                      </TopSection>
                                      {showAPList && (
                                        <BottomSection ref={bottomSectionRef}>
                                          <SearchInput
                                            value={payablesSearch}
                                            onChange={(e) =>
                                              setPayablesSearch(e.target.value)
                                            }
                                            placeholder="Search..."
                                          ></SearchInput>
                                          <SearchIcon className="search-icon" />
                                          <CheckboxSection>
                                            <FieldArray
                                              name="apAccouts"
                                              render={({ push, remove }) => (
                                                <ul>
                                                  {payablesData.length > 2 ? (
                                                    <li key={"select-all-ap"}>
                                                      <label>
                                                        <input
                                                          name="apAccounts-select-all"
                                                          type="checkbox"
                                                          value={"Select All"}
                                                          checked={
                                                            values.apAccouts
                                                              .length ==
                                                            payablesData.length
                                                          }
                                                          onChange={(e) => {
                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              const indexs =
                                                                values.apAccouts.map(
                                                                  function (e) {
                                                                    return e._id;
                                                                  }
                                                                );
                                                              indexs.forEach(
                                                                (i) => {
                                                                  remove(i);
                                                                }
                                                              );
                                                              payablesData.forEach(
                                                                (option) => {
                                                                  push({
                                                                    _id: option._id,
                                                                    Id: option.Id,
                                                                    AccountType:
                                                                      option.AccountType,
                                                                    FullyQualifiedName:
                                                                      option.FullyQualifiedName,
                                                                  });
                                                                }
                                                              );
                                                            } else {
                                                              const indexs =
                                                                values.apAccouts.map(
                                                                  function (e) {
                                                                    return e._id;
                                                                  }
                                                                );
                                                              indexs.forEach(
                                                                (i) => {
                                                                  remove(i);
                                                                }
                                                              );
                                                            }
                                                          }}
                                                        />
                                                        <span />
                                                        Select All
                                                      </label>
                                                    </li>
                                                  ) : null}
                                                  {payablesData?.map(
                                                    (option) => (
                                                      <li key={option.id}>
                                                        <label>
                                                          <input
                                                            name="apAccounts"
                                                            type="checkbox"
                                                            value={option}
                                                            checked={values.apAccouts
                                                              .map((e) => e._id)
                                                              .includes(
                                                                option._id
                                                              )}
                                                            onChange={(e) => {
                                                              if (
                                                                e.target.checked
                                                              ) {
                                                                push({
                                                                  _id: option._id,
                                                                  Id: option.Id,
                                                                  FullyQualifiedName:
                                                                    option.FullyQualifiedName,
                                                                  AccountType:
                                                                    option.AccountType,
                                                                });
                                                              } else {
                                                                const index =
                                                                  values.apAccouts
                                                                    .map(
                                                                      function (
                                                                        e
                                                                      ) {
                                                                        return e._id;
                                                                      }
                                                                    )
                                                                    .indexOf(
                                                                      option._id
                                                                    );
                                                                remove(index);
                                                              }
                                                            }}
                                                          />
                                                          <span />
                                                          {`${option.AccountType} | ${option.FullyQualifiedName}`}
                                                        </label>
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              )}
                                            />

                                            {totalCountPayables >
                                              RecordsLengthPayables && (
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    padding: "5px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setPayablesPage(
                                                      (payablesPage) =>
                                                        payablesPage + 1
                                                    );
                                                  }}
                                                >
                                                  load more...
                                                </div>
                                              )}
                                          </CheckboxSection>
                                        </BottomSection>
                                      )}
                                    </MainContainer>
                                  </Col>
                                  <Col sm={4}>
                                    <MainContainer>
                                      <TopSection
                                        onClick={() =>
                                          setShowAPList(!showAPList)
                                        }
                                      >
                                        <span>
                                          {values.apAccouts?.length > 0
                                            ? `${values.apAccouts?.length} selected`
                                            : "Select your Recurring Expense Accounts"}
                                          <TopArrowSubmenuIcon fontSize="9px" />
                                        </span>
                                      </TopSection>
                                      {showAPList && (
                                        <BottomSection ref={bottomSectionRef}>
                                          <SearchInput
                                            value={payablesSearch}
                                            onChange={(e) =>
                                              setPayablesSearch(e.target.value)
                                            }
                                            placeholder="Search..."
                                          ></SearchInput>
                                          <SearchIcon className="search-icon" />
                                          <CheckboxSection>
                                            <FieldArray
                                              name="apAccouts"
                                              render={({ push, remove }) => (
                                                <ul>
                                                  {payablesData.length > 2 ? (
                                                    <li key={"select-all-ap"}>
                                                      <label>
                                                        <input
                                                          name="apAccounts-select-all"
                                                          type="checkbox"
                                                          value={"Select All"}
                                                          checked={
                                                            values.apAccouts
                                                              .length ==
                                                            payablesData.length
                                                          }
                                                          onChange={(e) => {
                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              const indexs =
                                                                values.apAccouts.map(
                                                                  function (e) {
                                                                    return e._id;
                                                                  }
                                                                );
                                                              indexs.forEach(
                                                                (i) => {
                                                                  remove(i);
                                                                }
                                                              );
                                                              payablesData.forEach(
                                                                (option) => {
                                                                  push({
                                                                    _id: option._id,
                                                                    Id: option.Id,
                                                                    AccountType:
                                                                      option.AccountType,
                                                                    FullyQualifiedName:
                                                                      option.FullyQualifiedName,
                                                                  });
                                                                }
                                                              );
                                                            } else {
                                                              const indexs =
                                                                values.apAccouts.map(
                                                                  function (e) {
                                                                    return e._id;
                                                                  }
                                                                );
                                                              indexs.forEach(
                                                                (i) => {
                                                                  remove(i);
                                                                }
                                                              );
                                                            }
                                                          }}
                                                        />
                                                        <span />
                                                        Select All
                                                      </label>
                                                    </li>
                                                  ) : null}
                                                  {payablesData?.map(
                                                    (option) => (
                                                      <li key={option.id}>
                                                        <label>
                                                          <input
                                                            name="apAccounts"
                                                            type="checkbox"
                                                            value={option}
                                                            checked={values.apAccouts
                                                              .map((e) => e._id)
                                                              .includes(
                                                                option._id
                                                              )}
                                                            onChange={(e) => {
                                                              if (
                                                                e.target.checked
                                                              ) {
                                                                push({
                                                                  _id: option._id,
                                                                  Id: option.Id,
                                                                  FullyQualifiedName:
                                                                    option.FullyQualifiedName,
                                                                  AccountType:
                                                                    option.AccountType,
                                                                });
                                                              } else {
                                                                const index =
                                                                  values.apAccouts
                                                                    .map(
                                                                      function (
                                                                        e
                                                                      ) {
                                                                        return e._id;
                                                                      }
                                                                    )
                                                                    .indexOf(
                                                                      option._id
                                                                    );
                                                                remove(index);
                                                              }
                                                            }}
                                                          />
                                                          <span />
                                                          {`${option.AccountType} | ${option.FullyQualifiedName}`}
                                                        </label>
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              )}
                                            />

                                            {totalCountPayables >
                                              RecordsLengthPayables && (
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    padding: "5px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setPayablesPage(
                                                      (payablesPage) =>
                                                        payablesPage + 1
                                                    );
                                                  }}
                                                >
                                                  load more...
                                                </div>
                                              )}
                                          </CheckboxSection>
                                        </BottomSection>
                                      )}
                                      <br />
                                      <br />
                                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <div className="flex-div-forecast">
                                          <StyledLabel>Frequency</StyledLabel>
                                          <input id="forecast-inputs" type="text"
                                            value={0} onChange={(e) => { }} />
                                        </div>
                                        <div className="flex-div-forecast">
                                          <StyledLabel>Day</StyledLabel>
                                          <input id="forecast-inputs" type="text"
                                            value={0} onChange={(e) => { }} />
                                        </div>
                                        <div className="flex-div-forecast">
                                          <StyledLabel>Amount</StyledLabel>
                                          <input id="forecast-inputs" type="text"
                                            value={0} onChange={(e) => { }} />
                                        </div>
                                      </div>
                                    </MainContainer>
                                  </Col>
                                  <Col sm={4}>
                                    <MainContainer>
                                      <TopSection
                                        onClick={() =>
                                          setShowAPList(!showAPList)
                                        }
                                      >
                                        <span>
                                          {values.apAccouts?.length > 0
                                            ? `${values.apAccouts?.length} selected`
                                            : "Select your Remaining Expense Accounts"}
                                          <TopArrowSubmenuIcon fontSize="9px" />
                                        </span>
                                      </TopSection>
                                      {showAPList && (
                                        <BottomSection ref={bottomSectionRef}>
                                          <SearchInput
                                            value={payablesSearch}
                                            onChange={(e) =>
                                              setPayablesSearch(e.target.value)
                                            }
                                            placeholder="Search..."
                                          ></SearchInput>
                                          <SearchIcon className="search-icon" />
                                          <CheckboxSection>
                                            <FieldArray
                                              name="apAccouts"
                                              render={({ push, remove }) => (
                                                <ul>
                                                  {payablesData.length > 2 ? (
                                                    <li key={"select-all-ap"}>
                                                      <label>
                                                        <input
                                                          name="apAccounts-select-all"
                                                          type="checkbox"
                                                          value={"Select All"}
                                                          checked={
                                                            values.apAccouts
                                                              .length ==
                                                            payablesData.length
                                                          }
                                                          onChange={(e) => {
                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              const indexs =
                                                                values.apAccouts.map(
                                                                  function (e) {
                                                                    return e._id;
                                                                  }
                                                                );
                                                              indexs.forEach(
                                                                (i) => {
                                                                  remove(i);
                                                                }
                                                              );
                                                              payablesData.forEach(
                                                                (option) => {
                                                                  push({
                                                                    _id: option._id,
                                                                    Id: option.Id,
                                                                    AccountType:
                                                                      option.AccountType,
                                                                    FullyQualifiedName:
                                                                      option.FullyQualifiedName,
                                                                  });
                                                                }
                                                              );
                                                            } else {
                                                              const indexs =
                                                                values.apAccouts.map(
                                                                  function (e) {
                                                                    return e._id;
                                                                  }
                                                                );
                                                              indexs.forEach(
                                                                (i) => {
                                                                  remove(i);
                                                                }
                                                              );
                                                            }
                                                          }}
                                                        />
                                                        <span />
                                                        Select All
                                                      </label>
                                                    </li>
                                                  ) : null}
                                                  {payablesData?.map(
                                                    (option) => (
                                                      <li key={option.id}>
                                                        <label>
                                                          <input
                                                            name="apAccounts"
                                                            type="checkbox"
                                                            value={option}
                                                            checked={values.apAccouts
                                                              .map((e) => e._id)
                                                              .includes(
                                                                option._id
                                                              )}
                                                            onChange={(e) => {
                                                              if (
                                                                e.target.checked
                                                              ) {
                                                                push({
                                                                  _id: option._id,
                                                                  Id: option.Id,
                                                                  FullyQualifiedName:
                                                                    option.FullyQualifiedName,
                                                                  AccountType:
                                                                    option.AccountType,
                                                                });
                                                              } else {
                                                                const index =
                                                                  values.apAccouts
                                                                    .map(
                                                                      function (
                                                                        e
                                                                      ) {
                                                                        return e._id;
                                                                      }
                                                                    )
                                                                    .indexOf(
                                                                      option._id
                                                                    );
                                                                remove(index);
                                                              }
                                                            }}
                                                          />
                                                          <span />
                                                          {`${option.AccountType} | ${option.FullyQualifiedName}`}
                                                        </label>
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              )}
                                            />

                                            {totalCountPayables >
                                              RecordsLengthPayables && (
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    padding: "5px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setPayablesPage(
                                                      (payablesPage) =>
                                                        payablesPage + 1
                                                    );
                                                  }}
                                                >
                                                  load more...
                                                </div>
                                              )}
                                          </CheckboxSection>
                                        </BottomSection>
                                      )}
                                    </MainContainer>
                                  </Col>
                                </Row>
                              </PartionDiv> */}

                        {/* <PartionDiv>
                                <Row>
                                  <Col sm={3}>
                                    <StyledLabel>
                                      Specify All the Bank accounts
                                    </StyledLabel>
                                    <MainContainer>
                                      <TopSection
                                        onClick={() =>
                                          setShowBankList(!showBankList)
                                        }
                                      >
                                        <span>
                                          {values.bankAccounts?.length > 0
                                            ? `${values.bankAccounts?.length} selected`
                                            : "Choose Account"}
                                          <TopArrowSubmenuIcon fontSize="9px" />
                                        </span>
                                      </TopSection>
                                      {showBankList && (
                                        <BottomSection ref={bottomSectionRef}>
                                          <SearchInput
                                            value={bankSearch}
                                            onChange={(e) =>
                                              setBankSearch(e.target.value)
                                            }
                                            placeholder="Search..."
                                          ></SearchInput>
                                          <SearchIcon className="search-icon" />
                                          <CheckboxSection>
                                            <FieldArray
                                              name="bankAccounts"
                                              render={({ push, remove }) => (
                                                <ul>
                                                  {bankData.length > 2 ? (
                                                    <li key={"select-all-bank"}>
                                                      <label>
                                                        <input
                                                          name="bankAccounts-select-all"
                                                          type="checkbox"
                                                          value={"Select All"}
                                                          checked={
                                                            values.bankAccounts
                                                              .length ==
                                                            bankData.length
                                                          }
                                                          onChange={(e) => {
                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              const indexs =
                                                                values.bankAccounts.map(
                                                                  function (e) {
                                                                    return e._id;
                                                                  }
                                                                );
                                                              indexs.forEach(
                                                                (i) => {
                                                                  remove(i);
                                                                }
                                                              );
                                                              bankData.forEach(
                                                                (option) => {
                                                                  push({
                                                                    _id: option._id,
                                                                    Id: option.Id,
                                                                    AccountType:
                                                                      option.AccountType,
                                                                    FullyQualifiedName:
                                                                      option.FullyQualifiedName,
                                                                  });
                                                                }
                                                              );
                                                            } else {
                                                              const indexs =
                                                                values.bankAccounts.map(
                                                                  function (e) {
                                                                    return e._id;
                                                                  }
                                                                );
                                                              indexs.forEach(
                                                                (i) => {
                                                                  remove(i);
                                                                }
                                                              );
                                                            }
                                                          }}
                                                        />
                                                        <span />
                                                        Select All
                                                      </label>
                                                    </li>
                                                  ) : null}
                                                  {bankData?.map((option) => (
                                                    <li key={option.id}>
                                                      <label>
                                                        <input
                                                          name="bankAccounts"
                                                          type="checkbox"
                                                          value={option}
                                                          checked={values.bankAccounts
                                                            .map((e) => e._id)
                                                            .includes(
                                                              option._id
                                                            )}
                                                          onChange={(e) => {
                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              push({
                                                                _id: option._id,
                                                                Id: option.Id,
                                                                FullyQualifiedName:
                                                                  option.FullyQualifiedName,
                                                                AccountType:
                                                                  option.AccountType,
                                                              });
                                                            } else {
                                                              const index =
                                                                values.bankAccounts
                                                                  .map(
                                                                    function (
                                                                      e
                                                                    ) {
                                                                      return e._id;
                                                                    }
                                                                  )
                                                                  .indexOf(
                                                                    option._id
                                                                  );
                                                              remove(index);
                                                            }
                                                          }}
                                                        />
                                                        <span />
                                                        {`${option.AccountType} | ${option.FullyQualifiedName}`}
                                                      </label>
                                                    </li>
                                                  ))}
                                                </ul>
                                              )}
                                            />
                                          </CheckboxSection>
                                        </BottomSection>
                                      )}
                                    </MainContainer>
                                  </Col>
                                  <Col sm={9}>
                                    <AccountsDiv>
                                      <Row>
                                        {values.bankAccounts?.map((option) => (
                                          <Col sm={3}>
                                            <p>{option.FullyQualifiedName}</p>
                                          </Col>
                                        ))}
                                      </Row>
                                    </AccountsDiv>
                                  </Col>
                                </Row>
                              </PartionDiv> */}
                      </OuterDiv>
                    </Authorise>

                    <NavigationButtons>
                      <BasicButton
                        className="next-button"
                        type="submit"
                        width="110px"
                      >
                        Next
                      </BasicButton>
                      <BasicButton
                        className="back-button"
                        type="button"
                        width="110px"
                        onClick={() => {
                          // if (generalSettingsData?.isPaymentEnable) {

                          history.push(Route_URLs.setup_digital_payments);
                          // } else {
                          // history.push(Route_URLs.setup_general_settings)

                          // }
                        }}
                      >
                        Back
                      </BasicButton>
                    </NavigationButtons>
                  </Form>
                )}
              </Formik>
            </Page.Body>
          </Page>
        </PrivateLayout>
      ) : (
        <Loader />
      )}
    </>
  );
}
export default CashClassification;
