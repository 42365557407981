import { Modal,StyledModal } from "ui/Modal";
import SupplierDiscount from "./SupplierDiscount";
import { useProfile } from "queries/profile";


export default function SupplierDiscountModal({ show, onHide ,currentSelectedSupplier,addeddiscountData}) {
  const { data: userData, isLoading } = useProfile();
const userRole = userData?.role;
const userPermissions = userData?.permissions;
const userId = userData?._id;
  const supplierId = currentSelectedSupplier?.supplierId;
  return (
    <Modal show={show} centered onHide={onHide}>
      <div
        className="modal-content"
        style={{ width: "560px"}}
      >
      <StyledModal>
        <Modal.Header closeButton>
          <h1>Add Discount Programs For : {currentSelectedSupplier?.supplier_name}</h1>
        </Modal.Header>
        <Modal.Body style={{  padding: "9px" }}>
          <SupplierDiscount show={show} onHide={onHide} supplierId={supplierId} userId={userId} addeddiscountData={addeddiscountData}/>
        </Modal.Body>
      </StyledModal>
      </div>
    </Modal>
  );
}
