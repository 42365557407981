import styled, { css } from "styled-components";
import { Popover as BSPopover } from "react-bootstrap";

export const Popover = styled(BSPopover)`
  color: #808186;
  font-size: 13px;
  max-width: 350px;
  width: 350px;
  background-color: #232631;
  box-shadow: 0px 3px 46px #00000029;
  border: 1px solid #707070;
  .popover-body {
    padding: 0rem 1rem; 
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #000;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .popover-arrow {
    &:before {
      border-bottom-color: #707070;
    }
    &:after {
      border-bottom-color: #212529;
    }
  }
  .view-all {
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #ffffff;
    display: inline-block;
    margin: 10px;
    margin-left: 40%;
    text-decoration: none;
  }
  .styled-labels {
    text-align: left;
    font-family: Montserrat Alternates-semiBold;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #808186;
  }
  .or {
    font-family: Montserrat Alternates-semiBold;
    font-size: 10px;
    line-height: 25px;
    color: #ffffff;
    display: inline-block;
    margin-left: 2px;
  }
  .amount {
    width: 100%;
    border: 1px solid #707070;
    font-family: Montserrat Alternates-semiBold;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0px;
    color: #ffffff69;
    padding: 5px;
    background-color: #232631;
    &:focus {
      outline: none;
      ::placeholder {
        color: transparent;
      }
    }
  }
  .limit {
    width: 61px;
    height: 25px;
    border: 1px solid #707070;
    opacity: 1;
    background-color: #232631;
    margin: 10px;
    color: #808186;
  }
  .styled-top {
    text-align: left;
    font-family: Montserrat Alternates-semiBold;
    font-size: 11px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #808186;
    margin-top: 9px;
  }
  .styled-customer {
    text-align: right;
    font-family: Montserrat Alternates-semiBold;
    font-size: 11px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #808186;
    opacity: 1;
  }
  .link{  
  text-align: left;
  font-family: Montserrat Alternates;
  font-size: 11px;
  line-height: 25px;
  letter-spacing: 0px;
  color: #B5B5B5;
  opacity: 1; 
  cursor:pointer;
  &:hover{
    //text-decoration: underline;
  }}
  .filter-heading {
    font-family: Montserrat Alternates-semiBold;
    font-size: 11px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #808186;
    text-transform: capitalize;
    display: block;
  }
  select {
    border: 1px solid #707070;
    background-color: #232631;
    width: 100%;
    height: 25px;
    color: #ffffff69;
    margin-bottom: 10px;
    &:focus {
      outline: none;
      border: 1px solid #707070;
      background-color: #232631;
    }
  }
  .title {
    text-align: left;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 25px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
  .text {
    text-align: left;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
`;
