import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  RefreshIcon,
  ProfileIcon,
  LogoutIcon,
  IconGraph,
} from "assets/icons.js";
import SampleImage from "assets/placeholder-image.png";
import { Link } from "react-router-dom";
import moment from "moment";
import { OverlayTrigger } from "react-bootstrap";
import { Popover } from "ui/Popover";

const StyledNavBar = styled.nav`
  position: relative;
  width: 100%;
  background-color: #3a3b48;
  color: #ffffff;
  text-align: left;
  height: auto;
  overflow: auto;
  padding: 12px;
  margin: 15px 0;
  border-radius: 10px;

  li {
    text-decoration: none;
    list-style: none;
    float: right;
    font-size: 14px;
  }
  .refresh {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    font-family: Montserrat Alternates-Medium;
  }

  .profile-image {
    border-radius: 50%;
    height: 38px;
    width: 38px;
    margin-right: 15px;
  }

  .greetings {
    font-size: 18px;
    float: left;
    line-height: 22px;
    font-family: Montserrat Alternates-semiBold;
  }
  .logo-image {
    margin: 5px;
    height: 30px;
    width: 110px;
    border-radius: 5px;
    object-fit: contain;

    @media (min-width: 768px) and (max-width: 1024px) {
      width: 90px;
    }
  }
  .accounts-data {
    border-left: 1px solid #707070;
    padding: 5px 10px;
  }
`;
const Iconbackground = styled.div`
  height: 40px;
  width: 40px;
  background: #ffffff;
  box-shadow: 0px 3px 26px #00000017;
  border: 3px solid #5b5d6e;
  border-radius: 40px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  svg {
    font-size: 1.3em;
    margin-top: 8px;
  }
  .notification-icon {
    path {
      fill: #343540;
    }
  }
`;
const StyledRefreshIcon = styled.div`
  content: "${(props) => props.isloading}";
  display: inline-block;
  margin: 0px 10px;
  cursor: pointer;
  animation: ${(props) => props.isloading && "rotation 1s infinite linear"};
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
const StyledGarphIcon = styled.div`
  margin: 0px 10px;
`;

const DropdownContent = styled.div`
  display: block;
  color: white;
  text-align: left;
  background-color: #000000;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  right: 0;

  a {
    font-family: Montserrat Alternates-semiBold;
    font-weight: bold;
    font-size: 14px;
    line-height: 44px;
    font-style: normal;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: none;
    text-decoration: none;
    padding: 0 40px;
  }
`;

const NotificationDiv = styled.div`
  display: flex;
  border-bottom: 2px solid #707070;
  padding: 10px;
  .notifications-text {
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 20px;
    color: #909090;
    display: inline-block;
  }
  .flag {
    margin: 5px;
  }
`;
const ProfileDetails = styled.div`
  padding: 20px 15px;
  font-family: Montserrat Alternates-semiBold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: #909090;

  svg {
    margin-right: 10px;
    path {
      fill: white;
      stroke: white;
    }
  }
  a {
    text-decoration: none;
    color: #909090;
  }
`;

const LockOverlay = styled.div`
  top: 0;
  left: 0;
  background: transparent;
  cursor: not-allowed;
  width: 95%;
  display: block;
  position: absolute;
  height: 63px;
  z-index: 9999999;
`;

const BackStyledButton = styled.button`
  background: transparent linear-gradient(270deg, #33c2df 0%, #0081c5 100%);
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  text-align: left;
  font-family: Montserrat Alternates-semiBold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 12px;
`;

const ProfileDetailsPopover = (onLogout) => (
  <Popover id="popover-basic">
    <Popover.Body>
      <ProfileDetails onClick={onLogout}>
        <LogoutIcon font-size="20px" />
        <span className="profile-details">Logout as Super Admin</span>
      </ProfileDetails>
    </Popover.Body>
  </Popover>
);

const ProfileDetailsDropdown = () => {
  const onLogout = async () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      window.location.pathname = "/admin/login";
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };
  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={ProfileDetailsPopover(onLogout)}
      >
        <img alt="profile" className="profile-image" src={SampleImage} />
      </OverlayTrigger>
    </>
  );
};

const PrimaryPrivateNavbar = () => {
  return (
    <StyledNavBar>
      <li className="refresh">
        <>
          <span className="accounts" style={{ marginRight: "10px" }}>
            Last login : {moment().format("LLL")}
          </span>
        </>
        <ProfileDetailsDropdown
          bucketUrl={SampleImage}
          isLoadingUserData={false}
        />
      </li>
    </StyledNavBar>
  );
};
export default PrimaryPrivateNavbar;
