import React from "react";
import { Modal } from "ui/Modal";
import { StyledModal } from ".";
import SupplierForm from "./SupplierForm";

export default function ManageSetting({ show, onHide }) {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered enforceFocus={false}>
      <StyledModal>
        <Modal.Header closeButton>
          <h1>Settings</h1>
        </Modal.Header>
        <Modal.Body>
          <SupplierForm />
        </Modal.Body>
      </StyledModal>
    </Modal>
  );
}
