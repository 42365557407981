import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { DeActivateIcon, EditIcon } from "assets/icons";
import Filter from "ui/FilterUser";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import Table from "./table";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import moment from "moment";

const TopSection = styled.div`
  .download {
    text-decoration: underline;
    font-family: Montserrat Alternates;
    font-size: 12px;
    line-height: 15px;
    color: #909090;
    cursor: pointer;
    margin: "10px";
    padding: "10px";
  }
`;

const StyledTable = styled.div`
  .table-wrapper {
    padding: 25px 0px;
  }
`;

const StyledFilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;
const StyledParagraph = styled.p`
  letter-spacing: 0px;
  color: rgb(144, 144, 144);
  font-family: "Montserrat Alternates-Bold";
  font-size: 24px;
  line-height: 22px;
`;

export default function UserListingPerformance(props) {
  const [page, setPage] = useState(0);
  const [initialPage, setinitialPage] = useState(0);
  const [filterData, setFilterData] = useState({
    username: "",
    statusArray: [],
    roleArray: [],
    businessArray: [],
    sortOn: "createdAt",
    sortOrder: "desc",
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "First name",
        accessor: "givenName",
        id: "givenName",
        maxWidth: 100,
        disableSortBy: true,
        Cell: (props) => <Capitalize>{props.row.values.givenName}</Capitalize>,
      },
      {
        Header: "Mobile",
        accessor: "phoneNumber",
        id: "phoneNumber",
        maxWidth: 100,
        disableSortBy: true,
      },
      {
        Header: "Email",
        accessor: "email",
        id: "email",
        maxWidth: 150,
        disableSortBy: true,
      },
      {
        Header: "User Permission",
        accessor: "role",
        id: "role",
        maxWidth: 80,
        disableSortBy: true,
        Cell: (props) => (
          <>
            <Capitalize>
              {props.row.values.role == "simplicollect"
                ? "SimpliCollect"
                : props.row.values.role == "simplipay"
                ? "SimpliPay"
                : props.row.values.role == "SimpliCollect&Simplipay"
                ? "SimpliCollect and SimpliPay"
                : props.row.values.role}
            </Capitalize>
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        id: "status",
        maxWidth: 80,
        disableSortBy: true,
        Cell: (props) =>
          props.row.values.status === "active" ? (
            <Capitalize style={{ color: "#00CE7F" }}>
              {props.row.values.status}
            </Capitalize>
          ) : (
            <Capitalize>In Active</Capitalize>
          ),
      },
      {
        Header: "Last Activity",
        accessor: "lastActivity",
        id: "lastActivity",
        disableSortBy: true,
        maxWidth: 100,
        Cell: (props) => (
          <Capitalize>
            {moment(props.row.values?.lastActivity).format("YYYY-MM-DD HH:ss")}
          </Capitalize>
        ),
      },
      {
        Header: "Action",
        id: "action",
        disableSortBy: true,
        maxWidth: 100,
        Cell: (props) => (
          <>
            <DeActivateIcon
              style={{ width: "15px", marginRight: "5px" }}
              font-size="18px"
              onClick={() => {}}
            />
            Delete
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <ErrorHandlingComponent errors={""} />
      <TopSection>
        <Row>
          <Col>
            <StyledParagraph>Tenant Users</StyledParagraph>
          </Col>
        </Row>
      </TopSection>

      <Row>
        <Col>
          <StyledTable>
            <Table
              isloading={props?.isLoading}
              data={props.data}
              columns={columns}
              totalCount={0}
              rowClick={() => {}}
              handlePageChange={() => {}}
              initialPage={0}
              onHeaderClick={() => {}}
            />
          </StyledTable>
        </Col>
      </Row>
    </>
  );
}
