import styled from "styled-components";
import React from "react";

const StyledPage = styled.div`
  position: relative;
  width: 100%;
  margin-top:-20px;

  margin-top:-41px;

  .page-header {
    margin: 41px 0;
    /* margin: 37px 0; */
  }

  .page-title {
    font-family: Montserrat Alternates-Bold;
    color: #909090;
    font-size: 24px;
    line-height: 29px;
    font-style: normal;
    margin-bottom: 0;
    display: inline-block;
  }
  .page-body{
    margin-top:-29px;
  }
  .page-footer{
    margin-left: -15px;
  }
`;

const Page = ({ children }) => {
  const header = React.Children.map(children, (child) =>
    child.type.displayName === "Header" ? child : null
  );
  const body = React.Children.map(children, (child) =>
    child.type.displayName === "Body" ? child : null
  );
  const footer = React.Children.map(children, (child) =>
    child.type.displayName === "Footer" ? child : null
  );
  return (
    <StyledPage className="page">
      <div className="page-header">{header}</div>
      <div className="page-body">{body}</div>
      <div className="page-footer">{footer}</div>
    </StyledPage>
  );
};

const Header = ({ children }) => children;
Header.displayName = "Header";
Page.Header = Header;

const Body = ({ children }) => children;
Body.displayName = "Body";
Page.Body = Body;

const Footer = ({ children }) => children;
Footer.displayName = "Footer";
Page.Footer = Footer;

export default Page;
