import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { DeActivateIcon, DeleteIcon, LoginIcon } from "assets/icons";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import Table from "ui/table";
import { useHistory } from "react-router-dom";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import Page from "ui/Page";
import PrivateLayout from "layouts/operations-ui/privateLayout";
import { useGetTenantsForLogin } from "queries/operations";
import operationsService from "services/operations.service";
import SelectWithVirtualized from "./SelectWithVirtualized";
import { useGetUsers } from "queries/operations";
import { useQueryClient } from "react-query";
import moment from "moment";

const DashboardWrapper = styled.div`
  .link-to-pages {
    text-decoration: none;
  }
`;

const TopSection = styled.div`
  .download {
    text-decoration: underline;
    font-family: Montserrat Alternates;
    font-size: 12px;
    line-height: 15px;
    color: #909090;
    cursor: pointer;
  }
`;

const Counter = styled.div`
  background-color: #33c2df;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  display: inline-block;
  color: white;
  position: relative;
  top: 5px;
`;

const StyledTable = styled.div`
  .table-wrapper {
    padding: 0px 0px;
    min-height: 0px;
  }
`;

const StyledFilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Import = styled.div`
  width: 100px;
  height: 40px;
  background: #3a3b48 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  font-family: Montserrat Alternates-semiBold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 12px;
  /* margin: 0px 10px; */
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;
const StyledCard = styled.div``;

const StyledSelect = styled.div`
  .react-select-container .react-select__control {
    background: transparent;
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #ffffff;
    border-radius: 0px;
    color: #909091;
    height: 29px;
    padding: 1px;
    width: 100%;
  }
  .react-select-container .react-select__menu-list {
    background: #484a58 0% 0% no-repeat padding-box;
  }
  .react-select-container .react-select__option {
    color: #b5b5b5;
    border: 1px solid #ffffff;
    height: 13px;
    background: #3a3b48 0% 0% no-repeat padding-box;
    &:hover {
      background: #484a58 0% 0% no-repeat padding-box;
    }
  }
  .react-select-container .react-select__multi-value {
    background: #484a58 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 69px;
    height: 19px;
  }
  .react-select-container .react-select__multi-value__label {
    color: #b5b5b5;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 25px;
    align-items: center;
    border-radius: 8px;
    display: flex;
    padding-left: 8px;
    padding-right: 4px;
  }
  .react-select-container .react-select__input-container {
    color: #b5b5b5;
  }
  .react-select-container .react-select__multi-value__remove {
    background: #484a58 0% 0% no-repeat padding-box;
    &:hover {
      background: #484a58 0% 0% no-repeat padding-box;
      color: #b5b5b5;
    }
  }
  .react-select-container .react-select__indicators {
    display: none;
  }
  .react-select-container .react-select__placeholder {
    display: none;
  }
  .react-select-container .react-select__value-container {
    padding: 0px;
    position: absolute;
  }
`;

export default function UserListing() {
  const queryClient = useQueryClient();
  const [filterData, setFilterData] = useState({
    username: "",
    statusArray: [],
    roleArray: [],
    businessArray: [],
    sortOn: "createdAt",
    sortOrder: "desc",
  });
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const { data: tenants, isLoading: isloadingTenant } = useGetTenantsForLogin(
    {}
  );
  const tableData = tenants?.length > 0 ? tenants : [];

  const performAction = async (e, tenantName) => {
    console.log(e.value);
    if (e.value === "clear") e.value = "";
    let response = await operationsService.addTestPoc(tenantName, {
      testPoc: e.value,
    });
    queryClient.invalidateQueries("loginTenants");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Tenant",
        accessor: "tenantFullName",
        id: "tenant",
        maxWidth: 140,
      },
      {
        Header: "Last Activity",
        accessor: "lastActivity",
        id: "isActive",
        maxWidth: 100,
        Cell: (props) => (
          <span>
            {moment(props.row.original.lastActivity).format("YYYY/MM/DD HH:ss")}
          </span>
        ),
      },
      {
        Header: "Test POC",
        accessor: "testPoc",
        id: "testPoc",
        maxWidth: 100,
        Cell: (props) => (
          <SelectWithVirtualized
            options={props.row.original.users}
            value={{
              value: props.row.original?.pocDetails?.name,
              label: props.row.original?.pocDetails?.name,
            }}
            onChange={(e) => {
              performAction(e, props.row.original.tenantName);
            }}
            multiple={false}
            clearable={true}
            disabled={false}
          />
        ),
      },
      {
        Header: "Action",
        id: "action",
        disableSortBy: true,
        Cell: (props) =>
          props.row.original.isAnalyticsEnabled === true ? (
            <>
              <span
                className={"hover-cursor"}
                style={{ marginLeft: "25px" }}
                onClick={() => {
                  history.push(
                    `/admin/analytics/${props.row.original.tenantName}`
                  );
                }}
              >
                <LoginIcon
                  style={{ fill: "white", width: "20px", marginRight: "5px" }}
                  font-size="18px"
                />
                View Analytics
              </span>
              <span
                className={"hover-cursor"}
                style={{ marginLeft: "25px" }}
                onClick={() => {
                  history.push(`/admin/analytics?=${props.row.original}`);
                }}
              >
                <DeActivateIcon
                  style={{ width: "15px", marginRight: "5px" }}
                  font-size="18px"
                />
                Disable
              </span>
            </>
          ) : (
            <>
              <span style={{ marginLeft: "25px" }} className={"hover-cursor"}>
                Enable Analytics
              </span>
            </>
          ),
      },
    ],
    []
  );

  return (
    <>
      <DashboardWrapper>
        <PrivateLayout>
          <Page>
            <Page.Header>
              <Row>
                <Col sm={8}>
                  <h1 className="page-title">Analytics Dashboard</h1>
                </Col>
              </Row>
            </Page.Header>
            <Page.Body>
              <ErrorHandlingComponent errors={errorMessage} />
              <StyledCard
                className="card"
                style={{
                  background: "#3a3b48 0% 0% no-repeat",
                  minHeight: "400px",
                  marginBotton: "20px",
                }}
              >
                <div style={{ padding: "16px" }}>
                  {" "}
                  <TopSection>
                    <Row>
                      <Col>
                        {" "}
                        <p
                          className="HeadingText"
                          style={{
                            letterSpacing: "0px",
                            color: "#909090",
                            fontFamily: "Montserrat Alternates-Bold",
                            fontSize: "18px",
                            lineHeight: "22px",
                            marginBottom: "0px",
                          }}
                        >
                          Track tenant activities
                        </p>
                      </Col>
                    </Row>
                  </TopSection>
                </div>
                <div className="card-body">
                  <Row>
                    <Col className="table-wrapper">
                      <StyledTable>
                        <Table
                          isloading={isloadingTenant}
                          data={tableData}
                          columns={columns}
                          totalCount={10}
                          rowClick={() => {}}
                          handlePageChange={() => {}}
                          initialPage={1}
                          onHeaderClick={() => {}}
                        />
                      </StyledTable>
                    </Col>
                  </Row>
                </div>
              </StyledCard>
            </Page.Body>
          </Page>
        </PrivateLayout>
      </DashboardWrapper>
    </>
  );
}
