import React, { useState} from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import {
  IconStripe,
  UpIcon,
  ArrowDownIcon,
  IconPencilEdit,
  EditIcon,
} from "assets/icons";
import { BasicButton } from "ui/Buttons";
import Filter from "ui/FilterSupplier";
import "react-datepicker/dist/react-datepicker.css";
import Table, { StyledSubRows } from "ui/table";
import { useSuppliers } from "queries/suppliers";
import StripeModal from "./StripeModal";
import ManageDefault from "./ManageDefault";
import ManageSetting from "./ManageSetting";
import useDebounce from "hooks/useDebounce";
import { dummyData } from "./DummyDate";
import { Authorise } from "helpers/Authorization";
import SupplierDiscountModal from "./SupplierDiscountModal";
import { forFutureRelease } from "utils/Configs";
import { GetSupplierDiscount,StrategicAdd } from "mutations/adddiscount";
import { useRef } from "react";

const ExpandIcon = styled.div`
  display: flex;
  cursor: pointer;
  .icon {
    width: 8px;
    height: 6px;
    align-items: center;
    justify-content: center;
    path {
      fill: #33c2df;
    }
  }
`;
const TopSection = styled.div``;
const FilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledTable = styled.div`
  .table-wrapper {
    padding: 25px 0px;
  }
`;

const NavigationButtons = styled.div`
  .back-button {
    float: left;
    background: none;
    border: 2px solid #909090;
  }
  .next-button {
    float: right;
  }
  .skip-button {
    float: right;
    background: none;
    border: 2px solid #909090;
  }
  padding: 20px;
`;
const DiscountIcon = styled.div`
  path {
    fill: #33c2df;
  }
`;
export default function SupplierListing(props) {
  const [showManageSettings, setShowManageSettings] = useState(false);
  const hideModalManageSettings = () => setShowManageSettings(false);
  const [showDefault, setShowDefault] = useState(false);
  const hideModalDefault = () => setShowDefault(false);
  const [page, setPage] = useState(0);
  const [initialPage, setinitialPage] = useState(0);
  const [addeddiscountData, setaddeddiscountData] = useState(null);
  const [currentSelectedSupplier, setCurrentSelectSupplier] = useState(null);
  const addsupplierstrategic = StrategicAdd();
  const [filterData, setFilterData] = useState({
    supplierName: "",
    company: "",
    sortOn: "supplier",
    sortOrder: "asc",
  });
  const debouncedfilterData = useDebounce(filterData, 500);
  const [showStripe, setShowStripe] = useState(false);
  const hideModalStripe = () => setShowStripe(false);
  //discount modal
  const [showDiscountModal, setDiscountModal] = useState(false);

  const makeid = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };
  const onDiscountModalHide = async () => {
    setaddeddiscountData(null);
    setDiscountModal(false);
    handlePageChange(null);
    let value = makeid(3);
    filterData.toreload = value
    setFilterData({ ...filterData, ...filterData });
    setinitialPage(page);

  };
  let loader = useRef({});

  const { data: suppliers, isLoading: isLoadingSuppliers } = useSuppliers(
    page,
    debouncedfilterData
  );
  const tableData = suppliers ? suppliers.rows : [];
  const TotalRowsCount = suppliers ? suppliers.count : "";
  const SuppliersList = suppliers ? suppliers.suppliers : [];
  const rowClick = (row) => {
    return (null);  
  };
  const onHeaderClick = (column) => {
    filterData.sortOn = column.id;
    filterData.sortOrder = filterData.sortOrder === "desc" ? "asc" : "desc";
    setFilterData({ ...filterData, ...filterData });
  };

  const handlePageChange = (page) => {
    setPage(page);
    setinitialPage(page);
  };

  const updateTableData = (updatedFilterData) => {
    setPage(0);
    setinitialPage(0);
    setFilterData({ ...filterData, ...updatedFilterData });
  };

  const UseGetSupplierDiscount = GetSupplierDiscount();

  const apicallandopen = async (val) => {
    let body = {
      supplierid: val.supplierId
    }
    UseGetSupplierDiscount
      .mutateAsync({ body })
      .then((response) => {
        loader.current = {};
        if (response && response?.data) {
          setaddeddiscountData(response.data);
        }
        setDiscountModal(true);

      })
      .catch(function (error) {
        setDiscountModal(true);
       });

  };

    const handelCheckBox = (props, val) => {
 
    let value = makeid(3);

    let body = {
      supplierId: props.supplierId,
      strategic: val
    };

    addsupplierstrategic
      .mutateAsync({ body })
      .then((note) => {
   

        let flterdata = { ...filterData, toreload: value };
        

        setFilterData(flterdata);
      })
      .catch(function (error) { });
  };
  
  const data = React.useMemo(() => dummyData, []);
  const columns = React.useMemo(
    () => [
      {
        Header: "Supplier",
        accessor: "supplier",
        id: "supplier",
        Cell: ({ row, cell }) =>
          row.canExpand ? (
            <div
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 10}px`,
                },
              })}
            >
              {row.isExpanded ? (
                <ExpandIcon>
                  <div>
                    <UpIcon className="icon" />
                  </div>
                  {"  "}
                  <span style={{ paddingLeft: "5px" }}>{cell.value}</span>
                </ExpandIcon>
              ) : (
                <ExpandIcon>
                  <div>
                    <ArrowDownIcon className="icon" />
                  </div>
                  {"  "}
                  <span style={{ paddingLeft: "5px" }}>{cell.value}</span>
                </ExpandIcon>
              )}
            </div>
          ) : (
            <StyledSubRows depth={row.depth}>{cell.value}</StyledSubRows>
          ),
      },

      {
        Header: "Email",
        accessor: "email",
        id: "email",
         width: 300,
      },
      {
        Header: "City/State/Country",
        accessor: "address",
        id: "address",
         width: 300,

        Cell: (props) => (
          <>
            <div />
            {props.row.values.address.Line1},{props.row.values.address.City}
            <div />
          </>
        ),
      },
      {
        Header: "Company",
        accessor: "company",
        id: "company",
        width: 300,
      },
        {
        Header: "Strategic",
        accessor: "strategic",
        id: "strategic",
        width: 100,
        Cell: (props) => (
          <input
            type="checkbox"
            id="strategic"
            name="strategic"
            checked={props.row.original.strategic}
            onChange={(e) => {
              handelCheckBox(props.row.original, e.target.checked);
            }}
          />
        ),
      },
      {
        Header: "Discount Programs",
        id: "discountPrograms",
        // show:forFutureRelease,
        accessor: "discountPrograms",
        width: 100,

        disableSortBy: true,
        Cell: (props) => (
          <>
            <span>
              <div className="wrappericon">
              {props.row.values.discountPrograms && <span>{props.row.values.discountPrograms}</span>}

                <DiscountIcon>
                  <EditIcon
                    className="icon"
                    onClick={() => {
                      apicallandopen(props.row.original);
                      setCurrentSelectSupplier(props.row.original);
                      let temploader = {};
                      temploader[props.row.original.supplierId] = true;
                      loader.current = temploader;
                    }}
                    font-size="14px"
                  />
                </DiscountIcon>
              </div>
            </span>
            {loader?.current !== null &&
              Object.keys(loader.current).length > 0 &&
              loader?.current[props.row.original.supplierId] === true && (
                <div>
                  <div class="linear-activity">
                    <div class="indeterminate"></div>
                  </div>
                </div>
              )}
          </>
        ),
      },
      {
        id: "action",
        Header: "Action",
        show:forFutureRelease,
        width: 300,
        disableSortBy: true,
        Cell: (props) => (
          <>
            <Authorise
              allowedRoles={["admin"]}
              showUnAuthorisedComponenet={<></>}
            >
              <IconStripe
                onClick={() => {
                  setCurrentSelectSupplier(props.row.original);
                  setShowStripe(true);
                }}
                style={{ marginRight: "2px" }}
                font-size="16px"
              />
              <IconPencilEdit font-size="6px" />
              <span
                style={{
                  marginLeft: "10px",
                  color: "#33C2DF",
                  textDecoration: "underline",
                }}
                onClick={() => setShowManageSettings(true)}
              >
                Manage Settings
              </span>
            </Authorise>
          </>
        ),
      },
    ],
    [filterData,UseGetSupplierDiscount]
  );

  return (
    <>
      <TopSection>
        <Row>
          <Col sm={8}>
            <p className="HeadingText">
              Here are your Suppliers. In this section, you can choose to add
              approvers for outgoing payments. If you know your Supplier bank
              details, you can also set them up to receive digital payments
            </p>
          </Col>
          <Col sm={4}>
            <FilterSection>
              <Filter
                handleFilterData={updateTableData}
                SupplierData={SuppliersList}
              />

              {forFutureRelease && <Authorise
                allowedRoles={["admin"]}
                showUnAuthorisedComponenet={<></>}
              >
                <BasicButton
                  onClick={() => setShowDefault(true)}
                  type="button"
                  width="150px"
                >
                  Manage defaults
                </BasicButton>
              </Authorise> }
            </FilterSection>
          </Col>
        </Row>
      </TopSection>
      <Row>
        <Col>
          <StyledTable>
            <Table
              isloading={isLoadingSuppliers}
              data={tableData}
              columns={columns}
              totalCount={TotalRowsCount}
              rowClick={rowClick}
              handlePageChange={handlePageChange}
              onHeaderClick={onHeaderClick}
              initialPage={initialPage}
            />
          </StyledTable>
        </Col>
      </Row>
      {currentSelectedSupplier && (
        <StripeModal
          show={showStripe}
          onHide={hideModalStripe}
          currentSelectedSupplier={currentSelectedSupplier}
        />
      )}
      <ManageSetting
        show={showManageSettings}
        onHide={hideModalManageSettings}
      />
      <ManageDefault show={showDefault} onHide={hideModalDefault} />
      <SupplierDiscountModal
        currentSelectedSupplier={currentSelectedSupplier}
        show={showDiscountModal}
        onHide={onDiscountModalHide}
        addeddiscountData={addeddiscountData}
      />
    </>
  );
}
