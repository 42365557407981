import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import {
  Form,
  InputwithLabel,
  Formik,
  RadioBlue,
  ErrorMessage,
  Checkbox,
  PhoneInputField,
} from "ui/forms";

import "react-phone-number-input/style.css";
import * as Yup from "yup";
import { BasicButton } from "ui/Buttons";
import "react-datepicker/dist/react-datepicker.css";
import ToggleButtonComponent from "ui/ToggleButton";
import { withRouter, useHistory } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import Loader from "ui/Loader";
import ReactTooltip from "react-tooltip";
import { useDepartments } from "queries/departments";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import { useProfile } from "queries/profile";
import { forFutureRelease } from "utils/Configs";
import { updateMfa } from "services/user.service";

const StyledLabel = styled.span`
  font-family: Montserrat Alternates;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #909090;
  opacity: 1;
  margin-bottom: 8px;
  display: inline-block;
`;

const AccessOptionsDiv = styled.div`
  span {
    font-family: Montserrat Alternates-semiBold;
    font-size: 10px;
    line-height: 26px;
    color: #ffffff69;
    display: inline-block;
  }
  .checkbox {
    margin-left: 75%;
    margin-top: 2%;
  }
`;
const BusinessHierarchyDiv = styled.div`
  .scroll {
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #000;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  margin-bottom: 20px;
  .business {
    border-bottom: 1px solid #707070;
    padding: 3px;
    margin: 5px;
  }

  span {
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 25px;
    color: #b5b5b5;
    display: inline-block;
  }
  .checkbox {
    margin-left: 80%;
    margin-top: 2%;
  }

  .child-business {
    margin-left: 9px;
  }
`;

export default function UserForm({
  user,
  onSuccess,
  errorMessage,
  userRolesData,
}) {
  let defaultUser = {
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    access: "",
    status: "active",
    permissions: [],
    isMfaEnabled: false,
  };

  const [isEdit] = useState(user?._id ? true : false);
  // const [userStatus, setUserStatus] = useState("active");

  const { data: userData } = useProfile();
  const userRole = userData?.role;

  // useEffect(() => {
  //   setUserStatus(user?.status);
  // }, [user]);

  const getDomainName = (email) => {
    const first_split = email ? email.split("@")[1] : "";
    const second_split = first_split ? first_split.split(".") : "";
    return second_split[0];
  };
  const getDes = (value) => {
    if (userRolesData && userRolesData.length > 0) {
      for (let k in userRolesData) {
        if (userRolesData[k].name == value) {
          return `${userRolesData[k].description}`;
        }
      }
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <Formik
        initialValues={isEdit ? user : defaultUser}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().trim().required("Please enter First Name"),
          lastName: Yup.string().trim().required("Please enter Last Name"),
          mobile: Yup.string()
            .trim()
            .max(
              15,
              "Please input valid phone number. Your phone number is too long"
            ),
          isMfaEnabled: Yup.boolean(),
          access: Yup.string().required("Please select Type of access"),
          email: Yup.string()
            .email("Must be a valid email address")
            .max(100, "Email must be less than 100 characters")
            .required("Please enter valid email")
            .test(
              "DomainCheck",
              "User does not belong to the same organisation",
              (value) =>
                getDomainName(value) === sessionStorage.getItem("tenantName")
            ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSuccess(values).then(() => {
            setSubmitting(false);
          });
          resetForm();
        }}
        on
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          dirty,
          isValid,
        }) => (
          <Form className="mx-auto">
            {Object.values(errors).length > 0
              ? Object.values(errors).map((errorBody) => {
                  <ErrorHandlingComponent errors={errorBody} />;
                })
              : null}
            <Row>
              <Col>
                <InputwithLabel
                  placeholder="Enter First Name"
                  name="firstName"
                  type="text"
                  label="First Name *"
                />
              </Col>
              <Col>
                <InputwithLabel
                  placeholder="Enter Last Name"
                  name="lastName"
                  type="text"
                  label="Last Name *"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <PhoneInputField label="Mobile" name="mobile" />
              </Col>
              <Col>
                <InputwithLabel
                  placeholder="Enter Email Address"
                  name="email"
                  type="email"
                  label="Email Address *"
                  disabled={isEdit}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <StyledLabel>Type of Access *</StyledLabel>
                {userRole === "admin" && (
                  <RadioBlue
                    name="access"
                    options={userRolesData?.map((data) => {
                      return {
                        key: data.label,
                        value: data.name,
                        id: data.name,
                      };
                    })}
                  ></RadioBlue>
                )}
                <div style={{ position: "absolute", width: "80%" }}>
                  <p style={{ color: "#909090", fontSize: "11px" }}>
                    {getDes(values.access)}
                  </p>
                </div>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <StyledLabel>Disable User Access</StyledLabel>
                    <ToggleButtonComponent
                      name="status"
                      value={values.status == "active" ? false : true}
                      setToggle={(e) => {
                        if (e == false) setFieldValue("status", "active");
                        else setFieldValue("status", "inactive");
                      }}
                    />
                  </Col>
                  {user?._id && user?.phoneNumber !== "" ? (
                    <Col>
                      <StyledLabel
                        data-for="mfa"
                        data-tip="6 digit code will be sent on verified phone number for login"
                      >
                        Enable MFA
                      </StyledLabel>
                      <ToggleButtonComponent
                        name="isMfaEnabled"
                        value={values.isMfaEnabled}
                        setToggle={async (e) => {
                          console.log(e);
                          try {
                            let body = {
                              userPoolId: sessionStorage.getItem("userPoolId"),
                              username: user._id,
                              enableMFA: e,
                            };
                            let response = await updateMfa(body);
                            if (response.status === 200) {
                              if (e == true)
                                setFieldValue("isMfaEnabled", true);
                              else setFieldValue("isMfaEnabled", false);
                            }
                          } catch (ex) {
                            console.log(ex);
                          }
                        }}
                      />
                      <ReactTooltip
                        id="mfa"
                        place={"top"}
                        className="tooltip"
                      />
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>

            {/* <Row>
            <Col>
              {values.access === "read-write" ? (
                <>
                  <AccessOptionsDiv>
                    <Row>
                      <Col sm={6}>
                        <span>Settings : General settings</span>
                      </Col>
                      <Col sm={6}>
                        <div className="checkbox ">
                          <Checkbox
                            name="permissions"
                            value="SETTINGS_GS"
                            // onChange={handleChange1}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <span>Settings : User Management</span>
                      </Col>
                      <Col sm={6}>
                        <div className="checkbox">
                          <Checkbox name="permissions" value="SETTINGS_UM" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <span>Settings : Dispute Management.</span>
                      </Col>
                      <Col sm={6}>
                        <div className="checkbox">
                          <Checkbox name="permissions" value="SETTINGS_DM" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <span>Manual Override Data.</span>
                      </Col>
                      <Col sm={6}>
                        <div className="checkbox ">
                          <Checkbox name="permissions" value="MOD" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <span>Payment Approval.</span>
                      </Col>
                      <Col sm={6}>
                        <div className="checkbox ">
                          <Checkbox
                            name="permissions"
                            value="PAYMENT_APPROVAL"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <span>Dispute Follow up.</span>
                      </Col>
                      <Col sm={6}>
                        <div className="checkbox ">
                          <Checkbox
                            name="permissions"
                            value="DISPUTE_FOLLOW_UP"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <span>Send Customer Reminders.</span>
                      </Col>
                      <Col sm={6}>
                        <div className="checkbox ">
                          <Checkbox
                            name="permissions"
                            value="CUSTOMER_REMINDERS"
                          />
                        </div>
                      </Col>
                    </Row>
                  </AccessOptionsDiv>
                </>
              ) : (
                <></>
              )}
              {values.access === "read-write" ||
              values.access === "read-only" ? (
                <>
              
                  {forFutureRelease && (
                    <BusinessHierarchyDiv>
                      <div className="business mt-4">
                        <Row>
                          <Col sm={6}>
                            <span>Business Hierarchy</span>
                          </Col>
                          <Col sm={6}>
                            <div className="checkbox "></div>
                          </Col>
                        </Row>
                      </div>
                      <div className="scroll">
                        {isLoading ? (
                          <Loader />
                        ) : (
                          departments?.map((department, index) => {
                            return (
                              <Row className="business">
                                <Col sm={6}>
                                  <span className="px-2">
                                    {department.fullyQualifiedName}
                                  </span>
                                </Col>
                                <Col sm={6}>
                                  <div className="checkbox ">
                                    <Checkbox
                                      value={department.id}
                                      name="business"
                                     
                                      checked={values.business?.includes(
                                        department.id
                                      )}
                                      onChange={() => {
                                        const set = new Set(values.business);
                                        if (set.has(department.id)) {
                                          set.delete(department.id);
                                          department?.children?.forEach(
                                            (child) => {
                                              set.delete(child.id);
                                            }
                                          );
                                        } else {
                                          set.add(department.id);
                                          department?.children?.forEach(
                                            (child) => {
                                              set.add(child.id);
                                            }
                                          );
                                        }
                                        setFieldValue(
                                          "business",
                                          Array.from(set)
                                        );
                                      }}
                                    />
                                  </div>
                                </Col>

                                {department.children.map((childDepartment) => (
                                  <Row className="child-business">
                                    <Col sm={6}>
                                      <span className="px-2">
                                        {childDepartment.fullyQualifiedName}
                                      </span>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="checkbox ">
                                        <Checkbox
                                          value={childDepartment.id}
                                          name="business"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                ))}
                              </Row>
                            );
                          })
                        )}
                      </div>
                    </BusinessHierarchyDiv>
                  )}
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row> */}
            <BasicButton
              style={{ marginBottom: "30px", float: "right" }}
              type="submit"
              width="110px"
              disabled={!isValid}
              // onClick={() => setShow(false)}
              //onClick={handleSubmit}
            >
              {isEdit ? "Update" : "Save"}
            </BasicButton>
          </Form>
        )}
      </Formik>
    </div>
  );
}
