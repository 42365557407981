import axios from "utils/APIv2";
export const getSectors = async () => {
  const res = await axios.get(`/sectors`);
  return res.data;
};
export const getAllGeneralSettings = async () => {
  const res = await axios.get(`/general-settings`);
  return res.data;
};
export const setAllGeneralSettings = async (payload) => {
  return await axios.patch(`general-settings`, payload);
};
