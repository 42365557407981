import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { updateSupplier } from "services/supplier.services";

export const useUpdateSupplier = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ supplierId, values }) => updateSupplier(supplierId, values),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("suppliers");
      },
    }
  );
};
