import { getUsers } from "services/user.service";
import { useQuery  } from "react-query";

export const useUsers = (page, filterData) => {
  return useQuery(
    ["users", { page, filterData }],
    () => getUsers(page, filterData),
    { keepPreviousData: true, staleTime: 60 * 60 * 1000 }
  );
};
