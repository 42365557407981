import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { setOrnetSettings } from "services/ornet.services";

export const useOrnetSaveSettings = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => setOrnetSettings(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("ornetSettings");
    },
  });
};
