import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ToggleButton from "react-toggle-button";

export default function ToggleButtonComponent({ value, setToggle, ...props }) {
  // const [toggle, setToggle] = useState(false);
  return (
    <ToggleButton
      activeLabel={""}
      inactiveLabel={""}
      colors={{
        activeThumb: {
          base: "#33C2DF ",
        },
        inactiveThumb: {
          base: "#CBC8C8",
        },
          active: {
          base: "#292A33",
          hover: "#292A33",
        },
        inactive: {
          base: "#292A33",
          hover: "#292A33",
        },
      }}
      trackStyle={{
        height: 22,
      }}
      value={value}
      onToggle={(value) => {
        setToggle(!value);
      }}
    />
  );
}
