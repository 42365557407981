import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { OverlayTrigger } from "react-bootstrap";
import { BellIcon, IconFlag } from "assets/icons.js";
import { withRouter, useHistory, Link } from "react-router-dom";
import { Route_URLs } from "routesURLs";
import { Popover } from "ui/Popover";

const NotificationDiv = styled.div`
  display: flex;
  border-bottom: 2px solid #707070;
  padding: 10px;
  .notifications-text {
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 20px;
    color: #909090;
    display: inline-block;
  }
  .flag {
    margin: 5px;
  }
`;
const Iconbackground = styled.div`
  height: 40px;
  width: 40px;
  background: #ffffff;
  box-shadow: 0px 3px 26px #00000017;
  border: 3px solid #5b5d6e;
  border-radius: 40px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  svg {
    font-size: 1.3em;
    margin-top: 8px;
  }
  .notification-icon {
    path {
      fill: #343540;
    }
  }
`;
const Notificationspopover = (
  <Popover id="popover-basic">
    <Popover.Body>
      {[...Array(3)].map((i) => (
        <NotificationDiv>
          <div>
            <IconFlag font-size="20px" className="flag" />
          </div>
          <div>
            <span className="notifications-text">
              Dispute 232323 raised by Ramesh with category Dispute Category
              on IN 2323232
            </span>
            <span className="notifications-text">20 min ago </span>
          </div>
        </NotificationDiv>
      ))}
      <Link to={Route_URLs.notifications} className="view-all">
        View All
      </Link>
    </Popover.Body>
  </Popover>
);
export default function NotificationDropdown() {
 
  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={Notificationspopover}
      >
        <Iconbackground>
          <BellIcon className="notification-icon" />
        </Iconbackground>
      </OverlayTrigger>
    </>
  );
}
