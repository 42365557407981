import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useExpanded,
  useRowSelect,
} from "react-table";
import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { SortIcon } from "assets/icons.js";
import { Table as BSTable } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Loader from "./Loader";
import CustomScrollbars from "ui/scrollbar";
import Checkbox from "ui/Checkbox";
import Tooltip from "ui/Tooltip";
import { EditIcon, InfoIcon } from "assets/icons";

export const StyledSubRows = styled.span`
  content: "${(props) => props.depth}";
  padding-left: ${(props) =>
    (props.depth == 0 && "13px") ||
    (props.depth == 1 && "23px") ||
    `${props.depth * 11}px`};
`;
export const TableWrapper = styled.div`
  position: relative;
  background: #3a3b48 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  padding: 15px 25px;
  border-radius: 5px;
  min-height: 470px;
  .spinner-border.text-secondary {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .styledNoRecord {
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: inline-table;
    top: 251px;
  }
  input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 2px solid #707070;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    margin-top: 3px;
    background-repeat: no-repeat;
    &:after {
      content: "✔";
      color: transparent;
      position: absolute;
      top: -35%;
      right: 0%;
      font-size: 11px;
      padding: 2px;
    }
    &:checked {
      &:after {
        content: "✔";
        color: #33c2df;
        position: absolute;
        top: -35%;
        right: 0%;
        font-size: 11px;
        padding: 2px;
      }
    }
  }
`;
const StyledCheckboxHeader = styled.div`
  margin-top: 3px;
  input {
    background-color: transparent !important;
    border: 2px solid #ffffff !important;
  }
`;

const StyledLabel = styled.span`
  font-family: Montserrat Alternates;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #909090;
  opacity: 1;
  display: inline-block;
`;

export const StyledTable = styled(BSTable)`
  td {
    /* width: 72px; */
    height: 42px;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 25px;
    border-bottom: 1px solid gray;
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 9px;
    }
  }
  th {
    /* width: 72px; */
    height: 42px;
    text-align: center;
    font-family: Montserrat Alternates-semiBold;
    font-size: 11px;
    line-height: 25px;
    color: #ffffff;
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 9px;
    }
  }
  td:first-child {
    text-align: left;
    /* position: sticky;
    left: 0; */
  }
  th:first-child {
    text-align: left;
    /* position: sticky;
    left: 0; */
  }
  /* td:last-child {
    text-align: right;
  }
  th:last-child {
    text-align: right;
  } */
`;
export const TableHead = styled.thead`
  tr {
    background: transparent linear-gradient(90deg, #0081c5 0%, #31c0de 100%) 0%
      0%;
    border-bottom: underline;
    @media (min-width: 768px) and (max-width: 1024px) {
      th {
        background: #0081c5;
      }
    }
    &:hover {
      background: transparent linear-gradient(90deg, #0081c5 0%, #31c0de 100%)
        0% 0% no-repeat padding-box;
      color: #ffffff;
    }
  }
  svg {
    font-size: 8px;
    margin-left: 2px;
  }

  /* th:first-child {
    position: sticky;
    left: 0;
  } */
`;
export const StyledRow = styled.tr`
  color: #b5b5b5;
  cursor: pointer;
  &:hover {
    background-color: #636471;
    color: #ffffff;
  }
`;

export const PaginationWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Pagination = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  ul {
    margin: 10px;
  }

  & li {
    padding: 0.2rem;
    border-radius: 7px;
    padding-bottom: 0px !important;

    a {
      padding: 0.5rem;
    }
  }

  & .active {
    background-color: #33c2df;
  }
  .previous,
  .next {
    background-color: #373743;
  }
`;
const StyledNoRecord = styled.span`
  color: white;
`;
const StyledLoader = styled.div`
  /* font-size:1em; */
`;
const ScrollWindow = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background: #3a3b48;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }
`;
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);
function Table({
  isloading,
  editId,
  columns,
  data,
  rowClick,
  onHeaderClick,
  totalCount,
  handlePageChange,
  initialPage,
  isEdit,
  updateData,
  checkbox,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    gotoPage,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
        }),
        pageSize: 1000,
      },
      columns,
      data,
      manualSortBy: true,
      editId,
      isEdit,
      updateData,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      checkbox &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            width: 3,
            disableSortBy: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <StyledCheckboxHeader>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </StyledCheckboxHeader>
            ),
            Cell: ({ row }) => (
              <div onClick={(e) => e.stopPropagation()}>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  const Pagecount = Math.ceil(Math.abs(totalCount / 10));
  return (
    <>
      <TableWrapper className="table-wrapper">
        {isloading ? (
          <Loader />
        ) : (
          <>
            {/* <CustomScrollbars style={{ width: "100%", height: "100%" }}> */}
            <ScrollWindow>
              <StyledTable borderless {...getTableProps()}>
                <TableHead>
                  {headerGroups.map((headerGroup) => (
                    <StyledRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                            {
                              style: {
                                width: column.width,
                                minWidth: column.minWidth,
                                maxWidth: column.maxWidth,
                              },
                            }
                          )}
                          onClick={() =>
                            !column.disableSortBy && onHeaderClick(column)
                          }
                        >
                          {column.render("Header")}
                          {column?.Tooltip && (
                            <StyledLabel>
                              <InfoIcon
                                data-tip={column?.Tooltip}
                                data-for="info"
                                style={{ marginLeft: "5px" }}
                              />
                              <Tooltip place="right" id="info" />
                            </StyledLabel>
                          )}
                          {/* <span>{column.isSorted?(column.isSortedDesc ?<SortIcon/>:<SortIcon/>):" "}</span>  */}
                          {!column.disableSortBy && <span>{<SortIcon />}</span>}
                          {/* {<span>{<SortIcon />}</span>} */}
                        </th>
                      ))}
                    </StyledRow>
                  ))}
                </TableHead>

                <tbody {...getTableBodyProps()}>
                  {!isloading && data.length > 0 ? (
                    <>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          // <StyledRow {...row.getRowProps()} onClick={(e) => rowClick(row)}>
                          <StyledRow
                            {...row.getRowProps()}
                            onClick={(e) => rowClick(e, row, editId)}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.width,
                                      minWidth: cell.column.minWidth,
                                      maxWidth: cell.column.maxWidth,
                                    },
                                  })}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </StyledRow>
                        );
                      })}
                    </>
                  ) : (
                    <tr className="styledNoRecord">No Records Found</tr>
                  )}
                </tbody>
              </StyledTable>
            </ScrollWindow>
            {/* </CustomScrollbars> */}
            {!isloading && data.length > 0 ? (
              <PaginationWrapper>
                <Pagination>
                  <ReactPaginate
                    // initialPage={initialPage}
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(Pagecount)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={6}
                    // onPageChange={(_page) => gotoPage(_page.selected)}
                    onPageChange={(_page) => handlePageChange(_page.selected)}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    forcePage={initialPage}
                  />
                </Pagination>
              </PaginationWrapper>
            ) : (
              <></>
            )}
          </>
        )}
      </TableWrapper>
    </>
  );
}
export default Table;
