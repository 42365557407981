import BaseService from "./baseClass.services";
import moment from "moment";
import { apiURL } from "utils/base-url";
import axios from "utils/APIv2";
import FileSaver from "file-saver";

class EmailTemplateService extends BaseService {

  getAllEmailTemplates = async (filterData) => {
    const res = await this.axios.get(`/emailAlert/getAllEmailTemplates`);
    return res.data;
  };

  getAllEmailTemplatesUsingPageCountAndSkip = async (filterData) => {
    const res = await this.axios.get(`/emailAlert/getAllEmailTemplatesUsingPageCountAndSkip`,
      {
        params: filterData
      }
    );
    return res.data;
  };

  getFilteredRecords = async (filterData) => {
    const res = await this.axios.get(`/emailAlert/getFilteredRecords`,
      {
        params: filterData
      }
    );
    return res.data;
  };

  postCreateACutomerTemplate = async (payload) => {
    return await axios.post(
      `/emailAlert/createEmailTemplate`,
      payload
    );
  }

  postUpdateACutomerTemplate = async (payload) => {
    return await axios.post(
      `/emailAlert/updateEmailTemplate`,
      payload
    );
  }
  postDeleteACutomerTemplate = async (payload) => {
    return await axios.post(
      `/emailAlert/deleteEmailTemplate`,
      payload
    );
  }
  postAssignATemplateToCustomer = async (payload) => {
    return await axios.post(
      `/customers/assignEmailTemplate`,
      payload
    );
  }
  postAssignATemplateToAllCustomersOfCustomertype = async (payload) => {
    return await axios.post(
      `/customers/assignEmailTemplateByCustomerType`,
      payload
    );
  }
}
export default new EmailTemplateService();
