import React, { useState, useContext, useRef, useMemo } from "react";
import { Row, Col, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import {
  Form,
  InputwithLabel,
  Formik,
  TimePickerField,
  Checkbox,
} from "ui/forms";
import { IconMail, InfoIcon, ArrowDownIcon, UpIcon } from "assets/icons";
import Card from "ui/Card";
import { BasicButton } from "ui/Buttons";
import "react-datepicker/dist/react-datepicker.css";
import Table, { StyledSubRows } from "ui/table";
import { Modal } from "ui/Modal";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";
import { useHistory } from "react-router-dom";
import StepperComponent from "../Stepper";
import { useCustomers, GetallCustomerName } from "queries/customers";
import { useGeneralSettings } from "queries/generalSettings";
import { useAddGeneralSettings } from "mutations/addGeneralSettings";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import moment from "moment";
import { Route_URLs } from "routesURLs";
import { UIContext } from "context/UIContext";
import { useUpdateCustomer } from "mutations/updateCustomers";
import CustomerReminder from "components/reminders/CustomerReminder";
import useDebounce from "hooks/useDebounce";
import { Authorise } from "helpers/Authorization";
import Filter from "ui/FilterCustomer";
import { Popover } from "ui/Popover";
import { forFutureRelease } from "utils/Configs";
import { useEffect } from "react";
import { ManageSettingsDataAdd } from "mutations/adddiscount";
import { useGetAllEmailTemplates } from "queries/emailTemplates";
import {
  useAssignATemplateToCustomer,
  useAssignATemplateToAllCustomersOfCustomertype,
} from "mutations/emailTemplates";
import Select from "react-select";
import { getAllCustomerWithSameTempalteIdBoolean } from "services/customer.services";
import { SearchSection } from "screens/actuals/Styles";
import CustomerSearch from "filters/search/CustomerSearch";
import NumberFormat from "react-number-format";
import { useProfile } from "queries/profile";
import TableExpander from "ui/table-expander";

const StyledButton = styled.button`
  background-color: transparent;
  border: 1px solid #909090;
  border-radius: 5px;
  //width: 110px;
  height: 33px;
  opacity: 1;
  text-align: left;
  font-family: Montserrat Alternates-semiBold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #ffffff;
`;
const StyledModal = styled.div`
  .modal-header {
    padding: 1rem 1rem;
    border-bottom: 1px solid #707070;
    margin: 10px;
    .modal-body {
      padding: 0rem 1rem;
      padding-top: 0;
    }
    h1 {
      font-family: Montserrat Alternates-Bold;
      text-align: left;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }
  }
  /* input:not([type="checkbox"]) {
    height: 13px;
    width: 28px;
    margin: 0px 5px;
    background: #3a3b48;
    border: 1px solid #707070;
    font-family: Montserrat Alternates;
    color: #909090;
    &:focus {
      border: 1px solid #707070;
      outline: none;
      color: #3a3b48;
      background-color: #ffffff;
    }
  } */
  input:not([type="checkbox"]) {
    height: 13px !important;
    padding: 8px !important;
    border-radius: 0px !important;
    margin: 0px 5px !important;
    font-size: 9px !important;
  }
`;
const ModalText = styled.div`
  font-family: Montserrat Alternates-semiBold;
  font-size: 11px;
  line-height: 25px;
  color: #808186;
`;
const ModalSection = styled.div`
  border-bottom: 1px solid #707070;
  padding: 10px;
`;

const StyledCard = styled.div``;
const OuterDiv = styled.div`
  padding: 0px 20px;
  .HeadingText {
    letter-spacing: 0px;
    color: #909090;
    font-family: Montserrat Alternates-Bold;
    font-size: 14px;
    line-height: 18px;
  }
`;
const TopSection = styled.div``;
const FilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ExpandIcon = styled.div`
  display: flex;
  cursor: pointer;
  .icon {
    width: 8px;
    height: 6px;
    align-items: center;
    justify-content: center;
    path {
      fill: #33c2df;
    }
  }
`;
const StyledTable = styled.div`
  .table-wrapper {
    padding: 25px 0px;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: baseline;
  input[type="checkbox"] {
    margin-bottom: -3px;
    margin-right: 5px;
  }
`;
const NavigationButtons = styled.div`
  .back-button {
    float: left;
    background: none;
    border: 2px solid #909090;
  }
  .next-button {
    float: right;
  }
  .skip-button {
    float: right;
    background: none;
    border: 2px solid #909090;
  }
  padding: 20px;
`;
const StyleTimePicker = styled.div`
  .form-field {
    margin-bottom: 0px;
  }
  label {
    display: none;
  }
  input {
    width: 90px !important;
  }
`;

const StyledSelect = styled.div`
  .react-select-container .react-select__control {
    background: transparent;
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #ffffff;
    border-radius: 0px;
    color: #909091;
    height: 29px;
    padding: 1px;
    width: 100%;
  }
  .react-select-container .react-select__menu-list {
    background: #484a58 0% 0% no-repeat padding-box;
  }
  .react-select-container .react-select__option {
    color: #b5b5b5;
    border: 1px solid #ffffff;
    height: 13px;
    background: #3a3b48 0% 0% no-repeat padding-box;
    &:hover {
      background: #484a58 0% 0% no-repeat padding-box;
    }
  }
  .react-select-container .react-select__multi-value {
    background: #484a58 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 69px;
    height: 19px;
  }
  .react-select-container .react-select__multi-value__label {
    color: #b5b5b5;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 25px;
    align-items: center;
    border-radius: 8px;
    display: flex;
    padding-left: 8px;
    padding-right: 4px;
  }
  .react-select-container .react-select__input-container {
    color: #b5b5b5;
  }
  .react-select-container .react-select__multi-value__remove {
    background: #484a58 0% 0% no-repeat padding-box;
    &:hover {
      background: #484a58 0% 0% no-repeat padding-box;
      color: #b5b5b5;
    }
  }
  .react-select-container .react-select__indicators {
    display: none;
  }
  .react-select-container .react-select__placeholder {
    display: none;
  }
  .react-select-container .react-select__value-container {
    padding: 0px;
    position: absolute;
  }
`;

const customStyles = {
  control: (base) => ({
    ...base,
    background: "#232631 0% 0% no-repeat padding-box",
    borderRadius: 0,
    borderColor: "#ffffff69",
    boxShadow: null,
    minHeight: "40px",
    minWidth: "120px",
    height: "40px",
    "&:hover": {
      background: "#3a3b48 0% 0% no-repeat padding-box",
    },
    "&:focus": {
      background: "#3a3b48 0% 0% no-repeat padding-box",
    },
    "::-webkit-scrollbar": {
      width: "3px",
    },
  }),

  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: "8px",
    paddingLeft: 0,
  }),
  option: (base, { isFocused }) => ({
    ...base,
    color: "#ffffff69",
    boxShadow: "0px 3px 46px #00000029",
    border: "0px solid #FFFFFF",
    borderRadius: "0px",
    //color:"#FFFFFF",
    height: "40px",
    background: isFocused ? "#3a3b48" : "#232631 0% 0% no-repeat padding-box",
    display: "flex",
    alignItems: "center",
    fontSize: "11px !important",
    lineHeight: "15px",
  }),
  valueContainer: (base) => ({
    ...base,
    color: "#ffffff69",
    paddingTop: 0,
  }),
  placeholder: (base) => ({
    ...base,
    color: " #ffffff69",
    textAlign: "left",
    fontFamily: "Montserrat Alternates-semiBold",
    fontSize: "11px",
  }),
  input: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    color: "#ffffff69",
    textAlign: "left",
    fontFamily: "Montserrat Alternates-semiBold",
    fontSize: "11px",
  }),
  singleValue: (base) => ({
    ...base,
    color: "#ffffff69",
    textAlign: "left",
    fontFamily: "Montserrat Alternates-semiBold",
    fontSize: "11px",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    margin: 0,
    padding: 0,
    background: "#232631 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 46px #00000029",
    border: "1px solid #ffffff69",
    color: "#ffffff69",
    fontSize: "11px",
    lineHeight: "15px",
  }),
};

const popover = (
  customer,
  childFunc,
  handleReminderSettings,
  closeModal,
  childEmailCheck,
  parentRecord
) => {
  const initialValues = {
    onDueDate: customer?.preferences
      ? customer?.preferences?.manageSettings?.onDueDate
      : false,
    beforeDueDate: {
      isBeforeDueDate: customer?.preferences
        ? customer?.preferences?.manageSettings?.beforeDueDate?.isBeforeDueDate
        : false,
      days: customer?.preferences
        ? customer?.preferences?.manageSettings?.beforeDueDate?.days
        : 0,
    },
    afterDueDate: {
      isAfterDueDate: customer?.preferences
        ? customer?.preferences?.manageSettings?.afterDueDate?.isAfterDueDate
        : false,
      days: customer?.preferences
        ? customer?.preferences?.manageSettings?.afterDueDate?.days
        : 0,
    },
  };
  return (
    <Popover id="popover-basic">
      <Popover.Body>
        <CustomerReminder
          handleReminderSettings={handleReminderSettings}
          childFunc={childFunc}
          initialValues={initialValues}
          customer={customer}
          closeModal={closeModal}
          childEmailCheck={childEmailCheck}
          parentRecord={parentRecord}
        />
      </Popover.Body>
    </Popover>
  );
};

const AssignPopover = (
  customers,
  customer,
  childFunc,
  handleButtonClick,
  closeModal,
  advancedAssign
) => {
  const [assignToAll, setAssignToAll] = useState(customer.isAssignedToAllFlag);
  return (
    <Popover className="popover-template">
      <Popover.Body>
        {!customer.customerType ? (
          <div className="popover-template-div">
            <p style={{ textAlign: "center" }}>Customer type is missing.</p>
          </div>
        ) : customer?.preferences?.emailTemplateId ? (
          <div className="popover-template-div">
            <p style={{ fontSize: "14px" }}>Actions :</p>
            <div>
              <span className="popover-template-buttons">
                Assign to all {customer.customerType.toLowerCase()} types?
              </span>
              <input
                type="checkbox"
                id="AssignToAll"
                name="AssignToAll"
                checked={assignToAll}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setAssignToAll(true);
                    handleButtonClick(
                      "assign",
                      customer.customerType,
                      customer?.preferences.emailTemplateId
                    );
                  } else {
                    setAssignToAll(false);
                    handleButtonClick(
                      "remove",
                      customer.customerType,
                      customer?.preferences.emailTemplateId,
                      customer.Id
                    );
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <div className="popover-template-div">
            <p style={{ textAlign: "center" }}>Select a template first</p>
          </div>
        )}
      </Popover.Body>
    </Popover>
  );
};

function CustomerNotification(_props) {
  const { onBoarding } = useContext(UIContext);
  const childFunc = useRef(null);

  const { data: userData, isLoading } = useProfile();
  const userRole = userData?.role;
  let history = useHistory();
  const customerUpdate = useUpdateCustomer();
  const [errorMessage, setErrorMessage] = useState("");
  const [advancedAssign, setAdvancedAssign] = useState(true);
  const [page, setPage] = useState(0);
  const [initialPage, setinitialPage] = useState(0);
  const [childEmailCheck, setChildEmailCheck] = useState(false);
  const [parentRecord, setParentRecord] = useState(true);
  const [filterData, setFilterData] = useState({
    customerName: "",
    company: "",
    location: "",
    sortOn: "customer",
    sortOrder: "asc",
    emailTemplateId: "",
    customerType: "",
    isOpenInvoiceCustomers: false,
  });

  const [showCustomerType, setShowCustomerType] = useState(true);
  const debouncedfilterData = useDebounce(filterData, 500);
  const { data: generalSettingsData } = useGeneralSettings();

  const AddGeneralSettings = useAddGeneralSettings();
  const dataAdd = ManageSettingsDataAdd();

  const FormattedUpdateFormData = (values) => {
    let formData = new FormData();
    const object = {
      onDueDate: values?.onDueDate,
      beforeDueDate: {
        isBeforeDueDate: values?.beforeDueDate?.isBeforeDueDate,
        days: values.beforeDueDate.days,
      },
      afterDueDate: {
        isAfterDueDate: values?.afterDueDate?.isAfterDueDate,
        days: values?.afterDueDate?.days,
        perodicDaily: values?.afterDueDate?.perodicDaily,
        periodicWeekly: values?.afterDueDate?.periodicWeekly,

        // selectFixedTime: values.afterDueDate.selectFixedTime,
        // selectFixedDayTime: values.afterDueDate.selectFixedDayTime,
      },
    };

    let payload = {
      manageDefaults: object,
    };

    formData.append("data", JSON.stringify(payload));

    return formData;
  };
  const handleAddGeneralSettings = (values) => {
    const newFormData = FormattedUpdateFormData(values);
    AddGeneralSettings.mutateAsync(newFormData)
      .then((res) => {
        setErrorMessage(null);
        setShow(false);
      })
      .catch(function (error) {
        if (error.response) {
          setErrorMessage(error.response.data.message);
        }
      });
  };

  let {
    data: customers,
    isLoading: isLoadingCustomers,
    refetch: refetchCustomers,
  } = useCustomers(page, debouncedfilterData);
  const { data: allCustomerName, isLoading: isLoadingallCustomerName } =
    GetallCustomerName();

  const {
    data: emailData,
    isLoading: isLoadingTemplates,
    refetch: refetchEmailTemplates,
  } = useGetAllEmailTemplates();
  const [ddOptions, setDDOptions] = useState([]);

  const allCustomerNameArr = allCustomerName ? allCustomerName.customers : [];
  const allCustomerTypes = customers ? customers.customerTypes : [];

  let allEmailTemplatesName = emailData
    ? emailData.map((temp) => {
        return { name: temp.emailTemplateName, id: temp._id };
      })
    : [];

  // const data = React.useMemo(() => dummyData, [])
  const tableData = customers ? customers.customers : [];
  const TotalRowsCount = customers ? customers.length : 0;

  const [show, setShow] = useState(false);
  const hideModal = () => setShow(false);
  const [reloadTrigger, setReloadTrigger] = useState(0);

  const rowClick = (row) => {
    return null;
  };

  const onHeaderClick = (column) => {
    filterData.sortOn = column.id;
    filterData.sortOrder = filterData.sortOrder === "desc" ? "asc" : "desc";
    setFilterData({ ...filterData, ...filterData });
  };

  const updateTableData = (updatedFilterData) => {
    let customerName = "";
    if (updatedFilterData) {
      customerName = updatedFilterData.searchString;
      updatedFilterData["customerName"] = customerName;
      delete updatedFilterData.searchString;
    }
    setPage(0);
    setinitialPage(0);
    setFilterData({ ...filterData, ...updatedFilterData });
  };

  const handlePageChange = (_page) => {
    setPage(_page);
    setinitialPage(_page);
  };

  const handleReminderSettings = (customerId, values) => {
    customerUpdate
      .mutateAsync({ customerId, values })
      .then(() => {
        setErrorMessage(null);
      })
      .catch(function (error) {
        if (error.response) {
          setErrorMessage(error.response.data.message);
        }
      });
  };

  const closeModal = () => {
    document.body.click();
  };

  const assignATemplateToCustomer = useAssignATemplateToCustomer();
  const templateToAllCustomersOfCustomertype =
    useAssignATemplateToAllCustomersOfCustomertype();

  const selectedEmailTemplate = async (_id, Id) => {
    setAdvancedAssign(false);
    assignATemplateToCustomer
      .mutateAsync({ Id, _id })
      .then(async (res) => {
        const body = {
          _id: undefined,
          customer_id: Id,
          counterPartyId: Id,

          manageSettings: {
            onDueDate: false,
            beforeDueDate: {
              isBeforeDueDate: false,
              days: 1,
            },
            afterDueDate: {
              days: 1,
              isAfterDueDate: false,
            },
            customerLevel: {
              isOnSelectedDate: false,
              isOnSelectedDayOfWeek: false,
              weekdays: "monday",
              monthDate: 1,
            },
          },
        };
        // setReloadTrigger((prev) => prev + 1);
        await dataAdd
          .mutateAsync({ body })
          .then(async (res) => {
            if (res) {
              setReloadTrigger((prev) => prev + 1);
              setTimeout(() => {
                setAdvancedAssign(true);
              }, [2000]);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        // if (error.response) {
        //     setErrorMessage(error.response.data.message);
        // }
        console.log(error);
      });
  };

  useEffect(() => {
    if (emailData?.length) {
      setDDOptions([
        ...emailData.map((temp, pos) => {
          return {
            label: temp.emailTemplateName,
            val: temp._id,
            id: temp._id,
          };
        }),
      ]);
    }
  }, [emailData]);

  const handleButtonClick = async (type, customerType, emailTemplateId) => {
    setAdvancedAssign(false);
    // setReloadTrigger((prev) => prev + 1);
    templateToAllCustomersOfCustomertype
      .mutateAsync({ type, customerType, emailTemplateId })
      .then(async (res) => {
        setReloadTrigger((prev) => prev + 1);
        setTimeout(() => {
          setAdvancedAssign(true);
        }, [2000]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "toggle-icon",
        id: "toggle-icon",
        disableSortBy: true,
      },
      {
        Header: "Customer",
        accessor: "customer",
        id: "customer",
      },
      {
        Header: "Email",
        accessor: "email",
        id: "email",
      },
      {
        Header: "OutStanding",
        id: "outstanding",
        accessor: (d) => {
          return (
            <NumberFormat
              thousandSeparator={true}
              prefix={"$"}
              value={Number(d.outstanding).toFixed(2)}
              displayType="text"
              isNumericString={true}
              decimalScale={2}
            />
          );
        },
      },
      {
        Header: "City/State/Country",
        accessor: "location",
        id: "location",
      },
      {
        Header: "Type",
        accessor: "customerType",
        id: "customerType",
      },
      {
        id: "Assign Template",
        Header: "Assign Template",
        width: 180,
        minwidth: 180,
        disableSortBy: true,
        Cell: ({ row, cell }) => {
          // Only render for parent rows
          if (row.depth === 0) {
            return (
              <StyledSelect>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="right_input">
                    <div className="right_input_inner">
                      <Select
                        defaultValue={
                          cell.row.original?.preferences?.emailTemplateId
                            ? ddOptions.find(
                                (elem) =>
                                  cell.row.original?.preferences
                                    ?.emailTemplateId === elem.id && elem.val
                              )
                            : null
                        }
                        options={ddOptions}
                        onChange={(selectedOption, actionMeta) => {
                          if (actionMeta.action !== "clear") {
                            selectedEmailTemplate(
                              selectedOption.id,
                              cell.row.original.Id
                            );
                          } else {
                            selectedEmailTemplate("", cell.row.original.Id);
                          }
                        }}
                        isClearable
                        styles={customStyles}
                      />
                    </div>
                    {advancedAssign ? (
                      <OverlayTrigger
                        trigger="click"
                        rootClose={true}
                        placement="bottom-end"
                        onExited={() => closeModal()}
                        overlay={AssignPopover(
                          customers,
                          cell.row.original,
                          childFunc,
                          handleButtonClick,
                          closeModal,
                          advancedAssign
                        )}
                      >
                        <p
                          style={{
                            cursor: "pointer",
                            marginRight: "10px",
                            textDecoration: "underline",
                          }}
                        >
                          More
                        </p>
                      </OverlayTrigger>
                    ) : (
                      <p
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        Loading ...
                      </p>
                    )}
                  </div>
                </div>
              </StyledSelect>
            );
          }
          // Return null for child rows
          return null;
        },
      },
      {
        id: "action",
        Header: "Action",
        width: 180,
        minwidth: 180,
        disableSortBy: true,
        Cell: (props) => (
          <>
            {forFutureRelease && (
              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedRoles={[
                  "admin",
                  "simplicollect",
                  "SimpliCollect&Simplipay",
                  "read-only",
                ]}
              >
                <IconMail font-size="18px" />
                <span
                  style={{
                    marginLeft: "10px",
                    color: "#33C2DF",
                    textDecoration: "underline",
                  }}
                >
                  Resend Invitation
                </span>
              </Authorise>
            )}

            <Authorise
              showUnAuthorisedComponenet={<></>}
              allowedRoles={[
                "admin",
                "simplicollect",
                "SimpliCollect&Simplipay",
                "read-only",
              ]}
            >
              <OverlayTrigger
                trigger="click"
                rootClose={true}
                placement="bottom-end"
                onExited={() => closeModal()}
                overlay={popover(
                  props.row.original,
                  childFunc,
                  handleReminderSettings,
                  closeModal,
                  childEmailCheck,
                  parentRecord
                )}
              >
                <span
                  style={{
                    marginLeft: "10px",
                    color: "#33C2DF",
                    textDecoration: "underline",
                  }}
                >
                  Manage Settings
                </span>
              </OverlayTrigger>
            </Authorise>
          </>
        ),
      },
    ],
    [ddOptions, customers, emailData, advancedAssign]
  );
  useEffect(() => {
    refetchCustomers();
    refetchEmailTemplates();
  }, [reloadTrigger]);

  return (
    <>
      <PrivateLayout>
        <Page>
          <Page.Header>
            <Row className="m-0">
              <Col className="p-0">
                {/* <StatusBarComponent /> */}
                <h1 className="page-title">
                  Customer Notifications and Reminders
                </h1>
                <StepperComponent currentPage={onBoarding ? 4 : 8} />
              </Col>
            </Row>
          </Page.Header>
          <Page.Body>
            <StyledCard>
              <Card>
                <Card.Body>
                  <OuterDiv>
                    <TopSection>
                      <Row>
                        <Col sm={8}>
                          {" "}
                          <p className="HeadingText">
                            Here are your Customers. Out here, you can setup
                            payment reminders that will be automatically sent
                            out by us. Just make sure your Customer contact
                            details are accurate and up-to-date!
                          </p>
                          {generalSettingsData?.sourceEmailAddress && (
                            <p className="HeadingText">
                              Emails will be sent from{" "}
                              {generalSettingsData?.sourceEmailAddress}. If you
                              need to change this email address, please contact
                              the SimpliCapital team.
                            </p>
                          )}
                        </Col>
                        <Col sm={2}>
                          <BasicButton
                            style={{ marginBottom: "30px", float: "right" }}
                            type="button"
                            width="130px"
                            onClick={() => {
                              history.push(Route_URLs.emailTemplatesList);
                            }}
                          >
                            Templates
                          </BasicButton>
                        </Col>
                        <Col sm={2}>
                          <FilterSection>
                            <Filter
                              handleFilterData={updateTableData}
                              CustomerData={allCustomerNameArr}
                              emailTemplates={allEmailTemplatesName}
                              customersTypes={allCustomerTypes}
                            />
                            {forFutureRelease && (
                              <Authorise
                                showUnAuthorisedComponenet={<></>}
                                allowedRoles={["admin"]}
                              >
                                {/* <BasicButton
                                onClick={() => setShow(true)}
                                type="button"
                                width="150px"
                              >
                                Manage defaults
                              </BasicButton>*/}
                              </Authorise>
                            )}
                          </FilterSection>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {""}
                          <SearchSection>
                            <CustomerSearch
                              handleFilterData={updateTableData}
                            />
                          </SearchSection>
                        </Col>
                      </Row>
                    </TopSection>
                    <Row>
                      <Col>
                        <StyledTable>
                          <TableExpander
                            isloading={isLoadingCustomers}
                            data={tableData}
                            columns={columns}
                            totalCount={TotalRowsCount}
                            rowClick={rowClick}
                            handlePageChange={handlePageChange}
                            onHeaderClick={onHeaderClick}
                            initialPage={initialPage}
                            handleReminderSettings={handleReminderSettings}
                            Popover={popover}
                            closeModal={closeModal}
                            filterData={filterData}
                            setChildEmailCheck={setChildEmailCheck}
                            childEmailCheck={childEmailCheck}
                          />
                        </StyledTable>
                      </Col>
                    </Row>
                  </OuterDiv>
                </Card.Body>
              </Card>
            </StyledCard>
            <Modal
              show={show}
              onHide={hideModal}
              height="346px"
              width="558px"
              centered
            >
              <StyledModal>
                <Modal.Header closeButton>
                  <h1>Manage defaults</h1>
                </Modal.Header>
                <Modal.Body>
                  <CustomerReminder
                    childFunc={childFunc}
                    handleReminderDefaults={handleAddGeneralSettings}
                    initialValues={{
                      onDueDate: generalSettingsData
                        ? generalSettingsData?.manageDefaults?.onDueDate
                        : false,
                      beforeDueDate: {
                        isBeforeDueDate: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.beforeDueDate
                              ?.isBeforeDueDate
                          : false,
                        days: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.beforeDueDate
                              ?.days
                          : 0,
                      },
                      afterDueDate: {
                        isAfterDueDate: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.isAfterDueDate
                          : false,
                        days: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.days
                          : 0,
                        perodicDaily: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.perodicDaily
                          : false,
                        periodicWeekly: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.periodicWeekly
                          : false,
                        selectFixedTime: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.selectFixedTime
                          : "",
                        selectFixedDayTime: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.selectFixedDayTime
                          : "",
                      },
                    }}
                  />
                  <Formik
                    initialValues={{
                      onDueDate: generalSettingsData
                        ? generalSettingsData?.manageDefaults?.onDueDate
                        : false,
                      beforeDueDate: {
                        isBeforeDueDate: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.beforeDueDate
                              ?.isBeforeDueDate
                          : false,
                        days: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.beforeDueDate
                              ?.days
                          : 0,
                      },
                      afterDueDate: {
                        isAfterDueDate: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.isAfterDueDate
                          : false,
                        days: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.days
                          : 0,
                        perodicDaily: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.perodicDaily
                          : false,
                        periodicWeekly: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.periodicWeekly
                          : false,
                        selectFixedTime: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.selectFixedTime
                          : "",
                        selectFixedDayTime: generalSettingsData
                          ? generalSettingsData?.manageDefaults?.afterDueDate
                              ?.selectFixedDayTime
                          : "",
                      },
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(true);
                      handleAddGeneralSettings(values);
                      setSubmitting(false);
                    }}
                  >
                    {({ values, handleSubmit }) => (
                      <Form onSubmit={handleSubmit} className="mx-auto">
                        <ErrorHandlingComponent errors={errorMessage} />
                        <ModalSection>
                          <ModalText>Automatic Approval Reminders</ModalText>
                          <CheckboxWrapper>
                            <Checkbox
                              label="Before the Due date."
                              name="beforeDueDate.isBeforeDueDate"
                            />
                            <InputwithLabel
                              style={{ width: "30px" }}
                              name="beforeDueDate.days"
                              type="number"
                            />

                            <ModalText>Days</ModalText>
                          </CheckboxWrapper>
                          <CheckboxWrapper>
                            <Checkbox label="On Due date." name="onDueDate" />
                          </CheckboxWrapper>
                          <ModalText>After Due Date</ModalText>
                          <div
                            style={{
                              margin: "0px 20px",
                            }}
                          >
                            <CheckboxWrapper>
                              <Checkbox
                                label=""
                                name="afterDueDate.isAfterDueDate"
                              />
                              <InputwithLabel
                                style={{ width: "30px" }}
                                name="afterDueDate.days"
                                type="number"
                              />
                              <ModalText>Days</ModalText>
                            </CheckboxWrapper>
                            <CheckboxWrapper>
                              <Checkbox
                                label="Periodic Daily"
                                name="afterDueDate.perodicDaily"
                              />
                              <StyleTimePicker>
                                <TimePickerField
                                  name="afterDueDate.selectFixedTime"
                                  defaultValue={
                                    values?.afterDueDate.selectFixedTime !== ""
                                      ? moment(
                                          values?.afterDueDate.selectFixedTime,
                                          "HH:mm"
                                        )
                                      : ""
                                  }
                                  placeholder="select fixed time"
                                />
                              </StyleTimePicker>
                            </CheckboxWrapper>
                            <CheckboxWrapper>
                              <Checkbox
                                label="Periodic Weekly"
                                name="afterDueDate.periodicWeekly"
                              />
                              <StyleTimePicker>
                                <TimePickerField
                                  name="afterDueDate.selectFixedDayTime"
                                  placeholder="select fixed Day-time"
                                  defaultValue={
                                    values?.afterDueDate.selectFixedDayTime !==
                                    ""
                                      ? moment(
                                          values?.afterDueDate
                                            .selectFixedDayTime,
                                          "HH:mm"
                                        )
                                      : ""
                                  }
                                />
                              </StyleTimePicker>
                            </CheckboxWrapper>
                          </div>
                        </ModalSection>
                        <ModalSection>
                          <ModalText>
                            <InfoIcon style={{ marginRight: "10px" }} />
                            If None Checked No Reminder
                          </ModalText>
                        </ModalSection>
                        <BasicButton
                          style={{ float: "right", margin: "20px" }}
                          type="submit"
                          width="110px"
                        >
                          Save
                        </BasicButton>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </StyledModal>
            </Modal>
            <NavigationButtons>
              {userRole !== "simplicollect" &&
                userRole !== "SimpliCollect&Simplipay" && (
                  <BasicButton
                    className="back-button"
                    type="button"
                    width="110px"
                    onClick={() =>
                      history.push(Route_URLs.setup_user_management)
                    }
                  >
                    Back
                  </BasicButton>
                )}

              <BasicButton
                className="next-button"
                type="button"
                width="110px"
                onClick={() =>
                  history.push(Route_URLs.setup_dispute_management)
                }
              >
                Next
              </BasicButton>
            </NavigationButtons>
          </Page.Body>
        </Page>
      </PrivateLayout>
    </>
  );
}
export default CustomerNotification;

<div class=" css-lexmyp-menu" id="react-select-25-listbox">
  <div class=" css-qr46ko">
    <div
      class=" css-dmzqi1-option"
      aria-disabled="false"
      id="react-select-25-option-0"
      tabindex="-1"
    >
      CS Upcoming Payments Due
    </div>
    <div
      class=" css-1wawcbp-option"
      aria-disabled="false"
      id="react-select-25-option-1"
      tabindex="-1"
    >
      CS Payments Due Today
    </div>
    <div
      class=" css-1wawcbp-option"
      aria-disabled="false"
      id="react-select-25-option-2"
      tabindex="-1"
    >
      SimpliCapital Default
    </div>
    <div
      class=" css-1wawcbp-option"
      aria-disabled="false"
      id="react-select-25-option-3"
      tabindex="-1"
    >
      CS Payments Past Due
    </div>
  </div>
</div>;
