import React, { useState } from "react";
import styled from "styled-components";
import { OverlayTrigger, Row, Col } from "react-bootstrap";
import { ArrowDownIcon } from "assets/icons.js";
import { ClearButton, Label, Popover, StyledButton } from "filters/Style";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DateRangePickerUI from "ui/DateRangePicker";

const StyleButtonGroup = styled(ButtonGroup)`
  padding: 0;
  width: 53%;
  //height: 25px;
`;
const StyleButton = styled(Button)`
  padding: 0;
  height: 25px;
  border: 1px solid #707070;
  opacity: 1;
  background-color: #232631;
  border-radius: 0;
  &:hover {
    background-color: black !important;
    border: 0;
  }
`;

const ButtonLabel = styled.div`
  text-align: left;
  font-family: Montserrat Alternates;
  font-size: 10px;
  line-height: 13px;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  color: #ffffff69;
  opacity: 1;
  padding-left: 10px;
`;
export default function TransactionFilter({ handleFilterData }) {
  const [invoiceno, setInvoiceno] = useState("");
  const [type, setType] = useState("");

  const [greaterno, setGreaterno] = useState("");
  const [lessno, setLessno] = useState("");
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInputDue, setFocusedInputDue] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState("");

  const handleResetFilter = () => {
    setInvoiceno("");
    setType("");
    setGreaterno("");
    setLessno("");
    setValue("");
    setStartDate(null);
    setEndDate(null);
    setTransactionStatus("")
    const FilterData = {
      invoiceNumber: "",
      transactionId: "",
      maxAmount: "",
      minAmount: "",
      TransactionDate: "",
      Type: "",
      TransactionStatus: ""
    };
    handleFilterData(FilterData);
  };


  const searchInvoice = (val) => {
    setInvoiceno(val)
    const FilterData = { invoiceNumber: val, pageNumber: 0 };
    handleFilterData(FilterData);
  };
  const searchType = (val) => {
    setType(val)
    const FilterData = { Type: val, pageNumber: 0 };
    handleFilterData(FilterData);
  }

  const searchTransactionStatus = (val) => {
    setTransactionStatus(val)
    const FilterData = { TransactionStatus: val, pageNumber: 0 };
    handleFilterData(FilterData);
  };


  const searchBygreaterthan = (val) => {
    setGreaterno(val)
    const FilterData = { minAmount: val, pageNumber: 0 };
    handleFilterData(FilterData);
  };


  const searchByLessthan = (val) => {
    setLessno(val)
    const FilterData = { maxAmount: val, pageNumber: 0 };
    handleFilterData(FilterData);
  };

  const handleDue = (startDate, endDate) => {
    if (startDate && endDate === "Invalid Date") {
      handleFilterData({ TransactionDate: "" });
    } else {
      handleFilterData({ TransactionDate: [startDate, endDate] });
    }
  };



  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        
        <Row className="m-0">
          <Col className="p-0">
            <Label>Search Invoice No</Label>
            <input
              className="amount"
              name="invoiceno"
              value={invoiceno}
              onChange={(e) => searchInvoice(e.target.value)}
              type="text"
              placeholder="Invoice No."
              maxLength="20"
            />
          </Col>
        </Row>
        <Row className="m-0">
          <Col className="p-0">
            <Label>Search Method</Label>
            <input
              className="amount"
              name="Type"
              value={type}
              onChange={(e) => searchType(e.target.value)}
              type="text"
              placeholder="Type"
              maxLength="20"
            />
          </Col>
        </Row>
        <Row className="m-0">
          <Col className="p-0">
            <Label>Search Status</Label>
            <input
              className="amount"
              name="TransactionStatus"
              value={transactionStatus}
              onChange={(e) => searchTransactionStatus(e.target.value)}
              type="text"
              placeholder="status"
              maxLength="20"
            />
          </Col>
        </Row>
       
        <Row className="m-0" style={{ paddingTop: "5px" }}>
          <Col className="p-0 ">
            <Label>Transaction date</Label>
            <DateRangePickerUI
              handleDate={handleDue}
              startDate={startDate}
              endDate={endDate}
              focusedInput={focusedInputDue}
              setFocusedInput={setFocusedInputDue}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Col>
        </Row>


        <Row className="m-0">
          <Col style={{ paddingTop: "10px", paddingLeft: 0 }}>
            <Label> Amount Greater than   </Label>

          </Col>

          <Col style={{ paddingTop: "10px", paddingLeft: "23px" }}>
            <Label>Less than</Label>

          </Col>
        </Row>

        <Row className="m-0">
          <Col className="p-0">
            <input
              className="amount"
              type="text"
              placeholder="Enter $ value"
              name="greaterno"
              value={greaterno}
              onChange={(e) => searchBygreaterthan(e.target.value)}
              maxLength="10"
            />
          </Col>
          <Col className="p-0 " sm={1}>
            <span
              className="or"
              style={{ marginLeft: "4px", marginRight: "2px" }}
            >
              {" "}
              OR{" "}
            </span>
          </Col>
          <Col className="p-0">
            <input
              className="amount"
              type="text"
              placeholder="Enter $ value"
              name="lessno"
              value={lessno}
              onChange={(e) => searchByLessthan(e.target.value)}
              maxLength="10"
            />
          </Col>
        </Row>
        <ClearButton onClick={handleResetFilter} style={{ marginTop: "10px" }}>
          Clear
        </ClearButton>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={popover}
      >
        <StyledButton>
          Filter
          <ArrowDownIcon />
        </StyledButton>
      </OverlayTrigger>
    </>
  );
}
