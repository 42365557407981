import React from 'react'
import { useEffect } from 'react'
import { Route_URLs } from 'routesURLs'
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from "react-router-dom";

const PaymentProcessing = () => {
  let history = useHistory();
  useEffect(() => {

    let transaction = sessionStorage.getItem('transactionId')
    if (transaction) {

      setTimeout(() => {
        history.push(Route_URLs.successPaymentPage)
      }, [30000])

    } else {

      history.push(Route_URLs.transactions_customer)
    }


  }, [])
  return (
    <div style={{
      backgroundColor: "#3a3b46",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}>     <span
      style={{
        fontSize: "25px",
        fontFamily: "Montserrat Alternates-Bold",
        color: "#fff"
      }}
    >
        Please wait while we process your payment  <Spinner animation="grow" size="sm" className="me-1" />
        <Spinner animation="grow" size="sm" className="me-1" />
        <Spinner animation="grow" size="sm" />
      </span> </div>
  )
}

export default PaymentProcessing