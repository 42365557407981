import CustomerSidebar from 'layouts/customer-ui/customerSidebar';
import React from 'react'
import styled from "styled-components";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import CustomerNavbar from 'layouts/customer-ui/CustomerNavbar';

import { useState } from 'react';
import Table from 'ui/table';
import useDebounce from 'hooks/useDebounce';
import { DownloadPdf, GetAllTransaction } from 'queries/customers';
import Tooltip from "ui/Tooltip";
import moment from "moment";
import NumberFormat from "react-number-format";
import { ImportIcon, Greentick, IconExpandArrow, ExportIcon, ReceivablesIcon } from "assets/icons.js";
import {
  Counter, TableSection,
} from 'screens/actuals/Styles';
import jwt_decode from "jwt-decode";
import { useEffect } from 'react';
import customersServices from 'services/customers.services';
import TransactionFilter from './TransactionFilter';
import { filter } from 'lodash';
import ReactTooltip from 'react-tooltip';
import downloadPdf from "assets/download.gif"

const PageStyles = styled.div``;

const StyledLoaderCustom = styled.div`
    .spinner-border{
        font-weight: 500;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        z-index: 50000;
    }
`;
const StyledTable = styled.div`
  table {
    th:nth-child(2) {
      text-align: center;
      padding-left: 0px;
    }
    th:first-child {
      padding-bottom: 8px;
    }
    th {
      padding-bottom: 13px;
    }
    /* th:first-child{
        text-align: left;
        padding-right:0px;
    }
    td:first-child{
        text-align: left;
        padding-right:0px;
    }  */
    td:nth-child(2) {
      text-align: center;
      padding-left: 0px;
    }
    tr {
      &:hover {
        input {
          border: 1px solid #ffffff !important;
        }
      }
    }
    input {
      background: transparent;
      border: 0;
      color: #b5b5b5;
      text-align: center;
      width: 79px;
    }
    /* DatePicker{ */
    /* input{
        background: transparent;
        border:0;
        color:#B5B5B5;
      } */
    /* } */
  }
`;

const PageContainer = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  .scroll-div {
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
    height: auto;
    background-color: #232631;
  }
`;
const ContentContainer = styled(Container)`
/* overflow: auto; */
max-width: 100%;
flex: 1;
`;



const StickyDiv = styled.div`
  position: relative;
`;

const SidebarContainer = styled.div`
  position: relative;
  height: 100%;
  /* width: auto;
  overflow: auto; */
  display: block;
  /* border: 5px solid green; */
  z-index: 50000;
`;
const FilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .icon-div {
    width: 51px;
    height: 40px;
    background: #3a3b48 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #707070;
    border-radius: 20px;
    display: inline-block;
    margin-left: 10px;
    padding: 5px;
    svg {
      margin-left: 10px;
      path {
        fill: #33c2df;
      }
    }
  }
`;

export default function Transactions({ param }) {
  const [radioFilter, setRadioFilter] = useState("1");
  const [paramval, setparamval] = useState({});
  const [initialPage, setinitialPage] = useState(0);
  const [transactionId, setTransactionId] = useState("")
  let tokenData = sessionStorage.getItem("userToken");
  let decodedData = jwt_decode(tokenData);

  const rowClick = (e, row, editId) => {
    return null;
  };

  const [filterData, setFilterData] = useState({
    limit: "",
    searchString: "",
    deliveryDueDate: "",
    minAmount: "",
    maxAmount: "",
    bucket: "",
    pageNumber: 0,
    order: "desc",
    columnName: "TransactionTimeStamp",
    customerId: decodedData.username,
    invoiceNumber: "",
    Type: ""

  });
  const user = {
    firstName: "Richard",
    lastName: "",
  };
  const handlePageChange = (page) => {
    filterData.pageNumber = page;
    setFilterData({ ...filterData, ...filterData });
    setinitialPage(page);
  };



  useEffect(() => {
    setFilterData({ ...filterData, card: param?.card });
  }, [JSON.stringify(param)]);

  const updateFilters = (updatedFilterData) => {


    if (updatedFilterData.searchString == "") {

      handlePageChange(0);
    }
    if (filterData.searchString == "" && updatedFilterData.searchString != "") {
      handlePageChange(0);
    }
    let newFilterState = { ...filterData, ...updatedFilterData };
    setFilterData(newFilterState);
  };
  const onHeaderClick = (column) => {
    filterData.columnName = column.id;
    filterData.order = filterData.order === "desc" ? "asc" : "desc";
    let updatedFilter = { ...filterData, ...filterData };

    setFilterData(updatedFilter);
  };


  const debouncedfilterData = useDebounce(filterData, 1000);
  const { data: transactionData, isLoading: isloading, refetch: refetchData } =
    GetAllTransaction(debouncedfilterData);
  useEffect(() => {
    refetchData()
  }, [refetchData])






  let transactiondata = transactionData
    ? transactionData.data.transactions
    : [];


  useEffect(() => {

  }, [transactionData, isloading])
  const totalcount = transactionData ? transactionData?.data?.totalRecords : "";
  const business = [
    {
      name: "Industry",
      fullyQualifiedName:
        "Business1:Business 2:Business 3:Business 4:Business 5",
    },
  ];

  const handleDownload = async (transactionId, CustomerId) => {


    const url = await customersServices.downloadPdf(transactionId, CustomerId);


    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);


  };

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  function isWithin30Seconds(time) {

    const inputTime = moment(time);


    const now = moment();


    const durationInSeconds = now.diff(inputTime, 'seconds');


    return Math.abs(durationInSeconds) <= 30;
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Transaction Id",
        accessor: "transactionId",
        id: "transactionId",
        width: 150,
        minWidth: 130,
        Cell: (props) => (
          <>
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>

                <span
                  style={{ marginRight: '5px' }}
                  onClick={(e) => {

                    handleDownload(props.row.original.transactionId, props.row.original.CustomerId);

                  }}
                >
                  {props.row.original.TransactionStatus === "APPROVED" || props.row.original.TransactionStatus === "PENDING" || props.row.original.TransactionStatus === "SUCCESS" || props.row.original.TransactionStatus === "UPDATE" ?
                    <>


{!isWithin30Seconds(props.row.original.TransactionTimeStamp) ?
                        <ExportIcon font-size="20px" style={{ marginRight: "10px" }} /> : <><div data-for="txn" data-tip='Generating Invoice'>
                          <img src={downloadPdf} alt="downloadPdf" height="20px" width="20px" />

                        </div>  <ReactTooltip id="txn" place={"top"} className="tooltip" /></>}






                    </>

                    : ""}



                </span>
                {props.row.original.transactionId}
              </div>
            </>

          </>
        ),
      },
      {
        Header: "Invoice Id / Reference No.",
        accessor: (d) => {
          console.log("value--->", d);
          return d; // Return the data object for proper rendering
        },
        id: "invoiceArray",
        width: 100,
        minWidth: 80,
        Cell: ({ value }) => {
          // Check if invoiceArray is present and has items
          if (value?.invoiceArray && value?.invoiceArray?.length > 0) {
            return (
              <div>
                {value.invoiceArray.map((id, index) => (
                  <div key={index}>{id}</div>
                ))}
              </div>
            );
          } else {
            // If invoiceArray is not present, show referenceNumber
            return <div>{value?.referenceNumber}</div>;
          }
        },
        disableSortBy: true,
      },
      {
        Header: "Payment Method",
        accessor: "Type",
        id: "Type",
        width: 110,
        minWidth: 80,
      },

      {
        Header: "Date",
        accessor: "TransactionTimeStamp",      
        id: "TransactionTimeStamp",
        width: 100,
        minWidth: 80,
        accessor: (d) => {
          return moment(d.TransactionTimeStamp).format("MM/DD/YYYY")
        },
      },
      {
        Header: "Amount",
        id: "TotalAmount",
        width: 150,
        minWidth: 100,
        accessor: (d) => {
          return (
            <NumberFormat
              thousandSeparator={true}
              prefix={"$"}
              value={parseFloat(d.TotalAmount).toFixed(2)}
              displayType="text"
              isNumericString={true}
              decimalScale={2}
            />
          );
        },
      },

      {
        Header: "Status",
        accessor: "TransactionStatus",
        id: "TransactionStatus",
        width: 100,
        minWidth: 80,

      },



    ], [time]);

  return (
    <div>
      <PageContainer>
        <SidebarContainer>
          <CustomerSidebar />
        </SidebarContainer>
        <ContentContainer>
          <StickyDiv

          >
            <Row>
              <Col>
                <CustomerNavbar name={`${user?.firstName} ${user?.lastName}`} />
              </Col>
            </Row>
          </StickyDiv>
          <Row style={{ display: "flex" }}>
            <h1 style={{ fontFamily: "Montserrat Alternates-Bold", color: "#909090", fontSize: "24px", fontStyle: "normal", marginBottom: "0" }}>Transactions</h1>



            <FilterSection style={{ position: "relative", bottom: "30px", margin: "4px -27px -40px" }}>


              <TransactionFilter handleFilterData={updateFilters} />

              <div className="icon-div">
              <a onClick={async()=>{
                  let link = await customersServices.getallcustomersDetails(filterData)
                  window.open(link,"_blank")
                }}>
                  <ReceivablesIcon data-tip="Export" data-for="export" />
                  <Tooltip id="export" place="top" />
                </a>
              </div>
            </FilterSection>
          </Row>
          <Row>
            <TableSection>
              <Col className="p-0">

                {transactiondata?.length >= 0 && <Table
                  data={transactiondata}
                  columns={columns}
                  onHeaderClick={onHeaderClick}

                  totalCount={totalcount}
                  handlePageChange={handlePageChange}
                  initialPage={initialPage}
                  rowClick={rowClick}

                  isloading={isloading}

                />}

              </Col>
            </TableSection>
          </Row>
        </ContentContainer>
      </PageContainer>
    </div>
  )
}

