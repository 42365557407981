import styled from "styled-components";
import { UpIcon, DownIcon } from "assets/icons";

const Up = styled.span`
  color: #ff0505;
  font-weight: bold;

  svg {
    font-size: 10px;
    margin-bottom: 2px;
    margin-left: 3px;
  }
`;

const Down = styled.span`
  color: #07ba00;
  font-weight: bold;
  svg {
    font-size: 10px;
    margin-bottom: 2px;
    margin-left: 3px;
  }
`;
function ChangeReverse(props) {
  if (props.value > 0) {
    return (
      <Up {...props}>
        + {props.value} %<UpIcon type="reverse" />
      </Up>
    );
  } else {
    return (
      <Down {...props}>
        {props.value} %
        <DownIcon type="reverse" />
      </Down>
    );
  }
}
export default ChangeReverse;
