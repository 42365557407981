import React, { useEffect, useState } from "react";
import { IconRefresh } from "assets/icons";
import { StatusBar } from ".";

export default function ProgressBar({ message, closeable }) {
  return (
    <>
      <StatusBar style={{ background: "##33c2df" }}>
        <IconRefresh style={{ marginRight: "10px" }} fontSize="17px" />
        <span>{message}</span>
        {closeable && <strong className="close">X</strong>}
      </StatusBar>
    </>
  );
}
