import React from "react";
import { useQueryClient, useMutation } from "react-query";
import EmailTemplateService from "services/emailTemplate.services";

export const useCreateACustomerTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => EmailTemplateService.postCreateACutomerTemplate(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("Created a customer template.");
    },
  });
};

export const useUpdateACustomerTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => EmailTemplateService.postUpdateACutomerTemplate(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("Updated customer template.");
    },
  });
};
export const useDeleteACustomerTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => EmailTemplateService.postDeleteACutomerTemplate(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("Updated customer template.");
    },
  });
};
export const useAssignATemplateToCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => EmailTemplateService.postAssignATemplateToCustomer(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("Template assigned to customer.");
    },
  });
};
export const useAssignATemplateToAllCustomersOfCustomertype = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => EmailTemplateService.postAssignATemplateToAllCustomersOfCustomertype(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("Template assigned to all customer types.");
    },
  });
};
