import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";
import Card from "ui/Card";
import { BasicButton } from "ui/Buttons";
import { useHistory } from "react-router-dom";
import { ExportIcon } from "assets/icons.js";
import { Route_URLs } from "routesURLs";
import { Authorise } from "helpers/Authorization";
import { UIContext } from "context/UIContext";

import StepperComponent from "../Stepper";

const StyledCard = styled.div`
  .card {
    background: #444551;
  }
`;
const OuterDiv = styled.div`
  padding: 50px 100px 50px 100px;
  .HeadingText {
    letter-spacing: 0px;
    color: #909090;
    font-family: Montserrat Alternates-Bold;
    font-size: 20px;
    line-height: 24px;
  }
`;

const NavigationButtons = styled.div`
  padding: 20px;
  .back-button {
    float: left;
    background: none;
    border: 2px solid #909090;
  }
  .next-button {
    float: right;
  }
`;

function DigitalPayments(props) {
  const { onBoarding } = useContext(UIContext);

  let history = useHistory();

  return (
    <>
      <PrivateLayout>
        <Page>
          <Page.Header>
            <Row className="m-0">
              <Col className="p-0">
                <h1 className="page-title">Digital Payments</h1>
                <StepperComponent currentPage={onBoarding ? 0 : 8} />
              </Col>
            </Row>
          </Page.Header>
          <Page.Body>
            <StyledCard>
              <Card className="digital_card">
                <Card.Body>
                  <Authorise allowedRoles={["admin"]}>
                    <OuterDiv style={{ display: "flex" }}>
                      <>
                        <div
                          style={{
                            border: "1px solid grey",
                            borderRadius: "6px",
                            margin: "10px",
                            padding: "10px",
                            width: "50%",
                            display: "ruby",
                          }}
                        >
                          <div style={{ padding: "10px", color: "#8F8F8F" }}>
                            <h5 style={{ fontWeight: "bold" }}>
                            New Account for Charge Cards and ACH
                            </h5>
                            If this is a new application and you’re applying for
                            ACH processing also, download, fill and sign this
                            document. This should then be uploaded as a
                            supporting document in your online application form
                            by clicking the link below.
                          </div>
                          <div style={{ width: "100%", textAlign: "center" }}>
                            <a
                              href="https://allassets.s3.amazonaws.com/digital-payment-pdf/SimpliCapital+%26+Nuvei+ACH+Processing.pdf"
                              target="_blank"
                            >
                              <ExportIcon
                                fill={"#fff"}
                                width="30px"
                                height="30px"
                              />
                            </a>
                            <BasicButton
                              style={{ width: "100px", margin: "10px 50px" }}
                              onClick={() =>
                                window.open(
                                  "https://webapp.nuvei.com/app/Xm4qjelVFg",
                                  "_blank"
                                )
                              }
                            >
                              Apply
                            </BasicButton>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "ruby",
                            border: "1px solid grey",
                            borderRadius: "6px",
                            margin: "10px",
                            padding: "10px",
                            width: "50%",
                          }}
                        >
                          <div
                            style={{
                              padding: "10px",
                              color: "#8F8F8F",
                              minHeight: "126px",
                            }}
                          >
                            <h5 style={{ fontWeight: "bold" }}>
                            Add ACH to an existing Charge Card Account
                            </h5>
                            If you have an existing account with us and want to
                            add ACH processing, download, fill and sign this
                            document. Once completed, please email this document
                            to <span style={{fontWeight:"bold"}}>ACHApplications@Nuvei.com</span>
                          </div>
                          <div style={{ width: "100%", textAlign: "center" }}>
                            <a
                              href="https://allassets.s3.amazonaws.com/digital-payment-pdf/SimpliCapital+%26+Nuvei+ACH+Processing.pdf"
                              target="_blank"
                            >
                              <ExportIcon
                                fill={"#fff"}
                                width="30px"
                                height="30px"
                              />
                            </a>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "ruby",
                            border: "1px solid grey",
                            borderRadius: "6px",
                            margin: "10px",
                            padding: "10px",
                            width: "50%",
                          }}
                        >
                          <div
                            style={{
                              padding: "10px",
                              color: "#8F8F8F",
                              minHeight: "126px",
                            }}
                          >
                            <h5 style={{ fontWeight: "bold" }}>New ACH Account Only</h5>
                            If this is a new application and you want to enable
                            ACH processing ONLY, download, fill and sign this
                            document. Once completed, please email this document
                            to <span style={{fontWeight:"bold"}}>ACHApplications@Nuvei.com</span>
                          </div>
                          <div style={{ width: "100%", textAlign: "center" }}>
                            <a
                              href="https://allassets.s3.amazonaws.com/digital-payment-pdf/SimpliCapital+%26+Nuvei+ACH+ONLY+Processing.pdf"
                              target="_blank"
                            >
                              <ExportIcon
                                fill={"#fff"}
                                width="30px"
                                height="30px"
                              />
                            </a>
                          </div>
                        </div>
                      </>
                    </OuterDiv>

                    <OuterDiv style={{ display: "flex" }}>
                      <>
                        <div
                          style={{
                            border: "1px solid grey",
                            borderRadius: "6px",
                            margin: "10px",
                            padding: "10px",
                            width: "50%",
                          }}
                        >
                          <div
                            style={{
                              padding: "10px",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "15px",
                              color: "#8F8F8F",
                            }}
                          >
                            Click here to download a guide of our ACH
                            underwriting guidelines.
                          </div>
                          <div
                            style={{
                              padding: "10px",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                            }}
                          >
                            <a
                              href="https://allassets.s3.amazonaws.com/digital-payment-pdf/ACH+Underwriting+Guidelines.pdf"
                              target="_blank"
                            >
                              <ExportIcon
                                fill={"#fff"}
                                width="30px"
                                height="30px"
                              />
                            </a>
                          </div>
                        </div>
                        <div
                          style={{
                            border: "1px solid grey",
                            borderRadius: "6px",
                            padding: "10px",
                            width: "50%",
                            margin: "10px",
                          }}
                        >
                          <div
                            style={{
                              padding: "10px",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "15px",
                              color: "#8F8F8F",
                            }}
                          >
                            Click here to download our ACH pricing schedule.
                          </div>
                          <div
                            style={{
                              padding: "10px",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                            }}
                          >
                            <a
                              href="https://allassets.s3.amazonaws.com/digital-payment-pdf/Merchant+Pricing+Schedule.pdf"
                              target="_blank"
                            >
                              <ExportIcon
                                fill={"#fff"}
                                width="30px"
                                height="30px"
                              />
                            </a>
                          </div>
                        </div>
                      </>
                    </OuterDiv>
                  </Authorise>
                </Card.Body>
              </Card>
            </StyledCard>
            <NavigationButtons>
              <BasicButton
                onClick={() =>
                  history.push(Route_URLs.setup_cash_classification)
                }
                className="next-button"
                type="button"
                width="110px"
              >
                Next
              </BasicButton>
              <BasicButton
                className="back-button"
                type="button"
                width="110px"
                onClick={() => history.push(Route_URLs.setup_general_settings)}
              >
                Back
              </BasicButton>
            </NavigationButtons>
          </Page.Body>
        </Page>
      </PrivateLayout>
    </>
  );
}
export default DigitalPayments;
