import React from "react";
import styled from "styled-components";
import CheckIcon from "assets/Icon-check.svg";
import Stepper from "react-stepper-horizontal";
import { useHistory } from "react-router-dom";
import { Route_URLs } from "routesURLs";
import { useGeneralSettings } from "queries/generalSettings";
import { useProfile } from "queries/profile";

const StyledStepper = styled.div`
  margin: 30px 0px;
  a {
    font-family: Montserrat Alternates-Bold;
    color: #909090 !important;
    cursor: pointer;
    line-height: 30px !important;
  }
`;

function StepperComponent(props) {
  let history = useHistory();

  const { data: userData, isLoading } = useProfile();
  const userRole = userData?.role;


  const stepsToKeepforCollect = ['Customer Settings', 'Dispute Management'];
  const stepsToKeepforSimpliPay = ['Supplier Settings'];
  const stepsToKeepforSimpliPayAndCollect = ['Customer Settings', 'Dispute Management', 'Supplier Settings'];

  let sections = [
    {
      title: "Let’s connect to your System",
      icon: CheckIcon,
      onClick: (e) => {
        e.preventDefault();
        history.push(Route_URLs.setup_connect_integration);
      },
    },
    {
      title: "General Settings",
      icon: CheckIcon,
      onClick: (e) => {
        e.preventDefault();
        history.push(Route_URLs.setup_general_settings);
      },
    },
    {
      title: "Digital Payments",
      icon: CheckIcon,
      onClick: (e) => {
        e.preventDefault();
        history.push(Route_URLs.setup_digital_payments);
      },
    },
    {
      title: "Cash Classification",
      icon: CheckIcon,
      onClick: (e) => {
        e.preventDefault();
        history.push(Route_URLs.setup_cash_classification);
      },
    },

    {
      title: "User Management",
      icon: CheckIcon,
      onClick: (e) => {
        e.preventDefault();
        history.push(Route_URLs.setup_user_management);
      },
    },

    {
      title: "Customer Settings",
      icon: CheckIcon,
      onClick: (e) => {
        e.preventDefault();
        history.push(Route_URLs.setup_customer_notification);
      },
    },
    {
      title: "Dispute Management",
      icon: CheckIcon,
      onClick: (e) => {
        e.preventDefault();
        history.push(Route_URLs.setup_dispute_management);
      },
    },
    {
      title: "Supplier Settings",
      icon: CheckIcon,
      onClick: (e) => {
        e.preventDefault();
        history.push(Route_URLs.setup_supplier_payment);
      },
    },
  ];

  if (userRole == 'simplicollect') {
    sections = sections.filter(item => stepsToKeepforCollect.includes(item.title));
  }
  if (userRole == 'simplipay') {
    sections = sections.filter(item => stepsToKeepforSimpliPay.includes(item.title));
  }

  if (userRole == 'SimpliCollect&Simplipay') {
    sections = sections.filter(item => stepsToKeepforSimpliPayAndCollect.includes(item.title));
  }



  return (
    <>
      <StyledStepper>
        {userRole && <Stepper
          steps={sections}
          activeStep={props.currentPage}
          completeBarColor="#00AC8B"
          activeColor="#33C2DF"
          completeColor="#00AC8B"
          defaultColor="#3A3B48"
          titleFontSize={12}
          activeTitleColor="#909090"
          defaultTitleColor="#909090"
          completeTitleColor="#909090"
        />}
      </StyledStepper>
    </>
  );
}
export default StepperComponent;
