import { useQuery } from "react-query";
import EmailTemplateService from "services/emailTemplate.services";

export const useGetAllEmailTemplates = (filterData, mo = false) => {
  return useQuery(
    ["email-templates", { filterData }],
    () => EmailTemplateService.getAllEmailTemplates(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );
};
